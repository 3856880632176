import { ReactComponent as PadlockClosedS } from '../../assets/padlockClosed.svg'
import { ReactComponent as PadlockOpenS } from '../../assets/padlockOpen.svg'
import { ReactComponent as Warning } from '../../assets/warningTriangle.svg'
import { ReactComponent as Ring } from '../../assets/ring.svg'
import { selectWarehouse } from '../Warehouses/warehousesSlice'
import { MAG_STATUS } from '../../utils/constants'
import { useParams } from 'react-router-dom'
import { useReduxSelector } from 'store'
import React from 'react'

interface MagStatusProps {
  warning?: boolean
}

const MagStatus: React.FC<MagStatusProps> = ({ warning = false }) => {
  const { id } = useParams<warehouse.PageParams>()
  const { status = MAG_STATUS.closed, hasOnlineAccess } = useReduxSelector(state => selectWarehouse(state, id))
  const isOpen = status === MAG_STATUS.opened

  return (
    <div className='menu__status'>
      <div className='line' />
      <div className='status__options'>
        {hasOnlineAccess ? <>
          <div className='status__option'>
            {isOpen ? <>
              <PadlockOpenS />
              <span>Otwarty</span>
            </> : <>
              <PadlockClosedS />
              <span>Zamkniety</span>
            </>}
          </div>

          <div className='status__option'>
            <div className={isOpen ? 'ring--cut' : ''}>
              <Ring />
            </div>
            <span>Alarm {isOpen ? 'wył.' : 'wł'}</span>
          </div>

          {warning && <div className='status__option'>
            <Warning />
            <span>Brak awarii</span>
          </div>}
        </> : <h4>Magazyn na kłódkę</h4>}
      </div>
    </div>
  )
}

export default MagStatus