import { selectWarehouse, updateWarehouse } from '../Warehouses/warehousesSlice'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import MagNumber from '../../components/MagNumber'
import Introduction from './Introduction'
import { useLayoutEffect } from 'react'
import Beginning from './Beginning'
import Photo from './Photo'
import End from './End'

const PAGES = {
  1: 'introduction',
  2: 'photo',
  3: 'end'
}

const FirstOpening = () => {
  const { push, replace } = useHistory()
  const { number, key } = useParams()
  const dispatch = useDispatch()

  const { address, wasOpen = false } = useSelector(state => selectWarehouse(state, number))
  const { street, estateNumber, localNumber, city } = address || {}
  const displayedAddress = `${street} ${estateNumber}${localNumber ? `/${localNumber}` : ''}, ${city}`

  useLayoutEffect(() => {
    if (wasOpen) replace(`/warehouses/${number}`)
  }, [number, replace, wasOpen])

  const next = path => {
    if (path) push(`/first/${number}/${path}`)
    else {
      dispatch(updateWarehouse({ id: number, values: { wasOpen: true } }))
      replace(`/warehouses/${number}`)
    }
  }

  const header = <div>
    <MagNumber number={number} />
    <div className='slide__address'>{displayedAddress}</div>
  </div>

  return (
    <main className='newWarehouse'>
      <section key={key} className={key ? '--animated' : ''}>
        <div className='slides'>
          {(() => {
            switch (key) {
              case PAGES[1]: return <Introduction header={header} next={() => next(PAGES[3])} />
              case PAGES[2]: return <Photo header={header} next={() => next(PAGES[3])} />
              case PAGES[3]: return <End next={() => next()} />
              default: return <Beginning id={number} name={number} next={() => next(PAGES[1])} />
            }
          })()}
        </div>
      </section>
    </main>
  )
}

export default FirstOpening