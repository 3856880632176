import { selectBasket } from '../containers/Basket/basketSlice'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const useMagPrice = () => {

  const { toPay } = useSelector(selectBasket)
  const [final, setFinal] = useState({
    amount: 0,
    price: 0
  })

  useEffect(() => {
    const values = Object.values(toPay)
    if (values.length > 0) {
      const newFinal = values.reduce((result, { to_pay, type }) => {
        result.price += Number(to_pay?.price_sum || 0)
        if (type === 'rent') {
          result.amount += 1
        }
        return result
      }, { price: 0, amount: 0 })
      setFinal({
        amount: newFinal.amount,
        price: newFinal.price.toFixed(2)
      })
    }
  }, [toPay])

  return final
}

export default useMagPrice