import usePaymentProcessing from './hooks/usePaymentProcessing'
import { ReactComponent as GPay } from '../../assets/GPay.svg'
import { selectBasketCheckout } from '../Basket/basketSlice'
import ArrowBtn from '../../components/buttons/ArrowBtn'
import useTranslation from '../../hooks/useTranslation'
import { LOCATION_STATES } from '../../utils/constants'
import BackBtn from '../../components/buttons/BackBtn'
import usePopupState from '../../hooks/usePopupState'
import { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import blikSrc from '../../assets/blik.png'
import Error from '../../components/Error'
import { useSelector } from 'react-redux'
import OptionsPopup from './OptionsPopup'
import Option from 'components/Option'
import BlikPopup from './BlikPopup'
import Summary from './Summary'
import './style.scss'

const gPay = <GPay />
const blik = <img
  src={blikSrc}
  alt='blik'
/>

const Payment = ({ price, action, primaryTheme = true }) => {
  const bottomRef = useRef(null)

  const { popup, pathname } = usePopupState()
  const checkout = useSelector(selectBasketCheckout)
  const [isLoading, setLoading] = useState(false)
  const [selected, select] = useState({
    methodId: ''
  })

  const { purchase, callback, generateBody } = usePaymentProcessing(
    selected,
    isLoading,
    loading => {
      setLoading(l => ({ ...l, processing: loading }))
    }
  )

  const { t } = useTranslation('payment')
  const { push } = useHistory()

  useEffect(() => {
    if (selected.methodId) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selected.methodId])

  return (
    <>
      {popup && <div>
        {popup.type === 'banks'
          ? <OptionsPopup
            options={checkout.payments.find(p => p.id === popup.methodId)?.options}
            select={id => {
              select({ methodId: popup.methodId, optionId: id })
            }}
          />
          : popup.type === 'blik'
            ? <BlikPopup
              getBody={code => generateBody(code)}
              callback={remotePaymentId => {
                callback(remotePaymentId ? { ...popup.payment, remotePaymentId } : undefined)
              }}
            />
            : null}
      </div>}

      <div className={`payment --${primaryTheme ? 'primary' : 'secondary'}`}>
        <div className='title'>
          <div>{t('payment')}</div>
          <div>{t('chooseOneOfOptions')}</div>
        </div>

        <div className='payment__main center'>
          <div>
            <Summary price={price} />
            <p>{t('methodsAvailable')}</p>

            <div
              className='payment__methods'
              style={isLoading ? { opacity: 0.5 } : undefined}>
              {checkout?.payments ? checkout.payments.map(({ id, key, name, description, isBlocked, options }) => {
                if (key === 'applepay' && !window.ApplePaySession) return null
                const isSelected = selected.methodId === id
                const option = (options && isSelected)
                  ? options.find(o => o.id === selected.optionId)
                  : undefined
                const icon = option ? <img src={option.icon} alt='bankIcon' />
                  : key === 'blik' ? blik
                    : key === 'gpay' ? gPay
                      : undefined

                return <Option
                  key={id}
                  icon={icon}
                  title={name}
                  selected={isSelected}
                  desc={option ? option.name : description}
                  changeIconColor={false}
                  action={() => {
                    if (isBlocked || isLoading) return
                    if (options) {
                      push(pathname, LOCATION_STATES.popup({ type: 'banks', methodId: id }))
                    } else {
                      select({ methodId: id })
                    }
                  }} />
              }) : <Error />}
            </div>

            <ArrowBtn
              enlarged
              text={t('payNow')}
              action={action || purchase} />

            <BackBtn />
            <div ref={bottomRef} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Payment