import { selectBasketRentingSummary } from 'containers/Basket/basketSlice'
import Skeleton from 'react-loading-skeleton'
import { displayPrice } from 'utils/string'
import { useSelector } from 'react-redux'

const PriceSection = () => {
  const rentingSummary = useSelector(selectBasketRentingSummary)
  return (
    <div>
      <div className='list__header element__title'>Podsumowanie</div>
      <div className='list__element --big'>
        <div className='element__title'>Koszty</div>
        {rentingSummary?.to_pay
          ? <div className='element__desc'>
            {Number(rentingSummary?.to_pay?.installation?.grossPrice) > 0 && <div>Opłata instalacyjna: <span className='--marked'>{displayPrice(rentingSummary.to_pay.installation.grossPrice)}</span></div>}
            {Number(rentingSummary?.to_pay?.reservation?.grossPrice) > 0 && <div>Opłata rezerwacyjna: <span className='--marked'>{displayPrice(rentingSummary.to_pay.reservation.grossPrice)}</span></div>}
            <div>Do zapłaty: <span className='--marked'>{displayPrice(rentingSummary?.to_pay?.now?.grossPrice)}</span></div>
          </div>
          : <div className='element__desc'>
            <Skeleton />
          </div>}
        <div className='element__title'>Harmonogram</div>
        <div className='element__desc'>
          {rentingSummary?.to_pay
            ? rentingSummary?.to_pay?.monthly?.map(({ start, end, grossPrice }, idx) => <div key={idx}>
              {start} - {end}: <span className='--marked'>{displayPrice(grossPrice)}</span>
            </div>)
            : <Skeleton />}
        </div>
      </div>
    </div>
  )
}

export default PriceSection