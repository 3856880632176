import { ReactComponent as Service } from '../../assets/service.svg'
import { useEffect, useRef, useState } from 'react'
import { isMobile } from '../../utils/isMobile'
import Notifications from './Notifications'
import Actions from './Actions'
import Faq from './Faq'
import './style.scss'

const mobile = isMobile()
const notifications = <Notifications />

const Help = () => {

  const [expanded, expand] = useState(false)

  const faqRef = useRef(null)
  const menuRef = useRef(null)
  const scrolledUp = useRef(true)


  useEffect(() => {
    if (mobile) {
      document.body.style.setProperty('overflow', 'hidden')
      return () => document.body.style.removeProperty('overflow')
    }
  }, [])

  const onTouch = e => {
    const menu = menuRef.current
    if (!menu || !mobile) return

    const initialY = e.touches[0].clientY

    const touchMove = e => {
      const currentY = e.touches[0].clientY
      if ((initialY - currentY > 40 && !expanded) || (currentY - initialY > 40 && expanded)) {
        expand(!expanded)
        touchEnd()
      }
    }

    const touchEnd = () => {
      menu.removeEventListener('touchend', touchEnd, false)
      menu.removeEventListener('touchmove', touchMove, false)
    }

    menu.addEventListener('touchend', touchEnd, false)
    menu.addEventListener('touchmove', touchMove, false)
  }

  const scrollPage = e => {
    const { scrollTop } = e.target
    if (!expanded && scrollTop > 160) expand(true)
    else if (expanded && scrollTop < 20) expand(false)
  }

  const scrollMenu = e => {
    const { scrollTop } = e.target
    scrolledUp.current = scrollTop < 1
  }

  const showFaq = () => {
    expand(true)
    if (faqRef.current) {
      setTimeout(() => faqRef.current.scrollIntoView({ behavior: 'smooth' }), 500)
    }
  }

  return (
    <div
      className='help'
      onScroll={e => !mobile && scrollPage(e)}
      style={{ overflow: mobile ? 'hidden' : 'auto' }}>

      <div className='help__main center'>
        <div className='help__header title'>
          <div>Pomoc techniczna</div>
          <div className='--secondary'>Jak możemy pomóc?</div>
        </div>

        <Service />
      </div>

      <div
        ref={menuRef}
        className='help__menu'
        style={(expanded && mobile) ? { top: 0 } : undefined}
        onTouchStart={e => (mobile && scrolledUp.current) && onTouch(e)}>

        <div
          onScroll={e => mobile && scrollMenu(e)}
          style={{ overflowY: expanded ? 'auto' : 'hidden' }}>

          <div className='menu__header'>
            <div className='line' />
          </div>

          <Actions showFaq={showFaq} />
          {notifications}
          <div ref={faqRef}>
            <Faq />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Help