import { addPin, selectUser } from '../../Login/loginSlice'
import useTranslation from '../../../hooks/useTranslation'
import BackBtn from '../../../components/buttons/BackBtn'
import { useDispatch, useSelector } from 'react-redux'
import Submit from '../../../components/inputs/Submit'
import Popup from '../../../components/popups/Popup'
import PinForm from '../../../components/PinForm'
import { userConfig } from '../../../rest/urls'
import { PATCH } from '../../../rest/request'
import { useHistory } from 'react-router-dom'
import { useState } from 'react'

const INIT_FORM = {
  first: '',
  second: ''
}

const PinPopup = () => {

  const { pin } = useSelector(selectUser)
  const [{ first, second }, setForm] = useState(INIT_FORM)
  const [warning, setWarning] = useState({
    first: false,
    second: false
  })

  const { t } = useTranslation('form')
  const { goBack } = useHistory()
  const dispatch = useDispatch()

  const updateForm = (value, name = 'first') => setForm(f => { return { ...f, [name]: value } })
  const updateWarning = (value, name = 'first') => setWarning(f => { return { ...f, [name]: value } })

  const submit = async () => {
    const body = { new_pin: second }
    if (pin) body.current_pin = first

    try {
      const response = await PATCH(userConfig, { body })
      if (response?.result === 'ok') {
        dispatch(addPin())
        goBack()

      } else {
        updateForm('')
        updateWarning(true)
      }

    } catch (error) {
      updateWarning(true)
    }
  }

  return (
    <Popup>
      <div className='popup__pin'>
        <div className='popup__title'>
          <div>{t(pin ? 'changePin' : 'addPin')}</div>
        </div>

        <p>{t(pin ? 'pleaseEnterYourOldPin' : 'enterNewPin')}</p>
        <PinForm
          value={first}
          warning={warning.first}
          setWarning={updateWarning}
          setValue={updateForm}
        />

        <p>{t(pin ? 'enterNewPin' : 'repeatPin')}</p>
        <PinForm
          value={second}
          warning={warning.second}
          setWarning={w => updateWarning(w, 'second')}
          setValue={v => updateForm(v, 'second')}
        />

        <div className='popup__buttons'>
          <Submit
            action={submit}
            text={t(pin ? 'change' : 'add')}
            block={first.length !== 4 || second.length !== 4}
          />

          <BackBtn text={t('cancel')} />
        </div>
      </div>
    </Popup>
  )
}

export default PinPopup