import { getMessaging, getToken, onMessage } from 'firebase/messaging'
import { webpushdevices } from '../../rest/urls'
import { initializeApp } from 'firebase/app'
import { POST } from '../../rest/request'

const firebaseConfig = {
  apiKey: 'AIzaSyAgxE7j4QJf-otqRSIW4EfPsXRqcnY2Gic',
  authDomain: 'mm-client-f5c67.firebaseapp.com',
  projectId: 'mm-client-f5c67',
  storageBucket: 'mm-client-f5c67.appspot.com',
  messagingSenderId: '36121930238',
  appId: '1:36121930238:web:5ff033e4c27ced8a80b8af',
  measurementId: 'G-KJG9DDC512'
}

let messaging

const initialize = () => {
  const firebaseApp = initializeApp(firebaseConfig)
  messaging = getMessaging(firebaseApp)
}

export const fetchFirebaseToken = () => {
  if (!messaging) initialize()
  return getToken(messaging, { vapidKey: process.env.REACT_APP_VAPID_KEY }).then(async token => {
    if (token) {
      const body = {
        token,
        provider: 'firebase'
      }
      await POST(webpushdevices, { body })
      console.log('current token for client: ', token)
    } else {
      console.log('No registration token available. Request permission to generate one.')
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ')
  })
}

export const onMessageListener = () => {
  if (!messaging) initialize()
  return new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      const { title, body, tag } = payload.notification
      const notificationOptions = {
        body,
        tag,
      }

      new Notification(title, notificationOptions)
      resolve(payload)
    })
  })
}