import { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import Input from '../../../components/inputs/Input'
import { isMobile } from '../../../utils/isMobile'
import { checkAddress } from '../../../rest/urls'
import { STATUS } from '../../../utils/constants'
import { POST } from '../../../rest/request'
import { updateValues } from '../formSlice'
import { useDispatch } from 'react-redux'

const mobile = isMobile()

const InputWithOptions = forwardRef(({ value = '', secondaryTheme = false, name, additionalValues = false, optionalValueSetter, blurHandler, ...rest }, ref) => {
  const [status, setStatus] = useState(STATUS.idle)
  const [focus, setFocus] = useState(false)
  const [data, setData] = useState('')

  const fetchController = useRef(null)
  const lastBody = useRef(null)
  const containerRef = useRef(null)

  const dispatch = useDispatch()
  const saveValue = useCallback(
    v => optionalValueSetter ? optionalValueSetter(v) : dispatch(updateValues(v))
    , [dispatch, optionalValueSetter])

  const isAdditional = status === STATUS.pending || (status === STATUS.succeed && data.length > 0)
  const find = name === 'houseNumber' ? 'number' : name === 'postalCode' ? 'zip' : name

  useEffect(() => {
    const find = name === 'postalCode' ? 'zip' : name
    const isNumber = find === 'houseNumber'
    const isZip = find === 'zip'

    if (fetchController.current) {
      fetchController.current.abort()
      fetchController.current = null
    }

    if (!focus && !isZip) return
    if ((!isZip && value.length > 1) || (isNumber && additionalValues) || (isZip && !value && additionalValues)) {

      const body = { find, ...additionalValues }
      body[find] = value

      const bodyString = JSON.stringify(body)
      if (lastBody.current && lastBody.current === bodyString) return

      const controller = new AbortController()
      const signal = controller.signal

      fetchController.current = controller
      setStatus(STATUS.pending);

      (async () => {
        try {
          const response = await POST(checkAddress, { body, signal })
          if (response) {
            setStatus(STATUS.succeed)
            lastBody.current = bodyString
            if (isZip && response.length === 1) {
              saveValue({ [name]: response[0][find] })
              blurHandler({ target: { name, value: response[0][find] } })
            }
            else setData(response)
          }

        } catch (err) {
          setStatus(STATUS.failed)
          setData('')
        }
      })()
    } else setData('')
  }, [additionalValues, blurHandler, focus, name, saveValue, value])

  useEffect(() => {
    if (containerRef.current && focus) {
      const unfocus = (event) => {
        if (!containerRef.current.contains(event.target)) {
          setFocus(false)
        }
      }
      const root = document.querySelector('#root')
      root?.addEventListener('click', unfocus)
      return () => {
        root?.removeEventListener('click', unfocus)
      }
    }
  }, [focus])

  return (
    <div
      ref={containerRef}
      className={`select__container --${(isAdditional && focus) ? 'expanded' : ''}${mobile ? ' --mobile' : ''}${secondaryTheme ? ' --secTheme' : ''}`}>
      <Input
        ref={ref}
        name={name}
        value={value}
        autoComplete='off'
        blurHandler={blurHandler}
        focusHandler={() => {
          setFocus(true)
        }}
        {...rest}
      />

      <div className='select__options'>
        {status === STATUS.pending ? <p>ładowanie...</p>
          : isAdditional ? data.map((e, idx) => <div
            key={idx}
            className='select__option'
            onClick={() => {
              const values = { [name]: e[find] }
              if (name === 'street') values.streetPrefix = e.streetPrefix
              if (name === 'city') values.cityDistricted = e.cityDistricted
              blurHandler({ target: { name, value: e[find] } })
              saveValue(values)
              setFocus(false)
            }}>
            {e[find]}{find === 'city' ? `, pow. ${e.district}` : ''}
          </div>)
            : null}
      </div>
    </div>
  )
})

export default InputWithOptions