import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { login, logout, refresh } from 'rest/urls'
import HeadersManager from 'rest/HeadersManager'
import { DELETE, POST } from 'rest/request'
import { parseJwt } from 'utils/parseJwt'

export const fetchLogIn = createAsyncThunk('login/fetchLogIn', async body => {
  const response = await POST(login, { body, credentials: true })
  return response
})

export const fetchLogOut = createAsyncThunk('login/fetchLogOut', async () => {
  const response = await DELETE(logout)
  return response
})

export const fetchToken = createAsyncThunk('login/fetchToken', async () => {
  const response = await POST(refresh, { credentials: true }, false)
  return response
})

const initialState = {
  loggedIn: false,
  contract: false,
  isLoading: false,
  expirationDate: 0,
  user: {
    name: '',
    pin: false,
    device_verification: false,
  }
}

const logInUser = (state, data) => {
  if (data) {
    const { expires_in: expirationDate, cfg, access_token: token, hasDataForContract } = data
    const { name = '', sub = '' } = parseJwt(token)
    HeadersManager.jwt.set(token)
    state.expirationDate = (expirationDate - 60) * 1000
    state.user = { ...cfg, name, id: sub }
    state.isLoading = false
    state.loggedIn = true
    state.error = ''
    if (hasDataForContract && hasDataForContract.fields) state.contract = hasDataForContract.fields

  } else {
    HeadersManager.jwt.destroy()
    return initialState
  }
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    addPin: (state, _) => { state.user.pin = true },
    resetContract: (state, _) => { state.contract = false },
    updateDeviceVerification: (state, action) => { state.user.deviceVerification = action.payload },
  },
  extraReducers: {
    [fetchLogIn.fulfilled]: (state, action) => logInUser(state, action.payload),
    [fetchLogOut.fulfilled]: (state, _) => logInUser(state, false),

    [fetchToken.fulfilled]: (state, action) => logInUser(state, action.payload),
    [fetchToken.pending]: (state, _) => { state.isLoading = true },
    [fetchToken.rejected]: (state, _) => logInUser(state, false),
  },
})

export const { addPin, resetContract, updateDeviceVerification } = loginSlice.actions

export const selectExpirationDate = state => state.login.expirationDate
export const selectIsLoggedIn = state => state.login.loggedIn
export const selectIsLoading = state => state.login.isLoading
export const selectContract = state => state.login.contract
export const selectUserId = state => state.login.user?.id
export const selectUser = state => state.login.user

export default loginSlice.reducer
