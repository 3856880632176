import { paymentSettlement, paymentStatus } from 'rest/urls'
import ArrowBtn from 'components/buttons/ArrowBtn'
import useTranslation from 'hooks/useTranslation'
import BackBtn from 'components/buttons/BackBtn'
import logo from '../../assets/img/blikimg.png'
import { useHistory } from 'react-router-dom'
import Input from 'components/inputs/Input'
import Popup from 'components/popups/Popup'
import { STATUS } from 'utils/constants'
import Loading from 'components/Loading'
import { POST } from 'rest/request'
import { useState } from 'react'

const BlikPopup = ({ callback, getBody }) => {
  const [status, setStatus] = useState(STATUS.idle)
  const [code, setCode] = useState('')

  const { t } = useTranslation('payment')
  const { goBack } = useHistory()

  const processResponse = (response) => {
    if (response.status === 'SUCCESS') {
      callback(response.remotePaymentId)
      goBack()

    } else if (response.status === 'FAILURE') {
      setStatus(STATUS.failed)
      setCode('')

    } else if (response.status === 'INIT') {
      setTimeout(() => {
        fetchStatus(response.remotePaymentId)
      }, 3000)

    } else {
      callback()
      console.error('Something bad happened :/')
    }
  }

  const fetchStatus = async (remotePaymentId) => {
    const response = await POST(paymentStatus, { body: { remotePaymentId } })
    processResponse(response)
  }

  const send = async () => {
    if (status === STATUS.pending || code.length !== 6) return
    const body = await getBody(code)
    if (!body) return

    setStatus(STATUS.pending)
    const response = await POST(paymentSettlement, { body })
    processResponse(response)
  }

  return (
    <Popup>
      <div className='popup__blik'>
        <div className='popup__title'>
          <div>
            <img src={logo} alt='blik'></img>
          </div>
        </div>

        {status === STATUS.pending ? (
          <div className='popup__form'>
            <p>{t('processingPayment')}</p>
            <Loading />
          </div>
        ) : (
          <div className='popup__form'>
            <p>
              <span>{t('enterBlikCode')}</span>
              {status === STATUS.failed && <span className='--warning'> - {t('wrongCode')}!</span>}
            </p>
            <Input
              type='tel'
              placeholder='*** ***'
              value={code}
              disabled={status === STATUS.pending}
              changeHandler={e => {
                const { value } = e.target
                if (!value || /^[0-9]{0,6}$/.test(value)) setCode(value)
              }}
            />
          </div>
        )}

        <div className='popup__buttons'>
          <BackBtn />
          <ArrowBtn
            enlarged
            text={t('pay')}
            disabled={code.length !== 6}
            action={send} />
        </div>
      </div>
    </Popup>
  )
}

export default BlikPopup