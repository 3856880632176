import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

const useAccountKey = () => {
  const { key } = useParams()
  const [accountKey, setAccountKey] = useState(key)

  useEffect(() => {
    if (key) setAccountKey(key)
    else {
      const timeout = setTimeout(() => setAccountKey(key), 200)
      return () => clearTimeout(timeout)
    }
  }, [key])

  return { key: accountKey, isKey: !!key }
}

export default useAccountKey