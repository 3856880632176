import { selectBasketItem, selectBasketRentingSummary, selectBasketToPay, updateSelectedBasketItem } from 'containers/Basket/basketSlice'
import { useReduxDispatch, useReduxSelector } from 'store'
import Select from 'components/inputs/Select'
import { useSelector } from 'react-redux'
import React from 'react'

interface Tile {
  title: string
  text: string
  disabled: boolean
  description: string
  hover: string
  sign: any
  grossPrice: string
  netPrice: string
  warehouseRentingIds: string[]
  warehouseRentingHashes: string[]
  accumulative_discount: string
}

const PeriodsToPay: React.FC = () => {
  const toPay = useReduxSelector(selectBasketToPay)
  const summary = useReduxSelector(selectBasketRentingSummary)
  const { periodsToPay, identifier } = useSelector(selectBasketItem)
  const dispatch = useReduxDispatch()

  const warehouseId = toPay?.[identifier]?.warehouseId
  const tiles = summary?.to_pay?.warehouses_schedule?.[warehouseId]?.[0]?.tiles || []

  return (
    <>
      <div className='title'>
        <div>
          <span>Płatność za okres</span>
        </div>
      </div>

      <div className='orderInfo__periodsToPay'>
        <Select
          value={periodsToPay}
          label='Za jaki okres chcesz zapłacić z góry?'
          options={tiles.map(({ accumulative_discount }: Tile, idx: number, arr: Tile[]) => {
            const period = idx + 1
            return {
              value: idx,
              name: `${(arr.length === period) ? 'Cały okres' : `${period} ${period === 1 ? 'miesiąc' : period < 5 ? 'miesiące' : 'miesięcy'}`}${accumulative_discount ? ` (${accumulative_discount} rabatu)` : ''}`
            }
          })}
          setValue={value => {
            dispatch(updateSelectedBasketItem({ periodsToPay: value }))
          }}
        />
      </div>
    </>
  )
}

export default PeriodsToPay