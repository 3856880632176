import { selectBasketItem } from 'containers/Basket/basketSlice'
import { LOCATION_STATES } from 'utils/constants'
import usePopupState from 'hooks/usePopupState'
import { useHistory } from 'react-router-dom'
import DiscountPopup from './DiscountPopup'
import { useSelector } from 'react-redux'
import React from 'react'

const Discounts: React.FC = () => {
  const { discount } = useSelector(selectBasketItem)
  const { popup, pathname } = usePopupState()

  const { push } = useHistory()

  return (
    <>
      {popup === 'discounts' && <DiscountPopup />}
      <div className='title'>
        <div>
          <span>Rabaty</span>
        </div>
      </div>

      <div className='orderInfo__button'>
        <button
          className='button --coupon'
          onClick={() => {
            push(pathname, LOCATION_STATES.popup('discounts'))
          }}>
          {discount || 'Dodaj rabat/kupon'}
        </button>
      </div>
    </>
  )
}

export default Discounts