import { ReactComponent as Terminate } from '../../assets/terminateRent.svg'
import { ReactComponent as Calendar } from '../../assets/calendarSmall.svg'
import { changeSelectedItem, selectBasket } from './basketSlice'
import { ReactComponent as Cost } from '../../assets/cost.svg'
import useTranslation from '../../hooks/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import { getLocalDateString } from '../../utils/date'
import { useHistory } from 'react-router-dom'
import SummaryHeader from './SummaryHeader'

const BasketSummaryItem = ({ identifier, hash, start, end, termiateDays, locks, index, isSelected }) => {
  const { selected, toPay = {} } = useSelector(selectBasket)
  const _isSelected = isSelected || selected === index

  const { goBack, replace } = useHistory()
  const { t } = useTranslation('basket')
  const dispatch = useDispatch()

  return (
    <div
      className={`summary__item${_isSelected ? ' --selected' : ''}`}
      style={_isSelected ? undefined : { opacity: 0.57 }}
      onClick={() => {
        if (!_isSelected) {
          goBack()
          dispatch(changeSelectedItem(index))
          const pathname = window.location.pathname
          if (pathname.includes('calendar')) {
            replace(pathname.replace(/(\/calendar\/[A-z0-9-_]*\/)([A-z0-9-_]*)/, `$1${hash}`))
          }
        }
      }}>
      <SummaryHeader index={index} block={!_isSelected} />

      <div className='basket__decs group'>
        <div className='center'>
          <Calendar className='calendar' />
          <div>{t('rentalPeriod')}</div>
        </div>
        <div>{getLocalDateString(start)} - {end ? getLocalDateString(end) : 'nieokreślony'}</div>
      </div>

      {toPay[identifier]?.to_pay?.list?.map(({ text, price }, i) => (
        <div key={i} className='basket__decs group'>
          <div className='center'>
            <Cost className='cost' />
            <div>{text}</div>
          </div>
          <div>{price} zł</div>
        </div>
      ))}

      <div className='basket__decs group'>
        <div className='center'>
          <Terminate className='terminate' />
          <div>{t('periodOfNotice')}</div>
        </div>
        <div>{termiateDays} {t('days')}</div>
      </div>

      {locks?.map((rentAddonItem, rentAddonIdx) => (
        <div key={rentAddonIdx} className='basket__decs group'>
          <div className='center'>
            <Cost />
            <div>{rentAddonItem.ownerId ? 'Zamknięcie magazynu' : 'Zamknięcie placu'}</div>
          </div>
          <div>{rentAddonItem.name}</div>
        </div>
      ))}
    </div>
  )
}

export default BasketSummaryItem