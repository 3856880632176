import { GroupedWarehouseAddons } from 'containers/OrderDetails/Summary/hooks/useGroupedWarehouseAddons'
import { changeSelectedItem, selectBasketSelectedIndex } from 'containers/Basket/basketSlice'
import useSummaryData from 'containers/OrderDetails/Summary/hooks/useSummaryData'
import { useReduxDispatch, useReduxSelector } from 'store'
import React, { Fragment, useState } from 'react'
import { LOCATION_STATES } from 'utils/constants'
import useTranslation from 'hooks/useTranslation'
import Checkbox from 'components/inputs/Checkbox'
import { getLocalDateString } from 'utils/date'
import { useHistory } from 'react-router-dom'
import { displayPrice } from 'utils/string'
import htbox1 from 'assets/img/htbox1.png'

type BasketItem = basic.Form
interface ListItemProps extends BasketItem {
  index: number
  locks: BasketItem[]
  groupedWarehouseAddons: GroupedWarehouseAddons
}

const ListItem: React.FC<ListItemProps> = ({ identifier, index, name, address, start, end, termiateDays, locks, hash, placeId, groupedWarehouseAddons }) => {
  const selected = useReduxSelector(selectBasketSelectedIndex)
  const data = useSummaryData(groupedWarehouseAddons)
  const [isExpanded, expand] = useState(false)

  const isSelected = index === selected

  const dispatch = useReduxDispatch()
  const { t } = useTranslation('basket')
  const { push, goBack, replace } = useHistory()

  return (
    <div className='basketPopup__list__item'>
      <div className='basketPopup__list__item__block group'>
        <Checkbox
          name={crypto.randomUUID()}
          checked={isSelected}
          label={isSelected ? 'W trakcie edycji' : 'Kliknij, aby edytować'}
          changeHandler={() => {
            if (!isSelected) {
              goBack()
              dispatch(changeSelectedItem(index))
              replace(`/calendar/${placeId}/${hash}`)
            }
          }}
        />
        <div
          className='basketPopup__list__item__button'
          onClick={() => {
            expand(e => !e)
          }}>
          <span>{isExpanded ? 'Zwiń' : 'Rozwiń'}</span>
          <i className={`icon icon--chevron-${isExpanded ? 'up' : 'down'}`} />
        </div>
      </div>

      <div className='basketPopup__list__item__block basketPopup__list__item__header'>
        <img
          alt='warehouse'
          src={htbox1}
        />
        <div className='basketPopup__list__item__header__title'>
          <div>{name}</div>
          <div>ul.{address.street} {address.estateNumber}{address.localNumber ? `/${address.localNumber}` : ''}, {address.province}</div>
        </div>
      </div>

      <div className={`hidableElement --${isExpanded ? 'visible' : 'hidden'}`}>
        <div>
          <div className='basketPopup__list__item__block'>
            <div className='basketPopup__list__item__row'>
              <div>
                <i className='icon icon--calendar' />
                <span>{t('rentalPeriod')}</span>
              </div>
              <span>{getLocalDateString(start)} - {end ? getLocalDateString(end) : 'nieokreślony'}</span>
            </div>
            <div className='basketPopup__list__item__row'>
              <div>
                <i className='icon icon--terminate' />
                <span>{t('periodOfNotice')}</span>
              </div>
              <span>{termiateDays} {t('days')}</span>
            </div>
            <div className='basketPopup__list__item__row'>
              <div>
                <i className='icon icon--lock' />
                <span>Zamknięcie placu</span>
              </div>
              <span>
                {locks.map(({ ownerId, related, name }, idx) => {
                  if (ownerId || !related?.some((r: basic.Form) => r.object === 'Place' && r.id === placeId)) return null
                  return `${!idx ? '' : ', '}${name}`
                })}
              </span>
            </div>
            <div className='basketPopup__list__item__row'>
              <div>
                <i className='icon icon--lock' />
                <span>Zamknięcie magazynu</span>
              </div>
              <span>
                {locks.map(({ ownerId, related, name }, idx) => {
                  if (!ownerId && !related?.some((r: basic.Form) => r.cartId === identifier)) return null
                  return `${!idx ? '' : ', '}${name}`
                })}
              </span>
            </div>
          </div>

          <div className='basketPopup__list__item__block'>
            <div className='basketPopup__list__item__row'>
              <span className='--title'>Harmonogram</span>
              <span className='--smaller'>Płatne 14 dnia każdego miesiąca</span>
            </div>
            {data?.monthly?.map(({ start, end, grossPrice }, idx) => <div
              key={idx}
              className='basketPopup__list__item__row'>
              <span>{start ? `od ${start}` : ''}{end ? ` do ${end}` : ''}</span>
              <span>{grossPrice} zł</span>
            </div>)}
          </div>

          <div className='basketPopup__list__item__block'>
            <div className='basketPopup__list__item__row'>
              <span className='--title'>Opłaty na teraz</span>
            </div>
            {data?.now?.list?.map(({ text, price, depositAmount }, idx) => {
              return <Fragment key={idx}>
                <div className='basketPopup__list__item__row'>
                  <span className='--red'>{text}:</span>
                  <span>{displayPrice(depositAmount ? (Number(price) - Number(depositAmount)) : price)}</span>
                </div>
                {depositAmount && <div className='basketPopup__list__item__row'>
                  <span className='--red'>Kaucja:</span>
                  <span>{displayPrice(depositAmount)}</span>
                </div>}
              </Fragment>
            })}
          </div>
        </div>
      </div>

      <div className='basketPopup__list__item__block'>
        <div className='basketPopup__list__item__row'>
          {isSelected
            ? <span
              className='--button'
              onClick={() => {
                push(window.location.pathname, LOCATION_STATES.popup('confirmation'))
              }}>
              Usuń
            </span>
            : <span />}
          <div className='basketPopup__list__item__sum'>
            <span className='--red'>{isExpanded ? 'Suma opłat' : 'Suma'}</span>
            <span>{displayPrice(data?.now?.sum || 0)}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListItem