import { fetchFirebaseToken, onMessageListener } from './firebaseUtil'
import { getNativeHeaders } from '../../utils/getNativeHeaders'
import GetFirebaseContext from './Context'
import React from 'react'

const { search = '' } = window.location
if (!search.match(/(ios|android)/)) {
  onMessageListener()
}

const FirebaseProvider = ({ children }) => {
  const getToken = () => {
    if (search.includes('ios')) {
      window.webkit.messageHandlers.FirebaseToken.postMessage(getNativeHeaders())

    } else if (search.includes('android')) {
      window.Android.getFirebaseToken(getNativeHeaders())

    } else fetchFirebaseToken()
  }

  return (
    <GetFirebaseContext.Provider value={getToken}>
      {children}
    </GetFirebaseContext.Provider>
  )
}

export default FirebaseProvider