import './style.scss'

const ActionsButton = ({ action, text, Icon }) => {
  return (
    <div
      className='menu__button center'
      onClick={action}>
        <i className={`icon icon--${Icon} s32`}></i>
      <span>{text}</span>
    </div>
  )
}

export default ActionsButton