import { CoordinatesContext, MoveMapContext } from '../context/data/Context'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'
import { LeafletEvent, Map } from 'leaflet'
import { selectLightTheme } from 'appSlice'
import { useSelector } from 'react-redux'
import Markers from './Markers'

interface LeafletMapProps {
  close: (pointId: string) => void
}

const DELAY = 900

const LeafletMap: React.FC<LeafletMapProps> = ({ close }) => {
  const [isReady, setReady] = useState(false)
  const isLightTheme = useSelector(selectLightTheme)
  const coordinates = useContext(CoordinatesContext)
  const moveMap = useContext(MoveMapContext)

  const blockListener = useRef(false)
  const map = useRef<Map | null>(null)
  const timeout = useRef<NodeJS.Timeout | null>(null)

  useEffect(() => {
    if (map.current) {
      blockListener.current = true
      map.current.flyTo({ lat: coordinates.latitude, lng: coordinates.longitude }, 12)
    }
  }, [coordinates])

  useEffect(() => {
    const onMoveEnd = (e: LeafletEvent) => {
      if (blockListener.current) {
        blockListener.current = false

      } else {
        if (e.target.getZoom() < 12) return
        if (timeout.current) {
          clearTimeout(timeout.current)
        }
        const { center, bounds } = getMapBoundsAndMapCenter(e.target)
        timeout.current = setTimeout(() => {
          moveMap(center, bounds)
        }, DELAY)
      }
    }

    const m = map.current
    if (m) {
      m.addEventListener('moveend', onMoveEnd)
      return () => {
        m.removeEventListener('moveend', onMoveEnd)
      }
    }
  }, [isReady, moveMap])

  return (
    <div
      className='map__leaflet'
      onTouchStart={e => {
        e.stopPropagation()
      }}>
      <MapContainer
        zoom={6}
        minZoom={3}
        maxZoom={18}
        scrollWheelZoom={true}
        center={{
          lat: coordinates.latitude,
          lng: coordinates.longitude
        }}
        whenCreated={mapRef => {
          map.current = mapRef
          setReady(true)
        }}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
          url={`https://{s}.basemaps.cartocdn.com/${isLightTheme ? 'light' : 'dark'}_all/{z}/{x}/{y}{r}.png`}
          subdomains="abcd"
        />
        <Markers close={close} />
      </MapContainer>
    </div>
  )
}

const getMapBoundsAndMapCenter = (map: Map) => {
  const mapCenter = map.getCenter()
  const mapBounds = map.getBounds()
  const center: delivery.Coordinates = {
    latitude: mapCenter.lat,
    longitude: mapCenter.lng
  }
  const bounds: delivery.BodyMapBounds = {
    north_west: {
      latitude: mapBounds.getNorth(),
      longitude: mapBounds.getWest()
    },
    north_east: {
      latitude: mapBounds.getNorth(),
      longitude: mapBounds.getEast()
    },
    south_west: {
      latitude: mapBounds.getSouth(),
      longitude: mapBounds.getWest()
    },
    south_east: {
      latitude: mapBounds.getSouth(),
      longitude: mapBounds.getEast()
    }
  }
  return {
    center,
    bounds
  }
}

export default LeafletMap