import ArrowBtn from 'components/buttons/ArrowBtn'
import { useHistory } from 'react-router-dom'
import React from 'react'

interface NextButtonProps {
  validateLocks: (() => boolean) | null
}

const NextButton: React.FC<NextButtonProps> = ({ validateLocks }) => {
  const { push } = useHistory()
  return (
    <ArrowBtn
      enlarged
      text='Dalej'
      action={() => {
        if (validateLocks?.()) {
          push('/login')
        }
      }}
    />
  )
}

export default NextButton