import { DEVICE_TYPES, selectDevice, selectIsExpandedBasket, selectLightTheme } from '../../appSlice'
import { selectBasketIsEmpty } from 'containers/Basket/basketSlice'
import { ReactComponent as Back } from 'assets/back.svg'
import { ReactComponent as Logo } from 'assets/logo.svg'
import { ReactComponent as Menu } from 'assets/menu.svg'
import TimerProvider from './context/Timer/Provider'
import { LOCATION_STATES } from 'utils/constants'
import usePopupState from 'hooks/usePopupState'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import BasketTitle from './basket/Title'
import BasketPopup from './basket/Popup'
import { useReduxSelector } from 'store'
import BasketIcon from './basket/Icon'
import React, { useState } from 'react'
import './style.scss'

interface HeaderProps {
  dark?: boolean
  white?: boolean
  flying?: boolean
  rounded?: boolean
  backAction?: basic.BasicFunc
}

const Header: React.FC<HeaderProps> = ({ dark = false, white = false, flying = false, rounded = false, backAction }) => {
  const [bartekMode, setBartekMode] = useState(false)
  const isOldBasket = useReduxSelector(selectIsExpandedBasket)
  const basketIsEmpty = useReduxSelector(selectBasketIsEmpty)
  const lightTheme = useReduxSelector(selectLightTheme)
  const device = useSelector(selectDevice)
  const { popup } = usePopupState(500)

  const { push, replace, goBack, location: { pathname } } = useHistory()

  const isBasketExpanded = !isOldBasket && popup === 'basket'
  const isIos = device === DEVICE_TYPES.ios

  return (
    <TimerProvider>
      <header className={`--${lightTheme ? 'light' : 'dark'}${(dark || !basketIsEmpty) ? ' --black' : white ? ' --white' : ''}${flying ? ' --flying' : ''}${isIos ? ' --ios' : ''}${(rounded || !basketIsEmpty) ? ' --rounded' : ''}`}>
        <div className='group'>
          {(bartekMode && isBasketExpanded)
            ? <div className='s36' />
            : !pathname.match(/^\/(|chat|dashboard|help|account)$/) ? <div
              className='header__back'
              onClick={() => {
                backAction ? backAction() : window.history ? goBack() : replace('/')
              }}>
              <Back />
            </div>
              : null}

          {!flying && <div
            className='header__logo'
            onClick={() => {
              if (isBasketExpanded) setBartekMode(bm => !bm)
              else push('/')
            }}>
            {bartekMode && isBasketExpanded ? <span>Koszyk</span> : <Logo />}
          </div>}

          {basketIsEmpty
            ? <Menu
              onClick={() => {
                push(pathname, LOCATION_STATES.menu)
              }}
            />
            : bartekMode && isBasketExpanded
              ? <i
                className='icon icon--close s36'
                onClick={goBack}
              />
              : <BasketIcon
                action={() => {
                  if (isBasketExpanded) goBack()
                  else push(window.location.pathname, LOCATION_STATES.popup('basket'))
                }}
              />}
        </div>
        {!basketIsEmpty && <BasketTitle />}
      </header>
      {isBasketExpanded && <BasketPopup />}
    </TimerProvider>
  )
}

export default Header