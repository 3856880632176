import useTranslation from 'hooks/useTranslation'
import { ACCOUNT_TYPE } from '../accountSlice'
import Payments from 'components/Payments'
import InvoicesList from './List'
import { useState } from 'react'

export const type = ACCOUNT_TYPE.invoices

const Invoices = ({ onlyBody = false, customWrapperClassName = '' }) => {
  const [invoicesToPay, setInvoicesToPay] = useState({})
  const [page, setPage] = useState('invoices')

  const amount = Object.values(invoicesToPay).reduce((r, e) => r += Number(e), 0)

  const { t } = useTranslation()

  switch (page) {
    case 'payments': {
      const invoices = Object.entries(invoicesToPay).map(([invoiceId, amount]) => ({ invoiceId, amount }))
      return <Payments
        grossPrice={amount}
        summaryTop='Ilość faktur'
        getInvoices={() => invoices}
        summaryBottom={invoices.length}
        close={(remotePaymentId) => {
          if (remotePaymentId) setInvoicesToPay({})
          setPage('invoices')
        }}
      />
    }

    default: {
      const list = <InvoicesList
        amount={amount}
        invoicesToPay={invoicesToPay}
        customWrapperClassName={customWrapperClassName}
        setInvoicesToPay={setInvoicesToPay}
        showPayments={() => {
          setPage('payments')
        }}
      />

      return onlyBody
        ? list
        : <div className='account__safety'>
          <div className='account__header title'>
            <div>{t('account.invoices')}</div>
            <div>{t('account.subTitle')}</div>
          </div>
          {list}
        </div>
    }
  }
}

export default Invoices