import ArrowBtn from 'components/buttons/ArrowBtn'
import BackBtn from 'components/buttons/BackBtn'
import { useHistory } from 'react-router-dom'
import Warehouse from 'components/Warehouse'

const Final = () => {
  const { push } = useHistory()

  return (
    <div className='resignation__main' style={{ paddingTop: 0 }}>
      <div className='title'>
        <div>Dziękujemy</div>
        <div>Wynajem zakończony</div>
      </div>

      <div className='resignation__summary'>
        <div className='resignation__warehouses'>
          {[1, 2, 3].map(e => <div
            key={e}
            className={`warehouse${e === 1 ? ' --left' : e === 3 ? ' --right' : ''}`}>
            <Warehouse />
          </div>
          )}
        </div>

        <div className='resignation__text'>Podziel się swoja opinią i pomóż nam udoskonalać magazynomaty!</div>
      </div>

      <div className='resignation__buttons'>
        <ArrowBtn
          enlarged
          text='Oceń nas'
          action={() => { }}
        />

        <BackBtn
          text='Strona główna'
          action={() => push('/')}
        />
      </div>
    </div>
  )
}

export default Final