import { selectUser } from 'containers/Login/loginSlice'
import ArrowBtn from 'components/buttons/ArrowBtn'
import BackBtn from 'components/buttons/BackBtn'
import { useHistory } from 'react-router-dom'
import Select from 'components/inputs/Select'
import { displayPrice } from 'utils/string'
import { useReduxSelector } from 'store'
import Item from './Item'
import React from 'react'
import { Step } from '.'

interface SummaryProps {
  selectedData: payments.SelectedData
  response?: payments.CancelRentalResponse
  selectedTimePeriods: payments.SelectedTimePeriods
  setStep: React.Dispatch<React.SetStateAction<Step>>
  setSelectedTimePeriods: React.Dispatch<React.SetStateAction<payments.SelectedTimePeriods>>
}

const Summary: React.FC<SummaryProps> = ({ response, selectedData, selectedTimePeriods, setStep, setSelectedTimePeriods }) => {
  const { unpaidInvoicesAmount, unpaid_invoices } = useReduxSelector(selectUser)
  const tiles = response?.warehouses?.[0]?.schedule?.[0]?.tiles
  const amount = Number(unpaidInvoicesAmount) + getAmount(selectedTimePeriods, tiles)

  const { goBack } = useHistory()

  return <>
    <div className='title'>
      <div>Podsumowanie</div>
      <div>Kiedy chcesz skończyć wynajem?</div>
    </div>

    <div className='resignation__main'>
      <div className='resignation__list'>
        <Select
          secondaryTheme
          value={selectedTimePeriods}
          setValue={setSelectedTimePeriods}
          label='Za jaki okres chcesz zapłacić'
          options={tiles?.map(({ accumulative_discount }, idx, arr) => {
            const period = idx + 1
            return {
              name: arr.length === period
                ? 'Cały okres'
                : `${period} ${period === 1 ? 'miesiąc' : period < 5 ? 'miesiące' : 'miesięcy'}${accumulative_discount ? ` (${accumulative_discount})` : ''}`,
              value: idx
            }
          }) || []}
        />
        <Item
          icon={'calendar'}
          title='Zakończenie wynajmu'
          desc={new Date(selectedData.date).toLocaleDateString()}
        />

        <Item
          icon={'payments'}
          title='Zaległe faktury'
          desc={`${unpaid_invoices.length} faktur na kwotę: ${displayPrice(unpaidInvoicesAmount)}`}
        />
      </div>

      <div className='resignation__buttons'>
        <ArrowBtn
          enlarged
          text={`Zapłać ${displayPrice(amount)}`}
          action={() => {
            setStep('payment')
          }}
        />

        <BackBtn
          text='Wróć'
          action={() => {
            goBack()
          }}
        />
      </div>
    </div>
  </>
}

export const getAmount = (selectedIndex: number, tiles?: payments.WarehouseRentalScheduleTile[]) => {
  let amount = 0
  if (tiles) {
    for (let i = 0; i < tiles.length; i++) {
      amount += Number(tiles[i].grossPrice)
      if (i === selectedIndex) break
    }
  }
  return amount
}

export default Summary