import { selectUserId } from 'containers/Login/loginSlice'
import { useHistory, useLocation } from 'react-router-dom'
import { LOCATION_STATES } from 'utils/constants'
import { useSelector } from 'react-redux'
import { useState } from 'react'

const usePaymentProcessing = (selectedPayment, getInvoices, amount, paymentMethods) => {
  const [isLoading, setLoading] = useState(false)
  const userId = useSelector(selectUserId)
  const { pathname } = useLocation()

  const { push } = useHistory()

  const getPayment = () => {
    const payment = {
      amount,
      methodId: selectedPayment.methodId,
      date: 'now'
    }
    if (selectedPayment.optionId) {
      payment.optionId = selectedPayment.optionId
    }
    return payment
  }

  const generateBody = async (code) => {
    const payment = getPayment()
    const invoices = await getInvoices(payment)
    payment.init = true
    if (code) {
      payment.code = code
    }
  
    return {
      action: 'payment',
      userId,
      invoices,
      payments: [payment]
    }
  }

  const purchase = async () => {
    if (isLoading) return
    setLoading(true)
    const payment = getPayment()
    const blikId = paymentMethods?.find(p => p.key === 'blik')?.id
    switch (payment.methodId) {
      case blikId:
        push(pathname, LOCATION_STATES.popup({ type: 'blik', payment }))
        break

      default:
        console.log('Payment is not supported :/')
        break
    }
  }

  return {
    isProcessing: isLoading,
    purchase,
    generateBody
  }
}

export default usePaymentProcessing