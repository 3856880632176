import { selectBasket, sendBasket } from 'containers/Basket/basketSlice'
import { selectUserId } from 'containers/Login/loginSlice'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LOCATION_STATES } from 'utils/constants'

const usePaymentProcessing = (selected, isLoading, setLoading) => {
  const { checkout, invoice, newDocuments } = useSelector(selectBasket)
  const userId = useSelector(selectUserId)

  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const { replace, push } = useHistory()

  const getPayment = () => {
    const payment = {
      amount: checkout?.to_pay?.now?.grossPrice,
      methodId: selected.methodId,
      date: 'now'
    }
    if (selected.optionId) {
      payment.optionId = selected.optionId
    }
    return payment
  }

  const callback = (payment) => {
    if (payment) {
      dispatch(sendBasket({ step: 'finalize', payments: [payment] })).then(res => {
        if (res.payload?.sold) {
          replace('/newwarehouse')
        }
      })

    } else {
      setLoading(false)
    }
  }

  const generateBody = (code) => {
    const body = {
      userId,
      action: 'payment',
      invoices: newDocuments.map(({ id, toPay }) => ({ invoiceId: id, amount: toPay }))
    }

    if (invoice) {
      body.companyNIP = invoice.nip
    }

    const payment = getPayment()
    if (code) {
      payment.code = code
    }
    payment.init = true
    body.payments = [payment]

    return body
  }

  const purchase = async () => {
    if (isLoading) return
    setLoading(true)
    const payment = getPayment()
    const blikId = checkout.payments.find(p => p.key === 'blik')?.id
    dispatch(sendBasket({ step: 'newDocument', payments: [payment] })).then(res => {
      setLoading(false)
      if (res.payload?.newDocuments) {
        switch (payment.methodId) {
          case blikId:
            push(pathname, LOCATION_STATES.popup({ type: 'blik', payment }))
            break

          default:
            console.log('Payment is not supported :/')
            break
        }
      }
    })
  }

  return {
    purchase,
    generateBody,
    callback
  }
}

export default usePaymentProcessing