import useGroupedWarehouseAddons from 'containers/OrderDetails/Summary/hooks/useGroupedWarehouseAddons'
import useWarehouseName from 'hooks/useWarehouseName'
import useGroupedBasket from 'hooks/useGroupedBasket'
import ListItem from './ListItem'
import React from 'react'

const List: React.FC = () => {
  const list = useGroupedBasket(true)
  const getName = useWarehouseName()
  const getGroupedWarehouseAddons = useGroupedWarehouseAddons()

  return (
    <div className='basketPopup__list'>
      {list.map((data) => <ListItem
        {...data}
        name={getName(data)}
        key={data.identifier}
        groupedWarehouseAddons={getGroupedWarehouseAddons()}
      />)}
    </div>
  )
}

export default List