
import { useHistory, useLocation } from 'react-router-dom'
import useTranslation from '../../hooks/useTranslation'
import { isIos } from 'utils/isMobile'
import './style.scss'

const BottomBar = () => {
  const { t } = useTranslation('bottombar')
  const { push } = useHistory()
  const {pathname} = useLocation()

  const ios = isIos()

  return (
    <div className={`bottomBar ${pathname === '/' || pathname ==='/dashboard' ? 'gray' : ''} ${ios ? 'ios' : ''}`}>

      <div className={`bottomBar__button ${pathname === '/dashboard' ? 'active' : ''}`} onClick={() => push('/dashboard')}>
        <i className='icon icon--dashboard s24'></i>
        <span>{t('dashboard')}</span>
      </div>

      <div className={`bottomBar__button ${pathname === '/chat' ? 'active' : ''}`} onClick={() => push('/chat')}>
        <i className='icon icon--message s24'></i>
        <span>{t('message')}</span>
      </div>

      <div className={`bottomBar__button ${pathname === '/' ? 'active' : ''}`} onClick={() => push('/')}>
        <i className='icon icon--plus s24'></i>
        <span>{t('order')}</span>
      </div>

      <div className={`bottomBar__button ${pathname === '/account' ? 'active' : ''}`} onClick={() => push('/account')}>
        <i className='icon icon--user s24'></i>
        <span>{t('account')}</span>
      </div>

      <div className={`bottomBar__button ${pathname === '/help' ? 'active' : ''}`} onClick={() => push('/help')}>
        <i className='icon icon--help s24'></i>
        <span>{t('help')}</span>
      </div>
    </div>
  )
}

export default BottomBar