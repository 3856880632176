import { selectBasketDeliveries, selectBasketToPay } from 'containers/Basket/basketSlice'
import { displayPrice } from 'utils/string'
import { useSelector } from 'react-redux'
import { Fragment } from 'react'

const AddonSection = ({ identifier, deliveryId, name }) => {
  const deliveries = useSelector(selectBasketDeliveries)
  const toPay = useSelector(selectBasketToPay)

  const delivery = deliveries && deliveryId
    ? deliveries.find(d => d.id === deliveryId)
    : undefined

  const renderItem = ({ price_sum, depositAmount }, idx) => {
    return <Fragment key={idx}>
      <div>
        <span>{name}: </span>
        <span>{displayPrice(depositAmount ? (Number(price_sum) - Number(depositAmount)) : price_sum)}</span>
      </div>
      {depositAmount && <div>
        <span>Kaucja: </span>
        <span>{displayPrice(depositAmount)}</span>
      </div>}
    </Fragment>
  }

  return (
    <div>
      <div className='list__header element__title'>Zabezpieczenie</div>
      <div
        id={`lock_${identifier}`}
        className='list__element --big'>
        <div className='element__title'>{name}</div>

        {delivery && <>
          <div className='element__title'>Dostawa</div>
          <div className='element__desc'>
            {delivery.pointId
              ? `punkt odbioru ${delivery.pointId}${delivery.lockerId ? `, skrytka ${delivery.lockerId}` : ''}`
              : delivery.address
                ? `${delivery.address.street} ${delivery.address.estateNumber}${delivery.address.localNumber ? `/${delivery.address.localNumber}` : ''}, ${delivery.address.zip} ${delivery.address.city}`
                : 'Brak danych'}
          </div>
        </>}

        {toPay?.[identifier]?.to_pay && <>
          <div className='element__title'>Koszty</div>
          <div className='element__desc'>
            {renderItem(toPay[identifier].to_pay)}
          </div>
        </>}
      </div>
    </div>
  )
}

export default AddonSection