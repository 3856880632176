import { selectWarehouse, updateWarehouse } from '../Warehouses/warehousesSlice'
import { ReactComponent as Arrow } from 'assets/circleArrow.svg'
import { ReactComponent as Compass } from 'assets/compass.svg'
import BigCircleBtn from 'components/buttons/BigCircleBtn'
import { useReduxDispatch, useReduxSelector } from 'store'
import { ReactComponent as Alert } from 'assets/alert.svg'
import { ReactComponent as Gate } from 'assets/gate.svg'
import CircleBtn from 'components/buttons/CircleBtn'
import { MAG_STATUS, STATUS } from 'utils/constants'
import { useHistory } from 'react-router-dom'
import React from 'react'

interface MagActionsProps {
  id: string
  expanded: boolean
  toggleMenu: basic.BasicFunc
}

const MagActions: React.FC<MagActionsProps> = ({ id, expanded, toggleMenu }) => {
  const { status = MAG_STATUS.closed, hasOnlineAccess, gateHasOnlineAccess, placeAddress, isAlreadyOpened = false } = useReduxSelector(state => selectWarehouse(state, id))

  const dispatch = useReduxDispatch()
  const { push } = useHistory()

  const updateStatus = (status: warehouse.WarehouseStatus) => {
    if (!isAlreadyOpened) push(`/first/${id}`)
    else dispatch(updateWarehouse({
      id,
      values: { status }
    }))
  }

  return (
    <div className='menu__buttons --top'>
      {hasOnlineAccess ? <CircleBtn
        Icon={Compass}
        text='Nawiguj'
        action={() => {
          window.open(`https://www.google.com/maps/search/?api=1&query=${placeAddress}`)
        }}
      /> : <CircleBtn
        text='Zgłoś problem'
        Icon={Alert}
        action={() => {
          push('/help')
        }} />}

      <div className='buttons__col center'>
        <div style={hasOnlineAccess ? undefined : { opacity: 0.5 }}>
          <BigCircleBtn
            text={status === MAG_STATUS.opening ? 'Otwieram...'
              : status === MAG_STATUS.closing ? 'Zamykam...'
                : status === MAG_STATUS.opened ? 'Zamknij' : 'Otwórz'}
            status={(typeof status === 'string' && status?.includes('ing')) ? STATUS.pending : STATUS.idle}
            action={() => {
              if (hasOnlineAccess) updateStatus(status === MAG_STATUS.opened ? MAG_STATUS.closing : MAG_STATUS.opening)
            }} />
        </div>

        {!expanded && <CircleBtn
          text='Zarządzaj'
          Icon={Arrow}
          action={toggleMenu} />}
      </div>

      <CircleBtn
        disabled={!gateHasOnlineAccess}
        text='Otwórz bramę'
        Icon={Gate}
        action={() => {
          push('/entrance')
        }} />
    </div>
  )
}

export default MagActions