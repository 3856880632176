import { updateWarehouse } from 'containers/Warehouses/warehousesSlice'
import React, { useCallback, useEffect } from 'react'
import rightDoor from 'assets/warehouse/rightDoor.png'
import leftDoor from 'assets/warehouse/leftDoor.png'
import shadow from 'assets/warehouse/shadow.png'
import { MAG_STATUS } from 'utils/constants'
import { useDispatch } from 'react-redux'
import Alert from './Alert'
import './style.scss'

interface WarehouseProps {
  id?: string
  status?: warehouse.WarehouseStatus
  alerts?: warehouse.WarehouseAlert[]
  showShadow?: boolean
}

const Warehouse: React.FC<WarehouseProps> = ({ id, status = MAG_STATUS.closed, alerts = [], showShadow = false }) => {
  const isAlert = alerts.length > 0
  const mod = (() => {
    switch (status) {
      case MAG_STATUS.closing: return '--closed --animate'
      case MAG_STATUS.opening: return '--opened --animate'
      case MAG_STATUS.opened: return '--opened'
      default: return '--closed'
    }
  })()

  const dispatch = useDispatch()
  const updateState = useCallback((key: keyof warehouse.Warehouse, value: any) => {
    if (id) {
      dispatch(updateWarehouse({ id, values: { [key]: value } }))
    }
  }, [dispatch, id])
  const updateStatus = useCallback((status: warehouse.WarehouseStatus) => {
    updateState('status', status === MAG_STATUS.closing ? MAG_STATUS.closed : MAG_STATUS.opened)
  }, [updateState])
  const removeAlert = (index: number) => {
    updateState('alerts', [...alerts].filter((_, i) => i !== index))
  }

  useEffect(() => {
    if (!id) return
    if (status === MAG_STATUS.closing || status === MAG_STATUS.opening) {
      const timeout = setTimeout(() => updateStatus(status), 3000)
      return () => clearTimeout(timeout)
    }
  }, [id, status, updateStatus])

  return (
    <div className={`warehouse__animation ${mod} ${isAlert ? ' --warning' : showShadow ? ' --shadow' : ''}`}>
      <div className='animation__interior'>
        <img className='animation__door --left' src={leftDoor} alt='door' />
        <img className='animation__door --right' src={rightDoor} alt='door' />
        <img className='animation__shadow' src={shadow} alt='shadow' />
        {isAlert && <Alert
          {...alerts[0]}
          action={() => removeAlert(0)}
        />}
      </div>
    </div>
  )
}

export default Warehouse