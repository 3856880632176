import ArrowBtn from 'components/buttons/ArrowBtn'
import useTranslation from 'hooks/useTranslation'
import { displayPrice } from 'utils/string'
import React, { useState } from 'react'
import { basicDelivery } from '.'

interface DefaultViewProps {
  productName: string
  price: string
  delivery?: payments.Delivery
  popupName?: string
  additionalText?: string
  cancelAction: basic.BasicFunc
  deliveryMethods: payments.DeliveryMethod[]
  recentlySelectedDeliveries: payments.Delivery[]
  setDelivery: React.Dispatch<React.SetStateAction<payments.Delivery>>
  setEditable: React.Dispatch<React.SetStateAction<boolean>>
  saveDelivery: basic.BasicFunc
}

const DefaultView: React.FC<DefaultViewProps> = ({ popupName = 'Dostawa', additionalText, productName, cancelAction, price, deliveryMethods, delivery, recentlySelectedDeliveries, setDelivery, setEditable, saveDelivery }) => {
const [optionsAreVisible, showOptions] = useState(!recentlySelectedDeliveries?.length)
  const { t } = useTranslation('delivery')

  return (
    <>
      <div className='popup__title'>
        <div>{popupName}</div>
        <i
          className='icon icon--back s32'
          onClick={cancelAction}
        />
      </div>

      <div className='popup__delivery'>
        {additionalText && <div className='popup__delivery__additionalText'>{additionalText}</div>}
        <div className='popup__delivery__product'>
          <h2>Produkt do wysyłki</h2>
          <div className='popup__delivery__product__item checked'>
            <h2>{productName}</h2>
            <span>Cena: {displayPrice(price)}</span>
            <div className='checkCircle'>
              <i className='icon icon--check' />
            </div>
          </div>
        </div>

        {optionsAreVisible
          ? <>
            <h2>Wybierz sposób dostawy</h2>
            <div className='popup__delivery__options'>
              {deliveryMethods.map((_) => {
                return <div
                  key={_.id}
                  className={`selectItem ${delivery?.deliveryMethodId === _.id ? 'checked' : ''}`}
                  onClick={() => {
                    setDelivery(() => {
                      const base = {
                        id: '',
                        deliveryMethodId: _.id,
                        type: _.deliveryMethodKey
                      } as payments.Delivery
                      switch (_.deliveryMethodKey) {
                        case 'totem':
                        case 'magbox':
                        case 'code_locker': return { ...base, pointId: '' }
                        case 'courier': return { ...base, address: {} }
                        default: return base
                      }
                    })
                  }}>
                  <h2>{_.name}</h2>
                  <span>brak ceny</span>
                  <div className='checkCircle'>
                    <i className='icon icon--check' />
                  </div>
                </div>
              })}
            </div>
          </> : <>
            <h2>Wybierz sposób dostawy</h2>
            <div className='popup__delivery__saved'>
              {recentlySelectedDeliveries.map((recentlySelectedDelivery, index) => {
                const { id, type, address, pointId } = recentlySelectedDelivery
                const isSelected = id === delivery?.id
                return <div
                  key={id}
                  className={`popup__delivery__saved__item${isSelected ? ' checked' : ''}`}
                  onClick={() => {
                    setEditable(false)
                    setDelivery(isSelected ? basicDelivery : recentlySelectedDelivery)
                  }}>
                  {!index && <div className='priceWrap --green'>
                    <span>Ostatnio wybrane</span>
                  </div>}
                  <div className='popup__delivery__saved__item__content'>
                    <h2>{t(type)}</h2>
                    {address?.name && <p>{address.name} {address.surname}</p>}
                    {pointId && <p>Punkt: {pointId}</p>}
                    {(address?.street || address?.city) && <p>{address.street} {address.building_nr}, {address.city}</p>}
                  </div>
                  <div className='popup__delivery__saved__item__actions'>
                    <i
                      className='icon icon--rename'
                      onClick={e => {
                        e.stopPropagation()
                        setEditable(true)
                        setDelivery(recentlySelectedDelivery)
                      }} />
                    <div className='checkCircle'>
                      <i className='icon icon--check' />
                    </div>
                  </div>
                </div>
              })}

              <div
                className='btn'
                onClick={() => {
                  setEditable(true)
                  showOptions(true)
                  setDelivery(basicDelivery)
                }}>
                <i className='icon icon--plus2' />
                Dodaj inny sposób dostawy
              </div>
            </div>
          </>}

        <ArrowBtn
          enlarged
          text={'Gotowe'}
          disabled={!delivery?.deliveryMethodId}
          action={saveDelivery}
        />
      </div>
    </>
  )
}

export default DefaultView