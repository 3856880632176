import { selectUser, updateDeviceVerification } from 'containers/Login/loginSlice'
import { selectUserData, ACCOUNT_TYPE, updateValue } from '../accountSlice'
import { ReactComponent as Phone } from 'assets/phone.svg'
import { LOCATION_STATES, STATUS } from 'utils/constants'
import { detectBrowser, isMobile } from 'utils/isMobile'
import { useDispatch, useSelector } from 'react-redux'
import { ReactComponent as Pc } from 'assets/pc.svg'
import useTranslation from 'hooks/useTranslation'
import useUserFetch from '../hooks/useUserFetch'
import Switch from 'components/inputs/Switch'
import { useHistory } from 'react-router-dom'
import { userConfig } from 'rest/urls'
import { PATCH } from 'rest/request'
import Item from '../Item'

const type = ACCOUNT_TYPE.settings
const mobile = isMobile()

const UserSettings = () => {
  const { biometrics = true } = useSelector(state => selectUserData(state, type))
  const { data } = useSelector(state => selectUserData(state, ACCOUNT_TYPE.basic))
  const { pin, device_verification } = useSelector(selectUser)
  const status = useUserFetch(ACCOUNT_TYPE.basic)

  const { t } = useTranslation('account')
  const dispatch = useDispatch()
  const { push } = useHistory()

  const showPopup = name => push(window.location.pathname, LOCATION_STATES.popup(name))
  const updateVal = (name, value) => dispatch(updateValue({ type, name, value }))

  const updateVerification = async () => {
    try {
      const body = { device_verification: !device_verification }
      await PATCH(userConfig, { body })
      dispatch(updateDeviceVerification(!device_verification))

    } catch (err) {
      console.error(`Error: ${err}`)
    }
  }

  return (
    <>
      <p>{t('appSecurity')}</p>
      <div className='account__list'>
        <Item
          title={t('biometrics')}
          desc={t(biometrics ? 'on' : 'off')}
          right={<Switch
            name='biometrics'
            checked={biometrics}
            changeHandler={() => updateVal('biometrics', !biometrics)} />
          } />

        <Item
          title={t('deviceAuthorization')}
          desc={t(device_verification ? 'on' : 'off')}
          right={<Switch
            name='deviceAuthorization'
            checked={device_verification}
            changeHandler={updateVerification} />
          } />

        <Item
          showArrow
          title={t('pinCode')}
          desc={t(pin ? 'set' : 'none')}
          action={() => showPopup('changePin')} />
      </div>

      <p>{t('identityVerification')}</p>
      <div className='account__list'>
        <Item
          showArrow
          title={t('secondaryPhoneNumber')}
          desc={status === STATUS.pending ? 'ładowanie...' : (data?.additional_phone || t('none'))}
          action={() => {
            if (status === STATUS.succeed) showPopup('editAdditionalPhone')
          }}
        />

        <Item
          showArrow
          title={t('secondaryEmailAddress')}
          desc={status === STATUS.pending ? 'ładowanie...' : (data?.additional_email || t('none'))}
          action={() => {
            if (status === STATUS.succeed) showPopup('editAdditionalMail')
          }}
        />
      </div>

      <p>{t('activityHistory')}</p>
      <div className='account__list'>
        <Item
          showArrow
          desc={t('now')}
          icon={mobile ? <Phone /> : <Pc />}
          title={<span>{t(mobile ? 'mobileDevice' : 'desktop')} - {detectBrowser()}</span>}
          action={() => showPopup('deviceList')} />
      </div>
    </>
  )
}

export default UserSettings