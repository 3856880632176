import { selectBasketList, BASKET_TYPES } from 'containers/Basket/basketSlice'
import TimerContext from '../context/Timer/Context'
import React, { useContext } from 'react'
import { useReduxSelector } from 'store'

interface BasketIconProps {
  action: basic.BasicFunc
}

const BasketIcon: React.FC<BasketIconProps> = ({ action }) => {
  return (
    <div className='header__basketIcon'>
      <i
        className='icon icon--basket s24'
        onClick={action} />
      <BasketIconAmount />
      <BasketIconTimer />
    </div>
  )
}

const BasketIconAmount: React.FC = () => {
  const list = useReduxSelector(selectBasketList)
  return (
    <span className='header__basketIcon__amount'>
      {list?.reduce((r: number, e: basic.Object<string>) => r += e.type === BASKET_TYPES.rent ? 1 : 0, 0)}
    </span>
  )
}

const BasketIconTimer: React.FC = () => {
  const time = useContext(TimerContext)
  return (
    <span className='header__basketIcon__timer'>
      {timeToString(time)}
    </span>
  )
}

const timeToString = (seconds: number) => {
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.round(seconds % 60)
  return `${m}:${m && s < 10 ? '0' + s : s}`
}

export default BasketIcon