import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'

interface State {
  popup: unknown
}

const usePopupState = (delay = 200) => {
  const [popup, setPopup] = useState<unknown>(undefined)
  const { state, pathname } = useLocation<State>()

  useEffect(() => {
    const isPopup = state?.popup
    if (isPopup) setPopup(isPopup)
    else {
      const timeout = setTimeout(() => setPopup(isPopup), delay)
      return () => clearTimeout(timeout)
    }
  }, [delay, state?.popup])

  return { popup, pathname }
}

export default usePopupState