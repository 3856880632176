import useTranslation from 'hooks/useTranslation'
import React from 'react'
import { MONTHS } from 'utils/date'

interface DateTileProps {
  start?: boolean
  date?: Date
  action?: basic.BasicFunc
}

const DateTile: React.FC<DateTileProps> = ({ start = false, date, action }) => {
  const { t } = useTranslation()
  const message = t('calendar.forIndefinitePeriodArray')

  return <div
    className='calendar__input'
    onClick={action}>

    <div className='input__shadow'>
      <div />
      <div />
    </div>

    <div className='input__main'>
      <div>{t(`filters.${start ? 'from' : 'to'}`)}</div>
      {date && typeof date === 'object' ? <>
        <div className='input__month'>{MONTHS.short[date.getMonth()]}</div>
        <div className='input__day --termina'>{date.getDate()}</div>
        <hr />
        <div className='input__year'>{date.getFullYear()}</div>
      </> : Array.isArray(message) ? <>
        <div className='input__month'>{message[0]}</div>
        <div className='input__day --termina'>{message[1]}</div>
        <hr />
        <div className='input__year'>{message[2]}</div>
      </> : false}
    </div>
  </div>
}

export default DateTile