import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from 'store'

const initialState: rest.InitialState = {
  isPopup: false
}

const restSlice = createSlice({
  name: 'rest',
  initialState,
  reducers: {
    showPopup: (state, action: PayloadAction<undefined | rest.PopupBody>) => {
      state.isPopup = !!action.payload
      state.popupBody = action.payload
    }
  }
})

export const { showPopup } = restSlice.actions

export const selectRest = (state: RootState) => state.rest
export const selectRestIsPopup = (state: RootState) => state.rest.isPopup
export const selectRestPopupBody = (state: RootState) => state.rest.popupBody || {} as rest.PopupBody

export default restSlice.reducer