import { BASKET_TYPES, addItemToBasket, removeItemFromBasket, selectBasketItem, selectBasketList } from 'containers/Basket/basketSlice'
import useRelatedBasketItemsGroupedByOpeningMethodKey from './hooks/useRelatedBasketItemsGroupedByOpeningMethodKey'
import { selectCalendar } from 'containers/OrderDetails/Calendar/calendarSlice'
import { useRef, useState, useImperativeHandle, forwardRef } from 'react'
import InfoPlaceLockPopup from './popups/InfoPlaceLockPopup'
import { useReduxDispatch, useReduxSelector } from 'store'
import InfoMagLockPopup from './popups/InfoMagLockPopup'
import { LOCATION_STATES } from 'utils/constants'
import usePopupState from 'hooks/usePopupState'
import { useHistory } from 'react-router-dom'
import RfidPopup from './popups/RfidPopup'
import LockPopup from './popups/LockPopup'
import './style.scss'

const Locks = forwardRef((_, ref) => {
  const basketList = useReduxSelector(selectBasketList)
  const selectedBasketItem = useReduxSelector(selectBasketItem)
  const { lock, placeLock } = useReduxSelector(selectCalendar)
  const relatedBasketItemsGroupedByOpeningMethodKeyToGate = useRelatedBasketItemsGroupedByOpeningMethodKey(selectedBasketItem?.placeId)
  const relatedBasketItemsGroupedByOpeningMethodKeyToWarehouse = useRelatedBasketItemsGroupedByOpeningMethodKey(selectedBasketItem?.placeId, selectedBasketItem?.identifier)

  const { popup, pathname } = usePopupState()
  const [lockError, setLockError] = useState({
    place: false,
    mag: false
  })

  const dispatch = useReduxDispatch()
  const { push } = useHistory()

  const magLockRef = useRef(null)
  const placeLockRef = useRef(null)
  const currentBasketButtonProps = useRef({})
  currentBasketButtonProps.current = {
    lock,
    placeLock,
    basketList,
    placeId: selectedBasketItem.placeId,
    cartId: selectedBasketItem.identifier
  }

  useImperativeHandle(ref, () => {
    const scrollToRef = (ref) => {
      if (ref.current) {
        ref.current.scrollIntoView({ behavior: 'smooth' })
      }
    }

    return (() => {
      const { basketList, lock, placeLock, placeId, cartId } = currentBasketButtonProps.current
      const isPlaceLockSelected = checkIfSecurityIsSelected(placeLock?.gate, basketList, placeId)
      const isMagLockSelected = checkIfSecurityIsSelected(lock, basketList, placeId, cartId)

      if (isPlaceLockSelected && isMagLockSelected) {
        return true
      } else {
        scrollToRef(!isPlaceLockSelected ? placeLockRef : magLockRef)
        setLockError({
          mag: !isMagLockSelected,
          place: !isPlaceLockSelected
        })
        return false
      }
    })
  }, [])

  const handleMethodClick = ({ methodObject, cartId, ownerId }) => {
    if (!methodObject.hasOptions) {
      if (cartId) {
        dispatch(removeItemFromBasket({ removeIdentifiers: [cartId] }))

      } else if (selectedBasketItem) {
        const selectedMethodObject = {
          type: BASKET_TYPES.rentAddon,
          id: methodObject.id,
          name: methodObject.name,
          placeId: selectedBasketItem.placeId,
          openingMethodKey: methodObject.openingMethodKey
        }
        if (ownerId) {
          selectedMethodObject.ownerId = ownerId
          if (lockError.mag) setLockError(p => ({ ...p, mag: false }))

        } else if (lockError.place) {
          setLockError(p => ({ ...p, place: false }))
        }
        dispatch(addItemToBasket(selectedMethodObject))
      }
    } else {
      push(pathname, LOCATION_STATES.popup({ type: methodObject.openingMethodKey, method: methodObject, addToGate: !ownerId }))
    }
  }

  const generateStringListOfSelectedItems = (selectedItems) => {
    if (!selectedItems) return
    const groupedItems = selectedItems.reduce((r, { name, amount, objectId }) => {
      if (r[objectId]) {
        r[objectId].amount += amount
      } else {
        r[objectId] = {
          amount,
          name
        }
      }
      return r
    }, {})
    return Object.values(groupedItems).reduce((r, e, i) => r += `${i ? ', ' : ''}${e.amount > 1 ? `${e.amount} x ` : ''}${e.name}`, '')
  }

  return (
    <>
      {popup === 'info_mag_lock' && <InfoMagLockPopup
        body={lock}
      />}
      {popup === 'info_place_lock' && <InfoPlaceLockPopup
        body={placeLock}
      />}
      {popup?.type && <>
        {popup.type === 'rfid'
          ? <RfidPopup
            method={popup?.method}
            addToGate={popup?.addToGate}
          /> : <LockPopup
            method={popup?.method}
            addToGate={popup?.addToGate}
            selectedItems={
              popup?.addToGate
                ? relatedBasketItemsGroupedByOpeningMethodKeyToGate?.[popup?.method?.openingMethodKey]
                : relatedBasketItemsGroupedByOpeningMethodKeyToWarehouse?.[popup?.method?.openingMethodKey]
            }
          />}
      </>}

      <div className='locks'>
        <div className='locks__section' ref={placeLockRef}>
          <div className='title'>
            <div className='group'>
              <span>Sposób otwarcia placu</span>
              <i
                className='icon icon--quest'
                onClick={() => push(pathname, LOCATION_STATES.popup('info_place_lock'))}
              />
            </div>
            <span className={`error${lockError.place ? ' active' : ''}`}>Wybierz sposób otwarcia</span>
          </div>
          <div className='content'>
            {placeLock.gate && placeLock.gate.openingMethods && placeLock.gate.openingMethods.map((method) => {
              const group = relatedBasketItemsGroupedByOpeningMethodKeyToGate?.[method.openingMethodKey]
              const description = generateStringListOfSelectedItems(group)
              const cartId = group?.at(-1)?.identifier
              return <div
                key={method.id}
                className={`lockItem ${cartId ? 'checked' : ''}`}
                onClick={() => {
                  handleMethodClick({ methodObject: method, cartId })
                }}>
                <div className='lockItem__left'>
                  <div className='iconWrap'><i className='icon icon--byapp'></i></div>
                </div>
                <div className='lockItem__center'>
                  <h3>{method.nameForClient}</h3>
                  <span>{description || method.descriptionForClient}</span>
                </div>
                <div className='lockItem__right'>
                  <div className='checkCircle'><i className='icon icon--check'></i></div>
                </div>
              </div>
            })}
          </div>
        </div>

        <div
          ref={magLockRef}
          className='locks__section'>
          <div className='title'>
            <div className='group'>
              <span>Sposób otwarcia magazynu</span>
              <i
                className='icon icon--quest'
                onClick={() => push(pathname, LOCATION_STATES.popup('info_mag_lock'))}
              />
            </div>
            <span className={`error${lockError.mag ? ' active' : ''}`}>Wybierz sposób otwarcia</span>
          </div>
          <div className='content'>
            {lock && lock.openingMethods && lock.openingMethods.map((method) => {
              const group = relatedBasketItemsGroupedByOpeningMethodKeyToWarehouse?.[method.openingMethodKey]
              const description = generateStringListOfSelectedItems(group)
              const cartId = group?.at(-1)?.identifier
              return <div
                key={method.id}
                className={`lockItem ${cartId ? 'checked' : ''}`}
                onClick={() => {
                  handleMethodClick({ methodObject: method, cartId, ownerId: selectedBasketItem.identifier })
                }}>
                <div className='lockItem__left'>
                  <div className='iconWrap'>
                    <i className='icon icon--byapp' />
                  </div>
                </div>
                <div className='lockItem__center'>
                  <h3>{method.name}</h3>
                  <span>{description || method.descriptionForClient}</span>
                </div>
                <div className='lockItem__right'>
                  <div className='checkCircle'>
                    <i className='icon icon--check' />
                  </div>
                </div>
              </div>
            })}
          </div>
        </div>
      </div>
    </>)
})

const checkIfSecurityIsSelected = (locks, list, placeId, cartId) => {
  if (!locks) return true
  for (const item of list) {
    if (item.type === 'rentAddon' && (
      item.ownerId
        ? item.ownerId === cartId
        : item.related
          ? (item.related.some(r => cartId ? r.cartId === cartId : r.id === placeId))
          : (!cartId && item.placeId === placeId)
    )) {
      return true
    }
  }
  return false
}

export default Locks