import React from 'react'

interface LocationSectionProps {
  placeName: string
  placeAddress: string
}

const LocationSection: React.FC<LocationSectionProps> = ({ placeName, placeAddress }) => {
  return (
    <div>
      <div className='list__header element__title'>Lokalizacja</div>
      <div className='list__element --big'>
        <div className='group'>
          <div className='element__desc'>
            <div>{placeName}</div>
            {placeAddress && placeAddress.split(', ').map((text, index) => <div key={index}>{text}</div>)}
          </div>
          <div
            className='element__desc'
            onClick={() => window.open(`https://www.google.com/maps/search/?api=1&query=${placeAddress}`)}>Pokaż</div>
        </div>
      </div>
    </div>
  )
}

export default LocationSection