import { selectPlaces } from '../Maps/Map/mapSlice'
import { useEffect, useRef, useState } from 'react'
import { selectProvinces } from './mapSearchSlice'
import { checkAddress } from '../../rest/urls'
import { STATUS } from '../../utils/constants'
import { POST } from '../../rest/request'
import { useSelector } from 'react-redux'
import PlaceItem from './PlaceItem'

const Places = ({ search, selectPosition }) => {
  const [matchingPlaces, setMatchingPlaces] = useState([])
  const [matchingProvinces, setMatchingProvinces] = useState([])
  const [matchingLocations, setMatchingLocations] = useState('')
  const [fetchStatus, setFetchStatus] = useState(STATUS.idle)

  const provinces = useSelector(selectProvinces)
  const places = useSelector(selectPlaces)

  const fetchControllerRef = useRef(null)


  useEffect(() => {
    if (!search) return
    const searchList = search.split(/ |,/)
    const regList = searchList.reduce((r, e) => {
      if (e) r.push(new RegExp(e, 'i'))
      return r
    }, [])

    if (places.length) {
      const validPlaces = places.filter(e => {
        const { street, estateNumber, localNumber, zip, city, district, province, country } = e
        const address = `${street} ${estateNumber}${localNumber ? `/${localNumber}` : ''} ${zip} ${city} ${district} ${province} ${country}`
        const isLocation = regList.reduce((r, e) => r && !!address.match(e), true)
        return isLocation
      })
      setMatchingPlaces(validPlaces)
    }

    if (provinces.length) {
      const validProvinces = provinces.filter(({ search }) => {
        return regList.reduce((r, e) => r && !!search.match(e), true)
      })
      setMatchingProvinces(validProvinces)
    }

  }, [places, provinces, search])

  useEffect(() => {
    if (search.length > 1 && matchingPlaces.length === 0) {
      if (fetchControllerRef.current) {
        fetchControllerRef.current.abort()
        fetchControllerRef.current = null
      }

      const controller = new AbortController()
      const signal = controller.signal

      fetchControllerRef.current = controller
      setFetchStatus(STATUS.pending);

      (async () => {
        try {
          const response = await POST(checkAddress, { body: { address: search }, signal })
          if (response) {
            setFetchStatus(STATUS.succeed)
            setMatchingLocations(response)
          }

        } catch (err) {
          setFetchStatus(STATUS.failed)
          setMatchingLocations('')
        }
      })()
    } else setMatchingLocations('')
  }, [matchingPlaces, search])

  return (
    <section>
      <div className='search__list'>
        {matchingProvinces.length > 0 && matchingProvinces.map((e, idx) => <PlaceItem
          key={idx}
          logo={false}
          data={e}
          selectPosition={selectPosition} />)}

        {matchingPlaces.length > 0 ? [...matchingPlaces]
          .splice(0, 20)
          .map((e, idx) => <PlaceItem
            key={idx}
            data={e}
            selectPosition={selectPosition} />)
          : (fetchStatus === STATUS.succeed && matchingLocations.length > 0) && matchingLocations.map(({ number, ...rest }, idx) => <PlaceItem
            key={idx}
            logo={false}
            data={{ estateNumber: number, ...rest }}
            selectPosition={selectPosition} />)}
      </div>

      <h1>{matchingPlaces.length > 0 ? `Znaleziono (${matchingPlaces.length + matchingProvinces.length})`
        : fetchStatus === STATUS.succeed && matchingLocations.length + matchingProvinces.length > 0 ? `Znaleziono (${matchingLocations.length + matchingProvinces.length})`
          : fetchStatus === STATUS.pending ? 'ładowanie...'
            : 'Brak wyników'}</h1>
    </section>
  )
}

export default Places