import { fetchToken, selectExpirationDate, selectIsLoggedIn } from 'containers/Login/loginSlice'
import { resetAccount } from 'containers/Account/accountSlice'
import { useReduxDispatch, useReduxSelector } from 'store'
import GetFirebaseContext from 'context/firebase/Context'
import { useContext, useEffect } from 'react'

const Auth: React.FC<{ children: JSX.Element }> = ({ children }) => {
  const getFirebaseToken = useContext(GetFirebaseContext)
  const expirationDate = useReduxSelector(selectExpirationDate)
  const isLogIn = useReduxSelector(selectIsLoggedIn)
  const dispatch = useReduxDispatch()

  useEffect(() => {
    if (isLogIn && expirationDate) {
      let interval = setInterval(() => dispatch(fetchToken()), expirationDate)
      return () => clearInterval(interval)
    }
  }, [dispatch, expirationDate, isLogIn])

  useEffect(() => {
    if (isLogIn) {
      getFirebaseToken()
      return () => { dispatch(resetAccount()) }
    }
  }, [dispatch, getFirebaseToken, isLogIn])

  return children
}

export default Auth