import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { DISTANCE_LIST, getDistance, getZoom } from './mapUtils'
import { mapLications } from '../../../rest/urls'
import { STATUS } from '../../../utils/constants'
import { POST } from '../../../rest/request'

export const fetchMapLocations = createAsyncThunk('map/fetchMapLocations', async ({ body, signal }) => {
  const response = await POST(mapLications, { body, signal })
  return response
})

export const fetchMapStatus = createAsyncThunk('map/fetchMapStatus', async ({ body, signal }) => {
  const response = await POST(mapLications, { body, signal })
  return response
})

const addDistanceToList = (position, places) => {
  return places.map(e => {
    const { gpslatitude, gpslongitude } = e
    const p = [gpslatitude || 51.666, gpslongitude || 19.389]
    const distance = getDistance(position, p)?.toFixed(1)

    return { ...e, distance }
  })
}

export const mapSlice = createSlice({
  name: 'map',
  initialState: {
    options: {
      zoom: 5,
      distance: 0,
      showMarker: false,
      position: undefined,
      mapPosition: undefined,
      voivodship: undefined,
    },
    places: [],
    status: STATUS.idle,
  },
  reducers: {
    resetMapStatus: (state) => {
      state.status = STATUS.idle
    },
    updateMapOption: (state, action) => {
      const { position, mapPosition, showMarker, distance, zoom, voivodship } = action.payload

      if (position || distance) {
        const d = distance || DISTANCE_LIST[2]
        state.options.distance = d
        state.options.zoom = getZoom(d)
      }

      if (voivodship !== undefined) {
        state.options.zoom = getZoom('')
        state.options.position = undefined
        state.options.voivodship = voivodship
      }

      if (position) {
        state.options.position = position
        state.options.mapPosition = position
        state.options.voivodship = undefined
        state.places = addDistanceToList(position, state.places)
      }

      if (zoom) state.options.zoom = zoom
      if (mapPosition) state.options.mapPosition = mapPosition
      if (showMarker !== undefined) state.options.showMarker = showMarker
    }
  },
  extraReducers: {
    [fetchMapLocations.fulfilled]: (state, action) => {
      const { places } = action.payload
      const { position } = state.options

      state.places = position ? addDistanceToList(position, places) : places
      state.status = STATUS.succeed
    },
    [fetchMapStatus.fulfilled]: (state, action) => {
      const { places } = action.payload
      const { position } = state.options

      state.places = position ? addDistanceToList(position, places) : places
    },

    [fetchMapLocations.pending]: (state, action) => {
      state.status = STATUS.pending
    },
    [fetchMapLocations.rejected]: (state, action) => {
      state.status = STATUS.failed
    },
  }
});

export const { updateMapOption, resetMapStatus } = mapSlice.actions

export const selectMapOptions = state => state.map.options
export const selectMapStatus = state => state.map.status
export const selectPlaces = state => state.map.places

export default mapSlice.reducer
