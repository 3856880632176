import { fetchMapDetails, selectPlace, selectPlaceId, selectStatus } from './mapDetailsSlice'
import { addItemToBasket, BASKET_TYPES } from '../Basket/basketSlice'
import { LOCATION_STATES, STATUS } from '../../utils/constants'
import { useLocation, useParams } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import usePopupState from '../../hooks/usePopupState'
import htbox1 from '../../assets/img/htbox1.png'
import htbox2 from '../../assets/img/htbox2.png'
import DetailsPopup from './popups/DetailsPopup'
import DetailsLocation from './DetailsLocation'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import Error from '../../components/Error'
import MagElement from './MagElement'
import './style.scss'

const MapDetails = () => {
  const [userSelectedDifferentHash, selectDifferentHashByUser] = useState(false)
  const [isDimensions, setDimensions] = useState(false)
  const [sizeArray, setSizeArray] = useState([])
  const [type, setType] = useState()
  const [size, setSize] = useState({
    hash: '',
    index: 0,
  })

  const { warehouses, sizes, ...place } = useSelector(selectPlace)
  const placeId = useSelector(selectPlaceId)
  const status = useSelector(selectStatus)
  const dispatch = useDispatch()

  const { search } = useLocation()
  const { placeId: placeIdParams } = useParams()
  const { popup, pathname } = usePopupState()
  const { push } = useHistory()

  const succeed = status === STATUS.succeed

  useEffect(() => {
    dispatch(fetchMapDetails(placeIdParams))
  }, [placeIdParams, dispatch])

  useEffect(() => {
    const query = new URLSearchParams(search)
    const warehouseTypeId = query.get('warehouseTypeId')

    if (warehouseTypeId) setSize({
      hash: warehouseTypeId,
      index: -1
    })
  }, [search])

  useEffect(() => {
    if (warehouses && placeId === placeIdParams) {
      const keys = Object.entries(warehouses)
        .sort((a, b) => {
          const getSize = (v) => {
            const firstKey = Object.keys(v)[0]
            return v[firstKey]?.data?.capacity || 0
          }
          return getSize(a[1]) - getSize(b[1])
        })
        .map(([k]) => k)
      setSizeArray(keys)
    }
  }, [warehouses, placeId, placeIdParams])

  useEffect(() => {
    if (!size.hash && sizeArray[0]) setSize({
      hash: sizeArray[0],
      index: 0
    })
    else if (size.index === -1 && sizeArray.length > 0) {
      const newIdx = sizeArray.findIndex(e => e === size.hash)
      if (newIdx > -1) setSize({
        ...size,
        index: newIdx
      })
      else {
        selectDifferentHashByUser(true)
        setSize({
          hash: sizeArray[0],
          index: 0
        })
      }
    }
  }, [size, sizeArray])

  useEffect(() => {
    if (size.hash && warehouses && warehouses[size.hash]) {
      const group = warehouses[size.hash]
      const firstElementInGroup = warehouses[size.hash][Object.keys(group)[0]]
      setType(firstElementInGroup)
    }
  }, [size.hash, warehouses])

  const changeHash = (toBigger = true) => {
    const newIdx = size.index + (toBigger ? 1 : -1)
    if (sizeArray[newIdx]) setSize({
      hash: sizeArray[newIdx],
      index: newIdx
    })
  }

  return (
    <div className='placeResult'>
      {popup === 'details' && <DetailsPopup data={type?.details} />}

      <div className='placeResult__location'>
        <DetailsLocation succeed={succeed} />
      </div>

      {status === STATUS.failed
        ? <Error />
        : <section className='--animated'>
          {((succeed && warehouses[size.hash]) || status === STATUS.pending) && <>
            <div className='placeResult__mag'>
              <div className='placeResult__mag__title'>
                <h2>{sizes?.[size.index]?.name}</h2>
                <span></span>
                <h2>{sizes?.[size.index]?.size} m<sup>2</sup></h2>
              </div>

              <div className='placeResult__mag__graphics'>
                <div
                  className='mag__marker'
                  onClick={() => succeed && size.index > 0 && changeHash(false)}
                  style={size.index === 0 ? { opacity: '0.5' } : {}}>

                  <div className='marker'>
                    <i className='icon icon--chevron-left' />
                  </div>
                  <span>Mniejszy</span>
                </div>

                {succeed && (
                  <div className='mag__img'>
                    <img
                      alt='warehouse'
                      src={isDimensions
                        ? (/* sizes[size.index]?.renders?.transparent || */ htbox2)
                        : (/* sizes[size.index]?.renders?.default || */ htbox1)
                      }
                      style={userSelectedDifferentHash ? { opacity: .5 } : {}}
                    />
                    {userSelectedDifferentHash && <div className='svg__title'>Niedostępny</div>}
                  </div>
                )}

                <div
                  className='mag__marker'
                  onClick={() => succeed && size.index < sizeArray.length - 1 && changeHash()}
                  style={(!succeed || size.index === sizeArray.length - 1) ? { opacity: '0.5' } : undefined}>
                  <div className='marker'>
                    <i className='icon icon--chevron-right' />
                  </div>
                  <span>Większy</span>
                </div>

              </div>

              <div className='placeResult__mag__buttons'>
                <span className={isDimensions ? undefined : 'active'} onClick={() => setDimensions(false)}>Dostępne magazyny</span>
                <span className={isDimensions ? 'active' : undefined} onClick={() => setDimensions(true)}>Co tu zmieszczę?</span>
              </div>
            </div>
            {isDimensions
              ? (({ internalHeight, internalLength, internalWidth, doorHeight, doorWidth, fits }) => {
                return <div className='placeResult__sizes'>
                  <div className='placeResult__sizes__item'>
                    <h3>Wymiary wew:</h3>
                    <span>S: {internalWidth}m x W: {internalHeight}m x D: {internalLength}m</span>
                  </div>
                  <div className='placeResult__sizes__item'>
                    <h3>Wymiary drzwi:</h3>
                    <span>S: {doorWidth}m x W: {doorHeight}m</span>
                  </div>
                  <h4>Co tu zmieścisz?</h4>
                  <div className='placeResult__sizes__list'>
                    {fits.map(({ name }, idx) => <div
                      key={idx}
                      className='placeResult__sizes__list__item'>
                      <div className='iconWrap'>
                        <i className='icon icon--check'></i>
                      </div>
                      <span>{name}</span>
                    </div>)}
                  </div>
                </div>
              })(sizes[size.index] || {})
              : (<div className='placeResult__magList'>
                {succeed
                  ? Object.entries(warehouses[size.hash])
                    .sort(([, a], [, b]) => a.order - b.order)
                    .map(([k, v]) => (
                      <MagElement
                        key={k}
                        data={v}
                        openDetailsPopup={() => push(pathname, LOCATION_STATES.popup('details'))}
                        action={(date) => {
                          const { street, estateNumber, localNumber, city, province, gpslatitude, gpslongitude } = place
                          const {
                            warehouseTypeName = '',
                            data: { hasOnlineAccess, internalHeight, internalLength, internalWidth, area } = {},
                          } = type || {}

                          dispatch(
                            addItemToBasket({
                              type: BASKET_TYPES.rent,
                              object: v?.data?.object,
                              address: { street, estateNumber, localNumber, city, province, gpslatitude, gpslongitude },
                              minDate: date ? date.replace(/(\d{2})\.(\d{2})\.(\d{4})/, '$3-$2-$1') : undefined,
                              size: { internalHeight, internalLength, internalWidth, area },
                              name: warehouseTypeName,
                              hasOnlineAccess,
                              placeId,
                            })
                          )
                          push(`/calendar/${placeId}/${k}`)
                        }}
                      />
                    ))
                  : [1, 2, 3].map((e) => <MagElement key={e} />)}
              </div>)}
          </>}
        </section>}
    </div>
  )

}

export default MapDetails