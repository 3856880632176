import ArrowBtn from 'components/buttons/ArrowBtn'
import { useHistory } from 'react-router-dom'
import Popup from 'components/popups/Popup'

const MMScorePopup = ({ cancelAction, body }) => {
  const { goBack } = useHistory()

  return (
    <Popup close={cancelAction || goBack}>
      <div className='popup__title'>
        <div>MM Score</div>
      </div>
      <div className='popup__'>
        <span> test </span>
        <ArrowBtn
          enlarged
          showArrow={false}
          text={'Gotowe'}
          action={cancelAction || goBack} />
      </div>
    </Popup>
  )
}

export default MMScorePopup