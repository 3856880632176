import { Store } from '@reduxjs/toolkit'
import { RootState } from 'store'

type StoreType = Store<RootState, any>

class StoreManager {
  private store?: StoreType

  set = (_store: StoreType) => { this.store = _store }
  get = () => this.store
}

const manager = () => {
  const store = new StoreManager()
  return store
}

export default manager()