import { selectIsLoading, selectIsLoggedIn } from '../Login/loginSlice'
import { useHistory, useParams } from 'react-router-dom'
import { useEffect, useRef, useState } from 'react'
import Loading from '../../components/Loading'
import { ACCOUNT_TYPE } from './accountSlice'
import { useSelector } from 'react-redux'
import HiddenMenu from './HiddenMenu'
import Sections from './Sections'
import Popups from './Popups'
import Main from './Main'
import './style.scss'

const hiddenMenu = <HiddenMenu />
const sections = <Sections />
const popups = <Popups />

const Account = () => {
  const isLoading = useSelector(selectIsLoading)
  const isLogin = useSelector(selectIsLoggedIn)
  const [expanded, expand] = useState(true)

  const { replace } = useHistory()
  const { key } = useParams()

  const menuRef = useRef(null)


  useEffect(() => {
    if (!isLogin && !isLoading && key !== ACCOUNT_TYPE.settings) replace('/login')
  }, [isLoading, isLogin, key, replace])

  const onTouch = e => {
    const menu = menuRef.current
    if (!menu || key || menu.scrollTop !== 0) return

    const initialY = e.touches[0].clientY

    const touchMove = e => {
      const currentY = e.touches[0].clientY
      if ((initialY - currentY > 20 && !expanded) || (currentY - initialY > 20 && expanded)) {
        expand(!expanded)
        touchEnd()
      }
    }

    const touchEnd = () => {
      menu.removeEventListener('touchend', touchEnd, false)
      menu.removeEventListener('touchmove', touchMove, false)
    }

    menu.addEventListener('touchend', touchEnd, false)
    menu.addEventListener('touchmove', touchMove, false)
  }

  return (
    <div className='account'>
      {hiddenMenu}

      <div
        ref={menuRef}
        onTouchStart={onTouch}
        className='account__main'
        style={{ top: expanded ? 0 : '15em' }}>
        {isLoading ? <Loading middleOfPage /> : <Main />}
      </div>

      {!isLoading && sections}
      {popups}
    </div>
  )
}

export default Account