import useTranslation from 'hooks/useTranslation'
import { useHistory } from 'react-router-dom'
import './style.scss'

interface BackBtnProps {
  text: string 
  action?: () => void
}

const BackBtn: React.FC<BackBtnProps> = ({ text, action }) => {
  const { t } = useTranslation('basic')
  const { goBack } = useHistory()

  return (
    <button
      className='button --back'
      onClick={action || goBack}>
      <span>{text || t('back')}</span>
    </button>
  )
}

export default BackBtn