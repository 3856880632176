import InaccessiblePopup from '../../components/popups/InaccessiblePopup'
import { useState, useContext, useRef, useEffect } from 'react'
import { selectDevice, DEVICE_TYPES } from '../../appSlice'
import SystemContext from '../socket/SocketContext'
import { STATUS } from '../../utils/constants'
import { useSelector } from 'react-redux'
import CallContext from './CallContext'
import ServiceCall from './ServiceCall'
import Peer from 'simple-peer'
import { v7 } from 'uuid'

const CallProvider = ({ children }) => {

  const deviceType = useSelector(selectDevice)
  const [serviceCall, setServiceCall] = useState(undefined)
  const [status, setStatus] = useState(STATUS.idle)
  const [isBlocked, setBlock] = useState(false)
  const [error, setError] = useState('')

  const { messageData, sendMessage } = useContext(SystemContext)

  const statusRef = useRef(STATUS.idle)
  const connectionRef = useRef(null)
  const serviceVideo = useRef(null)
  const callId = useRef(undefined)
  const serviceId = useRef('')

  const updateStatus = s => {
    setStatus(s)
    statusRef.current = s
  }

  const closeServiceCall = () => {
    setServiceCall(undefined)
    setBlock(false)
  }

  useEffect(() => {
    const { event, payload } = messageData

    switch (event) {
      case 'exception':
        updateStatus(STATUS.failed)
        setError('Usługa niedostępna')
        connectionRef.current?.destroy()
        break

      case 'callAccepted':
        const { signal, serviceID } = payload
        updateStatus(STATUS.succeed)
        serviceId.current = serviceID
        connectionRef.current.signal(signal)
        break

      case 'callUser':
        if (statusRef.current === STATUS.idle) {
          setBlock(true)
          setServiceCall(payload)
        }
        break

      default:
        break
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messageData])

  const makeCall = (initiator = true, callerData = { from: '', call_id: '', signal: undefined }) => {
    setError('')
    updateStatus(initiator ? STATUS.pending : STATUS.succeed)

    if (deviceType === DEVICE_TYPES.android) {
      window.Android.checkAudioPermission()
    }

    navigator.mediaDevices.getUserMedia({ video: false, audio: true })
      .then(stream => {
        const close = (err = '') => {
          if (!initiator) setBlock(false)
          if (err) console.log(`error, ${err}`)
          stream.getTracks().forEach(track => track.stop())
          leaveCall()
        }

        const iceServers = [{
          urls: process.env.REACT_APP_STUN || 'stun:stun.l.google.com:19302'
        }]

        if (process.env.REACT_APP_TURN) {
          iceServers.push({
            urls: process.env.REACT_APP_TURN,
            username: process.env.REACT_APP_TURN_USER,
            credential: process.env.REACT_APP_TURN_PASSWORD
          })
        }

        const peer = new Peer({
          config: {
            iceServers
          },
          initiator,
          trickle: false,
          stream
        })

        peer.on('signal', data => {
          const call_id = initiator ? v7() : callerData.call_id
          callId.current = call_id
          sendMessage(initiator ? {
            command: 'callService',
            name: 'KLIENT',
            signal: data,
            call_id
          } : {
            command: 'answerCallFromService',
            signal: data,
            call_id,
            to: callerData.from
          })
        })

        peer.on('stream', stream => {
          serviceVideo.current.srcObject = stream
        })

        peer.on('close', close)
        peer.on('error', err => close(err))

        if (callerData.signal) peer.signal(callerData.signal)
        connectionRef.current = peer

      }).catch(err => {
        console.warn(`Error: ${err}`)
        updateStatus(STATUS.failed)
        setError('Musisz wyrazić zgody na użycie mikrofonu')
      })
  }

  const leaveCall = () => {
    updateStatus(STATUS.idle)
    callId.current = undefined

    if (connectionRef.current) {
      connectionRef.current.removeAllListeners('signal')
      connectionRef.current.destroy()
    }
    if (serviceCall) closeServiceCall()
  }

  const cancelCall = () => {
    sendMessage({ command: 'cancelCall', call_id: callId.current })
    leaveCall()
  }

  const endCall = () => {
    sendMessage({ command: 'endCall', id: serviceId.current, call_id: callId.current })
    leaveCall()
  }

  return (
    <CallContext.Provider value={{
      status,
      isBlocked,
      endCall,
      makeCall,
      cancelCall,
    }}>
      {children}

      {status === STATUS.succeed && <video
        playsInline
        autoPlay
        ref={serviceVideo}
        style={{ display: 'none' }} />}

      {serviceCall && <ServiceCall
        status={status}
        endCall={endCall}
        ignoreCall={closeServiceCall}
        answerCall={() => {
          makeCall(false, serviceCall)
        }}
      />}

      {error && <InaccessiblePopup
        desc={error}
        close={() => setError('')} />}

    </CallContext.Provider>
  )
}

export default CallProvider