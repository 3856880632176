import { useEffect, useState } from 'react'
import { POST, GET } from 'rest/request'

type Method = 'POST' | 'GET'

const useFetchedData = <T,>(url: string, destructKeys: string = '', method: Method = 'GET') => {
  const [data, setData] = useState<null | T>(null)
  const [isLoading, setLoading] = useState(false)

  const fetchMagDetails = async (_url: string, _destructKeys: string, _method: Method) => {
    setLoading(true)
    const request = _method === 'POST' ? POST : GET
    const response = await request(_url)
    if (response) {
      const d = _destructKeys
        ? _destructKeys.split('.').reduce((r, k) => r[k] || r, response)
        : response
      setData(d)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (url) fetchMagDetails(url, destructKeys, method)
  }, [destructKeys, method, url])

  return {
    data,
    isLoading,
    reload: async () => {
      fetchMagDetails(url, destructKeys, method)
    }
  }
}

export default useFetchedData