import { ReactComponent as Arrow } from 'assets/arrowDown.svg'
import { useHistory, useParams } from 'react-router-dom'
import useFetchedData from 'hooks/useFetchedData'
import Loading from 'components/Loading'
import Error from 'components/Error'
import { SECTION_TYPES } from '..'
import * as urls from 'rest/urls'
import React from 'react'

interface Invoice {
  id: string
  correctedInvoiceId: any
  proformaInvoiceId: any
  fullNumber: string
  description: string
  currency: string
  documentDate: string
  salesDate: string
  paymentDeadline: string
  netAmount: string
  taxAmount: string
  grossAmount: string
  remainingAmount: string
  isPaid: boolean
  contractor_type: string
  issuerCompanyId: string
  issuerName: string
  issuerShortName: string
  customerId: string
  link: string
  contractor: InvoiceContractor
}

interface InvoiceContractor {
  object: string
  id: string
  name1: string
  name2: any
  address1: string
  address2: any
  city: string
  zip: string
  nip: any
  nipEu: any
  pesel: string
  personName: string
  personSurname: any
  customerId: string
  address: string
  companyId: any
  companyName: any
}

const PaymentsSection: React.FC = () => {
  const { isLoading, data } = useFetchedData<Invoice[]>(`${urls.invoices}?limit=3`, 'data')
  const { id } = useParams<warehouse.PageParams>()

  const { push } = useHistory()

  return (
    <div>
      <div className='list__header group'>
        <div className='element__title'>Faktury</div>
        <div
          className='element__subTitle--arrow'
          onClick={() => {
            push(`/warehouses/${id}/${SECTION_TYPES.invoices}`)
          }}>
          <span>Więcej</span>
          <i className='icon icon--arrow-right' />
        </div>
      </div>
      <div className='list__element --big'>
        {data
          ? <div className='element__desc'>
            {data.map(({ id, fullNumber, documentDate, grossAmount, link }) => <div
              key={id}
              className='group'>
              <div className='element__invoice'>
                <div><b>Faktura {fullNumber}</b></div>
                <div>{documentDate} - {grossAmount}zł</div>
              </div>
              <Arrow
                onClick={() => {
                  if (link) window.open(link, '_blank')
                }} />
            </div>)}
          </div>
          : isLoading
            ? <Loading />
            : <Error text='Brak faktur' />}
      </div>
    </div>
  )
}

export default PaymentsSection