import { AppAuthEventDetail, dispatchAuthEvent } from 'rest/AuthEvent'
import { useHistory } from 'react-router-dom'
import { useEffect, useRef } from 'react'

const useAuthSuccessCallback = () => {
  const { goBack } = useHistory()

  const appAuthEventDetail = useRef<AppAuthEventDetail>({ success: false })

  const successCallback = (detail: AppAuthEventDetail) => {
    appAuthEventDetail.current = detail
    goBack()
  }

  useEffect(() => {
    return () => dispatchAuthEvent(appAuthEventDetail.current)
  }, [])

  return successCallback
}

export default useAuthSuccessCallback