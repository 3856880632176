import { ReactComponent as Mag } from '../../assets/magLogo.svg'
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react'
import { selectWarehouses } from './warehousesSlice'
import Warehouse from '../../components/Warehouse'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Autoplay } from 'swiper/modules'
import React, { useRef } from 'react'

import 'swiper/css/autoplay'
import 'swiper/css'

interface SlideShowProps {
  startIndex?: number
  autoplay?: boolean
  showInfo?: boolean
  actionInAllSlides?: boolean
  action: (id: string, status?: warehouse.WarehouseStatus) => void
}

const SlideShow: React.FC<SlideShowProps> = ({ startIndex = 0, autoplay = false, showInfo = false, actionInAllSlides = false, action }) => {

  const warehouses = useSelector(selectWarehouses)
  const index = useRef(startIndex)
  const { push } = useHistory()

  return (
    <Swiper
      grabCursor
      speed={1000}
      centeredSlides
      slideToClickedSlide
      touchMoveStopPropagation
      modules={[Autoplay]}
      preventClicks={true}
      slidesPerView='auto'
      initialSlide={startIndex}
      autoplay={autoplay ? { delay: 5000 } : false}
      loop={(warehouses.length + 1) * 200 > window.innerWidth}
      onSlideChangeTransitionEnd={({ realIndex }: SwiperClass) => {
        index.current = realIndex
      }}
      onSlideChange={({ realIndex }: SwiperClass) => {
        actionInAllSlides && warehouses[realIndex] && action(warehouses[realIndex]?.objectId)
      }}>

      {warehouses.map(({ objectId, name, typeName, status, alerts, placeName, placeAddress }, i) => <SwiperSlide
        key={i}
        className='warehouse__slide'>

        {({ isActive }) => <div
          className={`slide${isActive ? ' --selected' : ''}`}
          onClick={() => i === index.current && action(objectId, status)}>

          <Warehouse
            id={objectId}
            status={status}
            alerts={alerts}
          />

          {(isActive && showInfo) && <div className='warehouse__info'>
            <div className='info__text info__title'>
              <div className='--termina'>{typeName} nr. {name}</div>
              <div>{placeName}</div>
            </div>
            <div className='info__text info__desc'>
              <div>{placeAddress}</div>
            </div>
          </div>}
        </div>}

      </SwiperSlide>)}

      <SwiperSlide className='warehouse__slide commercial center'>
        {({ isActive }) => <div className={`slide${isActive ? ' --selected' : ''}`}>
          <div
            className='warehouse__commercial center'
            onClick={() => push('/categories')}>
            <div className='commercial__title --termina'>Wynajmij nowy</div>
            <Mag />
          </div>
        </div>}
      </SwiperSlide>
    </Swiper>
  )
}

export default SlideShow