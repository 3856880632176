import useTranslation from '../../hooks/useTranslation'
import React from 'react'
import './style.scss'

interface MagNumberProps {
  number: string | number
  isLight?: boolean
}

const MagNumber: React.FC<MagNumberProps> = ({ number, isLight }) => {
  const { t } = useTranslation('basic')

  return (
    <div className={`mag__number ${isLight ? 'light' : ''}`}>
      <div className='mag__icon'>
        <i className='icon icon--mag-number'></i>
      </div>

      <div className='mag__desc'>
        <span>{t('numberShort')}</span>
        <div className='--termina'>{number}</div>
      </div>
    </div>
  )
}

export default MagNumber