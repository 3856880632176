import DataProvider from './context/data/Provider'
import useTranslation from 'hooks/useTranslation'
import LeafletMap from './LeafletMap'
import Search from './Search'
import Modal from '../Popup'
import React from 'react'
import './style.scss'

interface DeliveryPointsModalProps {
  search?: string
  showModal?: boolean
  placeAddress?: string
  initialServices?: string[]
  uncheckDelivery: basic.BasicFunc
  close: (pointId?: string) => void
}

export const allServices = ['mm', 'inpost', 'dpd', 'dhl', 'ups', 'fedex', 'orlen', 'poczta']

const DeliveryPointsModal: React.FC<DeliveryPointsModalProps> = ({ search, placeAddress, initialServices = allServices, showModal = true, close, uncheckDelivery }) => {
  const { t } = useTranslation('delivery')

  const body = <DataProvider
    initialServices={initialServices}
    placeAddress={placeAddress}>
    <div className='popup__title'>
      <div>{t('delivery_points')}</div>
      <i
        className='icon icon--back s32'
        onClick={uncheckDelivery}
      />
    </div>
    <div className='modal__deliveryPoints'>
      <Search
        initialSearch={search}
        initialServices={initialServices} />
      <LeafletMap close={close} />
    </div>
  </DataProvider>

  if (!showModal) return body
  return (
    <Modal
      close={() => {
        close()
      }}>
      {body}
    </Modal>
  )
}

export default DeliveryPointsModal