import { useHistory, useParams } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react'
import Warehouse from '../../components/Warehouse'
import { Pagination } from 'swiper/modules'
import { useRef, useEffect } from 'react'

import 'swiper/css/pagination'
import 'swiper/css'

const SplashSlides = ({ mags }) => {

  const { number } = useParams()
  const { push, goBack } = useHistory()

  const swiper = useRef(null)

  useEffect(() => {
    if (number !== undefined && swiper.current) {
      const value = Number(number)
      swiper.current.slideTo(value)
    }
  }, [number])

  return (
    <div className='splash__slides'>
      <Swiper
        pagination
        grabCursor
        preventClicks
        centeredSlides
        slideToClickedSlide
        modules={[Pagination]}
        slidesPerView='auto'
        initialSlide={number}
        onSwiper={s => swiper.current = s}
        onSlideChange={({ realIndex }) => {
          const numValue = Number(number || 0)

          if (numValue === realIndex) return
          else if (numValue - realIndex === 1) goBack()
          else push(`/splash/${realIndex}`)
        }}>

        {mags.map(({ status, desc }, i) => <SwiperSlide key={i} className='warehouse__slide'>
          {({ isActive }) => <div className={`slide${isActive ? ' --selected' : ''}`}>
            <div
              className='slide__desc center'
              style={isActive ? {} : { visibility: 'hidden' }}>{desc}</div>

            <Warehouse
              status={status}
              showShadow={isActive} />
          </div>}
        </SwiperSlide>)}
      </Swiper>
    </div>
  )
}

export default SplashSlides