import { ReactComponent as Payments } from '../../assets/payments.svg'
import useTranslation from '../../hooks/useTranslation'
import React from 'react'
import './style.scss'

interface OptionProps {
  title: string
  desc: string
  label?: string
  icon?: JSX.Element
  selected?: boolean
  secondary?: boolean
  changeIconColor?: boolean
  buttonText?: string
  action: React.MouseEventHandler<HTMLElement>
}

const Option: React.FC<OptionProps> = ({ title, desc, label, icon = <Payments />, selected = false, secondary = false, changeIconColor = true, action, buttonText = '' }) => {
  const { t } = useTranslation('basic')

  return (
    <div className={`option${selected ? ' --selected' : ''}${secondary ? ' --secondary' : ''}`}>
      {label && <div className='option__label'>
        <span>{label}</span>
      </div>}

      <div className='center'>
        <div className={`option__icon center${changeIconColor ? '' : ' --color'}`}>
          {icon}
        </div>

        <div className='option__title'>
          <div className='--termina'>{title}</div>
          <div>{desc}</div>
        </div>
      </div>

      {action && <div
        className='option__button'
        onClick={action}>{buttonText ? buttonText : t(selected ? 'selected' : 'choose')}</div>}
    </div>
  )
}

export default Option