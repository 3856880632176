import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { GET } from 'rest/request'
import * as urls from 'rest/urls'
import { RootState } from 'store'

const initialState: warehouse.initialState = {
  warehouses: []
}

export const getWarehouses = createAsyncThunk<warehouse.WarehousesResponse, string>('warehouses/getWarehouses', async (userId) => {
  return await GET(urls.warehouses(userId))
})

export const warehousesSlice = createSlice({
  name: 'warehouses',
  initialState,
  reducers: {
    updateWarehouse: (state, action: PayloadAction<{ id: string, values: Partial<warehouse.Warehouse> }>) => {
      const { id, values } = action.payload
      if (id) {
        const index = state.warehouses.findIndex(e => e.objectId === id)
        if (index > -1) {
          state.warehouses[index] = { ...state.warehouses[index], ...values }
        }
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(getWarehouses.fulfilled, (state, action) => {
      if (action.payload.data) {
        state.warehouses = action.payload.data.warehouses
      }
    })
  },
})

export const { updateWarehouse } = warehousesSlice.actions

export const selectWarehouses = (state: RootState) => state.warehouses.warehouses
export const selectWarehouseIndex = (state: RootState, id: string) => {
  return state.warehouses.warehouses.findIndex(w => w.objectId === id)
} 
export const selectWarehouse = (state: RootState, id: string) => {
  return state.warehouses.warehouses.find(w => w.objectId === id) || {} as warehouse.Warehouse
}

export default warehousesSlice.reducer
