import React from 'react'
import './style.scss'

interface CircleBtnProps {
  disabled?: boolean
  text: string
  Icon: React.FC
  action: React.MouseEventHandler<HTMLDivElement>
}

const CircleBtn: React.FC<CircleBtnProps> = ({ disabled, text, Icon, action }) => {
  return (
    <div
      className={`button__circle${disabled ? ` --disabled` : ''}`}
      onClick={disabled ? undefined : action}>
      <div className='button__icon'><Icon /></div>
      <div className='button__text'>{text}</div>
    </div>
  )
}

export default CircleBtn