import { resetForm, selectFields, selectForm, updateValue, updateValues } from '../formSlice'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { errorMessage, finalExp } from '../../../utils/validation'
import { LOCATION_STATES, STATUS } from '../../../utils/constants'
import useTranslation from '../../../hooks/useTranslation'
import usePopupState from '../../../hooks/usePopupState'
import { useDispatch, useSelector } from 'react-redux'
import Select from '../../../components/inputs/Select'
import Submit from '../../../components/inputs/Submit'
import Input from '../../../components/inputs/Input'
import { findCompany } from '../../../rest/urls'
import { useHistory } from 'react-router-dom'
import CompaniesPopup from './CompaniesPopup'
import { GET } from '../../../rest/request'

const Form = ({ block, submit, cancel }) => {
  const { companyData = '', nip = '', ...form } = useSelector(selectForm)
  const fields = useSelector(selectFields)

  const [warning, setWarning] = useState(false)
  const [keys, setKeys] = useState([])

  const { push, location: { pathname } } = useHistory()
  const { t } = useTranslation('form')
  const { popup } = usePopupState()

  const dispatch = useDispatch()
  const lastSearch = useRef('')

  const refs = []

  const showPopup = useCallback(() => push(pathname, LOCATION_STATES.popup()), [pathname, push])
  const enter = t('enter')

  useEffect(() => {
    const keys = fields.map(({ inputName }) => inputName)
    setKeys(keys)
  }, [fields])

  useEffect(() => {
    lastSearch.current = nip.length === 10 ? true : ''
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.id])

  useEffect(() => {
    if (nip.length === 10 && companyData) lastSearch.current = false
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (lastSearch.current !== false && lastSearch.current !== nip && nip.length === 10) {
      document.activeElement.blur()

      if (lastSearch.current !== true) {
        dispatch(updateValues({ companyStatus: STATUS.pending }))
        showPopup()
      }

      (async () => {
        try {
          const response = await GET(`${findCompany}${nip}`)
          const { error = '' } = response

          dispatch(updateValues({
            companyData: error || response,
            companyStatus: STATUS.succeed
          }))

        } catch (err) {
          dispatch(updateValues({ companyStatus: STATUS.failed }))
        }
      })()
    }

    if (nip.length === 10) lastSearch.current = nip
  }, [dispatch, nip, showPopup])

  const changeHandler = e => {
    if (!e) return
    const { name, value } = e.target

    dispatch(updateValue({ name, value }))
    if (warning && name === 'nip' && value.match(finalExp.nip)) setWarning(false)
  }

  const blurHandler = e => {
    if (!e || e.target.name !== 'nip') return
    const { value } = e.target

    if (warning) setWarning(value.length < 10 ? errorMessage(10 - value.length) : !value.match(finalExp.nip) ? 'niewłaściwy NIP' : false)
  }

  const enterClicked = ref => {
    const index = refs.findIndex(r => Object.is(r, ref))
    if (index !== undefined && refs[index + 1]) refs[index + 1].focus()
  }

  const validation = () => {
    const nipOK = !!nip.match(finalExp.nip)

    if (nipOK) {
      const body = Object.entries(form).reduce((r, [k, v]) => {
        if (keys.includes(k)) r = { ...r, [k]: v }
        return r
      }, {})
      body.nip = nip
      lastSearch.current = ''
      form.id ? submit(body, form.id) : submit(body)

    } else setWarning(nip.length < 10 ? errorMessage(10 - nip.length) : !nipOK ? 'niewłaściwy NIP' : false)
  }

  const counterIcon = useMemo(() => Array.isArray(companyData) ? <div
    className='element__icon'
    onClick={showPopup}>
    {companyData.length}
  </div> : undefined, [companyData, showPopup])

  return (
    <>
      {popup && <CompaniesPopup
        chnageNip={() => {
          if (refs[0]) refs[0]?.focus()
        }}
        selectData={({ raport = {}, ...data } = {}) => {
          const values = Object.entries(data).reduce((r, [k, v]) => {
            if (keys.includes(k)) {
              const val = k === 'streetName' ? `${v} ${data?.homeNr}${data.flatNr ? `/${data.flatNr}` : ''}` : v
              r = { ...r, [k]: val }
            }
            return r
          }, {})

          if (raport.addressHeadCountryName) values['country'] = raport.addressHeadCountryName
          if (raport.basicLegalFormId) {
            const companyFormId = fields?.find(({ inputName }) => inputName === 'companyFormId')

            if (companyFormId && companyFormId.options) {
              const basicLegalFormId = Number(raport.basicLegalFormId)
              const option = companyFormId.options?.find(({ code }) => Number(code) === basicLegalFormId)

              if (option) values['companyFormId'] = option.value
            }
          }

          dispatch(updateValues(values))
        }}
      />}

      <div className='billing__form form'>
        <p className='form__title'>
          <span>{t(form.id ? 'editCompany' : 'addCompany')}</span>
        </p>

        <Input
          required
          ref={r => refs.push(r)}
          value={nip}
          name='nip'
          label={t('tin')}
          minLength='10'
          maxLength='10'
          icon={counterIcon}
          iconPosRight
          disabled={block}
          warning={warning}
          placeholder={`${enter} ${t('tin')}`}
          enterClicked={enterClicked}
          changeHandler={changeHandler}
          blurHandler={blurHandler} />

        {nip.length === 10 && fields.length > 0 && fields.map(({ id, inputName, inputdefaultValue, inputType, inputLabel, placeholder, options }) => {
          if (inputdefaultValue && !form[inputName]) dispatch(updateValue({ name: inputName, value: inputdefaultValue }))
          return inputType === 'select' ? (
            <Select
              key={id}
              disabled={block}
              name={inputName}
              label={inputLabel}
              value={form[inputName] || ''}
              setValue={value => {
                changeHandler({ target: { value, name: inputName } })
              }}
              options={options}
            />
          ) : inputType === 'text' ? (
            <Input
              key={id}
              disabled={block}
              ref={r => refs.push(r)}
              name={inputName}
              placeholder={placeholder || `${enter} ${inputLabel}`}
              label={inputLabel}
              value={form[inputName] || ''}
              changeHandler={changeHandler}
              enterClicked={enterClicked}
            />
          ) : false
        })}

        {form.id ? <div className='center'>
          <button
            className='button --arrow --black --enlarged'
            onClick={() => !block && submit(null, form.id)}>
            <span className='--termina'>{t('delete')}</span>
          </button>

          <button
            className='button --arrow --enlarged'
            onClick={() => !block && nip.length === 10 && validation()}>
            <span className='--termina'>{t('change')}</span>
          </button>
        </div> :
          <Submit
            text={t('add')}
            action={() => !block && validation()} />}

        <button
          className='button --back'
          onClick={() => {
            dispatch(resetForm())
            cancel()
          }}>
          <span>{t('cancel')}</span>
        </button>
      </div>
    </>
  )
}

export default Form