import { BASKET_TYPES, selectBasketList } from 'containers/Basket/basketSlice'
import { useReduxSelector } from 'store'

const useGroupedBasket = (
  showOnlyRent = true
) => {
  const list = useReduxSelector(selectBasketList)

  const groupBasketListByType = () => {
    const betterList = []
    list.forEach((item, idx) => {
      if (item.type === BASKET_TYPES.rent) {
        const locks = list.filter(l => l.type === 'rentAddon' && (
          l.ownerId
            ? l.ownerId === item.identifier
            : l.related
              ? (l.related.some(r => r.cartId === item.identifier || r.id === item.placeId))
              : l.placeId === item.placeId
        ))
        betterList.push({ ...item, index: idx, locks })
      } else if (!showOnlyRent) {
        betterList.push({ ...item, index: idx })
      }
    })
    return betterList
  }

  return (list && list.length > 0)
    ? groupBasketListByType()
    : []
}

export default useGroupedBasket