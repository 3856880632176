import { ReactComponent as Service } from 'assets/service.svg'
import Popup from 'components/popups/Popup'
import { STATUS } from 'utils/constants'
import React from 'react'

interface ServiceCallProps {
  status: keyof typeof STATUS
  answerCall: basic.BasicFunc
  ignoreCall: basic.BasicFunc
  endCall: basic.BasicFunc
}

const ServiceCall: React.FC<ServiceCallProps> = ({ status, answerCall, ignoreCall, endCall }) => {
  return (
    <Popup close={() => { }}>
      <div className='serviceCall__popup'>
        <h3>Dzwoni serwis</h3>
        <div className='center'>
          <Service />
        </div>

        {status === STATUS.succeed
          ? <div className='popup__buttons center'>
            <div
              className='button --accept'
              onClick={endCall}>
              Zakończ
            </div>
          </div>
          : <div className='popup__buttons group'>
              <div
                className='button --cancel'
                onClick={ignoreCall}>
                Ignoruj
              </div>
              <div
                className='button --accept'
                onClick={answerCall}>
                Odbierz
              </div>
            </div>}
      </div>
    </Popup>
  )
}

export default ServiceCall