import useResignationData from './hooks/useResignationData'
import { useParams } from 'react-router-dom'
import Loading from 'components/Loading'
import React, { useState } from 'react'
import Calendar from './Calendar'
import Payments from './Payments'
import Summary from './Summary'
import Default from './Default'
import Camera from './Camera'
import Final from './Final'

export type Step = 'init' | 'payment' | 'finish'
export type Action = 'default' | 'calendar' | 'camera' | 'summary'
interface Params {
  action: Action
}

const MagResignation = () => {
  const [step, setStep] = useState<Step>('init')
  const [deliveries, setDeliveries] = useState<payments.Delivery[]>([])
  const [selectedTimePeriods, setSelectedTimePeriods] = useState<payments.SelectedTimePeriods>(0)
  const { response, selectedData, selectData, generateInvoice } = useResignationData('finishRenting', deliveries)
  const { action } = useParams<Params>()

  return (
    <div className='menu__section menu__resignation'>
      {(() => {
        switch (step) {
          case 'finish': return <Final />

          case 'payment': return <Payments
            response={response}
            selectedData={selectedData}
            selectedTimePeriods={selectedTimePeriods}
            generateInvoice={generateInvoice}
            setStep={setStep}
          />

          default: {
            switch (action) {
              case 'calendar': return <Calendar
                response={response}
                selectedData={selectedData}
                selectData={(prev: React.SetStateAction<payments.SelectedData>) => {
                  selectData(prev)
                  setSelectedTimePeriods(0)
                }}
              />

              case 'camera': return <Camera
                selectedData={selectedData}
                selectData={selectData}
              />

              case 'summary': return <Summary
                response={response}
                selectedData={selectedData}
                selectedTimePeriods={selectedTimePeriods}
                setSelectedTimePeriods={setSelectedTimePeriods}
                setStep={setStep}
              />

              default: return response
                ? <Default
                  response={response}
                  deliveries={deliveries}
                  selectedData={selectedData}
                  setDeliveries={setDeliveries}
                  selectData={selectData}
                />
                : <div className='resignation__main'>
                  <Loading middleOfPage />
                </div>
            }
          }
        }
      })()}
    </div>
  )
}

export default MagResignation