import { CoordinatesContext, MarkersContext, MoveMapContext, SearchMarkersContext } from './Context'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { delivery_points } from 'rest/urls'
import { POST } from 'rest/request'

interface DataProviderProps {
  children: React.ReactNode
  placeAddress?: string
  initialServices: string[]
}

const DataProvider: React.FC<DataProviderProps> = ({ children, initialServices, placeAddress }) => {
  const [markers, setMarkers] = useState<delivery.Point[]>([])
  const [coordinates, setCoordinates] = useState<delivery.Coordinates>({
    latitude: 51.666,
    longitude: 19.389
  })

  const currentServices = useRef<string[]>(initialServices)
  const currentCoordinates = useRef<delivery.Coordinates>(coordinates)
  currentCoordinates.current = coordinates

  const serachMarkers = useCallback(async (search_phrase: string, services: string[]) => {
    currentServices.current = services
    const body: delivery.Body = {
      location: { search_phrase },
      filters: { services }
    }
    const response: delivery.Response = await POST(delivery_points, { body })
    if (response.points) setMarkers(response.points)
    if (response.coordinates && (
      currentCoordinates.current.latitude !== response.coordinates.latitude &&
      currentCoordinates.current.longitude !== response.coordinates.longitude
    )) {
      setCoordinates(response.coordinates)
    }
  }, [])

  const moveMapMarkers = useCallback(async (coordinates: delivery.Coordinates, bounds: delivery.BodyMapBounds) => {
    const body: delivery.Body = {
      location: { coordinates },
      filters: {
        services: currentServices.current,
        map_bounds: bounds
      }
    }
    const response: delivery.Response = await POST(delivery_points, { body })
    if (response.points) setMarkers(response.points)
  }, [])

  useEffect(() => {
    if (placeAddress) serachMarkers(placeAddress, currentServices.current)
  }, [placeAddress, serachMarkers])

  return (
    <MoveMapContext.Provider value={moveMapMarkers}>
      <SearchMarkersContext.Provider value={serachMarkers}>
        <CoordinatesContext.Provider value={coordinates}>
          <MarkersContext.Provider value={markers}>
            {children}
          </MarkersContext.Provider>
        </CoordinatesContext.Provider>
      </SearchMarkersContext.Provider>
    </MoveMapContext.Provider>
  )
}

export default DataProvider