import { RefObject, useEffect, useState } from 'react'
import { GET } from 'rest/request'

const useFetchedDataWithPagination = <T,>(
  url: string,
  listRef: RefObject<HTMLDivElement>,
  reversedList = false
) => {
  const [response, setResponse] = useState<null | response.DataResponse<T[]>>(null)
  const [isLoading, setLoading] = useState(false)

  const fetchMagDetails = async (_url: string, initData = true) => {
    setLoading(true)
    try {
      const response = await GET(_url)
      if (response) setResponse(prev => {
        if (!prev || initData) return response
        return {
          data: [...prev.data, ...response.data],
          meta: response.meta
        }
      })
    } catch (error) {}
    setLoading(false)
  }

  const handleScroll = () => {
    const list = listRef.current
    const pagination = response?.meta?.pagination
    if (!list || !pagination) return
    const { scrollTop, scrollHeight, clientHeight } = list
    if (
      pagination
      && pagination.links.next
      && pagination.count > pagination.current_page
      && scrollHeight + ((reversedList ? 1 : -1) * scrollTop) - 2 <= clientHeight
    ) {
      fetchMagDetails(pagination.links.next, false)
    }
  }

  useEffect(() => {
    if (url) fetchMagDetails(url)
  }, [url])

  useEffect(() => {
    if (!isLoading && response?.meta?.pagination?.links?.next) handleScroll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, response?.meta?.pagination])

  return {
    data: response?.data,
    isLoading,
    handleScroll,
    reload: async () => {
      fetchMagDetails(url)
    }
  }
}

export default useFetchedDataWithPagination