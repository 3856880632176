const en = [{
  label: 'Period of time',
  name: 'date',
  type: 'range',
}, {
  disabled: true,
  label: 'Show downloaded invoices',
  name: 'download',
  type: 'section',
  options: [
    { name: 'All', value: '' },
    { name: 'Yes', value: 'true' },
    { name: 'No', value: 'false' },
  ]
}, {
  label: 'Show paid invoices',
  name: 'download',
  type: 'section',
  options: [
    { name: 'All', value: '' },
    { name: 'Yes', value: 'true' },
    { name: 'No', value: 'false' },
  ]
}, {
  disabled: true,
  label: 'Warehouse',
  name: 'mag',
  type: 'section',
  options: [
    { name: 'All', value: '' }
  ]
}]

const pl = [{
  label: 'Okres czasu',
  name: 'date',
  type: 'range'
}, {
  disabled: true,
  label: 'Pokaż pobrane faktury',
  name: 'download',
  type: 'section',
  options: [
    { name: 'Wszystkie', value: '' },
    { name: 'Tak', value: 'true' },
    { name: 'Nie', value: 'false' },
  ]
}, {
  label: 'Pokaż opłacone faktury',
  name: 'isPaid',
  type: 'section',
  options: [
    { name: 'Wszystkie', value: '' },
    { name: 'Tak', value: 'true' },
    { name: 'Nie', value: 'false' },
  ]
}, {
  disabled: true,
  label: 'Magazyn',
  name: 'mag',
  type: 'section',
  options: [
    { name: 'Wszystkie', value: '' }
  ]
}]

export { en, pl }