import ArrowBtn from '../../components/buttons/ArrowBtn'

const DetailsElement = ({ data, action, openDetailsPopup }) => {
  const { name, isAvailableForRentNow, attrs, free_now_to_rent_count: freeCount, count, for_rent = [], for_reservation = [], badge, data: { monthlyGrossPrice } = {} } = data || {}

  const countBadge = (freeCount) => {
    if (freeCount < 1) {
      return "zero"
    } else if (freeCount >= 1 && freeCount <= 5) {
      return "empty"
    } else if (freeCount > 5 && freeCount <= 20) {
      return "average"
    } else {
      return "full"
    }
  }

  return (
    <div className={`magElement ${badge ? ' --badge' : ''}
    ${isAvailableForRentNow || (for_rent?.length > 0 && for_rent[0]) || (for_reservation?.length > 0 && for_reservation[0])
        ? ''
        : '--orderLast'
      }`}>

      {badge && <div className='badge' style={{ borderColor: badge.borderColor, backgroundColor: badge.bgColor, color: badge.textColor }}>{badge.text}</div>}

      <div className='magElement__head'>
        <div className='amount'>
          <div className='amount__bg'><span className={`${countBadge(freeCount)}`}></span></div>
          <div className='amount__desc'>Ilość: {freeCount}</div>
        </div>
        <h2>{name}</h2>
        <span onClick={openDetailsPopup}>Zobacz szczegóły</span>
      </div>

      <div className='magElement__important'>
        {attrs.map((item, idx) => {
          return (
            item.isImportant && <div key={idx} className='magElement__important__item'>
              <i className={`icon icon--${item.key}`} />
              <span>{item.name}</span>
            </div>
          )
        })}
        <span className='divider'></span>
      </div>

      {attrs.some(item => !item.isImportant) && <div className='magElement__body'>
        {attrs.map((item, idx) => {
          return (
            !item.isImportant && <div key={idx} className='attrItem'>
              <i className={`icon icon--${item.key}`} />
              <span>{item.name}</span>
            </div>
          )
        })}
      </div>}

      <div className='magElement__footer'>

        <div className='magElement__footer__price'>
          <span>{Number(monthlyGrossPrice)} zł</span>
          <span>Miesięcznie</span>
        </div>

        <ArrowBtn
          className={`${isAvailableForRentNow || (for_rent?.length > 0 && for_rent[0]) || (for_reservation?.length > 0 && for_reservation[0])
            ? ''
            : '--black'
            }`}
          enlarged
          disabled={!count}
          text={isAvailableForRentNow ? 'Wynajmij teraz' :
            for_rent?.length > 0 && for_rent[0] ? `Wynajmij od ${for_rent[0]}` :
              for_reservation?.length > 0 && for_reservation[0] ? `Zarezerwuj od ${for_reservation[0]}`
                : 'Powiadom o dostępności'}
          action={() => {
            if (isAvailableForRentNow) action()
            else if (for_rent?.length > 0 && for_rent[0]) action(for_rent[0])
            else if (for_reservation?.length > 0 && for_reservation[0]) action(for_reservation[0])
            else (alert('Modal od powiadomienia o dostępności'))
          }} />

      </div>


    </div>
  )
}

export default DetailsElement