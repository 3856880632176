import ArrowBtn from 'components/buttons/ArrowBtn'
import Popup from 'components/popups/Popup'
import '../style.scss'


const EditFavoritePopup = () => {

  return (
    <Popup>
      <div className='popup__title'>
        <div>Ulubione</div>
        <div>Wybierz usługi, które chcesz wiedzieć na pulpicie</div>
      </div>

      <div className='popup__favorite'>

        <div className='favorite__item --checked'>
          <div className='favorite__item__left'>
            <h2>Mag #01</h2>
            <span>Pabianice, 20 stycznia 1</span>
          </div>
          <div className='favorite__item__right'>
          <i className='icon icon--heart'></i>
          </div>
        </div>

        <div className='favorite__item'>
          <div className='favorite__item__left'>
            <h2>Mag #01</h2>
            <span>Pabianice, 20 stycznia 1</span>
          </div>
          <div className='favorite__item__right'>
          <i className='icon icon--heart'></i>
          </div>
        </div>

        <div className='favorite__item --checked'>
          <div className='favorite__item__left'>
            <h2>Mag #01</h2>
            <span>Pabianice, 20 stycznia 1</span>
          </div>
          <div className='favorite__item__right'>
          <i className='icon icon--heart'></i>
          </div>
        </div>

        <div className='favorite__item'>
          <div className='favorite__item__left'>
            <h2>Mag #01</h2>
            <span>Pabianice, 20 stycznia 1</span>
          </div>
          <div className='favorite__item__right'>
          <i className='icon icon--heart'></i>
          </div>
        </div>

        <div className='favorite__item'>
          <div className='favorite__item__left'>
            <h2>Mag #01</h2>
            <span>Pabianice, 20 stycznia 1</span>
          </div>
          <div className='favorite__item__right'>
          <i className='icon icon--heart'></i>
          </div>
        </div>

      </div>

      <ArrowBtn
        enlarged
        showArrow={false}
        text={'Zapisz'}
        action={''} />
    </Popup>
  )
}

export default EditFavoritePopup