import ArrowBtn from 'components/buttons/ArrowBtn'
import Popup from 'components/popups/Popup'
import React, { useState } from 'react'
import '../style.scss'

const EditShortcutsPopup = () => {
  const [favoriteItems, setFavoriteItems] = useState([
    { id: 'item1', content: 'Item 1' },
    { id: 'item2', content: 'Item 2' },
    { id: 'item3', content: 'Item 3' }
  ])

  const [restItems, setRestItems] = useState([
    { id: 'item4', content: 'Item 4' },
    { id: 'item5', content: 'Item 5' },
    { id: 'item6', content: 'Item 6' },
    { id: 'item7', content: 'Item 7' },
    { id: 'item8', content: 'Item 8' },
    { id: 'item9', content: 'Item 9' }
  ])

  const handleAddToFavorite = (item) => {
    if (favoriteItems.length < 6) {
      setFavoriteItems(prevItems => [...prevItems, item])
      setRestItems(prevItems => prevItems.filter(restItem => restItem.id !== item.id))
    }
  }

  const handleRemoveFromFavorite = (item) => {
    setRestItems(prevItems => [...prevItems, item])
    setFavoriteItems(prevItems => prevItems.filter(favoriteItem => favoriteItem.id !== item.id))
  }

  return (
    <Popup>
      <div className='popup__title'>
        <div>Skróty</div>
      </div>

      <div className='popup__shortcuts'>
        <div className='favorite'>
          <h3>Przypięte (Max 6)</h3>
          {favoriteItems.map(item => (
            <div key={item.id} className='favorite__item'>
              <div className='iconWrap --red' onClick={() => handleRemoveFromFavorite(item)}><i className='icon icon--minus'></i></div>
              <span>{item.content}</span>
            </div>
          ))}
        </div>

        <div className='rest'>
        <h3>Pozostałe</h3>
          {restItems.map(item => (
            <div key={item.id} className='favorite__item'>
              <div className='iconWrap --green' onClick={() => handleAddToFavorite(item)}><i className='icon icon--plus'></i></div>
              <span>{item.content}</span>
            </div>
          ))}
        </div>
      </div>
      <ArrowBtn
        enlarged
        showArrow={false}
        text={'Zapisz'}
        action={''} />
    </Popup>
  )
}

export default EditShortcutsPopup
