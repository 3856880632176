import { selectBasketList, selectBasketToPay, selectBasketItem } from 'containers/Basket/basketSlice'
import useGroupedWarehouseAddons, { GroupedWarehouseAddons } from './useGroupedWarehouseAddons'
import { useReduxSelector } from 'store'

export interface ItemData {
  text: string
  price: string
  price_net: string
  depositAmount?: string
}

interface Data {
  list: ItemData[]
  sum: number
}

interface MonthlyScheduleItem {
  grossPrice: string
  netPrice: string
  text: string
  start: string
  end: string
}

type Object = Record<string, string>

const useSummaryData = (
  _groupedWarehouseAddons?: GroupedWarehouseAddons
) => {
  const { identifier } = useReduxSelector(selectBasketItem)
  const list = useReduxSelector(selectBasketList)
  const toPay = useReduxSelector(selectBasketToPay)
  const getGroupedWarehouseAddons = useGroupedWarehouseAddons()
  const groupedWarehouseAddons = _groupedWarehouseAddons || getGroupedWarehouseAddons()

  const generateDataItem = (type: 'to_pay' | 'to_pay_monthly') => {
    const data: Data = {
      sum: 0,
      list: []
    }
    if (toPay[identifier]?.[type]?.list) {
      data.list.push(...toPay[identifier][type].list)
      data.sum += Number(toPay[identifier][type].price_sum)
    }
    const addonsCartIds = groupedWarehouseAddons[identifier]
    if (addonsCartIds) {
      addonsCartIds.forEach(id => {
        if (toPay[id]?.[type]) {
          data.list.push({
            text: toPay[id][type]?.text || (list.find((l: Object) => l.identifier === id)?.name || 'Nie znaleziono nazwy zabezpieczenia'),
            price: toPay[id][type].price_sum,
            price_net: toPay[id][type].price_sum_net,
            depositAmount: toPay[id][type].depositAmount,
          })
          data.sum += Number(toPay[id][type].price_sum)
        }
      })
    }
    return data
  }

  const generateData = () => {
    return {
      now: generateDataItem('to_pay'),
      monthly: toPay[identifier]?.object_price?.schedule_list?.monthly as MonthlyScheduleItem[],
    }
  }

  if (!identifier || !toPay) return
  return generateData()
}

export default useSummaryData