import { selectWarehouse } from '../../Warehouses/warehousesSlice'
import ArrowBtn from '../../../components/buttons/ArrowBtn'
import DateTile from '../../OrderDetails/Calendar/DateTile'
import InvoicesSection from './InvoicesSection'
import LocationSection from './LocationSection'
import SettingsSection from './SettingsSection'
import InvoiceSection from './InvoiceSection'
import { useParams } from 'react-router-dom'
import { useReduxSelector } from 'store'
import MagSection from './MagSection'
import { forwardRef } from 'react'

const MagDetails = forwardRef<HTMLDivElement>((_, ref) => {

  const { id } = useParams<warehouse.PageParams>()
  const { typeName, fullDateFrom, fullDateTo, size, placeName, placeAddress, clientName, clientSurname, clientAddress } = useReduxSelector(state => selectWarehouse(state, id))

  const getDateFromString = (s = '') => new Date(s.split('.').reverse().join('-'))

  return (
    <div className='menu__section summary'>
      <p>Szczegóły wynajmu</p>

      <div
        ref={ref}
        className='menu__details'>

        <div className='details__date calendar'>
          <div className='calendar__main'>
            <div className='calendar__inputs center'>
              <DateTile
                start
                date={getDateFromString(fullDateFrom)}
              />
              <DateTile
                date={fullDateTo ? getDateFromString(fullDateTo) : undefined}
              />
            </div>
          </div>
        </div>

        <div className='list'>
          <MagSection
            {...size}
            name={typeName}
          />
          <LocationSection
            placeName={placeName}
            placeAddress={placeAddress}
          />
          <InvoiceSection
            clientName={clientName}
            clientSurname={clientSurname}
            clientAddress={clientAddress}
          />
          <InvoicesSection />
          <SettingsSection />
        </div>

        <div className='details__buttons'>
          {fullDateTo && <ArrowBtn
            enlarged
            text='Przedłuż wynajem'
            action={() => { }}
          />}
          <div className='button__cancel'>Anuluj wynajem</div>
        </div>
      </div>
    </div>
  )
})

export default MagDetails