import { ReactComponent as Logo } from '../../assets/logo.svg'
import Submit from '../../components/inputs/Submit'
import Warehouse from '../../components/Warehouse'

const FirstSlide = ({ setFirstSlideVisible }) => {
  return (
    <>
      <Logo />
      <div className='slide__title center'>
        <div className='--termina'>Gratulacje!</div>
        <div>Wynająłeś swojego MAGa</div>
      </div>

      <div className='fireworks'>
        <div />
        <div />
      </div>

      <Warehouse showShadow />

      <div className='slide__desc'>
        <div>Przed Tobą ostatnia prosta,</div>
        <div>musisz teraz skonfiugrować swój panel</div>
      </div>

      <Submit text='Zaczynamy' action={() => setFirstSlideVisible(false)} />
    </>
  )
}

export default FirstSlide