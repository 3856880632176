import { ButtonStateContext, ButtonDispatchContext } from './Context'
import { useCallback, useRef, useState } from 'react'

const ButtonContextProvider = ({ children }) => {
  const [isButton, showButton] = useState(false)
  const action = useRef(undefined)

  const updateButton = useCallback((newAction) => {
    action.current = newAction
    showButton(!!newAction)
  }, [])

  return (
    <ButtonDispatchContext.Provider value={updateButton}>
      <ButtonStateContext.Provider value={{ isButton, action }}>
        {children}
      </ButtonStateContext.Provider>
    </ButtonDispatchContext.Provider>
  )
}

export default ButtonContextProvider