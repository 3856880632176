import { APP_AUTH_KEY, AppAuthEventDetail } from './AuthEvent'
import { UNAUTHORIZED_TYPES } from 'utils/constants'
import HeadersManager from './HeadersManager'
import StoreManager from './storeManager'
import { getUuid } from '../utils/uuid'
import { showPopup } from './restSlice'
import { DEVICE_TYPES } from 'appSlice'

const FIVE_MINUTES = 5 * 60 * 1000
const uuid = getUuid()

const baseRequest: rest.BaseRequest = async (
  resource = '',
  method = 'GET',
  { body = undefined, form = undefined, signal = undefined, credentials = false }
) => {
  const connectionId = HeadersManager.connectionId.get()
  const dbHeader = process.env.REACT_APP_DB_HEADER
  const token = HeadersManager.jwt.get()
  const headers = new Headers({
    'Accept': 'application/json',
    'X-Client': 'MM-Client'
  })

  if (token) headers.set('Authorization', `Bearer ${token}`)
  if (!resource.includes('dostolika.pl')) {
    headers.set('X-Client-Hash', uuid)
    if (dbHeader) headers.set('X-Database', dbHeader)
    if (connectionId) headers.set('WSS-ID', connectionId)
  }

  const init: RequestInit = {
    method,
    headers,
  }

  if (signal) init.signal = signal
  if (credentials) init.credentials = 'include'

  if (form) {
    init.body = form
  } else if (body) {
    headers.append('content-type', 'application/json')
    init.headers = headers
    init.body = JSON.stringify(body)
  }

  try {
    const response = await fetch(resource, init)
    const type = response.headers.get('content-type')
    const data = (method === 'DELETE' && !response.bodyUsed) ? true
      : type?.includes('json') ? await response.json() : await response.text()
    return { response, data }

  } catch (error) {
    const message = error instanceof Error ? error.message : String(error)
    return `Request failed with message: ${message}`
  }
}

const request: rest.Request = async (resource, method, options, showErrorPopup = true, isNativeAuthInProgress = false) => {
  try {
    const baseResponse = await baseRequest(resource, method, options)
    if (typeof baseResponse === 'string') throw new Error(baseResponse)
    const { response, data } = baseResponse

    if (response.ok) {
      if (isNativeAuthInProgress) unlockNativeApp()
      return data

    } else if (options.baseRequest) {
      return data
    
    } else if (response.status === 401 && typeof data === 'object' && data?.type) {
      const { type } = data

      if ((type === UNAUTHORIZED_TYPES.pin
        || type === UNAUTHORIZED_TYPES.mail
        || type === UNAUTHORIZED_TYPES.phone
        || type === UNAUTHORIZED_TYPES.device
        || type === UNAUTHORIZED_TYPES.smsOtp
        || type === UNAUTHORIZED_TYPES.mailOtp
        || type === UNAUTHORIZED_TYPES.webAuth)) {
        displayPopup(data)

        const authorizedRes = await authorization()
        if (authorizedRes?.success) {
          if (authorizedRes.token) {
            const isNativeAuth = authorizedRes.type && authorizedRes.type !== 'none'
            const key = getTypeKey(isNativeAuth ? authorizedRes.type : data.type)
            const v = { [key]: authorizedRes.token }
            const value = isNativeAuth ? { mmauth: v } : v
            const newOptions = { ...options }
            newOptions.body = { ...newOptions.body, ...value }
            return request(resource, method, newOptions, showErrorPopup, isNativeAuth)

          } else {
            if (authorizedRes.token) unlockNativeApp()
            return request(resource, method, options, showErrorPopup)
          }
        }

      } else throw new Error('Wrong error type')

    } else if (showErrorPopup && typeof data === 'object' && data?.message) {
      displayPopup({ ...data, type: 'formError' })
    }

    throw new Error(data ? JSON.stringify(data) : response.statusText)

  } catch (error) {
    const message = error instanceof Error ? error.message : String(error)
    if (isNativeAuthInProgress) unlockNativeApp()
    return Promise.reject(message)
  }
}

const authorization = (): Promise<AppAuthEventDetail> => {
  return new Promise((resolve, reject) => {
    const authListener = (e: Event) => {
      console.log('auth - callback', e)
      if ('detail' in e && typeof e.detail === 'object') {
        const detail = e.detail as AppAuthEventDetail
        console.log(`is success: ${detail.success}, token: ${detail.token}, type: ${detail.type}`)
        clearTimeout(timeout)
        if (!detail.success && detail.type === 'none') unlockNativeApp()
        detail.success ? resolve(detail) : reject('Authorization closed')
      } else reject()
    }

    document.addEventListener(APP_AUTH_KEY, authListener, false)
    const timeout = setTimeout(() => reject('Time is up'), FIVE_MINUTES)
  })
}

const unlockNativeApp = () => {
  const store = StoreManager.get()
  try {
    if (store && store.getState().app.device === DEVICE_TYPES.ios) {
      window.webkit.messageHandlers.Authorize.postMessage('false')
    } else {
      Android.authorize('false')
    }
  } catch (error) {
    console.error(`Error: ${error}`)
  }
}

const displayPopup = (popupBody: rest.PopupBody) => {
  const store = StoreManager.get()
  if (store && !store.getState().rest.isPopup) {
    store.dispatch(showPopup(popupBody))
    window.history.pushState(null, '', window.location.pathname)
  }
}

const getTypeKey = (type: rest.ProtectedByTypes | 'biometric') => {
  switch (type) {
    case 'password': return 'current_password'
    case 'email_otp': return 'email_token'
    case 'sms_otp': return 'sms_token'
    case 'webauthn': return 'webauthn'
    case 'biometric': return 'signed'
    case 'pin': return 'current_pin'
  }
}

export const GET = (resource: string, options: rest.Options = {}) => request(resource, 'GET', options)
export const DELETE = (resource: string) => request(resource, 'DELETE', { credentials: true })
export const POST = (resource = '', options: rest.Options = {}, showErrorPopup?: boolean) => request(resource, 'POST', options, showErrorPopup)
export const PATCH = (resource = '', options: rest.Options = {}) => request(resource, 'PATCH', options)
