import { selectRestPopupBody } from 'rest/restSlice'
import useTranslation from 'hooks/useTranslation'
import { useHistory } from 'react-router-dom'
import Submit from 'components/inputs/Submit'
import { useReduxSelector } from 'store'

const ErrorPopup: React.FC = () => {

  const body = useReduxSelector(selectRestPopupBody)
  const { message, errors } = body as rest.FormErrorResponse
  const { t } = useTranslation('basic')

  const { goBack } = useHistory()

  return (
    <div
      className='popup__verification'
      style={{ paddingTop: 0 }}>

      <div className='popup__title'>
        <div>{t('caution')}</div>
      </div>

      {message && <p>{message}</p>}
      {errors && Object.keys(errors).length > 0 && <div className='popup__list'>
        <ul>
          {Object.values(errors).map((e, i) => {
            const value = Array.isArray(e) ? e.reduce((r, v, c) => r += c ? ', ' : '' + v, '') : e
            return <li key={i}>{value}</li>
          })}
        </ul>
      </div>}

      <Submit
        text='OK'
        action={goBack} />
    </div>
  )
}

export default ErrorPopup