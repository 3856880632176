import { useHistory } from 'react-router-dom'
import Popup from 'components/popups/Popup'
import '../style.scss'

const DetalisPopup = ({ cancelAction, data }) => {
  const { goBack } = useHistory()
  console.log(data)
  return (
    <Popup close={cancelAction || goBack}>
      <div className='popup__title'>
        <div>Szczegóły</div>
      </div>
      <div className='popup__details'>

        {data && data.map((item, idx) => {
          return (
            <div key={idx}>
              <h5>{item.name}</h5>
              {/* {map.item.list(() => {
                return <div>

                </div>
              })} */}
            </div>
          )
        })}
      </div>

    </Popup>

  )
}
export default DetalisPopup