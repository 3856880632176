import { ReactComponent as Forward } from '../../assets/forwardBtn.svg'
import { ReactComponent as Mobile } from '../../assets/mobileMag.svg'
import { ReactComponent as New } from '../../assets/newMag.svg'
import { ReactComponent as Dots } from '../../assets/dots.svg'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import { LOCATION_STATES } from '../../utils/constants'
import { useHistory } from 'react-router-dom'
import Popups, { POPUPS } from './Popups'
import { POST } from 'rest/request'
import { api } from 'rest/urls'
import { useRef } from 'react'
import './style.scss'

const Home = () => {
  const ref = useRef(null)
  let { push } = useHistory()

  const showPopup = p => push(window.location.pathname, LOCATION_STATES.popup(p))

  const testPin = async () => {
    try {
      const res = await POST(`${api}protected`)
      ref.current.style.backgroundColor = 'green'
      console.log(Array.isArray(res) && res[0] ? res[0] : res)
    } catch (error) {
      ref.current.style.backgroundColor = 'red'
      console.warn(error)
    }
  }

  return (
    <div className='home'>
      <Popups />
      <div className='title'>
        <div>Twój ruch!</div>
        <div>Co chcesz zrobić?</div>
      </div>

      <div className='list'>
        <div className='list__element --big'
          onClick={() => push('/categories')}>
          <div className='element__group group'>
            <New />
            <Dots />
          </div>

          <div className='element__title --termina '>
            <div>Zamów</div>
            <div>magazynomat</div>
          </div>

          <div className='element__back'><Forward /></div>
        </div>

        <div
          className='list__element'
          onClick={() => showPopup(POPUPS.mobileWarehouse)}>
          <div className='element__group group'>
            <Mobile />
            <Dots />
          </div>

          <div className='element__title --termina'>
            <div>Zamów mobilny</div>
            <div>magazyn</div>
          </div>

          <div className='element__back'><Forward /></div>
        </div>

        <div
          className='list__element --black'
          onClick={() => showPopup(POPUPS.otherServices)}>
          <Logo />

          <div className='element__title --termina'>
            <div>Nasze</div>
            <div>inne usługi</div>
          </div>

          <div className='element__text'>Zobacz</div>
        </div>

        <div
          className='list__element'// --big'
          onClick={() => showPopup(POPUPS.invest)}>
          <div className='element__group group'>
            <Logo />
            <Dots />
          </div>

          <div className='element__title --termina'>Zainwestuj</div>
          <div className='element__back'><Forward /></div>
        </div>

        <div
          ref={ref}
          className='list__element'
          onClick={() => testPin()}>
          <div className='element__group group'>
            <Logo />
            <Dots />
          </div>

          <div className='element__title --termina'>test</div>
          <div className='element__back'><Forward /></div>
        </div>
      </div>
    </div>
  )
}

export default Home