import WarehouseAttrPopup from '../Popups/WarehouseAttrPopup'
import MMScorePopup from '../Popups/MMScorePopup'
import { LOCATION_STATES } from 'utils/constants'
import usePopupState from 'hooks/usePopupState'
import { useHistory } from 'react-router-dom'
import { useEffect, useState } from 'react'
import MmRating from 'components/MmRating'

const PlaceElement = ({ idx, data, scrollMenu, warehouseTypeId }) => {
  const { id, mm_score, automation, place_attrs, city, street, estateNumber, localNumber, sizes } = data || {}
  const { push, replace } = useHistory()
  const { popup, pathname } = usePopupState()

  const [activeSize, setActiveSize] = useState(null)

  useEffect(() => {
    if (sizes && sizes.length > 0) {
      const newSize = warehouseTypeId
        ? (sizes.find(s => s.warehouseTypeId === warehouseTypeId) || sizes[0])
        : sizes[0]
      setActiveSize(newSize)
    }
  }, [sizes, warehouseTypeId])

  const handleGo = () => {
    if (idx !== undefined) replace(`${window.location.pathname}?from=mag_${idx}`)
    push(`/map/${id}`)
  }

  return (
    <>
      {popup === 'mm_score' && <MMScorePopup />}
      {popup === 'warehouse_attr' && <WarehouseAttrPopup body={place_attrs} />}
      <div
        className='placeElement'
        onScroll={scrollMenu}>
        <div className='placeElement__name'>
          <h2 onClick={() => push(pathname, LOCATION_STATES.popup('mm_score'))}>{city}, {street} {localNumber} {estateNumber}</h2>
          <MmRating number={mm_score} />
        </div>

        <div className='placeElement__attr' onClick={() => push(pathname, LOCATION_STATES.popup('warehouse_attr'))}>
          {place_attrs.map((item) => {
            return (
              <div key={item.id} className='placeElement__attr__item'>
                <i className={`icon icon--check`}></i>
                <span>{item.name}</span>
              </div>
            )
          })}
        </div>

        <div className='placeElement__automation' onClick={() => push(pathname, LOCATION_STATES.popup('automation'))}>
          <div>
            {automation.key === 'full-automatic'
              ? <i className='icon icon--shield'></i>
              : ''}
            <span className={`${automation.key === 'full-automatic' ? 'green' : ''}`}>{automation.title}</span>
          </div>
          <div>{automation.description}</div>
        </div>

        <div className='placeElement__sizes'>
          <span className='subtitle'>Dostępne rozmiary</span>
          {sizes.map((item, idx) => {
            return (
              <div
                key={idx}
                className={`sizeItem ${item.warehouseTypeId === (activeSize && activeSize.warehouseTypeId) ? 'active' : ''}`}
                onClick={() => setActiveSize(item)}>
                {item.size} m<sup>2</sup>
              </div>
            )
          })}
        </div>

        <div className='placeElement__price'>
          <span className='subtitle'>Koszt wybranego rozmiaru</span>
          <span className='--termina'>Od {activeSize?.monthlyGrossPrice}</span>
          <span className='--termina small'>/ miesięcznie</span>
          <div className='buttonGo' onClick={handleGo}>
            <i className='icon icon--arrow-right s24'></i>
          </div>
        </div>

      </div>
    </>
  )
}

export default PlaceElement