import { isFirstLaunch } from '../../containers/SplashScreen/storage'
import { updateStatusBarColor } from 'utils/updateStatusBarColor'
import { useHistory, useLocation } from 'react-router-dom'
import SplashScreen from '../../containers/SplashScreen'
import { LOCATION_STATES } from '../../utils/constants'
import { isIos, isMobile } from '../../utils/isMobile'
import Notifications from './Notifications'
import { useState, useEffect } from 'react'
import { selectLightTheme } from 'appSlice'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga4'
import Router from './Router'
import Popups from './Popups'
import Menu from './Menu'
import './style.scss'

const notifications = <Notifications />
const router = <Router />
const popups = <Popups />

const ios = isIos()
const mobile = isMobile()
const REG_PATHS = /(?=(map|account))(?!mapdetails)/
const ANALYTICS = process.env.REACT_APP_GA_TRACKING_ID

if (ANALYTICS) {
  ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID)
}

const Mobile = ({ disabledAnimation = false }) => {

  const lightTheme = useSelector(selectLightTheme)
  const [paths, setPaths] = useState([window.location.pathname])
  const [splashScreen, showSplashScreen] = useState(!isFirstLaunch())
  const [animation, setAmination] = useState('')

  const { goBack, listen, push } = useHistory()
  const { state, pathname } = useLocation()

  const isMenu = state === LOCATION_STATES.menu

  useEffect(() => isMenu && setAmination(''), [isMenu])

  useEffect(() => {
    return !disabledAnimation && !splashScreen && listen(({ pathname, search }, action) => {
      const page = pathname + search
      let direction = (pathname.match(REG_PATHS) && paths[0]?.match(REG_PATHS)) || pathname === paths[0] ? '' : ' --right'

      //Troche na trytyki 🙈:

      if (paths[0] === '/') {
        if (pathname === '/chat' || pathname === '/dashboard') {
          direction = ' --left';
        } else if (pathname === '/help' || pathname === '/account') {
          direction = ' --right';
        }
      } else if (paths[0] === '/dashboard') {
        if (pathname === '/' || pathname === '/chat' || pathname === '/help' || pathname === '/account') {
          direction = ' --right';
        }
      } else if (paths[0] === '/chat') {
        if (pathname === '/' || pathname === '/help' || pathname === '/account') {
          direction = ' --right';
        } else if (pathname === '/dashboard') {
          direction = ' --left';
        }
      } else if (paths[0] === '/account') {
        if (pathname === '/help') {
          direction = ' --right';
        } else if (pathname === '/dashboard' || pathname === '/' || pathname === '/chat') {
          direction = ' --left';
        }
      } else if (paths[0] === '/help') {
        if (pathname === '/' || pathname === '/chat' || pathname === '/dashboard' || pathname === '/account') {
          direction = ' --left';
        }
      }

      if (ANALYTICS) ReactGA.send({ hitType: 'pageview', page, title: page })
      if (ios) window.scrollTo(0, 0)
      if (action === 'POP') {
        if (paths[1] === pathname) setPaths(([_, ...paths]) => paths) // forward btn
        else {
          if (direction) direction = ' --left'
          setPaths(l => [pathname, ...l]) // back btn
        }
        if (ios && direction) direction = ''
      } else setPaths([pathname])
      setAmination(isMenu ? '' : direction)
    })
  }, [disabledAnimation, isMenu, listen, paths, splashScreen])


  useEffect(() => {
    if (lightTheme) {
      if (pathname.startsWith("/mapdetails")) {
        updateStatusBarColor("#ffffff")
      } else if (pathname.startsWith("/map")) {
        updateStatusBarColor("#FAFAF8")
      } else if (pathname.startsWith("/categories")) {
        updateStatusBarColor("#181818")
      } else {
        updateStatusBarColor("#f6f6f7")
      }
    } else {
      if (pathname.startsWith("/mapdetails")) {
        updateStatusBarColor("#2c2c2d")
      } else if (pathname.startsWith("/map")) {
        updateStatusBarColor("#090909")
      } else if (pathname.startsWith("/categories")) {
        updateStatusBarColor("#181818")
      } else {
        updateStatusBarColor("#202020")
      }
    }
  }, [pathname, lightTheme])

  const onTouch = e => {
    const width = window.innerWidth
    const initialX = e.touches[0].clientX

    const touchMove = e => {
      const currentX = e.touches[0].clientX
      if ((initialX - currentX > 60 && !isMenu) || (currentX - initialX > 60 && isMenu)) {
        isMenu ? goBack() : push(window.location.pathname, LOCATION_STATES.menu)
        touchEnd()
      }
    }

    const touchEnd = () => {
      document.removeEventListener('touchend', touchEnd, false)
      document.removeEventListener('touchmove', touchMove, false)
    }

    if ((width - initialX < 30 && !isMenu) || isMenu) {
      document.addEventListener('touchend', touchEnd, false)
      document.addEventListener('touchmove', touchMove, false)
    }
  }

  const app = <div className='app__container'>
    <div
      className={`app__main${isMenu ? ' --small' : ''}${mobile ? ' --rotate' : ''}`}
      onClick={() => isMenu && goBack()}
      onTouchStart={onTouch}>{router}</div>
    {notifications}
    {popups}
    {isMenu && <Menu />}
  </div>

  return (
    <>
      <div
        className={`app__mobile${animation}`}>
        {splashScreen ? <SplashScreen close={showSplashScreen} /> : app}
      </div>
    </>
  )
}

export default Mobile