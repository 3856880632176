import { fetchUserData, selectUserData } from '../accountSlice'
import { useDispatch, useSelector } from 'react-redux'
import { STATUS } from 'utils/constants'
import { useEffect } from 'react'
import { useRef } from 'react'

const useUserFetch = (type) => {

  const { status = STATUS.idle } = useSelector(state => selectUserData(state, type))
  const dispatch = useDispatch()

  const statusRef = useRef(status)
  statusRef.current = status

  useEffect(() => {
    if (statusRef.current === STATUS.idle || statusRef.current === STATUS.failed) dispatch(fetchUserData(type))
  }, [dispatch, type])

  return status
}

export default useUserFetch