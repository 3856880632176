import { ReactComponent as Search } from '../../../assets/search.svg'
import { selectMapOptions, updateMapOption } from '../Map/mapSlice'
import { useSelector, useDispatch } from 'react-redux'
import Location from '../../../components/Location'
import { DISTANCE_LIST } from '../Map/mapUtils'
import { selectLocation } from './filtersSlice'
import { useHistory } from 'react-router-dom'
import Range from '../../../components/Range'

const locationBtn = <Location />

const Filter = ({ desktop = false }) => {
  const { distance, voivodship } = useSelector(selectMapOptions)
  const location = useSelector(selectLocation)
  const dispatch = useDispatch()

  const { push } = useHistory()

  const updateRange = !!location && voivodship === undefined
  const showRange = updateRange || desktop

  return (
    <div className={`map__filter ${!showRange ? '--smaller' : ''}`}>
      <div className='filter__group group'>
        <div className='filter__location'>
          {locationBtn}
        </div>

        <div
          className='filter__input'
          onClick={() => push('/mapsearch')}>

          <input
            key={location}
            defaultValue={location}
            placeholder='szukaj ulicy...' />

          <Search />
        </div>
      </div>

      {showRange && <div className='filter__group group'>
        <Range
          darkBG
          unit='km'
          value={distance || DISTANCE_LIST[0]}
          options={DISTANCE_LIST}
          updateValue={d => {
            if (updateRange) dispatch(updateMapOption({ distance: d }))
            else push('/mapsearch')
          }}
        />
      </div>}
    </div>
  )
}

export default Filter