import locationIcon from 'assets/userLocation.svg'
import pocztaPolskaIcon from './pocztaPolska.svg'
import orlenPaczkaIcon from './orlenPaczka.svg'
import inPostIcon from './inpost.svg'
import fedexIcon from './fedex.svg'
import glsIcon from './gls.svg'
import dhlIcon from './dhl.svg'
import upsIcon from './ups.svg'
import dpdIcon from './dpd.svg'
import { Icon } from 'leaflet'
import mmIcon from './mm.svg'

const iconAnchor: [number, number] = [30, 44]
const iconSize: [number, number] = [60, 88]
const dpd = new Icon({
  iconUrl: dpdIcon,
  iconAnchor,
  iconSize,
})

const dhl = new Icon({
  iconUrl: dhlIcon,
  iconAnchor,
  iconSize,
})

const inpost = new Icon({
  iconUrl: inPostIcon,
  iconAnchor,
  iconSize,
})

const poczta = new Icon({
  iconUrl: pocztaPolskaIcon,
  iconAnchor,
  iconSize,
})

const ups = new Icon({
  iconUrl: upsIcon,
  iconAnchor,
  iconSize,
})

const mm = new Icon({
  iconUrl: mmIcon,
  iconAnchor,
  iconSize,
})

const fedex = new Icon({
  iconUrl: fedexIcon,
  iconAnchor,
  iconSize,
})

const gls = new Icon({
  iconUrl: glsIcon,
  iconAnchor,
  iconSize,
})

const orlen = new Icon({
  iconUrl: orlenPaczkaIcon,
  iconAnchor,
  iconSize,
})

const location = new Icon({
  iconUrl: locationIcon,
  iconAnchor: [15, 25],
  iconSize: [30, 50],
})

export const getIcon = (supplier: string) => {
  switch (supplier) {
    case 'dpd': return dpd
    case 'dhl': return dhl
    case 'inpost': return inpost
    case 'poczta': return poczta
    case 'orlen': return orlen
    case 'fedex': return fedex
    case 'gls': return gls
    case 'ups': return ups
    case 'mm': return mm
    default: return location
  }
}