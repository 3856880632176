import useTranslation from '../../../hooks/useTranslation'
import { updateCompany } from '../../Basket/basketSlice'
import { resetForm, updateValues } from '../formSlice'
import Submit from '../../../components/inputs/Submit'
import { useEffect, useRef, useState } from 'react'
import { submit as companySubmit } from './submit'
import CompaniesElement from './CompaniesElement'
import { STATUS } from '../../../utils/constants'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import NewBtn from './NewBtn'
import Form from './Form'

const Companies = ({ menuRef, data, getData }) => {
  const [status, setStatus] = useState(STATUS.idle)
  const [companyIdx, setCompanyIdx] = useState(null)
  const [form, showForm] = useState(false)

  const dispatch = useDispatch()
  const buttonRef = useRef(null)

  const { t } = useTranslation('basic')
  const { push } = useHistory()

  useEffect(() => {
    if (form && menuRef?.current) scroll(menuRef)
  }, [form, menuRef])

  const selectData = invoice => {
    if (!invoice && companyIdx === null) return
    const i = invoice || data[companyIdx]
    dispatch(updateCompany(i))
    push('/summary')
  }

  const scroll = ref => ref?.current?.scrollIntoView({ behavior: 'smooth' })

  const submit = (body, id = '') => {
    const error = () => setStatus(STATUS.failed)
    const success = data => {
      setStatus(STATUS.succeed)
      dispatch(resetForm())
      showForm(false)

      body && !id && data ? selectData(data) : getData()
    }

    setStatus(STATUS.pending)
    companySubmit(body, id, success, error)
  }

  return (
    form || data?.length === 0 ? <Form
      submit={submit}
      block={status === STATUS.pending}
      cancel={() => showForm(false)}
    /> : <>
      <div className='companies__list list'>
        {data.length > 0 && data.map((e, i) => <CompaniesElement
          key={i}
          data={e}
          selected={i === companyIdx}
          selectData={() => setCompanyIdx(idx => {
            if (idx !== i) scroll(buttonRef)
            return idx === i ? null : i
          })}
          edit={() => {
            dispatch(updateValues(e))
            showForm(true)
          }} />)}

      </div>

      <div className='companies__info'>
        <NewBtn
          newCompany
          action={() => showForm(true)} />

        <div className='companies__or'>{t('or')}</div>
        <div ref={buttonRef}>
          <Submit
            text={'Dalej'}
            action={() => selectData()}
            block={companyIdx === null} />
        </div>
      </div>
    </>
  )
}

export default Companies