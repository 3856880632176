import useAuthSuccessCallback from './useAuthSuccessCallback'
import { STATUS, UNAUTHORIZED_TYPES } from 'utils/constants'
import { selectRestPopupBody } from 'rest/restSlice'
import { newmailcode, newsmscode } from 'rest/urls'
import Submit from 'components/inputs/Submit'
import Popup from 'components/popups/Popup'
import PinForm from 'components/PinForm'
import { useReduxSelector } from 'store'
import { POST } from 'rest/request'
import { useState } from 'react'

const OtpPopup: React.FC = () => {

  const popupBody = useReduxSelector(selectRestPopupBody)
  const { recipient, type, verified } = popupBody as rest.UnauthorizedResponse

  const [token, setToken] = useState('')
  const [warning, setWarning] = useState(false)
  const [status, setStatus] = useState<keyof typeof STATUS>(STATUS.idle)

  const successCallback = useAuthSuccessCallback()

  const submit = () => {
    successCallback({ success: true, token })
  }

  const getCode = async () => {
    if (status === STATUS.pending) return
    setStatus(STATUS.pending)

    try {
      const body = { [type]: recipient }
      const response = await POST(type === UNAUTHORIZED_TYPES.mailOtp ? newmailcode : newsmscode, { body })
      if (response) {
        setStatus(STATUS.succeed)
      } else throw new Error('Wrong response')

    } catch (error) {
      setStatus(STATUS.failed)
      console.error('Getting code failed, failed message: ', error)
    }
  }

  return (
    <Popup>
      <div className='popup__verification'>
        <div className='popup__title'>
          <div>Autoryzacja</div>
        </div>

        <div className='popup__form'>
          {verified === false && <p className='verification__warning'>Niewłaściwy kod</p>}

          {recipient && <>
            <div className='verification__text'>
              <div>Przepisz kod weryfikacyjny</div>
              <div>wysłany na</div>
            </div>

            <h2>{recipient}</h2>
          </>}

          <div
            className='verefication__phone'
            onClick={getCode}>
            {status === STATUS.pending ? 'ładowanie...' : 'Przyślij ponownie'}
          </div>

          <PinForm
            isCode
            autoFocus
            value={token}
            warning={warning}
            autoLoadCodeFromSMS
            setWarning={setWarning}
            setValue={setToken}
            submit={submit}
          />

          <Submit
            text='Zapisz'
            action={() => token?.length === 6 ? submit() : setWarning(true)} />
        </div>
      </div>
    </Popup>
  )
}

export default OtpPopup