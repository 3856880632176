import SocketProvider from 'context/socket/SocketProvider'
import { fetchToken } from 'containers/Login/loginSlice'
import AppProvider from 'context/AppProvider'
import StoreManager from 'rest/storeManager'
import { Provider } from 'react-redux'
import Desktop from 'layouts/Desktop'
import * as process from 'process'
import ReactDOM from 'react-dom'
import Router from './Router'
import store from './store'
import React from 'react'
import Auth from './Auth'
import App from './App'
import './index.css'

/**
 * Fix for simple-peer package
 */
window.global = window;
window.process = process;

/**
 * Saving a store instance to a singleton
 */
StoreManager.set(store)
store.dispatch(fetchToken())

const app = <React.StrictMode>
  <Provider store={store}>
    <Auth>
      <SocketProvider>
        <App>
          <Router>
            <AppProvider>
              <Desktop />
            </AppProvider>
          </Router>
        </App>
      </SocketProvider>
    </Auth>
  </Provider>
</React.StrictMode>

ReactDOM.render(app, document.getElementById('root'))
