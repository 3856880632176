import { useHistory, useParams } from 'react-router-dom'
import { selectLocation } from './menu/filtersSlice'
import { useEffect, useRef, useState } from 'react'
import { selectMapOptions } from './Map/mapSlice'
import { isMobile } from '../../utils/isMobile'
import { useSelector } from 'react-redux'
import Options from './Map/Options'
import Filter from './menu/Filter'
import Close from './menu/Close'
import Place from './menu/Place'
import Result from './Result'
import Map from './Map'

const mobile = isMobile()

const map = <Map />
const filter = <Filter desktop={!mobile} />

const View = () => {

  const location = useSelector(selectLocation)
  const { voivodship } = useSelector(selectMapOptions)

  const [expanded, expand] = useState(false)
  const [fullScreen, setFullScreen] = useState(false)
  const [scrollableMenu, setMenuScrollable] = useState(false)

  const { placeId } = useParams()
  const { replace, location: { search } } = useHistory()

  const mapRef = useRef(null)
  const menuRef = useRef(null)
  const scrolledUp = useRef(true)


  useEffect(() => {
    if (mobile) {
      document.body.style.setProperty('overflow', 'hidden')
      return () => document.body.style.removeProperty('overflow')
    }
  }, [])

  useEffect(() => {
    if (placeId) {
      return () => {
        expand(false)
        scrolledUp.current = true
      }
    }
  }, [placeId])

  useEffect(() => {
    if (!search) return
    const query = new URLSearchParams(search)
    const from = query.get('from')

    if (from) {
      expand(true)
      setTimeout(() => {
        const mag = document.querySelector(`#${from}`)
        if (mag) mag.scrollIntoView({ block: 'center' })
      }, 600)
    }
    replace('/map')
  }, [search, replace])

  const onTouch = e => {
    const menu = menuRef.current
    if (!menu || !mobile) return

    const initialY = e.touches[0].clientY
    const touchMove = e => {
      const currentY = e.touches[0].clientY
      if ((initialY - currentY > 50 && !expanded) || (currentY - initialY > 50 && expanded)) {
        expand(!expanded)
        touchEnd()
      }
    }

    const touchEnd = () => {
      menu.removeEventListener('touchend', touchEnd, false)
      menu.removeEventListener('touchmove', touchMove, false)
    }

    menu.addEventListener('touchend', touchEnd, false)
    menu.addEventListener('touchmove', touchMove, false)
  }

  const scrollMenu = e => {
    const { scrollTop } = e.target
    scrolledUp.current = scrollTop < 1
  }

  const scrollMap = e => {
    const halfHeight = window.innerHeight / 2
    const y = e.target.scrollTop

    if (!scrollableMenu && y >= halfHeight) setMenuScrollable(true)
    else if (scrollableMenu && y < halfHeight) setMenuScrollable(false)

    if (!expanded && y > 160) expand(true)
    else if (expanded && y < 20) expand(false)
  }

  const scroll = ref => ref.current?.scrollIntoView({ behavior: 'smooth' })
  const isMobileWithoutPopups = () => mobile && !window.history.state?.state

  const menuWider = location && voivodship === undefined
  const menuSize = fullScreen ? 2.9 : menuWider ? 21.5 : 17.5

  const toggleMenu = () => {
    if (menuRef.current && expanded) menuRef.current.scrollTop = 0
    mobile ? expand(!expanded) : scroll(expanded ? mapRef : menuRef)
  }

  const menuStyle = {
    mobile: !placeId ?
      expanded
        ? { top: '2em', overflowY: 'auto' }
        : { top: `calc(var(--vh, 1vh) * 100 - ${menuSize}em )`, overflowY: 'hidden' }
      : expanded ? undefined : { marginTop: '-2em' },
    desktop: scrollableMenu ? { overflowY: 'auto' } : { overflowY: 'hidden' }
  }

  return (
    <div
      className='maps'
      onScroll={e => !mobile && scrollMap(e)}
      style={{ overflow: mobile ? 'hidden' : 'auto' }}>

      <div
        ref={mapRef}
        className={`maps__main ${fullScreen ? '--full' : menuWider ? '' : '--wider'}`}
        style={placeId ?
          mobile
            ? { height: expanded ? '0' : `calc(var(--vh, 1vh) * 45)` }
            : { height: 'calc(var(--vh, 1vh) * 60)' }
          : undefined}>

        {map}
        {((mobile && !expanded) || (!mobile && !scrollableMenu)) && !placeId && <Options
          fullScreen={fullScreen}
          setFullScreen={setFullScreen}
        />}
      </div>

      <div
        ref={menuRef}
        className={`map__menu${mobile ? ' --mobile' : ''}${(placeId && expanded) ? ' --expanded' : ''}`}
        onScroll={e => isMobileWithoutPopups() && scrollMenu(e)}
        onTouchStart={e => (isMobileWithoutPopups() && scrolledUp.current) && onTouch(e)}
        style={mobile ? menuStyle.mobile : menuStyle.desktop}>

        {!(placeId && expanded) && <Close
          expand={expand}
          placeId={placeId}
          expanded={expanded}
          fullScreen={fullScreen}
          toggleMenu={toggleMenu}
          setFullScreen={setFullScreen}
        />}

        {placeId && <Place
          placeId={placeId}
          scrollMenu={e => isMobileWithoutPopups() && scrollMenu(e)}
        />}

        <div style={placeId ? { display: 'none' } : {}}>
          {!expanded && filter}
          <Result
            menuRef={menuRef}
            expanded={expanded}
            show={toggleMenu}
          />
        </div>
      </div>
    </div>
  )
}

export default View