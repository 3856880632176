import { selectWarehouse } from '../Warehouses/warehousesSlice'
import useWarehousesLoading from 'hooks/useWarehousesLoading'
import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import MagNumber from '../../components/MagNumber'
import { isMobile } from '../../utils/isMobile'
import WarehouseSlides from './WarehouseSlides'
import { useReduxSelector } from 'store'
import MagPromotion from './MagPromotion'
import MagMessages from './MagMessages'
import MagActions from './MagActions'
import MagHistory from './MagHistory'
import MagAccess from './MagAccess'
import MagStatus from './MagStatus'
import MagManage from './MagManage'
import './style.scss'

const mobile = isMobile()

const statuses = <MagStatus />
const promotion = <MagPromotion />

export const SECTION_TYPES = {
  access: 'access',
  details: 'details',
  history: 'history',
  invoices: 'invoices',
  resignation: 'resignation'
}

export type GoTo = (options?: { path: string, back?: boolean }) => void

const Warehouse: React.FC = () => {

  const { push, replace, location: { pathname, search } } = useHistory()
  const { id } = useParams<warehouse.PageParams>()

  const { hasOnlineAccess, name, contractId } = useReduxSelector(state => selectWarehouse(state, id))
  const [expanded, expand] = useState(!!search)
  useWarehousesLoading(!contractId)

  const menuRef = useRef<HTMLDivElement>(null)
  const accessRef = useRef<HTMLDivElement>(null)
  const manageRef = useRef<HTMLDivElement>(null)
  const historyRef = useRef<HTMLDivElement>(null)
  const warehouseRef = useRef<HTMLDivElement>(null)

  const scrolledUp = useRef(true)

  useEffect(() => {
    if (mobile) {
      document.body.style.setProperty('overflow', 'hidden')
      return () => {
        document.body.style.removeProperty('overflow')
      }
    }
  }, [])

  useEffect(() => {
    if (search) {
      const query = new URLSearchParams(search)
      const from = query.get('from')

      if (from) {
        scroll(from === SECTION_TYPES.history ? historyRef
          : from === SECTION_TYPES.access ? accessRef
            : manageRef, 'auto')
        replace(pathname)
      }
    }
  }, [pathname, replace, search])

  const onTouch = (e: React.TouchEvent<HTMLDivElement>) => {
    const menu = menuRef.current
    if (!menu || !mobile) return

    const initialY = e.touches[0].clientY
    const touchMove = (e: TouchEvent) => {
      const currentY = e.touches[0].clientY
      if ((initialY - currentY > 40 && !expanded) || (currentY - initialY > 40 && expanded)) {
        expand(!expanded)
        touchEnd()
      }
    }

    const touchEnd = () => {
      menu.removeEventListener('touchend', touchEnd, false)
      menu.removeEventListener('touchmove', touchMove, false)
    }

    menu.addEventListener('touchend', touchEnd, false)
    menu.addEventListener('touchmove', touchMove, false)
  }

  const scrollPage = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollTop } = e.target as HTMLDivElement
    if (!expanded && scrollTop > 160) expand(true)
    else if (expanded && scrollTop < 20) expand(false)
  }

  const scrollMenu = (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollTop } = e.target as HTMLDivElement
    scrolledUp.current = scrollTop < 1
  }

  const scroll = (ref: React.RefObject<HTMLElement>, behavior: ScrollBehavior = 'smooth') => {
    ref.current?.scrollIntoView({ behavior })
  }

  const goTo: GoTo = ({ path, back } = { path: SECTION_TYPES.access, back: true }) => {
    if (back) replace(`${pathname}?from=${path}`)
    push(`${pathname}/${path}`)
  }

  const toggleMenu = () => {
    if (menuRef.current && expanded) menuRef.current.scrollTop = 0
    mobile ? expand(!expanded) : scroll(expanded ? warehouseRef : menuRef)
  }

  return (
    <div
      className='warehouse'
      onScroll={e => !mobile && scrollPage(e)}
      style={{ overflow: mobile ? 'hidden' : 'auto' }}>

      <div
        ref={warehouseRef}
        className='warehouse__main center'>
        <MagNumber number={name} />
        <WarehouseSlides id={id} />
      </div>

      <div
        ref={menuRef}
        className='menu__main'
        style={(expanded && mobile) ? { top: 0 } : undefined}
        onTouchStart={e => (mobile && scrolledUp.current) && onTouch(e)}>

        <div
          className='menu__full'
          onTouchStart={e => mobile && onTouch(e)}>
          {statuses}
        </div>

        <div
          onScroll={e => mobile && scrollMenu(e)}
          style={{ overflowY: expanded ? 'auto' : 'hidden' }}>

          <MagActions
            id={id}
            expanded={expanded}
            toggleMenu={toggleMenu} />

          {expanded && <>
            <MagMessages id={id} />
            <div ref={manageRef}>
              <MagManage
                id={id}
                goTo={goTo}
              />
            </div>
            {hasOnlineAccess && <div ref={historyRef}>
              <MagHistory
                id={id}
                more={() => goTo({ path: SECTION_TYPES.history })}
              />
            </div>}
            {hasOnlineAccess && <div ref={accessRef}>
              <MagAccess more={goTo} />
            </div>}
            {promotion}
          </>}
        </div>
      </div>
    </div>
  )
}

export default Warehouse