import { addMessage, updateUnreadMessages } from '../../containers/Chats/chatSlice'
import useWssConnection from './useWssConnection'
import SocketContext from './SocketContext'
import { useDispatch } from 'react-redux'
import logo from '../../assets/logo.svg'
import { useEffect } from 'react'

const SocketProvider = ({ children }) => {
  const { messageData, sendMessage } = useWssConnection()
  const dispatch = useDispatch()

  useEffect(() => {
    if (messageData.event === 'newMessage') {
      dispatch(addMessage(messageData.payload))
      if (!window.location.pathname.includes('chat')) {
        dispatch(updateUnreadMessages())
        if (Notification.permission === 'granted') {
          new Notification('Masz nową wiadomość', {
            body: messageData.payload.message,
            icon: logo
          })
        }
      }
    }
  }, [dispatch, messageData])

  return (
    <SocketContext.Provider value={{
      messageData,
      sendMessage,
    }}>
      {children}
    </SocketContext.Provider>
  )
}

export default SocketProvider