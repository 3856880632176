import { addItemToBasket, selectBasketItem, extendFormToMap } from 'containers/Basket/basketSlice'
import { selectCalendar } from 'containers/OrderDetails/Calendar/calendarSlice'
import { getDateString, getEndDate, getStartDate } from 'utils/date'
import { ReactComponent as Arrow } from 'assets/arrowRight.svg'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from 'hooks/useTranslation'
import { useHistory } from 'react-router-dom'
import { checkAvailability } from 'rest/urls'
import Popup from 'components/popups/Popup'
import { useEffect, useState } from 'react'
import { POST } from 'rest/request'

const NewMagPopup = () => {
  const [isAvailable, setAvailable] = useState(undefined)
  const calendar = useSelector(selectCalendar)
  const item = useSelector(selectBasketItem)

  const dispatch = useDispatch()
  const { replace } = useHistory()
  const { t } = useTranslation('basket')

  const verifyAvailability = async (placeId, hash) => {
    try {
      const response = await POST(checkAvailability(placeId), { body: { hash } })
      if (typeof response?.count !== 'number') {
        throw new Error('wrong response')
      } else {
        setAvailable(response.count > 0)
      }
    } catch (err) {
      setAvailable(false)
      console.log(`Error: ${err}`)
    }
  }

  const addNewItem = withTheSameDate => {
    if (!isAvailable) return
    const { identifier, ...newItem } = item
    if (!withTheSameDate) {
      const { m, d, gross_price, gross_price_d } = calendar?.priceList?.at(-1) || {}
      const date = getStartDate(calendar?.start)
      newItem.rangeDate = { m, d }
      newItem.start = date
      newItem.monthlyPrice = gross_price
      newItem.dailyPrice = gross_price_d
      newItem.end = calendar?.end || getEndDate({ m, d }, date)
    }
    dispatch(addItemToBasket(newItem))
    replace(`/calendar/${item.placeId}/${item.hash}`)
  }

  const openMap = withDetails => {
    dispatch(extendFormToMap(true))
    replace((withDetails && item.placeId) ? `/mapdetails/${item.placeId}` : '/map')
  }

  useEffect(() => {
    verifyAvailability(item.placeId, item.hash)
  }, [item.hash, item.placeId])

  return (
    <Popup>
      <div className='popup__newMag'>
        <h2>{t('anotherMAG')}</h2>

        <div className='popup__header'>{calendar.typeName}{isAvailable === false ? ' - niedostępny' : ''}:</div>
        <div
          className='popup__menu'
          style={isAvailable
            ? undefined
            : { pointerEvents: 'none', opacity: 0.6 }}>
          <div
            className='menu__item group'
            onClick={() => addNewItem(true)}>
            <div className='item__desc --termina'>Ten sam okres: {getDateString(item.start)} → {item.end ? getDateString(item.end) : '∞'}</div>
            <div className='menu__arrow center'>
              <Arrow />
            </div>
          </div>

          <div
            className='menu__item group'
            onClick={() => addNewItem(false)}>
            <div className='item__desc --termina'>{t('selectAgain')}</div>
            <div className='menu__arrow center'>
              <Arrow />
            </div>
          </div>
        </div>

        <div className='popup__header'>Inny magazyn:</div>
        <div className='popup__menu'>
          <div
            className='menu__item group'
            onClick={() => openMap(true)}>
            <div className='item__desc --termina'>Ten sam plac: {calendar.placeName}</div>
            <div className='menu__arrow center'>
              <Arrow />
            </div>
          </div>

          <div
            className='menu__item group'
            onClick={() => openMap(false)}>
            <div className='item__desc --termina'>Inny plac</div>
            <div className='menu__arrow center'>
              <Arrow />
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default NewMagPopup