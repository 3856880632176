import { errorMessage, finalExp } from '../../../utils/validation'
import Checkbox from '../../../components/inputs/Checkbox'
import { selectForm, updateValue } from '../formSlice'
import { useDispatch, useSelector } from 'react-redux'
import Submit from '../../../components/inputs/Submit'
import Input from '../../../components/inputs/Input'
import { preregister } from '../../../rest/urls'
import { POST } from '../../../rest/request'
import { useState } from 'react'

const Form = ({ block, submit, showWarning }) => {
  const { firstName = '', lastName = '', mail = '', phone = '', rulesAgreement = false } = useSelector(selectForm)

  const [needOtp, setOtp] = useState(true)
  const [passwords, setPasswords] = useState({
    first: '',
    second: '',
  })
  const [warning, setWarning] = useState({
    firstName: false,
    lastName: false,
    mail: false,
    phone: false,
    password: false,
    passwordSec: false,
    rulesAgreement: false,
  })

  const dispatch = useDispatch()

  const refs = []


  const changeHandler = e => {
    if (!e) return

    const { name, value, checked, type } = e.target
    const newValue = type === 'checkbox' ? checked : value

    if (name.includes('password')) setPasswords({
      ...passwords,
      [name === 'password' ? 'first' : 'second']: value
    })

    else dispatch(updateValue({ name, value: newValue }))

    if (newValue && warning[name] && (
      (name === 'firstName' && newValue.length > 2) ||
      (name === 'lastName' && newValue.length > 1) ||
      (name === 'mail' && newValue.match(finalExp.mail)) ||
      (name === 'phone' && newValue.match(finalExp.phone)) ||
      (name === 'password' && newValue.match(finalExp.password)) ||
      (name === 'passwordSec' && newValue === passwords.first) ||
      (name === 'rulesAgreement')))
      setWarning({
        ...warning,
        [name]: false,
      })
  }

  const blurHandler = e => {
    if (!e) return
    const { name, value } = e.target
    let warn = false

    switch (name) {
      case 'firstName':
        warn = value.length < 3 ? errorMessage(3 - value.length) : false
        break
      case 'lastName':
        warn = value.length < 2 ? errorMessage(2 - value.length) : false
        break
      case 'mail':
        if (value.match(finalExp.mail)) checkFormData(name, { email: value })
        else warn = 'niewłaściwy e-mail'
        break
      case 'phone':
        if (value.length < 9) warn = errorMessage(9 - value.length)
        else if (!value.match(finalExp.phone)) warn = 'niewłaściwy numer'
        else checkFormData(name, { phone: value })
        break
      case 'password':
        warn = passwordMessage(value)
        break
      case 'passwordSec':
        warn = passwords.first !== passwords.second ? 'hasła nie pasują' : false
        break
      default: warn = false
    }

    if (warn) setWarning({
      ...warning,
      [name]: warn,
    })
  }

  const checkFormData = async (name, body) => {
    try {
      const response = await POST(preregister, { body })
      const { valid = true, need_phone_otp } = response
      if (need_phone_otp !== needOtp) setOtp(need_phone_otp)
      if (!valid) {
        document.activeElement.blur()
        setWarning({
          ...warning,
          [name]: 'już w użyciu',
        })
        showWarning(name === 'mail')
      }

    } catch (err) {
      console.warn('Fetch preregister faild')
    }
  }

  const passwordMessage = (p = '') => {
    if (p.length < 8) return errorMessage(8 - p.length)
    else if (p.match(finalExp.password)) return false

    let warn = 'brakuje:'
    if (!p.match(/^(?=.*?[A-Z]).*$/)) warn += ' dużej litery,'
    if (!p.match(/^(?=.*?[a-z]).*$/)) warn += ' małej litery,'
    if (!p.match(/^(?=.*?[0-9]).*$/)) warn += ' liczby,'
    if (!p.match(/^(?=.*?[#?!@$%^&*-]).*$/)) warn += ' symbolu'
    return warn
  }

  const enterClicked = ref => {
    const index = refs.findIndex(r => Object.is(r, ref))
    if (index !== undefined && refs[index + 1]) refs[index + 1].focus()
  }

  const validation = () => {
    if (firstName.length > 2 && lastName.length > 1 && mail.match(finalExp.mail) && phone.match(finalExp.phone) && passwords.first.length > 7 && passwords.second === passwords.first && rulesAgreement) {
      const body = {
        first_name: firstName, 
        surname: lastName, 
        email: mail, phone, 
        password: passwords.first, 
        password_confirmation: passwords.second, 
        terms: rulesAgreement
      }
      submit(needOtp, body)

    } else setWarning({
      firstName: firstName.length < 3 ? errorMessage(3 - firstName.length) : false,
      lastName: lastName.length < 2 ? errorMessage(2 - lastName.length) : false,
      mail: !mail.match(finalExp.mail) ? 'niewłaściwy e-mail' : false,
      phone: phone.length < 9 ? errorMessage(9 - phone.length) : !phone.match(finalExp.phone) ? 'niewłaściwy numer' : false,
      password: passwordMessage(passwords.first),
      passwordSec: passwords.first !== passwords.second ? 'hasła nie pasują' : false,
      rulesAgreement: !rulesAgreement,
    })
  }

  return (
    <div className='registration__form form'>
      <Input
        required
        ref={r => refs.push(r)}
        value={firstName}
        name='firstName'
        label='Imię'
        minLength='3'
        maxLength='100'
        disabled={block}
        warning={warning.firstName}
        placeholder='Wpisz imię'
        blurHandler={blurHandler}
        enterClicked={enterClicked}
        changeHandler={changeHandler} />

      <Input
        required
        ref={r => refs.push(r)}
        value={lastName}
        name='lastName'
        label='Nazwisko'
        minLength='2'
        maxLength='100'
        disabled={block}
        warning={warning.lastName}
        placeholder='Wpisz nazwisko'
        blurHandler={blurHandler}
        enterClicked={enterClicked}
        changeHandler={changeHandler} />

      <Input
        required
        ref={r => refs.push(r)}
        value={mail}
        name='mail'
        label='Adres e-mail'
        minLength='4'
        maxLength='100'
        disabled={block}
        warning={warning.mail}
        placeholder='Wpisz e-mail'
        blurHandler={blurHandler}
        enterClicked={enterClicked}
        changeHandler={changeHandler} />

      <Input
        required
        ref={r => refs.push(r)}
        value={phone}
        name='phone'
        type='tel'
        label='Numer telefonu'
        minLength='9'
        maxLength='12'
        disabled={block}
        warning={warning.phone}
        placeholder='Wpisz numer telefonu'
        blurHandler={blurHandler}
        enterClicked={enterClicked}
        changeHandler={changeHandler} />

      <Input
        required
        ref={r => refs.push(r)}
        value={passwords.first}
        name='password'
        type='password'
        label='Hasło'
        minLength='8'
        maxLength='60'
        disabled={block}
        warning={warning.password}
        placeholder='Wpisz hasło'
        blurHandler={blurHandler}
        enterClicked={enterClicked}
        changeHandler={changeHandler} />

      <Input
        required
        ref={r => refs.push(r)}
        value={passwords.second}
        name='passwordSec'
        type='password'
        label='Powtórz hasło'
        minLength='8'
        maxLength='60'
        disabled={block}
        warning={warning.passwordSec}
        placeholder='Wpisz ponownie hasło'
        blurHandler={blurHandler}
        enterClicked={enterClicked}
        changeHandler={changeHandler} />

      <div className={`checkbox__area input__default${warning.rulesAgreement ? ' --warning' : ''}`}>
        <label className='input__label'>
          <span>Regulamin</span>
        </label>

        <Checkbox
          required
          disabled={block}
          name='rulesAgreement'
          checked={rulesAgreement}
          label='Akceptuje regulamin i inne postanowienia naszej aplikacji'
          changeHandler={changeHandler} />
      </div>

      <Submit
        text='Zarejestruj'
        action={() => !block && validation()} />
    </div>
  )
}

export default Form