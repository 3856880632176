import useFetchedDataWithPagination from 'hooks/useFetchedDataWithPagination'
import { ReactComponent as Arrow } from 'assets/arrowDown.svg'
import { ACCOUNT_TYPE, updateValue } from '../accountSlice'
import { displayPrice, toStringNumber } from 'utils/string'
import { useEffect, useRef, useState } from 'react'
import ArrowBtn from 'components/buttons/ArrowBtn'
import useTranslation from 'hooks/useTranslation'
import Input from 'components/inputs/Input'
import * as filtersFields from './filters'
import { useDispatch } from 'react-redux'
import Filters from 'components/Filters'
import Loading from 'components/Loading'
import Error from 'components/Error'
import { MONTHS } from 'utils/date'
import * as urls from 'rest/urls'
import Item from '../Item'

const type = ACCOUNT_TYPE.invoices

const InvoicesList = ({ amount, invoicesToPay, setInvoicesToPay, customWrapperClassName = '', showPayments }) => {

  const listRef = useRef(null)
  const [filters, setFilters] = useState({})
  const { isLoading, data, handleScroll } = useFetchedDataWithPagination(getListUrl(filters), listRef)

  const dispatch = useDispatch()
  const { t, lang } = useTranslation()
  const updateVal = (name, value) => {
    dispatch(updateValue({ type, name, value }))
  }

  const downloadFv = (id, link, updateItem = false) => {
    if (updateItem) {
      const value = [...data].map(e => {
        if (e.id === id) return { ...e, lastAccessDate: new Date(Date.now()).toLocaleDateString() }
        else return e
      })
      updateVal('data', value)
    }

    if (link) window.open(link, '_blank')
  }

  /**
   * eh...
   */
  useEffect(() => {
    const popup = document.querySelector(`.${customWrapperClassName || 'account__list'}`)
    if (popup) {
      listRef.current = popup
      popup.addEventListener('scroll', handleScroll)
      return () => {
        popup.removeEventListener('scroll', handleScroll)
      }
    }
  }, [customWrapperClassName, handleScroll])

  return (
    <>
      <Filters
        showFiltersBtn
        filters={filters}
        setFilters={setFilters}
        fields={filtersFields[lang]}
      />

      <div className='account__list'>
        {data
          ? data?.length > 0
            ? data.map(({ id, documentDate, fullNumber, link, lastAccessDate, isPaid, grossAmount, remainingAmount }, i) => <Item
              key={i}
              label={(() => {
                const date = new Date(documentDate)
                return `${date.getDate()} ${MONTHS.short[date.getMonth()]}`
              })()}
              title={`FV ${fullNumber}`}
              icon={<div className='circle center'>
                {lastAccessDate && <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.4077 0L4.35194 6.84321L0.596373 3.20182L0 3.78024L4.35194 8L12 0.578372L11.4077 0Z" fill="var(--theme-text)" />
                </svg>}
              </div>}
              desc={<>
                {!isPaid && <div>Do zapłaty: {remainingAmount}{remainingAmount !== grossAmount ? ` z ${grossAmount}` : ''}</div>}
                <div>{lastAccessDate ? `${t('account.downloaded')} ${lastAccessDate}` : t('account.notDownloaded')}</div>
                {invoicesToPay[id] !== undefined && <Input
                  value={invoicesToPay[id]}
                  changeHandler={e => {
                    const { value } = e.target
                    if (value && !value.match(/^\d+\.?\d{0,2}$/)) return
                    setInvoicesToPay(prev => {
                      if (!value) {
                        return { ...prev, [id]: (value || '') }

                      } else {
                        const potentialNewValue = Number(value)
                        const maxInvoiceAmount = Number(remainingAmount)
                        const newValue = potentialNewValue > maxInvoiceAmount ? toStringNumber(maxInvoiceAmount) : value
                        return { ...prev, [id]: newValue }
                      }
                    })
                  }}
                />}
              </>}
              right={<div className='center --gap1'>
                {!isPaid && (invoicesToPay[id] !== undefined
                  ? <i
                    className='icon icon--close'
                    onClick={() => {
                      setInvoicesToPay(prev => {
                        const { [id]: _, ...rest } = prev
                        return rest
                      })
                    }} />
                  : <i
                    className='icon icon--paid'
                    onClick={() => {
                      setInvoicesToPay(prev => {
                        return { ...prev, [id]: remainingAmount }
                      })
                    }} />)}
                <Arrow onClick={() => downloadFv(id, link, !lastAccessDate)} />
              </div>}
            />)
            : <Error staticPos text={t('filters.noItems')} />
          : isLoading
            ? <Loading middleOfPage={!customWrapperClassName} />
            : <Error staticPos />}

        {amount > 0 && <ArrowBtn
          enlarged
          className='account__flyingButton'
          text={`Zapłać ${displayPrice(amount)}`}
          action={showPayments}
        />}
      </div>
    </>
  )
}

const getListUrl = (filters) => {
  const params = generateFilterParams(filters)
  return `${urls.invoices}${params ? `?${params}` : ''}`
}

const generateFilterParams = (filters) => {
  const params = new URLSearchParams()
  for (const key in filters) {
    if (key === 'date') {
      params.append('documentDate', `${filters[key]?.start || ''},${filters[key]?.end || ''}`)
    } else {
      params.append(key, filters[key])
    }
  }
  return params.toString()
}

export default InvoicesList