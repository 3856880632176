import ArrowBtn from 'components/buttons/ArrowBtn'
import useTranslation from 'hooks/useTranslation'
import Input from 'components/inputs/Input'
import React, { useState } from 'react'
import { basicDelivery } from '.'
import '../style.scss'

interface DeliveryCourierProps {
  delivery: payments.Delivery
  fields?: payments.DeliveryMethodField[]
  setDelivery: React.Dispatch<React.SetStateAction<payments.Delivery>>
  saveDelivery: basic.BasicFunc
}

const DeliveryCourier: React.FC<DeliveryCourierProps> = ({ delivery, fields, setDelivery, saveDelivery }) => {
  const [errors, setErrors] = useState<Record<string, string>>({})
  const { t } = useTranslation()

  const validateForm = () => {
    if (!delivery?.address || !fields) return
    const newErrors = fields.reduce((r, e) => {
      if (e.required && !delivery.address[e.inputName]) {
        r[e.inputName] = 'Pole jest wymagane!'
      }
      return r
    }, {} as Record<string, string>)
    if (Object.keys(newErrors).length > 0) setErrors(newErrors)
    else saveDelivery()
  }

  return (
    <>
      <div className='popup__title'>
        <div>Wpisz adres</div>
        <i
          className='icon icon--back s32'
          onClick={() => {
            setDelivery(basicDelivery)
          }}
        />
      </div>

      <div className='popup__form'>
        {fields?.map(({ inputName, readableName, readOnly }, idx) => <Input
          key={idx}
          name={inputName}
          disabled={readOnly}
          label={t(readableName)}
          warning={!!errors[inputName]}
          value={delivery?.address?.[inputName] || ''}
          changeHandler={e => {
            setDelivery(d => ({ ...d, address: { ...d.address, [inputName]: e.target.value } }))
          }}
        />)}

        <ArrowBtn
          enlarged
          text='Zapisz'
          action={validateForm}
        />
      </div>
    </>
  )
}

export default DeliveryCourier