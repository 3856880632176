import useTranslation from '../../../hooks/useTranslation'
import PaymentHistory from './PaymentHistory'
import { useParams } from 'react-router-dom'
import PaymentCard from './PaymentCard'

const Payments = () => {

  const { t } = useTranslation('account')
  const { action } = useParams()

  return (
    <div className='account__safety'>
      <div className='account__header title'>
        <div>{t('payments')}</div>
        <div>{t('subTitle')}</div>
      </div>

      {!action && <PaymentCard />}
      <PaymentHistory full={!!action} />
    </div>
  )
}

export default Payments