import { selectWarehouse } from 'containers/Warehouses/warehousesSlice'
import { useParams } from 'react-router-dom'
import { useReduxSelector } from 'store'
import { FC } from 'react'


const MagPromotion: FC = () => {
  const { id } = useParams<warehouse.PageParams>()
  const { pricelist } = useReduxSelector(state => selectWarehouse(state, id))

  if (!pricelist) return null
  return (
    <div className='menu__section'>
      <p>Dłużej magazynujesz, więcej zyskujesz</p>

      <div className='menu__promotion'>
        {pricelist.map(({ d, m, selected, gross_price, gross_price_d }, i) => <div
          key={i}
          className={`promotion__line center${selected ? ' --selected' : ''}`}>
          {m
            ? <div className={`promotion__label ${selected ? ' --selected' : ''}`}>
              <span>{gross_price} zł</span>
              <span className='--bottom'>{m} m-c</span>
            </div>
            : <div className={`promotion__label ${selected ? ' --selected' : ''}`}>
              <span>{gross_price_d} zł</span>
              <span className='--bottom'>{d} dzień</span>
            </div>}
        </div>)}
      </div>
    </div>
  )
}

export default MagPromotion