import { fetchMapDetails, selectPlace } from 'containers/MapDetails/mapDetailsSlice'
import WarehouseAttrPopup from '../Popups/WarehouseAttrPopup'
import ArrowBtn from '../../../components/buttons/ArrowBtn'
import GalleryPopup from 'components/popups/GalleryPopup'
import { useHistory, useParams } from 'react-router-dom'
import AutomationPopup from '../Popups/AutomationPopup'
import { useDispatch, useSelector } from 'react-redux'
import MMScorePopup from '../Popups/MMScorePopup'
import { LOCATION_STATES } from 'utils/constants'
import usePopupState from 'hooks/usePopupState'
import { useEffect, useState } from 'react'
import MmRating from 'components/MmRating'

const PlaceElement = ({ idx, data, scrollMenu, warehouseTypeId }) => {
  const { id, mm_score, automation, place_attrs, important_attrs, city, street, estateNumber, localNumber, sizes, photos } = data || {}
  const { push, replace, goBack } = useHistory()
  const { popup, pathname } = usePopupState()

  const dispatch = useDispatch()
  const { placeId: placeIdParams } = useParams()
  const { tabs } = useSelector(selectPlace)

  const [activeSize, setActiveSize] = useState(null)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [expandedIndex, setExpandedIndex] = useState(null)

  useEffect(() => {
    dispatch(fetchMapDetails(placeIdParams))
  }, [placeIdParams, dispatch])

  useEffect(() => {
    if (sizes && sizes.length > 0) {
      const newSize = warehouseTypeId
        ? (sizes.find(s => s.warehouseTypeId === warehouseTypeId) || sizes[0])
        : sizes[0]
      setActiveSize(newSize)
    }
  }, [sizes, warehouseTypeId])

  const handleGo = () => {
    if (idx !== undefined) replace(`${window.location.pathname}?from=mag_${idx}`)
    push(`/mapdetails/${id}${activeSize ? `?warehouseTypeId=${activeSize.warehouseTypeId}` : ''}`)
  }

  return (
    <>
      {popup === 'mm_score' && <MMScorePopup />}
      {popup === 'warehouse_attr' && <WarehouseAttrPopup body={place_attrs} />}
      {popup === 'automation' && <AutomationPopup body={automation} />}
      {popup?.type === 'gallery' && <GalleryPopup initialSlide={popup.index} data={photos} />}

      <div
        className='placeElement'
        onScroll={scrollMenu}>
        <div className='placeElement__name'>
          <h2 onClick={() => push(pathname, LOCATION_STATES.popup('mm_score'))}>{city}, {street} {localNumber} {estateNumber}</h2>
          <MmRating number={mm_score} />
          <div className='buttonGo' onClick={handleGo}>
            <i className='icon icon--arrow-right s24'></i>
          </div>
        </div>

        <div className='placeElement__attr' onClick={() => push(pathname, LOCATION_STATES.popup('warehouse_attr'))}>
          {place_attrs.map((item) => {
            return (
              <div key={item.id} className='placeElement__attr__item'>
                <i className={`icon icon--check`}></i>
                <span>{item.name}</span>
              </div>
            )
          })}
        </div>

        <div className='placeElement__automation' onClick={() => push(pathname, LOCATION_STATES.popup('automation'))}>
          <div>
            {automation.key === 'full-automatic'
              ? <i className='icon icon--shield'></i>
              : ''}
            <span className={`${automation.key === 'full-automatic' ? 'green' : ''}`}>{automation.title}</span>
          </div>
          <div>{automation.description}</div>
        </div>

        <div className='placeElement__sizes'>
          <span className='subtitle'>Dostępne rozmiary</span>
          {sizes.map((item, idx) => {
            return (
              <div
                key={idx}
                className={`sizeItem ${item.warehouseTypeId === (activeSize && activeSize.warehouseTypeId) ? 'active' : ''}`}
                onClick={() => setActiveSize(item)}>
                {item.sizeShortName || item.size}
              </div>
            )
          })}
        </div>

        <div className='placeElement__price'>
          <span className='subtitle'>Koszt wybranego rozmiaru</span>
          <span className='--termina'>Od {activeSize?.monthlyGrossPrice}</span>
          <span className='--termina small'>/ miesięcznie</span>
        </div>

        {photos && photos.length > 0 && <div className='placeElement__photos'>
          {photos.map((item, idx) => {
            return (
              <img
                key={idx}
                alt=''
                src={item.public_path}
                onClick={() => {
                  push(pathname, LOCATION_STATES.popup({ type: 'gallery', index: idx }))
                }} />
            )
          })}
        </div>}

        <div className='placeElement__important'>
          {important_attrs.map((item, idx) => {
            const isExpanded = expandedIndex === idx
            return (
              <div key={idx} className='placeElement__important__item'>
                <div
                  className='placeElement__important__item__main'
                  onClick={() => setExpandedIndex(expandedIndex === idx ? null : idx)}>
                  <i className={`icon icon--${item.key}`}></i>
                  <span>{item.name}</span>
                  <i className='icon icon--info'></i>
                </div>
                <div className={`placeElement__important__item__addons ${isExpanded ? 'active' : ''}`}>
                  {item.description}
                </div>
              </div>
            )
          })}
        </div>

        <div className='placeElement__tabs'>
          <div className='placeElement__tabs__head'>
            {tabs?.map((tab, index) => (
              <span key={index} className={activeTabIndex === index ? 'active' : ''} onClick={() => setActiveTabIndex(index)}>
                {tab.name}
              </span>
            ))}
          </div>

          {tabs && <div className='placeElement__tabs__body'>
            {tabs[activeTabIndex].type === 'list'
              ? (tabs[activeTabIndex].content.map((item, index) => (
                <div key={index} className='list__item'>
                  <i className={`icon icon--${item.icon}`}></i>
                  <span>{item.name}</span>
                  <span>{item.description}</span>
                </div>
              )))
              : (<div className='htmlFromApi' dangerouslySetInnerHTML={{ __html: tabs[activeTabIndex].content }} />)}
          </div>}
        </div>
      </div>

      <div className='placeElement__bottomBar'>
        <button
          className='button --back'
          onClick={goBack}>Wróć do mapy</button>
        <ArrowBtn
          enlarged
          text={'Zobacz magazyny'}
          action={handleGo} />
      </div>
    </>
  )
}

export default PlaceElement