import { selectWarehouse, selectWarehouseIndex, updateWarehouse } from '../Warehouses/warehousesSlice'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useReduxSelector, useReduxDispatch } from 'store'
import SlideShow from '../Warehouses/SlideShow'
import { MAG_STATUS } from 'utils/constants'
import React from 'react'

interface WarehouseSlidesProps {
  id: string
}

const WarehouseSlides: React.FC<WarehouseSlidesProps> = ({ id }) => {
  const { hasOnlineAccess, isAlreadyOpened = false } = useReduxSelector(state => selectWarehouse(state, id))
  const index = useReduxSelector(state => selectWarehouseIndex(state, id))

  const { replace, push } = useHistory()
  const { path } = useRouteMatch()

  const dispatch = useReduxDispatch()

  const changeMagStatus = (status: warehouse.WarehouseStatus) => dispatch(updateWarehouse({
    id,
    values: { status: status === MAG_STATUS.closed ? MAG_STATUS.opening : MAG_STATUS.closing }
  }))

  return (
    <SlideShow
      actionInAllSlides
      startIndex={index}
      action={(warehouseId, status) => {
        if (warehouseId === id && hasOnlineAccess && status) {
          if (!isAlreadyOpened) push(`/first/${warehouseId}`)
          else changeMagStatus(status)

        } else if (warehouseId !== id) {
          replace(path.replace(':id', warehouseId))
        }
      }} />
  )
}

export default WarehouseSlides