import { ReactComponent as PadlockClosedS } from 'assets/padlockClosed.svg'
import { ReactComponent as PadlockOpenS } from 'assets/padlockOpen.svg'
import React, { forwardRef, Fragment, useEffect, useState } from 'react'
import FilterButton from 'components/Filters/FilterButton'
import { ReactComponent as Ring } from 'assets/ring.svg'
import Filters from 'components/Filters'
import { MONTHS } from 'utils/date'
import { fields } from './fields'
import { data } from './data'

interface HistoryItem {
  time: string,
  date: string,
  title: string,
  action: string,
  user: string,
  desc: string,
}

const LENGTH = data.length
const history: HistoryItem[] = data.reverse()
const height = window.innerHeight
const ITEMS = height > 900 ? 12 : 8

interface MagHistoryProps {
  id: string
  full?: boolean
  more?: basic.BasicFunc
}

const MagHistory = forwardRef<HTMLDivElement, MagHistoryProps>(({ id, full, more }, ref) => {
  const [filteredHistory, filterHistory] = useState(history)
  const [filters, setFilters] = useState<basic.Form>({ mag: id })
  const [items, showItems] = useState(full ? ITEMS : 3)

  const addItems = () => {
    showItems(i => i + ITEMS <= LENGTH ? i + ITEMS : LENGTH)
  }

  const onScroll: React.UIEventHandler<HTMLDivElement> = e => {
    const { clientHeight, scrollTop, scrollHeight } = e.target as HTMLElement
    if (scrollHeight - clientHeight - scrollTop < 5) addItems()
  }

  /**
   * Ugly code 🫣
   */
  useEffect(() => {
    const filterDate: { start?: Date, end?: Date } | false = filters.date ? {} : false
    if (filterDate) {
      if (filters.date.start) filterDate.start = new Date(`${filters.date.start}, 00:00`)
      if (filters.date.end) filterDate.end = new Date(`${filters.date.end}, 00:00`)
    }
    const filtered = history.filter((item) => {
      return (() => {
        for (const filterKey in filters) {
          if (filterKey === 'date') {
            if (!item.date || !filterDate) return false
            const dateObj = new Date(item.date.split('.').reverse().join(','))
            if (filterDate?.start && dateObj < filterDate.start) return false
            if (filterDate?.end && dateObj > filterDate.end) return false

          } else if (item[filterKey as keyof HistoryItem] !== undefined) {
            if (item[filterKey as keyof HistoryItem] !== filters[filterKey]) return false
          }
        }
        return true
      })()
    })
    filterHistory(filtered)
  }, [filters])

  return (
    <div className='menu__section'>
      <div className='group'>
        <p>Lista zdarzeń</p>
        {full && <FilterButton />}
      </div>

      {full && <Filters
        fields={fields}
        filters={filters}
        setFilters={setFilters}
      />}

      <div
        ref={ref}
        className='menu__history'
        onScroll={onScroll}>

        {filteredHistory
          .filter((_, i) => i < items)
          .map(({ time, date, action, title, desc, user }, i, arr) => {
            const showDate = i === 0 || arr[i - 1].date !== date
            const dateList = showDate ? date.split('.') : []

            return <Fragment key={i}>
              {showDate && <>
                <div className='history__date'>{Number(dateList[0])} {dateList[1] ? MONTHS.long[Number(dateList[1]) - 1]: ''}</div>
                <hr />
              </>}

              <div className='history__item'>
                <div className='group'>
                  <div className='item__left'>
                    <div className='history__symbol center'>
                      {action === 'opening' ? <PadlockOpenS />
                        : action === 'closing' ? <PadlockClosedS />
                          : <Ring />}
                    </div>

                    <div className='history__desc'>
                      <div
                        className='--termina'
                        style={action === 'alarm' ? { color: 'var(--red)' } : undefined}>{title}</div>
                      <div>{desc || `Akcje uruchomił uzytkownik: ${user}`}</div>
                    </div>
                  </div>

                  <div className='history__time'>{time}</div>
                </div>
              </div>
            </Fragment>
          })}

        {!full && <>
          <div
            className='menu__more center --termina --high'
            onClick={more}>Więcej →</div>
          <hr />
        </>}
      </div>
    </div>
  )
})

export default MagHistory