import { BASKET_TYPES, selectBasket } from 'containers/Basket/basketSlice'
import useTranslation from '../../hooks/useTranslation'
// import { getRangeString } from '../../utils/date'
import { useSelector } from 'react-redux'
import { displayPrice } from 'utils/string'

const Summary = ({ price: additionalPrice }) => {
  const { list, checkout } = useSelector(selectBasket)
  const { t } = useTranslation()

  const amount = list.reduce((r, e) => r += e.type === BASKET_TYPES.rent ? 1 : 0, 0)

  return (
    <div className='payment__summary center'>
      <div>
        <div className='summary__item --primary'>
          <div>{t('account.toPay')}</div>
          <div>{displayPrice(checkout?.to_pay?.now?.grossPrice || additionalPrice)}</div>
        </div>
      </div>

      <div>
        {/* <div className='summary__item'>
          <div>Czas trwania</div>
          <div>{getRangeString({ m, d })}</div>
        </div> */}

        {amount > 0 && <div className='summary__item'>
          <div>{t('basket.numberOfMags')}</div>
          <div>{amount}</div>
        </div>}
      </div>
    </div>
  )
}

export default Summary