import ButtonContextProvider from './buttonContext/Provider'
import { useEffect, useState, useRef } from 'react'
import { isMobile } from '../../utils/isMobile'
import BasketDetails from './BasketDetails'
import './style.scss'

const mobile = isMobile()
export const BASKET_CONTAINER_ID = 'basket_container_id'

const BasketContainer = ({ extendedAppearance = true, children }) => {
  const [isFullBasket, showFullBasket] = useState(true)
  const lastScrollPosition = useRef(0)

  useEffect(() => {
    if (mobile) {
      document.body.style.setProperty('overflow', 'hidden')
      return () => document.body.style.removeProperty('overflow')
    }
  }, [])

  return (
    <ButtonContextProvider>
      <div className='basket'>
        <section
          id={BASKET_CONTAINER_ID}
          className='--animated'
          onScroll={e => {
            const { scrollTop, scrollHeight, clientHeight } = e.target
            if (scrollTop + clientHeight + 10 >= scrollHeight) return
            if (scrollTop < lastScrollPosition.current) {
              if (!isFullBasket) showFullBasket(true)
            } else {
              if (isFullBasket) showFullBasket(false)
            }
            lastScrollPosition.current = scrollTop
          }}>
          {children}
        </section>

        <BasketDetails
          isFullBasket={isFullBasket}
          extendedAppearance={extendedAppearance} />
      </div>
    </ButtonContextProvider>
  )
}

export default BasketContainer