import { sendBasket } from 'containers/Basket/basketSlice'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

const useCheckout = () => {
  const [isLoading, setLoading] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(sendBasket({ step: 'checkout' })).then(() => {
      setLoading(false)
    })
  }, [dispatch])

  return {
    isLoading
  }
}

export default useCheckout