import { ReactComponent as Calendar } from 'assets/calendar.svg'
import { ReactComponent as Camera } from 'assets/camera.svg'
import DeliveryPopup from 'components/popups/DeliveryPopup'
import { useHistory, useParams } from 'react-router-dom'
import ArrowBtn from 'components/buttons/ArrowBtn'
import { LOCATION_STATES } from 'utils/constants'
import usePopupState from 'hooks/usePopupState'
import { displayPrice } from 'utils/string'
import Option from 'components/Option'
import React from 'react'
import { Action } from '.'

interface DefaultProps {
  response?: payments.CancelRentalResponse
  selectedData: payments.SelectedData
  deliveries: payments.Delivery[]
  selectData: React.Dispatch<React.SetStateAction<payments.SelectedData>>
  setDeliveries: React.Dispatch<React.SetStateAction<payments.Delivery[]>>
}

const Default: React.FC<DefaultProps> = ({ response, selectedData, deliveries, selectData, setDeliveries }) => {
  const { id } = useParams<warehouse.PageParams>()
  const { popup } = usePopupState()
  const { push, goBack } = useHistory()
  const openAction = (action: Action) => {
    push(`/warehouses/${id}/resignation/${action}`)
  }

  let missingSteps = (() => {
    let ms = 0
    if (!selectedData.date) ms++
    if (!selectedData.photo) ms++
    return ms
  })()

  const itemsToReturn = response?.warehouses?.[0]?.itemsToReturn?.map((item) => {
    const { id, name, deposit } = item
    const deliveryId = selectedData.itemsToReturn?.[id]
    if (!deliveryId) missingSteps++
    return <Option
      key={id}
      selected
      label='Zwróć'
      title={`Zwróć ${name}`}
      secondary={!!deliveryId}
      buttonText={deliveryId ? 'Zmień' : 'Zwróć'}
      desc={`Pobrana kaucja: ${displayPrice(deposit)}`}
      action={() => {
        push(window.location.pathname, LOCATION_STATES.popup({ type: 'returns', data: item } as unknown as string))
      }}
    />
  })

  return <>
    {(popup as any)?.type === 'returns' && <div>
      {((data: payments.CancelRentalResponseWarehouseReturn) => {
        return <DeliveryPopup
          popupName='Zwroty'
          price={data.deposit}
          cancelAction={goBack}
          productName={data.name}
          deliveryMethods={data.returnMethods}
          recentlySelectedDeliveries={deliveries}
          setRecentlySelectedDeliveries={setDeliveries}
          callback={({ deliveryId }) => {
            if (deliveryId) {
              selectData(prev => ({ ...prev, itemsToReturn: { ...(prev.itemsToReturn || {}), [data.id]: deliveryId } }))
            }
            goBack()
          }}
        />
      })((popup as any).data)}
    </div>}

    <div className='title'>
      <div>Już kończymy?</div>
      <div>Musisz jeszcze {missingSteps ? `wykonać ${missingSteps} kroki` : 'potwierdzić rezygnację'}</div>
    </div>

    <div className='resignation__main'>
      <div className='resignation__steps'>
        <Option
          selected
          secondary={!!selectedData.date}
          label={selectedData.date ? 'Wybrano' : 'Wybierz datę'}
          title='Kiedy chcesz zakończyć wynajem?'
          desc={selectedData.date ? new Date(selectedData.date).toLocaleDateString() : 'Nie wybrano'}
          buttonText='Wybierz'
          icon={<Calendar />}
          action={() => {
            openAction('calendar')
          }}
        />

        <Option
          selected
          secondary={!!selectedData.photo}
          label={selectedData.photo ? 'Dodano' : 'Zrób zdjęcie'}
          title='Zrób zdjęcie MAGa'
          desc={selectedData.photo ? selectedData.photo.name : 'Pokaż stan swojego MAGa'}
          buttonText='Dodaj'
          icon={<Camera />}
          action={() => {
            openAction('camera')
          }}
        />

        {itemsToReturn}
      </div>

      <ArrowBtn
        enlarged
        text='Dalej'
        disabled={missingSteps !== 0}
        action={() => {
          openAction('summary')
        }}
      />
    </div>
  </>
}

export default Default