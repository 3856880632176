import Popup from '../../components/popups/Popup'
import useAccountKey from './hooks/useAccountKey'
import { ACCOUNT_TYPE } from './accountSlice'
import BasicInfo from './BasicInfo'
import Settings from './Settings'
import Invoices from './Invoices'
import Payments from './Payments'
import Company from './Company'
import React from 'react'

const Sections = () => {
  const { isKey, key } = useAccountKey()

  if (key) return (
    <Popup isPopup={isKey}>
      <div className='account__popup'>
        {(() => {
          switch (key) {
            case ACCOUNT_TYPE.invoices: return <Invoices customWrapperClassName='popup' />
            case ACCOUNT_TYPE.payments: return <Payments />
            case ACCOUNT_TYPE.settings: return <Settings />
            case ACCOUNT_TYPE.company: return <Company />
            case ACCOUNT_TYPE.basic: return <BasicInfo />
            default: return false
          }
        })()}
      </div>
    </Popup>
  )

  return null
}

export default Sections