import { addItemToBasket, removeItemFromBasket, selectBasketDeliveries, selectBasketItem, selectBasketToPay, updateBasketItem, updateDelivery } from 'containers/Basket/basketSlice'
import { selectCalendar } from 'containers/OrderDetails/Calendar/calendarSlice'
import useOpeningMethodData from '../hooks/useOpeningMethodData'
import DeliveryPopup from 'components/popups/DeliveryPopup'
import { useDispatch, useSelector } from 'react-redux'
import ArrowBtn from 'components/buttons/ArrowBtn'
import useTranslation from 'hooks/useTranslation'
import { useHistory } from 'react-router-dom'
import Popup from 'components/popups/Popup'
import { displayPrice } from 'utils/string'
import Loading from 'components/Loading'
import Error from 'components/Error'
import { useState } from 'react'

const LockPopup = ({ addToGate = false, method, selectedItems, cancelAction }) => {
  const toPay = useSelector(selectBasketToPay)
  const { placeAddress } = useSelector(selectCalendar)
  const deliveries = useSelector(selectBasketDeliveries)
  const selectedBasketItem = useSelector(selectBasketItem)
  const [productDetails, setProductDetails] = useState(null)
  const recentlySelectedDeliveries = useSelector(selectBasketDeliveries)
  const { isLoading, padlockInfo } = useOpeningMethodData(method.id, setProductDetails)

  const dispatch = useDispatch()
  const { goBack } = useHistory()
  const { t } = useTranslation('delivery')

  if (productDetails) {
    return (
      <DeliveryPopup
        placeAddress={placeAddress}
        delivery={productDetails?.delivery}
        isEditableOnStartup={!productDetails?.cartId}
        productName={productDetails?.name || method?.name}
        price={productDetails?.grossPrice || method?.grossPrice}
        deliveryMethods={productDetails?.deliveryMethods || method.deliveryMethods}
        recentlySelectedDeliveries={recentlySelectedDeliveries}
        setRecentlySelectedDeliveries={(getState) => {
          const state = typeof getState === 'function' ? getState(recentlySelectedDeliveries) : getState
          dispatch(updateDelivery(state))
        }}
        cancelAction={() => {
          setProductDetails(null)
        }}
        callback={values => {
          // update
          if (productDetails?.cartId) {
            dispatch(updateBasketItem({ identifier: productDetails.cartId, values }))

            // add new
          } else {
            const value = {
              type: 'rentAddon',
              id: method.id,
              placeId: selectedBasketItem.placeId,
              name: productDetails.name || method.name,
              openingMethodKey: method.openingMethodKey,
              depositAmount: method.depositAmount,
              object: productDetails.object,
              objectId: productDetails.id,
              ...values
            }
            if (!addToGate) {
              value.ownerId = selectedBasketItem.identifier
            }
            dispatch(addItemToBasket(value))
          }
          setProductDetails(null)
        }}
      />
    )
  }

  return (
    <Popup close={cancelAction || goBack} darkBg={true}>
      <div className='popup__title'>
        <div>{method?.name}</div>
      </div>

      <div className='popup__padlock'>
        {method.openingMethodKey === 'our_padlock' && <div className='popup__padlock__info'>
          <h2>Dostęp przy pomocy kłódki</h2>
          <p>Magazyn wymaga posiadania własnej kłódki do zabezpieczenia drzwi</p>
        </div>}

        <div className='popup__padlock__items'>
          <h2>
            {method.openingMethodKey === 'our_padlock'
              ? 'Kłódki z shop.magazynomaty.pl'
              : 'Wybierz z listy'}
          </h2>
          {isLoading
            ? <Loading />
            : padlockInfo
              ? padlockInfo.map((data) => {
                const { id, name, grossPrice } = data
                const selected = selectedItems?.find(item => item.objectId && item.objectId === id)
                const delivery = (selected?.deliveryId && deliveries) ? deliveries.find(d => d.id === selected.deliveryId) : undefined
                const toPayData = (toPay && selected?.identifier) ? toPay[selected.identifier] : undefined
                return <div
                  key={id}
                  className={`popup__padlock__item${selected ? ' checked' : ''}`}
                  onClick={() => {
                    if (selected?.identifier) {
                      dispatch(removeItemFromBasket({ removeIdentifiers: [selected.identifier] }))
                    } else {
                      setProductDetails(data)
                    }
                  }}>
                  <div className='popup__padlock__item__left'>
                    <h3>{name}</h3>
                    {toPayData?.to_pay?.depositAmount && <div>Kaucja: <b>{displayPrice(toPayData?.to_pay?.depositAmount)}</b></div>}
                    {delivery?.type && <div>Dostawa: <b>{t(delivery?.type)}</b></div>}
                    {toPayData?.to_pay?.price_sum
                      ? <div>Do zapłaty: <b>{displayPrice(toPayData?.to_pay?.price_sum)}</b></div>
                      : <div>Cena: <b>{displayPrice(grossPrice)}</b></div>}
                  </div>
                  <div className='popup__padlock__item__right'>
                    {selected && <i
                      className='icon icon--rename'
                      onClick={e => {
                        e.stopPropagation()
                        setProductDetails({
                          ...data,
                          cartId: selected.identifier,
                          delivery
                        })
                      }} />}
                    <div className='checkCircle'>
                      <i className='icon icon--check' />
                    </div>
                    {/* <div className='circle'><i className='icon icon--minus'></i></div>
                    <div>1</div>
                    <div className='circle'><i className='icon icon--plus2'></i></div> */}
                  </div>
                </div>
              })
              : <Error staticPos />}
        </div>

        <ArrowBtn
          enlarged
          showArrow={false}
          text={'Gotowe'}
          action={goBack}
        />
      </div>
    </Popup>
  )
}

export default LockPopup