import { useHistory, useLocation } from 'react-router-dom'
import React, { useEffect, useRef } from 'react'
import './style.scss'

interface PopupProps {
  close?: () => void
  isPopup?: boolean
  isLine?: boolean
  centered?: boolean
  darkBg?: boolean 
  children: React.ReactNode
}

const Popup: React.FC<PopupProps> = ({ close: oldClose, isPopup, isLine = true, centered = false, darkBg = false, children }) => {

  const { goBack } = useHistory()
  const { state } = useLocation()

  const popupRef = useRef<HTMLDivElement>(null)

  const close = oldClose || goBack
  const popup = (state && typeof state === 'object' && 'popup' in state) || isPopup

  useEffect(() => {
    const escDetect = (e: KeyboardEvent) => e.keyCode === 27 && close()
    const isHidden = document.body.style.overflow === 'hidden'
    if (!isHidden) document.body.style.overflow = 'hidden'
    document.addEventListener('keydown', escDetect, false)

    return () => {
      if (!isHidden) document.body.style.overflow = 'auto'
      document.removeEventListener('keydown', escDetect, false)
    }
  }, [close])

  const onTouch = (e: React.TouchEvent<HTMLDivElement>) => {
    const { scrollTop = 0 } = popupRef.current || {}
    if (scrollTop) return

    const initialY = e.touches[0].clientY
    const touchMove = (e: TouchEvent) => {
      const currentY = e.touches[0].clientY
      if (currentY - initialY > 50) {
        touchEnd()
        close()
      }
    }

    const touchEnd = () => {
      document.removeEventListener('touchmove', touchMove, false)
      document.removeEventListener('touchend', touchEnd, false)
    }

    document.addEventListener('touchmove', touchMove, false)
    document.addEventListener('touchend', touchEnd, false)
  }

  return (
    <div
      className={`popup__bg${centered ? ' --centered' : ''}${popup ? '' : ' --close'}`}
      onClick={close}>

      <div
        ref={popupRef}
        className={`popup ${darkBg? 'darkBg' : ''}`}
        onClick={e => e.stopPropagation()}
        style={isLine ? { paddingTop: 0 } : {}}
        onTouchStart={e => isLine && popup && onTouch(e)}>
        {isLine && <div className='popup__line' />}
        {children}
      </div>
    </div>
  )
}

export default Popup