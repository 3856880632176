import { AVAILABLE_LANGUAGES, selectLanguage } from '../appSlice'
import { useReduxSelector } from 'store'
import en from '../lang/en.json'
import pl from '../lang/pl.json'

type Language = 'pl' | 'en'

const useTranslation = (section = '') => {
  const languageName = useReduxSelector(selectLanguage)
  const getKeys = (k = '') => k.split('.')

  const translate = (key = '') => {
    const keys = getKeys(`${section ? `${section}.` : ''}${key}`)
    const language = getLanguage(languageName)

    return (
      getNestedTranslation(language, keys) ??
      getNestedTranslation(en, keys) ??
      `{${key}}`)
  }

  return {
    lang: languageName as Language,
    t: translate,
  }
}

const getLanguage = (language: Language) => {
  switch (language) {
    case AVAILABLE_LANGUAGES.pl: return pl
    default: return en
  }
}

const getNestedTranslation = (language: any, keys: string[]) => {
  return language ? keys.reduce((obj, key) => {
    return obj?.[key]
  }, language) : undefined
}

export default useTranslation