import { selectWarehouse } from '../Warehouses/warehousesSlice'
import { useHistory } from 'react-router-dom'
import { useReduxSelector } from 'store'
import { GoTo, SECTION_TYPES } from '.'
import React from 'react'

interface ManageButtonProps {
  text: string
  icon: string
  action?: React.MouseEventHandler<HTMLElement>
}

const ManageButton: React.FC<ManageButtonProps> = ({ action, text, icon }) => <div
  className='menu__button center'
  onClick={action}>
  <i className={`icon icon--${icon} s24`} />
  <span>{text}</span>
</div>

interface MagManageProps {
  id: string
  goTo: GoTo
}

const MagManage: React.FC<MagManageProps> = ({ id, goTo }) => {
  const { hasOnlineAccess } = useReduxSelector(state => selectWarehouse(state, id))
  const { push } = useHistory()

  return (
    <div className='menu__section menu__manage'>
      <p>Zarządzaj magazynem</p>

      <div className='menu__buttons'>
        <div className='buttons__row center'>
          <ManageButton
            icon='mag-details'
            text='Szczegóły wynajmu'
            action={() => goTo({ path: SECTION_TYPES.details })} />

          <hr />

          <ManageButton
            icon='payments'
            text='Faktury'
            action={() => goTo({ path: SECTION_TYPES.invoices })} />

          <hr />

          <ManageButton
            icon='insurance'
            text='Ubezpieczenie magazynu' />
        </div>

        <hr />

        <div className='buttons__row center'>
          <ManageButton
            icon='share'
            text='Udostępnij MAGa'
            action={() => goTo()} />

          <hr />

          <ManageButton
            icon='terminate'
            text='Zrezygnuj z wynajmu'
            action={() => goTo({ path: SECTION_TYPES.resignation })} />

          {hasOnlineAccess && <>
            <hr />
            <ManageButton
              icon='alarm'
              text='Zgłoś awarię'
              action={() => push('/help')} />
          </>}
        </div>
      </div>
    </div>
  )
}

export default MagManage