import EditShortcutsPopup from './Popups/EditShortcutsPopup'
import EditFavoritePopup from './Popups/EditFavoritePopup'
import usePopupState from '../../hooks/usePopupState'

export const POPUPS = {
  editFavorite: 1,
  editShortcuts: 2
}

const Popups = () => {
  const { popup } = usePopupState()

  switch (popup) {
    case 1: return <EditFavoritePopup />
    case 2: return <EditShortcutsPopup />
    default: return false
  }
}

export default Popups