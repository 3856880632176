import { selectRestPopupBody, showPopup } from 'rest/restSlice'
import { useReduxDispatch, useReduxSelector } from 'store'
import { DEVICE_TYPES, selectDevice } from 'appSlice'
import HeadersManager from 'rest/HeadersManager'
import { APP_AUTH_KEY } from 'rest/AuthEvent'
import { parseJwt } from 'utils/parseJwt'
import React, { useEffect } from 'react'

const NativeAuth: React.FC = () => {
  const popupBody = useReduxSelector(selectRestPopupBody)
  const { nativeKey = '', type, verified } = popupBody as rest.UnauthorizedResponse
  const device = useReduxSelector(selectDevice)
  const dispatch = useReduxDispatch()

  useEffect(() => {
    const authListener = () => {
      dispatch(showPopup())
    }
    document.addEventListener(APP_AUTH_KEY, authListener)
    return () => document.removeEventListener(APP_AUTH_KEY, authListener)
  }, [dispatch])

  useEffect(() => {
    if (type) {
      const message = getAuthData(nativeKey, verified === false)
      if (device === DEVICE_TYPES.android) {
        Android.authorize(message)
      } else if (device === DEVICE_TYPES.ios) {
        window.webkit.messageHandlers.Authorize.postMessage(message)
      }
    }
  }, [device, nativeKey, type, verified])

  return (
    <div>NativeAuth</div>
  )
}

const getAuthData = (nativeKey: string, error = false) => {
  const jwt = HeadersManager.jwt.get()
  const { enc } = parseJwt(jwt)
  const data = {
    nativeKey,
    key: enc,
    error
  }

  return JSON.stringify(data)
}

export default NativeAuth