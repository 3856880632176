import { BASKET_TYPES, selectBasket } from '../Basket/basketSlice'
import ArrowBtn from '../../components/buttons/ArrowBtn'
import BackBtn from '../../components/buttons/BackBtn'
import useGroupedBasket from 'hooks/useGroupedBasket'
import useWarehouseName from 'hooks/useWarehouseName'
import { useHistory } from 'react-router-dom'
import useCheckout from './hooks/useCheckout'
import { useSelector } from 'react-redux'
import PriceSection from './PriceSection'
import AddonSection from './AddonSection'
import UserSection from './UserSection'
import { useLayoutEffect } from 'react'
import MagSection from './MagSection'
import Section from './Section'
import './style.scss'

const Summary = () => {
  const list = useGroupedBasket(false)
  const { invoice } = useSelector(selectBasket)
  const { isLoading } = useCheckout()

  const { push, replace } = useHistory()
  const getName = useWarehouseName()

  useLayoutEffect(() => {
    if (list?.length === 0) replace('/map')
  }, [list?.length, replace])

  return (
    <div className='summary'>
      <div className='orderTitle'>
        <span>Podsumowanie</span>
        <span>Sprawdź dane</span>
      </div>

      <UserSection />

      {invoice && <Section
        header='Dane do faktury'
        title={[invoice?.name, `NIP: ${invoice?.nip}`]}
        desc={[invoice?.streetName, `${invoice?.postalCode} ${invoice?.voivodshipName}`]} />}

      {list?.length > 0 && list.map((item) => item.type === BASKET_TYPES.rent
        ? <MagSection
          {...item}
          name={getName(item)}
          key={item.identifier}
        />
        : <AddonSection
          {...item}
          key={item.identifier}
        />)}

      <PriceSection />

      <Section
        header='Oświadczenia'
        title={['Oświadczam że']}
        desc={['Nie będę magazynować przedmiotów nielegalnych oraz że będę wykonywał wszystkie polecenia i wskazania aby umowa przebiegała miło i sympatycznie']} />

      <div className='summary__buttons'>
        <ArrowBtn
          enlarged
          text='Zapłać'
          disabled={isLoading}
          action={() => {
            window.scrollTo({ top: 0 })
            push('/payment')
          }} />

        <BackBtn />
      </div>
    </div>
  )
}

export default Summary