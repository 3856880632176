import { useHistory, useLocation } from 'react-router-dom'
import React, { useEffect } from 'react'
import Summary from './Summary'
import List from './List'
import './style.scss'

const BasketPopup: React.FC = () => {
  const { goBack } = useHistory()
  const { state } = useLocation()

  const isBasketExpanded = (state && typeof state === 'object' && 'popup' in state && state.popup === 'basket')

  useEffect(() => {
    const escDetect = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        goBack()
      }
    }

    document.addEventListener('keydown', escDetect, false)
    return () => {
      document.removeEventListener('keydown', escDetect, false)
    }
  }, [goBack])

  return (
    <div className={`basketPopup__bg${isBasketExpanded ? '' : ' --close'}`}>
      <div className='basketPopup'>
        <List />
        <Summary />
      </div>
    </div>
  )
}

export default BasketPopup