import { useEffect, useState } from 'react'

const useRestPopupState = (name?: string) => {
  const [isPopup, showPopup] = useState<boolean>(false)

  useEffect(() => {
    if (name) showPopup(true)
    else {
      const timeout = setTimeout(() => showPopup(false), 200)
      return () => clearTimeout(timeout)
    }
  }, [name])

  return isPopup
}

export default useRestPopupState