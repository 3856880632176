import useAuthSuccessCallback from './useAuthSuccessCallback'
import { selectRestPopupBody } from 'rest/restSlice'
import useTranslation from 'hooks/useTranslation'
import BackBtn from 'components/buttons/BackBtn'
import Submit from 'components/inputs/Submit'
import PinForm from 'components/PinForm'
import { useReduxSelector } from 'store'
import React, { useState } from 'react'

const CODE_LENGTH = 4

const PinPopup: React.FC = () => {

  const popupBody = useReduxSelector(selectRestPopupBody)

  const [token, setToken] = useState('')
  const [warning, setWarning] = useState(false)

  const successCallback = useAuthSuccessCallback()
  const { t } = useTranslation('form')

  const submit = () => {
    successCallback({ success: true, token })
  }

  const getSubmitText = (l: number) => {
    return l === CODE_LENGTH ? 'Autoryzuj' : `Brakuje ${CODE_LENGTH - l} znak${l === CODE_LENGTH - 1 ? 'u' : 'ów'}`
  }

  return (
    <div className='popup__pin popup__verification'>
      <div className='popup__title'>
        <div>{t('enterYourPin')}</div>
      </div>

      {popupBody && 'verified' in popupBody && popupBody?.verified === false && <p className='verification__warning'>Niewłaściwy kod</p>}

      <PinForm
        value={token}
        warning={warning}
        setValue={setToken}
        setWarning={setWarning}
      />

      <div className='popup__buttons'>
        <Submit
          action={submit}
          block={token.length !== 4}
          text={getSubmitText(token.length)}
        />

        <BackBtn text={t('cancel')} />
      </div>
    </div>
  )
}

export default PinPopup