import { createSlice } from '@reduxjs/toolkit'

const chatSlice = createSlice({
  name: 'chat',
  initialState: {
    unreadMessages: 0,
    threads: []
  },
  reducers: {
    updateUnreadMessages: (state, action) => {
      state.unreadMessages = action.payload ?? state.unreadMessages + 1
    },
    addMessage: (state, action) => {
      const { threads } = state
      const { _from, sender, message, file, files = [] } = action.payload
      if (message || file || files.length > 0) {
        if (file) files.push(file)
        const date = new Date()
        const newMessage = {
          sender: sender || _from?.id,
          files,
          message,
          time: date.toLocaleTimeString('pl', { hour: '2-digit', minute: '2-digit' }),
          date: date.toLocaleDateString()
        }
        if (threads.length > 0) {
          const messages = state.threads[0]?.messages || []
          state.threads[0].messages = [...messages, newMessage]
        } else {
          state.threads[0] = {
            id: '13456789',
            date: {
              start: date.toLocaleDateString(),
              end: ''
            },
            messages: [newMessage]
          }
        }
      }
    }
  }
})

export const { addMessage, updateUnreadMessages } = chatSlice.actions

export const selectChatUnreadMessages = (state) => state.chat.unreadMessages
export const selectChatThreads = (state) => state.chat.threads
export const selectChatThread = (state, threadId) => {
  const threads = state.chat.threads
  return threadId === 'new' ? threads.at(-1) : threads?.find(({ id }) => id === threadId)
}

export default chatSlice.reducer