import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { STATUS } from '../../../utils/constants'
import { mapFilters } from '../../../rest/urls'
import { POST } from '../../../rest/request'

export const fetchFilters = createAsyncThunk('filters/fetchFilters', async () => {
  const response = await POST(mapFilters)
  return response
})

const filters = {
  attributes: {},
  mag: {},
}

export const filtersSlice = createSlice({
  name: 'filters',
  initialState: {
    filters,
    location: '',
    filtersTypes: [],
    status: STATUS.idle,
  },
  reducers: {
    updateLocation: (state, action) => {
      state.location = action.payload
    },
    addFilters: (state, action) => {
      const f = action.payload
      state.filters = Object.keys(f).length === 0 ? filters : f
    },
    addOrUpdateFilter: (state, action) => {
      const { name, where, value } = action.payload

      if (!name && !where) return
      if (name === 'categoryId' && state.filters.attributes['warehouseTypeId'])
        delete state.filters.attributes['warehouseTypeId']
      state.filters[where][name] = value
    },
    removeFilter: (state, action) => {
      const { name, where, value } = action.payload

      if (!name && !where) return
      if (name === 'categoryId' && state.filters.attributes['warehouseTypeId'])
        delete state.filters.attributes['warehouseTypeId']

      if (name === 'defaultAttributes') {
        const copy = [...state.filters.attributes['defaultAttributes']].filter(e => e !== value)
        copy.length > 0 ? state.filters.attributes['defaultAttributes'] = copy : delete state.filters.attributes['defaultAttributes']

      } else delete state.filters[where][name]
    },
  },
  extraReducers: {
    [fetchFilters.fulfilled]: (state, action) => {
      const { filters } = action.payload

      state.filtersTypes = filters
      state.status = STATUS.succeed
    },
    [fetchFilters.rejected]: (state, action) => {
      state.status = STATUS.failed
    },
  }
});

export const { updateLocation, addFilters, addOrUpdateFilter, removeFilter } = filtersSlice.actions

export const selectFiltersTypes = state => state.filters.filtersTypes
export const selectFiltersStatus = state => state.filters.status
export const selectLocation = state => state.filters.location
export const selectFilters = state => state.filters.filters

export default filtersSlice.reducer
