import ButtonStateContext from './buttonContext/Context'
import ArrowBtn from 'components/buttons/ArrowBtn'
import useTranslation from 'hooks/useTranslation'
import { useContext } from 'react'

const BasketNextBtn = () => {
  const { isButton, action } = useContext(ButtonStateContext)
  const { t } = useTranslation('basket')

  return (
    isButton && <ArrowBtn
      text={t('next')}
      action={() => {
        action.current && action.current()
      }} />
  )
}

export default BasketNextBtn