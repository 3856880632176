import FilterButton from '../../../components/Filters/FilterButton'
import { selectUserData, ACCOUNT_TYPE } from '../accountSlice'
import useTranslation from '../../../hooks/useTranslation'
import React, { useEffect, useState } from 'react'
import Filters from '../../../components/Filters'
import Error from '../../../components/Error'
import { useHistory } from 'react-router-dom'
import { MONTHS } from '../../../utils/date'
import * as filtersFields from './filters'
import { useReduxSelector } from 'store'
import Item from '../Item'

const type = ACCOUNT_TYPE.payments

interface HistoryItem {
  date: string
  title: string
  desc: string
  mag: string[]
}

interface PaymentHistoryProps {
  full?: boolean
  mag: string
}

const PaymentHistory: React.FC<PaymentHistoryProps> = ({ full = false, mag }) => {
  const { history } = useReduxSelector(state => selectUserData(state, type))
  const [filters, setFilters] = useState<basic.Form>(mag ? { mag } : {})
  const [filteredList, filterList] = useState<HistoryItem[]>(history)

  const { t, lang } = useTranslation()
  const { push } = useHistory()


  useEffect(() => {
    const filterDate: { start?: Date, end?: Date } | false = filters.date ? {} : false
    if (filterDate) {
      if (filters.date.start) filterDate.start = new Date(`${filters.date.start}, 00:00`)
      if (filters.date.end) filterDate.end = new Date(`${filters.date.end}, 00:00`)
    }
    const filtered = (history as any[]).filter((item) => {
      return (() => {
        for (const filterKey in filters) {
          if (filterKey === 'date') {
            if (!item.date || !filterDate) return false
            const dateObj = new Date(item.date.split('.').reverse().join(','))
            if (filterDate?.start && dateObj < filterDate.start) return false
            if (filterDate?.end && dateObj > filterDate.end) return false

          } else if (filterKey === 'mag') {
            if (item.mag && Array.isArray(item.mag) && !item.mag.includes(filters[filterKey])) return false

          } else if (item[filterKey as keyof HistoryItem] !== undefined) {
            if (item[filterKey as keyof HistoryItem] !== filters[filterKey]) return false
          }
        }
        return true
      })()
    })
    filterList(filtered)
  }, [filters, history])

  return (
    <>
      <div className='group'>
        <p>{t('account.paymentHistory')}</p>
        {full && <FilterButton />}
      </div>

      {full && <Filters
        filters={filters}
        setFilters={setFilters}
        fields={filtersFields[lang]}
      />}

      <div className='account__list'>
        {filteredList.length > 0 ? filteredList
          .filter((_, i) => i < (full ? filteredList.length : 3))
          .map(({ date, title, desc }, i) => <Item
            key={i}
            showArrow
            label={(() => {
              const [day, month] = date.split('.')
              return `${day} ${month ? MONTHS.short[Number(month) - 1] : ''}`
            })()}
            title={title}
            desc={desc}
            action={() => { }}
          />) : <Error staticPos text={t('filters.noItems')} />}

        {!full && <div
          className='list__more'
          onClick={() => push(`${window.location.pathname}/history`)}>{t('account.more')}</div>}
      </div>
    </>
  )
}

export default PaymentHistory