import { selectBasket, updateCompany } from '../../Basket/basketSlice'
import { getCompaniesFields, selectFieldsStatus } from '../formSlice'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import useTranslation from '../../../hooks/useTranslation'
import { selectIsLoggedIn } from '../../Login/loginSlice'
import { companies, userInfo } from '../../../rest/urls'
import Submit from '../../../components/inputs/Submit'
import { useSelector, useDispatch } from 'react-redux'
import { STATUS } from '../../../utils/constants'
import { useHistory } from 'react-router-dom'
import { GET } from '../../../rest/request'
import Companies from './Companies'
import NewMagBtn from './NewBtn'
import Receipt from './Receipt'
import '../style.scss'
import './style.scss'

const Billing = () => {
  const { list } = useSelector(selectBasket)
  const isLogin = useSelector(selectIsLoggedIn)
  const fieldsStatus = useSelector(selectFieldsStatus)

  const [invoice, setInvoice] = useState(null)
  const [data, setData] = useState({
    company: [],
    user: {},
  })

  const { push, replace } = useHistory()
  const { t } = useTranslation()

  const dispatch = useDispatch()
  const menuRef = useRef(null)

  useLayoutEffect(() => {
    if (list?.length === 0) replace('/map')
  }, [list?.length, replace])

  useEffect(() => {
    if (isLogin && fieldsStatus !== STATUS.succeed) dispatch(getCompaniesFields())
  }, [dispatch, fieldsStatus, isLogin])

  useEffect(() => {
    if (isLogin && fieldsStatus === STATUS.succeed) {
      getData().then(() => getData(true))
    }
  }, [fieldsStatus, isLogin])

  const getData = async (user = false) => {
    try {
      const response = await GET(user ? userInfo : companies)
      if (response.data) {
        setData(d => {
          return {
            ...d,
            [user ? 'user' : 'company']: response.data
          }
        })
      }

    } catch (err) {
      console.warn('Fetch: failed')
    }
  }

  return (
    <div className='billing'>
      <div className='orderTitle'>
        <span>{t('form.dataCompletion')}</span>
        <span>{t('form.contractData')}</span>
      </div>

      <div
        ref={menuRef}
        className='billing__main main center'>
        <div className='billing__menu'>
          <div
            className={`menu__item group ${invoice ? '--selected' : ''}`}
            onClick={() => setInvoice(true)}>
            <div className='item__desc'>
              <div>Faktura na</div>
              <div>firmę</div>
            </div>

            <div className='checkCircle'><i className='icon icon--check'></i></div>
          </div>

          <div
            className={`menu__item group ${invoice === false ? '--selected' : ''}`}
            onClick={() => setInvoice(false)}>
            <div className='item__desc'>
              <div>Faktura na</div>
              <div>osobę prywatną</div>
            </div>

            <div className='checkCircle'><i className='icon icon--check'></i></div>
          </div>
        </div>

        {invoice === null ? <div className='billing__info'>
          <div className='orderTitle'>
            <span>{t('basic.justMoment')}</span>
            <span>{t('basic.doYouNeedMoreSpace')}</span>
          </div>
          <NewMagBtn />
        </div>
          : invoice ? <>
          <div className='--element__title'>Wybierz firmę</div>
          <Companies
            menuRef={menuRef}
            data={data?.company}
            getData={getData} />
          </>
            : <>
              <Receipt data={data.user} />
              <div className='recipt__button'>
                <Submit
                  text={'Dalej'}
                  action={() => {
                    dispatch(updateCompany(false))
                    push('/summary')
                  }} />
              </div>
            </>}
      </div>
    </div>
  )
}

export default Billing