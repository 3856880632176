import { selectBasketRentingSummary } from 'containers/Basket/basketSlice'
import ArrowBtn from 'components/buttons/ArrowBtn'
import { LOCATION_STATES } from 'utils/constants'
import React, { useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { displayPrice } from 'utils/string'
import { useReduxSelector } from 'store'

const Summary: React.FC = () => {
  const checkout = useReduxSelector(selectBasketRentingSummary)
  const [isExpanded, expand] = useState(false)
  const summaryRef = useRef<HTMLDivElement>(null)

  const { push } = useHistory()
  const onTouch: React.TouchEventHandler<HTMLDivElement> = e => {
    const summary = summaryRef.current
    if (!summary) return

    const initialY = e.touches[0].clientY
    const touchMove = (e: TouchEvent) => {
      const currentY = e.touches[0].clientY
      if ((initialY - currentY > 20 && !isExpanded) || (currentY - initialY > 20 && isExpanded)) {
        expand(!isExpanded)
        touchEnd()
      }
    }
    const touchEnd = () => {
      summary.removeEventListener('touchend', touchEnd, false)
      summary.removeEventListener('touchmove', touchMove, false)
    }
    summary.addEventListener('touchend', touchEnd, false)
    summary.addEventListener('touchmove', touchMove, false)
  }

  return (
    <>
      {isExpanded && <div className='basketPopup__summary__bg' />}
      <div
        ref={summaryRef}
        onTouchStart={onTouch}
        className='basketPopup__summary'>
        <div
          className='basketPopup__summary__header'
          onClick={() => {
            expand(e => !e)
          }}>
          <span>Harmonogram opłat miesięcznych</span>
          <i className={`icon icon--chevron-${isExpanded ? 'down' : 'up'}`} />
        </div>

        <div className={`hidableElement --${isExpanded ? 'visible' : 'hidden'}`}>
          <div>
            <div className='basketPopup__summary__header__subTitle'>Płatne 14 dnia każdego miesiąca</div>
            {checkout?.to_pay?.monthly?.map(({ start, end, grossPrice }: basic.Form, index: number) => <div
              key={index}
              className='basketPopup__summary__row'>
              <span>{start} - {end}</span>
              <span>{displayPrice(grossPrice)}</span>
            </div>)}
          </div>
        </div>

        <div className='basketPopup__summary__main group'>
          <div className='basketPopup__summary__price'>
            <div>Razem do zapłaty:</div>
            <div>{checkout?.to_pay?.now?.grossPrice ? displayPrice(checkout.to_pay.now.grossPrice) : 'ładowanie...'}</div>
          </div>
          <ArrowBtn
            text='Dodaj kolejny'
            showArrow={false}
            action={() => {
              push(window.location.pathname, LOCATION_STATES.popup('newMag'))
            }}
          />
        </div>
      </div>
    </>
  )
}

export default Summary