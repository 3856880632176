import { ReactComponent as Location } from '../../../assets/location.svg'
import { selectFilters, selectLocation } from '../menu/filtersSlice'
import { selectMapOptions } from '../Map/mapSlice'
import { useHistory } from 'react-router-dom'
import FiltersElement from './FiltersElement'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import FiltersList from './FiltersList'

const Filters = ({ menuRef, showFilters }) => {
  const [upDirection, setUpDirection] = useState(false)

  const { distance } = useSelector(selectMapOptions)
  const location = useSelector(selectLocation)
  const filters = useSelector(selectFilters)

  const { push } = useHistory()

  useEffect(() => {
    if (!menuRef) return
    let position = 0
    const menu = menuRef.current
    const scroll = e => {
      const { scrollTop } = e.target
      if ((scrollTop - position > 100) || (position - scrollTop > 20)) {
        setUpDirection(scrollTop - position > 100)
        position = scrollTop
      }
    }

    menu.addEventListener('scroll', scroll)
    return () => menu.removeEventListener('scroll', scroll)
  }, [menuRef])


  return <>
    <div className={`filters__list --${upDirection ? 'hide' : 'show'}`}>
      {location && <FiltersElement
        name='Odległość'
        desc={`${distance} km`}
        openPopup={showFilters} />}

      <FiltersList
        filters={filters}
        openPopup={showFilters}
      />
    </div>

    {location && <div className='filters__location'>
      <div className='location__group group'>
        <div className='location__desc'>
          <Location />
          <div className='location__title --termina'>{location}</div>
        </div>
        <div
          className='location__button'
          onClick={() => push('/mapsearch')}>Zmień</div>
      </div>
    </div>}
  </>
}

export default Filters 