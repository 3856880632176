import { ReactComponent as Arrow } from 'assets/arrowRight.svg'
import React, { useState, useEffect } from 'react'
import './style.scss'

interface ArrowBtnProps {
  text: string
  className?: string
  showArrow?: boolean
  enlarged?: boolean
  disabled?: boolean
  isBlankButton?: boolean
  action: basic.BasicFunc
}

const ArrowBtn: React.FC<ArrowBtnProps> = ({ text, className = '', isBlankButton = false, showArrow = true, enlarged = false, disabled = false, action }) => {
  const [animation, animate] = useState(false)

  useEffect(() => {
    if (animation) {
      const timeout = setTimeout(() => {
        animate(false)
        action()
      }, 200)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [action, animation])

  return (
    <button
      className={`button --${isBlankButton ? 'back' : 'arrow'}${enlarged ? ' --enlarged' : ''}${animation ? ' --animate' : disabled ? ' --disabled' : ''} ${className}`}
      onClick={() => !disabled && animate(true)}>
      <span className={isBlankButton ? undefined : '--termina'}>{text}</span>
      {showArrow && <Arrow />}
    </button>
  )
}

export default ArrowBtn