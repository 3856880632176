import { selectWarehouse, updateWarehouse } from '../Warehouses/warehousesSlice'
import { ReactComponent as Logo } from '../../assets/logo.svg'
import useTranslation from '../../hooks/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import Submit from '../../components/inputs/Submit'
import Warehouse from '../../components/Warehouse'
import { MAG_STATUS } from '../../utils/constants'
import { useEffect } from 'react'

const Beginning = ({ id, name, next }) => {
  const { status } = useSelector(state => selectWarehouse(state, id))
  const { t } = useTranslation('slides')

  const dispatch = useDispatch()

  const desc = t('openedMagDescArray')

  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch(updateWarehouse({
        id,
        values: { status: MAG_STATUS.opening }
      }))
    })
    return () => { clearTimeout(timeout) }
  }, [dispatch, id])

  return (
    <>
      <Logo />
      <div className='slide__title center'>
        <div className='--termina'>{t('congratulations')}</div>
        <div>{t('youOpenedYourMag')}</div>
      </div>

      <div className='fireworks'>
        <div />
        <div />
      </div>

      <Warehouse status={status} number={name} showShadow />
      <div className='slide__desc'>
        {Array.isArray(desc)
          ? desc.map((d, i) => <div key={i}>{d}</div>)
          : desc}
      </div>
      <Submit text={t('letsStart')} action={next} />
    </>
  )
}

export default Beginning