import ArrowBtn from 'components/buttons/ArrowBtn'
import { useHistory } from 'react-router-dom'
import Popup from 'components/popups/Popup'

const InfoPlaceLockPopup = ({ cancelAction, body }) => {
  const { goBack } = useHistory()

  return (
    <Popup close={cancelAction || goBack}>
      <div className='popup__title'>
        <div>Sposób otwarcia placu</div>
      </div>
      <div className='popup__infoBlocks'>
        <span className='desc'>Zaznacz w jaki sposób chciałbyś otwierać <span>bramę placu</span> z magazynami. Możesz wybrać kilka spośród dostępnych możliwości</span>
        <h2>Jak to działa?</h2>
        {body?.gate?.openingMethods?.map((method) => {
          return (
            <div key={method.id} className={`lockItem lockItem--info`}>
              <div><i className='icon icon--byapp'></i>{method.name} </div>
              <div>{method.howToForClient}</div>
            </div>
          )
        })}
        <ArrowBtn
          enlarged
          showArrow={false}
          text={'Rozumiem'}
          action={cancelAction || goBack} />
      </div>
    </Popup>
  )
}

export default InfoPlaceLockPopup