import DeliveryPointsModal from '../DeliveryPointsModal'
import DeliveryCourier from './DeliveryCourier'
import { useHistory } from 'react-router-dom'
import DefaultView from './DefaultView'
import React, { useState } from 'react'
import Popup from '../Popup'
import './style.scss'

export const basicDelivery = ({
  id: '',
  deliveryMethodId: '',
  type: ''
}) as unknown as payments.Delivery

interface DeliveryPopupProps {
  productName: string
  price: string
  delivery?: payments.Delivery
  popupName?: string
  additionalText?: string
  isEditableOnStartup?: boolean
  placeAddress?: string
  cancelAction: basic.BasicFunc
  deliveryMethods: payments.DeliveryMethod[]
  callback: (_: { deliveryId: string }) => void
  recentlySelectedDeliveries: payments.Delivery[]
  setRecentlySelectedDeliveries: React.Dispatch<React.SetStateAction<payments.Delivery[]>>
}

const DeliveryPopup: React.FC<DeliveryPopupProps> = ({ delivery: initDelivery, popupName, additionalText, isEditableOnStartup = true, placeAddress, recentlySelectedDeliveries, cancelAction, deliveryMethods, productName, price, callback, setRecentlySelectedDeliveries }) => {
  const [isEditable, setEditable] = useState(isEditableOnStartup)
  const [delivery, setDelivery] = useState<payments.Delivery>(initDelivery || basicDelivery)

  const { goBack } = useHistory()

  const saveDelivery = (pointId?: string) => {
    const deliveryId = delivery.id || crypto.randomUUID()
    if (!delivery.id) {
      const newDelivery: payments.Delivery = { ...delivery, id: deliveryId }
      if (pointId && typeof pointId === 'string') {
        newDelivery.pointId = pointId
      }
      setRecentlySelectedDeliveries(prev => [...prev, newDelivery])

    } else {
      setRecentlySelectedDeliveries(prev => {
        return prev.reduce((r, d) => {
          d.id === deliveryId ? r.unshift(d) : r.push(d)
          return r
        }, [] as payments.Delivery[])
      })
    }
    callback({ deliveryId })
  }

  return (
    <Popup
      darkBg={true}
      close={cancelAction || goBack}>
      {(() => {
        const renderDefaultView = () => {
          return <DefaultView
            price={price}
            delivery={delivery}
            popupName={popupName}
            productName={productName}
            setEditable={setEditable}
            setDelivery={setDelivery}
            saveDelivery={saveDelivery}
            cancelAction={cancelAction}
            additionalText={additionalText}
            deliveryMethods={deliveryMethods}
            recentlySelectedDeliveries={recentlySelectedDeliveries}
          />
        }

        if (!isEditable) return renderDefaultView()
        switch (delivery.type) {
          case 'courier':
            return <DeliveryCourier
              delivery={delivery}
              fields={deliveryMethods.find(d => d.deliveryMethodKey === 'courier')?.fields}
              saveDelivery={saveDelivery}
              setDelivery={setDelivery}
            />

          case 'magbox':
          case 'delivery_point':
            return <DeliveryPointsModal
              showModal={false}
              placeAddress={placeAddress}
              initialServices={delivery.type === 'magbox' ? ['mm'] : undefined}
              close={saveDelivery}
              uncheckDelivery={() => {
                setDelivery(basicDelivery)
              }}
            />

          case 'code_locker':
            return <div>Not supported</div>

          default:
            return renderDefaultView()
        }
      })()}
    </Popup>
  )
}

export default DeliveryPopup