import { getCompaniesFields, resetForm, updateValues } from '../forms/formSlice'
import { fetchUserData, selectUserData, ACCOUNT_TYPE } from './accountSlice'
import { submit as companySubmit } from '../forms/Billing/submit'
import useTranslation from '../../hooks/useTranslation'
import { useDispatch, useSelector } from 'react-redux'
import useUserFetch from './hooks/useUserFetch'
import { STATUS } from '../../utils/constants'
import Skeleton from 'react-loading-skeleton'
import NewBtn from '../forms/Billing/NewBtn'
import { useEffect, useState } from 'react'
import Error from '../../components/Error'
import Form from '../forms/Billing/Form'
import { useRef } from 'react'

const type = ACCOUNT_TYPE.company

const Company = () => {
  const { data } = useSelector(state => selectUserData(state, type))
  const { t } = useTranslation()
  const ref = useRef(null)

  const status = useUserFetch(type)

  const [submitStatus, setSubmitStatus] = useState(STATUS.idle)
  const [form, showForm] = useState(false)

  const dispatch = useDispatch()

  useEffect(() => {
    if (status === STATUS.succeed) dispatch(getCompaniesFields())
  }, [dispatch, status])

  const submit = (body, id = '') => {
    const error = () => setSubmitStatus(STATUS.failed)
    const success = () => {
      setSubmitStatus(STATUS.succeed)
      dispatch(resetForm())
      showForm(false)
      dispatch(fetchUserData(type))
    }

    setSubmitStatus(STATUS.pending)
    companySubmit(body, id, success, error)
  }

  const openFrom = () => {
    showForm(true)
    ref.current?.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div
      ref={ref}
      className='account__company summary billing'>
      <div className='account__header title'>
        <div>{t('account.companies')}</div>
        <div>{t('account.subTitle')}</div>
      </div>

      {form ? <Form
        submit={submit}
        cancel={() => showForm(false)}
        block={submitStatus === STATUS.pending} />
        : <>
          {status === STATUS.failed ? <Error staticPos /> : <>
            <div className='list'>
              {Array.isArray(data) ? data.map((e, i) => {
                const { name, nip, postalCode, cityName, streetName, homeNr, flatNr } = e
                return <div key={i}>
                  <div className='list__element --big'>
                    <div className='group'>
                      <div>
                        <div className='element__title'>{t('form.name')}</div>
                        <div className='element__desc'>{name}</div>

                        <div className='element__title'>{t('form.tin')}</div>
                        <div className='element__desc'>{nip}</div>

                        <div className='element__title'>{t('form.address')}</div>
                        <div className='element__desc'>
                          <div>{postalCode} {cityName}</div>
                          <div>{streetName} {homeNr}{flatNr ? `/${flatNr}` : ''}</div>
                        </div>
                      </div>

                      <div
                        className='element__title'
                        onClick={() => {
                          dispatch(updateValues(e))
                          openFrom()
                        }}
                      >{t('form.change')}</div>
                    </div>
                  </div>
                </div>
              }) : [0, 1].map(e => <div key={e}>
                <div className='list__element --big --skeleton'>
                  <div className='group'>
                    <div>
                      <Skeleton className='skeleton__title' />
                      <Skeleton className='skeleton__desc' />
                      <Skeleton className='skeleton__title' />
                      <Skeleton className='skeleton__desc' />
                      <Skeleton className='skeleton__title' />
                      <Skeleton className='skeleton__desc' count={2} />
                    </div>
                    <Skeleton containerClassName='skeleton__button' />
                  </div>
                </div>
              </div>)}
            </div>

            <div className='center'>
              <NewBtn newCompany action={openFrom} />
            </div>
          </>}
        </>}
    </div >
  )
}

export default Company