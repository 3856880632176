import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getLocalDateString } from '../../utils/date'
import { POST } from '../../rest/request'
import { cart } from '../../rest/urls'
import { v4 } from 'uuid'

export const BASKET_TYPES = {
  rent: 'rent',
  rentAddon: 'rentAddon'
}

export const DELIVERY_ACTIONS = {
  add: 'add',
  remove: 'remove',
  moveToTheBeginning: 'moveToTheBeginning',
  edit: 'edit'
}

const basketItem = {
  type: BASKET_TYPES.rent,
  object: 'Warehouse',
  start: '',
  hash: '',
  end: '',
  name: '',
  amount: 1,
  placeId: '',
  address: '',
  dailyPrice: '',
  monthlyPrice: '',
  hasOnlineAccess: false,
  rangeDate: { m: 0, d: 0 },
  periodsToPay: 0,
  promotions: [],
  termiateDays: 0,
}

const initialState = {
  invoice: false,
  unlock: false,
  backup: false,
  time: 0,
  selected: 0,
  lastSentTime: 0,
  errors: false,
  list: [],
  delivery: [],
  toPay: {}
}

const THREE_MINUTES = 1000 * 60 * 3

export const sendBasket = createAsyncThunk('basket/send', async (args, thunkAPI) => {
  const store = thunkAPI.getState()
  const { list, invoice, newDocuments, delivery, time, rentingSummary, toPay } = store.basket
  const body = {
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    companyId: invoice?.id || '',
    time: time + THREE_MINUTES,
    delivery,
    list: list.map(({ identifier, id, name, notify, object, type, hash, placeId, pricelistId, start, end, address, objectId, description, deliveryId, related, ownerId, reservation, dailyPrice, monthlyPrice, attr }) => {
      const item = (() => {
        switch (type) {
          case BASKET_TYPES.rent: {
            return {
              identifier,
              type,
              object,
              parameters: {
                hash,
                placeId,
                pricelistId,
                reservation,
                startDate: getLocalDateString(start),
                endDate: end ? getLocalDateString(end) : undefined,
                attr: attr?.map(id => ({ id })),
                monthlyPrice,
                dailyPrice,
                address
              }
            }
          }

          case BASKET_TYPES.rentAddon: {
            return {
              identifier,
              type,
              id: id || null,
              object: 'LockUserOpeningMethod',
              parameters: {
                name,
                placeId: placeId || undefined,
                object,
                objectId,
                description,
                deliveryId,
                related,
                ownerId
              }
            }
          }

          default: return
        }
      })()
      if (item && notify) item.parameters.notify = notify
      return item
    })
  }

  if (rentingSummary && toPay) {
    const hashes = getSelectedHashes(store.basket)
    if (hashes.length > 0) {
      body.selectedHashes = hashes
    }
  }

  if (args?.step) {
    body.step = args.step

    if (args.step === 'newDocument' || args.step === 'finalize') {
      const hashes = getSelectedHashes(store.basket)
      const newDocument = {
        periods_to_pay: hashes.length === 0 ? '1m' : hashes,
        type: invoice ? 'invoice' : 'receipt',
        payments: args?.payments
      }
      if (args?.step === 'finalize' && newDocuments) {
        newDocument.invoiceIds = newDocuments.map(d => d.id)
      }

      body.newDocument = newDocument
    }
  }

  return await POST(cart, { body })
})

const getSelectedHashes = (basket) => {
  const { list, toPay, rentingSummary } = basket
  return list.reduce((r, { identifier, periodsToPay }) => {
    const warehouseId = toPay?.[identifier]?.warehouseId
    const warehousesSchedule = rentingSummary?.to_pay?.warehouses_schedule?.[warehouseId]?.[0]
    if (warehousesSchedule) {
      for (let i = 0; i <= periodsToPay; i++) {
        r.push(...warehousesSchedule.tiles[i].warehouseRentingHashes)
      }
    }
    return r
  }, [])
}

export const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    addItemToBasket: (state, action) => {
      const { payload } = action

      if (typeof payload === 'object') {
        state.list.push({ identifier: v4(), ...basketItem, ...payload })
        if (payload?.type === BASKET_TYPES.rent) {
          state.selected = state.list.length - 1
        }
      }
    },
    addItemsToBasket: (state, action) => {
      const { payload } = action

      if (Array.isArray(payload)) {
        const newItems = []
        payload.forEach(data => {
          newItems.push({ identifier: v4(), ...basketItem, ...data })
        })
        state.list = [...state.list, ...newItems]
      }
    },
    removeItemFromBasket: (state, action) => {
      const { removeSelected, removeIdentifiers } = action.payload || {}
      const { selected, list, invoice } = state

      const isWarehouseRemoved = removeSelected || (removeIdentifiers
        ? list.some(l => {
          if (l.type !== BASKET_TYPES.rent) return false
          return removeIdentifiers.includes(l.identifier)
        }) : true)

      const finalRemoveIdentifiers = removeIdentifiers || (removeSelected ? [list[selected].identifier] : undefined)
      const initialList = finalRemoveIdentifiers ? list.filter(e => !finalRemoveIdentifiers.includes(e.identifier)) : undefined
      const rentList = initialList ? initialList.filter(e => e.type === BASKET_TYPES.rent) : undefined
      const newList = initialList
        ? initialList.filter(({ type, ownerId, related, placeId }) => {
          if (type === BASKET_TYPES.rentAddon) {
            return (ownerId
              ? rentList.find(e => e.identifier === ownerId)
              : related
                ? (related.every(r => r.object === 'Place' ? rentList.some(e => e.placeId === r.id) : rentList.some(e => e.identifier === r.cartId)))
                : rentList.find(e => e.placeId === placeId))
          } else {
            return true
          }
        })
        : []

      if (isWarehouseRemoved) {
        state.backup = { list, invoice }
        if (newList.length === 0) {
          state.toPay = {}
          state.time = 0
          state.selected = 0
          state.lastSentTime = 0
          state.invoice = false
          state.errors = false

        } else {
          state.selected = newList.findLastIndex(e => e.type === BASKET_TYPES.rent)
        }
        state.unlock = false
      }
      state.list = newList
    },
    changeSelectedItem: (state, action) => {
      const { list } = state
      const { payload } = action

      if (payload >= 0 && list.length > payload) state.selected = payload
    },
    updateDelivery: (state, action) => {
      state.delivery = action.payload
    },
    updateBasketItem: (state, action) => {
      const { list } = state
      const { identifier, values } = action.payload

      if (identifier && typeof values === 'object') {
        state.list = list.map(e => identifier === e.identifier ? { ...e, ...values } : e)
      }
    },
    updateSelectedBasketItem: (state, action) => {
      const { selected, list } = state
      const { payload } = action

      if (payload && list[selected]) {
        state.list[selected] = { ...list[selected], ...payload }
      }
    },
    updateBasketList: (state, action) => {
      const { payload } = action
      const { list } = state

      if (typeof payload === 'object') {
        state.list = list.map(e => payload[e.identifier] ? { ...e, ...payload[e.identifier] } : e)
      }
    },
    extendFormToMap: (state, action) => { state.unlock = action.payload },
    updateCompany: (state, action) => { state.invoice = action.payload },
    resetTime: (state) => { state.time = Date.now() },
    restoreBackup: (state, action) => {
      const { payload } = action
      const { backup } = state

      if (payload && backup) {
        const { list = [], invoice } = backup || {}

        state.list = list
        state.time = Date.now()
        state.invoice = invoice
        state.selected = list.findLastIndex(l => l.type === BASKET_TYPES.rent)
      }
      state.backup = false
      state.lastSentTime = 0
    },
  },
  extraReducers: {
    [sendBasket.fulfilled]: (state, action) => {
      const { positions: positionsObj, checkout, rentingSummary, newDocuments, sold, contracts } = action.payload
      const { list } = state

      if (sold) {
        return {
          ...initialState,
          contracts
        }

      } else {
        const positionsList = Object.entries(positionsObj)
        const newList = positionsList.reduce((r, [k, v]) => {
          if (v.result === 'ok') {
            r.toPay = {
              ...r.toPay,
              [k]: v
            }

          } else if (v.result === 'reservation_required') {
            r.reservations = {
              ...(r.reservations || {}),
              [k]: v?.reservation
            }

          } else if (v.result === 'error') {
            const item = list.find(({ identifier }) => identifier === k)
            if (item) {
              r.errors.push({ ...item, result: v.result, errors: v.errors })
            }
          }
          return r
        }, { toPay: {}, reservations: undefined, errors: [] })

        if (positionsList.length !== list.length) {
          state.list = list.filter(({ identifier }) => !!positionsObj[identifier])
        }

        state.toPay = newList.toPay
        state.errors = newList.errors.length > 0 ? newList.errors : false
        state.lastSentTime = state.time + THREE_MINUTES - 500
        if (newList.reservations) {
          state.list = state.list.map(item => {
            return newList.reservations[item.identifier]
              ? { ...item, reservation: newList.reservations[item.identifier] }
              : item
          })
        }
        if (newDocuments) {
          state.newDocuments = newDocuments
        }
        if (checkout) {
          state.checkout = checkout
        }
        if (rentingSummary) {
          state.rentingSummary = rentingSummary
        }
      }
    },
  }
})

export const { addItemToBasket, addItemsToBasket, removeItemFromBasket, updateDelivery, changeSelectedItem, updateBasketList, updateBasketItem, updateSelectedBasketItem, extendFormToMap, updateCompany, resetTime, restoreBackup } = basketSlice.actions

export const selectBasket = state => state.basket
export const selectBasketTime = state => state.basket.time
export const selectBasketList = state => state.basket.list
export const selectBasketToPay = state => state.basket.toPay
export const selectBasketCheckout = state => state.basket.checkout
export const selectBasketContracts = state => state.basket.contracts
export const selectBasketDeliveries = state => state.basket.delivery
export const selectBasketSelectedIndex = state => state.basket.selected
export const selectBasketRentingSummary = state => state.basket.rentingSummary
export const selectBasketIsEmpty = state => Array.isArray(state.basket.list) ? state.basket.list.length === 0 : false
export const selectBasketItem = (state, idx) => {
  const { selected, list } = state.basket
  const index = idx !== undefined ? idx : selected
  return list[index] || {}
}

export default basketSlice.reducer
