import GetFirebaseToken from '../../context/firebase/Context'
import { Fragment, useContext, useEffect } from 'react'
import useTranslation from '../../hooks/useTranslation'
import { selectChatThread } from './chatSlice'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Input from './Input'

const TODAY = new Date().toLocaleDateString()

const Chat = () => {
  const { id } = useParams()
  const data = useSelector(state => selectChatThread(state, id))
  const { title = 'Rozmowa z konsultantem', date = {}, messages = [] } = data || {}

  const getFirebaseToken = useContext(GetFirebaseToken)
  const { t } = useTranslation('basic')

  useEffect(() => {
    getFirebaseToken()
  }, [getFirebaseToken])

  return (
    data || id === 'new' ? <>
      <div className='chat__header'>
        <div className='--temina'>{title}</div>
        <p>{t('started')}: {date?.start || TODAY}</p>
      </div>

      <div className='chat__list --high'>
        <div>
          {messages.map(({ sender, date, time, message, files }, i, arr) => {
            const showDate = i === 0 || arr[i - 1].date !== date

            return <Fragment key={i}>
              {showDate && <p>{date}</p>}
              <div className={`chat__messgage --${sender === 'USER' ? 'client' : 'service'}`}>
                {files && <div className='message__files'>
                  {files.map(({ name, download_link, thumbnail }, idx) => download_link ? <a
                    key={idx}
                    target='_blank'
                    rel='noreferrer'
                    href={thumbnail || download_link}>
                    {thumbnail ? <img
                      src={thumbnail}
                      alt={name} /> : name}
                  </a> : null)}
                </div>}
                {message && <div className='message__desc'>{message}</div>}
                <div className='message__time'>{time}</div>
              </div>
            </Fragment>
          })}

          {date.end && <p>{t('completed')}: {date?.end}</p>}
        </div>
      </div>

      <Input disabled={!!date.end} />
    </> : <div className='chat__list center --high'>
      <h3>{id ? 'Nie znaleziono chatu' : 'Wybierz chat'}</h3>
    </div>
  )
}

export default Chat