import { ReactComponent as NewPlace } from '../../../assets/newPlace.svg'
import { ReactComponent as Location } from '../../../assets/location.svg'
import { selectPlaces, updateMapOption } from '../Map/mapSlice'
import { LOCATION_STATES } from '../../../utils/constants'
import BackBtn from '../../../components/buttons/BackBtn'
import { useDispatch, useSelector } from 'react-redux'
import Popup from '../../../components/popups/Popup'
import { useHistory } from 'react-router-dom'

const FindPlacePopup = () => {

  const places = useSelector(selectPlaces)

  const { goBack, push } = useHistory()
  const dispatch = useDispatch()

  return (
    <Popup
      centered
      isLine={false}>

      <div className='popup--findPlace'>
        <div className='popup__header'>
          <div>W wybranej lokalizacji</div>
          <div>nie znaleźlimy MAGa!</div>
        </div>

        <p>Najblisze magazyny:</p>

        <div className='popup__places center'>
          {[...places]
            .sort((a, b) => a?.distance - b?.distance)
            .slice(0, 2)
            .map(({ id, gpslatitude, gpslongitude, street = '', estateNumber = '', city, distance, zip }, idx) => {
              const showPlace = () => {
                if (!id) return
                goBack()
                if (gpslatitude && gpslongitude) dispatch(updateMapOption({
                  mapPosition: [gpslatitude, gpslongitude],
                  zoom: 12
                }))
                setTimeout(() => push(`/map/${id}`))
              }

              return <div
                key={idx}
                className='places__item'>
                <div className='item__label'>{distance}km od Ciebie</div>
                <div className='group'>
                  <div className='item__address'>
                    <span>{zip} {city}, {street} {estateNumber}</span>
                  </div>
                  <div className='item__icon' onClick={showPlace}>
                    <Location />
                  </div>
                </div>
              </div>
            })}
        </div>

        <div className='popup__footer center'>
          <p>MAG bliżej ciebie?</p>

          <button
            className='button --arrow --enlarged'
            onClick={() => push(window.location.pathname, LOCATION_STATES.popup('newplace'))}>
            <NewPlace />
            <span className='--termina'>Zgłoś tą lokalizację</span>
          </button>

          <BackBtn text='Zamknij' />
        </div>
      </div>
    </Popup>
  )
}

export default FindPlacePopup