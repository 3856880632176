import { selectIsLoading, selectIsLoggedIn } from 'containers/Login/loginSlice'
import { Redirect, Route } from 'react-router-dom'
import BottomBar from 'components/BottomBar'
import { useSelector } from 'react-redux'
import Loading from 'components/Loading'
import Header from 'containers/Header'

const MRoute = ({ path, darkHeader = false, whiteHeader = false, roundedHeader = false, flyingHeader = false, animation = true, bottomBar = false, protectedPath = false, children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isLoading = useSelector(selectIsLoading)

  return (
    (protectedPath && !isLoggedIn)
      ? isLoading
        ? <main>
          <Loading middleOfPage />
        </main>
        : <Redirect to='/login' />
      : <Route path={path}>
        <Header
          dark={darkHeader}
          white={whiteHeader}
          flying={flyingHeader}
          rounded={roundedHeader}
        />
        <main className={animation ? ' --animated' : undefined}>
          {children}
        </main>
        {bottomBar && <BottomBar />}
      </Route>
  )
}

export default MRoute