import { selectBasketList } from 'containers/Basket/basketSlice'
import { useReduxSelector } from 'store'

const useRelatedBasketItemsGroupedByOpeningMethodKey = (placeId, cartId) => {
  const list = useReduxSelector(selectBasketList)

  const getRelatedBasketItemsGroupedByOpeningMethodKey = () => {
    const relatedBasketItemsGroupedByOpeningMethodKey = {}
    for (const item of list) {
      if (item.type === 'rentAddon' && (
        item.ownerId
          ? item.ownerId === cartId
          : item.related
            ? (item.related.some(r => cartId ? r.cartId === cartId : r.id === placeId))
            : (!cartId && item.placeId === placeId)
      )) {
        const openingMethodKey = item?.openingMethodKey
        if (openingMethodKey) {
          if (relatedBasketItemsGroupedByOpeningMethodKey[openingMethodKey]) {
            relatedBasketItemsGroupedByOpeningMethodKey[openingMethodKey].push(item)

          } else {
            relatedBasketItemsGroupedByOpeningMethodKey[openingMethodKey] = [item]
          }
        }
      }
    }
    return relatedBasketItemsGroupedByOpeningMethodKey
  }

  return getRelatedBasketItemsGroupedByOpeningMethodKey()
}

export default useRelatedBasketItemsGroupedByOpeningMethodKey