import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import calendarReducer from 'containers/OrderDetails/Calendar/calendarSlice'
import categoriesReducer from 'containers/Categories/categoriesSlice'
import mapDetailsReducer from 'containers/MapDetails/mapDetailsSlice'
import warehousesReducer from 'containers/Warehouses/warehousesSlice'
import mapSearchReducer from 'containers/MapSearch/mapSearchSlice'
import locationReducer from 'containers/Maps/Result/locationSlice'
import filtersReducer from 'containers/Maps/menu/filtersSlice'
import accountReducer from 'containers/Account/accountSlice'
import basketReducer from 'containers/Basket/basketSlice'
import loginReducer from 'containers/Login/loginSlice'
import mapReducer from 'containers/Maps/Map/mapSlice'
import formReducer from 'containers/forms/formSlice'
import chatReducer from 'containers/Chats/chatSlice'
import { loadState, saveState } from 'utils/storage'
import { configureStore } from '@reduxjs/toolkit'
import restReducer from 'rest/restSlice'
import appReducer from './appSlice'

const preloadedState = loadState()

const reducer = {
  app: appReducer,
  account: accountReducer,
  basket: basketReducer,
  calendar: calendarReducer,
  categories: categoriesReducer,
  chat: chatReducer,
  filters: filtersReducer,
  form: formReducer,
  location: locationReducer,
  login: loginReducer,
  map: mapReducer,
  mapDetails: mapDetailsReducer,
  mapSearch: mapSearchReducer,
  rest: restReducer,
  warehouses: warehousesReducer,
}

const store = configureStore({
  preloadedState,
  reducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    })
})

const getState = () => {
  const { app, account, login, warehouses, rest, ...r } = store.getState()
  return { ...r }
}

store.subscribe(() => saveState(getState()))

export type RootState = ReturnType<typeof store.getState>

export const useReduxDispatch = () => useDispatch<typeof store.dispatch>()
export const useReduxSelector: TypedUseSelectorHook<RootState> = useSelector

export default store