import './style.scss'

const MmRating = ({ number }) => {

  const stars = Array.from({ length: 5 }, (_, index) => {
    const isRed = index < number
    const starClass = `icon icon--star ${isRed ? '--red' : ''}`
    return <i key={index} className={starClass}></i>
  })

  return (
    <div className='mmrating' >
      <div className='mmrating__title --termina'>MM Score:</div>
      <div className='mmrating__stars'>{stars}</div>
    </div>
  )
}

export default MmRating
