import { selectUserId } from 'containers/Login/loginSlice'
import { useEffect, useState } from 'react'
import { paymentMethod } from 'rest/urls'
import { useReduxSelector } from 'store'
import { POST } from 'rest/request'

interface PaymentMethod {
  object: string
  id: number
  date?: string
  shortName: string
  order: number
  name: string
  key: string
  paymentMethodTypeId: PaymentMethodTypeId
  paymentPeriodDays: number
  isBlocked: boolean
  positions?: PaymentMethodPosition[]
  description: string
}

interface PaymentMethodTypeId {
  name: string
  value: string
}

interface PaymentMethodPosition {
  value: string
  name: string
}

const usePaymentMethods = () => {
  const userId = useReduxSelector(selectUserId)
  const [isLoading, setLoading] = useState(true)
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[] | undefined>(undefined)

  const getPaymentMethods = async (_userId: string) => {
    if (!_userId) return
    try {
      const body = { userId: _userId }
      const response = await POST(paymentMethod, { body })
      if (response && typeof response === 'object') {
        setLoading(true)
        setPaymentMethods(Object.values(response))
      } else {
        throw new Error('Wrong response')
      }

    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    getPaymentMethods(userId)
  }, [userId])

  return {
    isLoading,
    paymentMethods
  }
}

export default usePaymentMethods