import { ReactComponent as Triangle } from '../../assets/warningTriangle.svg'
import useTranslation from '../../hooks/useTranslation'
import React from 'react'

interface AlertProps extends warehouse.WarehouseAlert {
  action: basic.BasicFunc
}

const Alert: React.FC<AlertProps> = ({ title, desc, action }) => {
  const { t } = useTranslation('basic')

  return (
    <div className='warehouse__popup'>
      <div className='popup__header'>
        <Triangle />
        <div>{t('actionRequired')}</div>
      </div>

      <div className='popup__title'>
        <div className='--termina'>{title}</div>
        <div>{desc}</div>
      </div>

      <div
        className='popup__button'
        onClick={e => {
          action()
          e.stopPropagation()
        }}>
        Opłać
      </div>
    </div>
  )
}

export default Alert