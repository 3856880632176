import { createContext } from 'react'

const CoordinatesContext = createContext<delivery.Coordinates>({
  latitude: 0,
  longitude: 0
})

const MarkersContext = createContext<delivery.Point[]>([])
const SearchMarkersContext = createContext<(search: string, services: string[]) => void>(() => { })
const MoveMapContext = createContext<(center: delivery.Coordinates, bounds: delivery.BodyMapBounds) => void>(() => { })

export {
  MarkersContext,
  MoveMapContext,
  CoordinatesContext,
  SearchMarkersContext
}