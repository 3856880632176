import { BASKET_TYPES, removeItemFromBasket, restoreBackup, selectBasket, sendBasket } from '../../../containers/Basket/basketSlice'
import ReservationFailedPopup from '../Popups/ReservationFailedPopup'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

const FORM_PATHS = 'billing|calendar|locks|contract|login|payment|registration|summary'

const Reservation = () => {
  const { backup, time, lastSentTime, unlock = false, errors, list, invoice } = useSelector(selectBasket)
  const [reservation, setReservation] = useState(true)

  const { goBack, replace } = useHistory()
  const { pathname } = useLocation()
  const dispatch = useDispatch()

  const reg = new RegExp(`${unlock ? 'map|' : ''}${FORM_PATHS}`)
  const isFormPath = !!pathname.match(reg)

  const send = () => {
    dispatch(sendBasket({ step: 'rentingSummary' }))
  }

  useEffect(() => {
    if (time && !isFormPath) dispatch(removeItemFromBasket())
  }, [dispatch, isFormPath, time])

  useEffect(() => {
    if (lastSentTime) {
      const time = lastSentTime - Date.now()
      const timeout = setTimeout(send, time)
      return () => {
        clearTimeout(timeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastSentTime])

  useEffect(() => {
    if (isBasketValid(list)) {
      const timeout = setTimeout(send, 100)
      return () => {
        clearTimeout(timeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, invoice])

  useEffect(() => {
    if (backup) {
      setReservation(!isFormPath)
      const timeout = setTimeout(() => { dispatch(restoreBackup(false)) }, 6000)
      return () => {
        clearTimeout(timeout)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, backup])

  if (errors) return <ReservationFailedPopup />
  if (!backup) return false
  return (
    <div className='notification'>
      <div className='notification__desc'>{reservation ? 'Nie dokończyłeś rezerwacji!' : 'Chcesz cofnąć akcję?'}</div>
      <div className='notification__button --termina' onClick={() => {
        dispatch(restoreBackup(true))

        if (!pathname.match(/(additional|calendar)/)) {
          const list = backup?.list
          const { placeId, hash } = Array.isArray(list)
            ? (list.findLast(l => l.type === BASKET_TYPES.rent) || {})
            : {}
          if (hash && placeId) replace(`/calendar/${placeId}/${hash}`)
          else goBack()
        }
      }}>{reservation ? 'Wróć' : 'Cofnij'}</div>
    </div>
  )
}

const isBasketValid = (list) => {
  if (!list || list.length === 0) return false
  for (const item of list) {
    if (item.type === BASKET_TYPES.rent && !item.start) {
      return false
    }
  }
  return true
}

export default Reservation