import React from 'react'

type InvoiceSectionProps = Pick<warehouse.Warehouse, 'clientName' | 'clientSurname' | 'clientAddress'>

const InvoiceSection: React.FC<InvoiceSectionProps> = ({ clientName, clientSurname, clientAddress }) => {
  return (
    <div>
      <div className='list__header element__title'>Najemca</div>
      <div className='list__element --big'>
        <div className='group'>
          <div>
            <div
              className='element__title'
              style={{ marginBottom: '0.5em' }}>
              {clientName} {clientSurname}
            </div>
            <div className='element__desc'>
            {clientAddress && clientAddress.split(', ').map((text, index) => <div key={index}>{text}</div>)}
            </div>
          </div>
          <div className='element__desc'>Zmień</div>
        </div>
      </div>
    </div>
  )
}

export default InvoiceSection