import { ReactComponent as NewPlace } from '../../../assets/newPlace.svg'
import { ReactComponent as Location } from '../../../assets/location.svg'
import { ReactComponent as Arrow } from '../../../assets/arrowDown.svg'
import { selectFilters, selectLocation } from '../menu/filtersSlice'
import { selectLocationStatus, selectPlaces } from './locationSlice'
import { LOCATION_STATES, STATUS } from '../../../utils/constants'
import { useState, useCallback, Fragment, useEffect } from 'react'
import { ReactComponent as Sort } from '../../../assets/sort.svg'
import DropDownList from '../../../components/DropDownList'
import PlaceElementForList from './PlaceElementForList'
import { isMobile } from '../../../utils/isMobile'
import Error from '../../../components/Error'
import { useHistory } from 'react-router-dom'
import ResultElement from './ResultElement'
import { useSelector } from 'react-redux'

const SORT_OPTIONS = ['Domyślnie', 'Najtaniej', 'Najdrożej']
const mobile = isMobile()

const ResultList = () => {
  const { attributes: { warehouseTypeId } = {} } = useSelector(selectFilters)
  const status = useSelector(selectLocationStatus)
  const location = useSelector(selectLocation)
  const places = useSelector(selectPlaces)

  const [selectedOption, selectOption] = useState(SORT_OPTIONS[0])
  const [list, showList] = useState(false)

  const { push } = useHistory()

  const getPendingList = useCallback(() => mobile ? [1, 2] : [1, 2, 3], [])


  useEffect(() => {
    let timeout = setTimeout(() => showList(true), 500)
    return () => clearTimeout(timeout)
  }, [])

  const getList = () => Object.entries(places).map(([k, v]) => {
    const title = k === 'filtered' && v.length > 0 ? (
      <div className='list__header'>
        <span>Zobacz inne lokalizacje</span>
        <Arrow />
      </div>
    ) : k === 'localized' && v.length > 0 ? (
      <div className='list__header'>
        <span>Zobacz inne opcje</span>
        <Arrow />
      </div>
    ) : false

    const elements = [...v].sort((a, b) => {
      switch (selectedOption) {
        case SORT_OPTIONS[1]:
          return a.min_day_price - b.min_day_price
        case SORT_OPTIONS[2]:
          return b.min_day_price - a.min_day_price
        default: return 0
      }
    }).map((e, idx) => <PlaceElementForList
      data={e}
      key={idx}
      idx={idx}
      warehouseTypeId={warehouseTypeId} />)

    return <Fragment key={k}>
      {title}
      {v.length > 0 && <div className='resultList'>
        {elements}
      </div>}
    </Fragment>
  })

  return (
    status === STATUS.idle ? <div
      className='result__start'>
      {!location && <div className='start__tile center'>
        <p>Wyniki pojawią się po podaniu lokalizacji</p>
        <button
          className='button --arrow --enlarged'
          onClick={() => push('/mapsearch')}>
          <Location />
          <span className='--termina'>Podaj adres</span>
        </button>
      </div>}

      <div
        className='start__tile center'
        onClick={() => push(window.location.pathname, LOCATION_STATES.popup('newplace'))}>
        <p>MAG bliżej ciebie?</p>
        <button className='button --arrow --enlarged --black'>
          <NewPlace />
          <span className='--termina'>Zgłoś nową lokalizację</span>
        </button>
      </div>
    </div> : <div className='result__main'>
      <div className='result__group group'>
        <div className='result__title'>Znalezione pozycje</div>

        <div className='result__sort'>
          <Sort />
          <DropDownList
            list={SORT_OPTIONS}
            selectedValue={selectedOption}
            changeValue={selectOption} />
        </div>
      </div>

      {status === STATUS.failed ? <Error text='Brak wyników' />
        : status === STATUS.succeed && list ? <div>{getList()}</div>
          : <div className='list'>
            {getPendingList().map(e => <ResultElement key={e} />)}
          </div>}
    </div>
  )
}

export default ResultList