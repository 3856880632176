import { ButtonDispatchContext } from 'containers/Basket/buttonContext/Context'
import { BASKET_CONTAINER_ID } from 'containers/Basket/BasketContainer'
import { RefObject, useContext, useEffect, useRef } from 'react'
import { selectCalendar } from './Calendar/calendarSlice'
import Locks from 'containers/OrderDetails/Locks'
import { LOCATION_STATES } from 'utils/constants'
import BackBtn from 'components/buttons/BackBtn'
import { useHistory } from 'react-router-dom'
import PeriodsToPay from './PeriodsToPay'
import { useReduxSelector } from 'store'
import NextButton from './NextButton'
import Additional from './Additional'
import Discounts from './Discounts'
import Calendar from './Calendar'
import Summary from './Summary'
import './style.scss'

const OrderDetails = () => {
  const { availableAttributesAmount, lock, placeLock } = useReduxSelector(selectCalendar)

  const dispatch = useContext(ButtonDispatchContext)
  const { push } = useHistory()

  const validateLocks = useRef<() => boolean>(null)
  const insuranceRef = useRef<HTMLDivElement>(null)
  const summaryRef = useRef<HTMLDivElement>(null)
  const addonsRef = useRef<HTMLDivElement>(null)
  const locksRef = useRef<HTMLDivElement>(null)

  const scrollPosition = useRef({
    addons: false,
    insurance: false,
    locks: false,
    summary: false
  })

  const thereAreAddonsAvailable = availableAttributesAmount?.addons > 0
  const thereAreInsuranceAvailable = availableAttributesAmount?.insurance > 0
  const thereAreLocksAvailable = (!!lock?.openingMethods && lock.openingMethods.length > 0)
    || (!!placeLock?.openingMethods && placeLock.openingMethods.length > 0)

  useEffect(() => {
    const container = document.querySelector(`#${BASKET_CONTAINER_ID}`) as HTMLDivElement
    const insurance = insuranceRef.current
    const summary = summaryRef.current
    const addons = addonsRef.current
    const locks = locksRef.current

    const onScroll = () => {
      if (!container || !insurance || !summary || !addons || !locks) return
      const { scrollHeight, clientHeight, scrollTop, offsetTop } = container

      // Sprawdzanie czy scroll dotarł do końca kontenera
      if (!scrollPosition.current.summary) {
        scrollPosition.current.summary = scrollTop + clientHeight >= scrollHeight - 50
      }

      // Sprawdzanie czy scroll dotarł do obszaru ubezpieczenia
      if (!scrollPosition.current.insurance) {
        const insuranceScrollTop = insurance.offsetTop - offsetTop
        const insuranceScrollBottom = insuranceScrollTop + insurance.clientHeight
        scrollPosition.current.insurance = (scrollTop + clientHeight >= insuranceScrollBottom && scrollTop <= insuranceScrollTop)
      }

      // Sprawdzanie czy scroll dotarł do obszaru dodatkowego
      if (!scrollPosition.current.addons) {
        const addonsScrollTop = addons.offsetTop - offsetTop
        const addonsScrollBottom = addonsScrollTop + addons.clientHeight
        scrollPosition.current.addons = (scrollTop + clientHeight >= addonsScrollBottom && scrollTop <= addonsScrollTop)
      }

      // Sprawdzanie czy scroll dotarł do zabezpieczeń
      if (!scrollPosition.current.locks) {
        const locksScrollTop = locks.offsetTop - offsetTop
        const locksScrollBottom = locksScrollTop + locks.clientHeight
        scrollPosition.current.locks = (scrollTop + clientHeight >= locksScrollBottom && scrollTop <= locksScrollTop)
      }
    }

    if (container && insurance && summary && addons && locks) {
      container.addEventListener('scroll', onScroll)
      return () => {
        container.removeEventListener('scroll', onScroll)
      }
    }
  }, [])

  const goTo = (ref: RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' })
  }


  useEffect(() => {
    const buttonAction = () => {
      switch (true) {
        case thereAreAddonsAvailable && !scrollPosition.current.addons:
          goTo(addonsRef)
          break

        case thereAreInsuranceAvailable && !scrollPosition.current.insurance:
          goTo(insuranceRef)
          break

        case thereAreLocksAvailable && !scrollPosition.current.locks:
          goTo(locksRef)
          break

        case !scrollPosition.current.summary:
          goTo(summaryRef)
          break

        default:
          if (!validateLocks.current || validateLocks.current()) {
            push('/login')
          }
          break
      }
    }
    dispatch(buttonAction)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, push, thereAreAddonsAvailable, thereAreInsuranceAvailable, thereAreLocksAvailable])

  return (
    <div className='orderInfo'>
      <Calendar />
      <div ref={addonsRef}>
        {availableAttributesAmount?.addons > 0 && <Additional />}
      </div>
      <div ref={insuranceRef}>
        {availableAttributesAmount?.insurance > 0 && <Additional insurance />}
      </div>
      <div ref={locksRef}>
        {thereAreLocksAvailable && <Locks ref={validateLocks} />}
      </div>
      <div ref={summaryRef}>
        <PeriodsToPay />
        <Discounts />
        <Summary />
        <div className='orderInfo__buttons'>
          <BackBtn
            text='Dodaj kolejny magazyn'
            action={() => {
              push(window.location.pathname, LOCATION_STATES.popup('newMag'))
            }}
          />
          <NextButton validateLocks={validateLocks.current} />
        </div>
      </div>
    </div>
  )
}

export default OrderDetails