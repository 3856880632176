
type AppAuthEventDetailType = 'none' | 'pin' | 'biometric'
export interface AppAuthEventDetail {
  success: boolean
  token?: string
  type?: AppAuthEventDetailType
}

export const APP_AUTH_KEY = 'appAuth'

const generateAuthEvent = (detail: AppAuthEventDetail): CustomEvent => {
  return new CustomEvent(APP_AUTH_KEY, { detail })
}

export const dispatchAuthEvent = (detail: AppAuthEventDetail) => {
  document.dispatchEvent(generateAuthEvent(detail))
}