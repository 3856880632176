import useAuthSuccessCallback from './useAuthSuccessCallback'
import { STATUS, UNAUTHORIZED_TYPES } from 'utils/constants'
import { newmailcode, newsmscode, verify } from 'rest/urls'
import { ReactComponent as Logo } from 'assets/logo.svg'
import { selectRestPopupBody } from 'rest/restSlice'
import Submit from 'components/inputs/Submit'
import PinForm from 'components/PinForm'
import { useReduxSelector } from 'store'
import { POST } from 'rest/request'
import { useState } from 'react'

const CODE_LENGTH = 6
type StatusNames = 'getting' | 'sending'
type Status = Record<StatusNames, keyof typeof STATUS>

const VerificationPopup = () => {

  const popupBody = useReduxSelector(selectRestPopupBody)
  const { type, code_sent, recipient: r } = popupBody as rest.UnauthorizedResponse

  const [recipient, setRecipient] = useState(r)
  const [warning, setWarning] = useState(false)
  const [token, setToken] = useState('')
  const [status, setStatus] = useState<Status>({
    getting: STATUS.idle,
    sending: STATUS.idle
  })

  const successCallback = useAuthSuccessCallback()

  const updateStatus = (status: keyof typeof STATUS, name: StatusNames = 'sending') => setStatus(s => { return { ...s, [name]: status } })
  const getSubmitText = (l: number) => {
    return l === CODE_LENGTH ? 'Autoryzuj' : `Brakuje ${CODE_LENGTH - l} znak${l === CODE_LENGTH - 1 ? 'u' : 'ów'}`
  }

  const submit = async () => {
    updateStatus(STATUS.pending)
    try {
      const body = {
        token,
        [type]: r
      }
      const response = await POST(verify(type), { body, baseRequest: true })
      if (response?.verified) {
        successCallback({ success: true })
      } else {
        throw new Error('Wrong code')
      }

    } catch (err) {
      updateStatus(STATUS.failed)
      setWarning(true)
    }
  }

  const getCode = async () => {
    if (status.getting === STATUS.pending) return
    updateStatus(STATUS.pending, 'getting')

    try {
      const body = { [type]: r }
      const response = await POST(type === UNAUTHORIZED_TYPES.mail ? newmailcode : newsmscode, { body })
      if (response) {
        updateStatus(STATUS.succeed, 'getting')
        setRecipient(response?.recipient)
      } else throw new Error('Wrong response')

    } catch (error) {
      updateStatus(STATUS.failed, 'getting')
      console.error('Code is not sent!')
    }
  }

  return (
    <div className='popup__verification'>
      <Logo />

      <div className='popup__title'>
        <div>Autoryzacja {type === UNAUTHORIZED_TYPES.device ? 'urządzenia' : type === UNAUTHORIZED_TYPES.mail ? 'mailowa' : 'SMS'}</div>
      </div>

      {(code_sent || status.getting === STATUS.succeed) && <div className='verefication__phone'>
        <div>Kod został wysłany na:</div>
        <div>{recipient}</div>
      </div>}

      <div
        className='verefication__phone'
        onClick={getCode}>
        {status.getting === STATUS.pending ? 'ładowanie...'
          : (status.getting === STATUS.idle && !code_sent) ? 'Przyślij kod'
            : 'Wyślij ponownie'}
      </div>

      <PinForm
        isCode
        autoFocus
        value={token}
        warning={warning}
        setValue={setToken}
        submit={submit}
        setWarning={w => setWarning(w)}
        autoLoadCodeFromSMS={status.sending === STATUS.idle && type !== UNAUTHORIZED_TYPES.device}
      />

      <Submit
        text={getSubmitText(token.length)}
        block={token.length !== CODE_LENGTH || status.sending === STATUS.pending}
        action={submit}
      />
    </div>
  )
}

export default VerificationPopup