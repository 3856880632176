import { ReactComponent as Terminate } from '../../../../assets/terminateRent.svg'
import { ReactComponent as Calendar } from '../../../../assets/calendarSmall.svg'
import { removeItemFromBasket } from '../../../../containers/Basket/basketSlice'
import { ReactComponent as Cost } from '../../../../assets/cost.svg'
import ArrowBtn from '../../../../components/buttons/ArrowBtn'
import BackBtn from '../../../../components/buttons/BackBtn'
import useTranslation from '../../../../hooks/useTranslation'
import { getLocalDateString } from '../../../../utils/date'
import { useDispatch } from 'react-redux'
import './style.scss'

const List = ({ list, showForm }) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return (
    <div className='popup__reservationFailed'>
      {list.map(({ result, errors }, idx) => (
        <>
          <div key={idx} className='reservationFailed__header'>{result}</div>
          {errors?.map((e, i) => <div key={i}>*{e}</div>)}
        </>
      ))}
      <div className='reservationFailed__list'>
        <hr />
        {list.map(({ name, result, errors, start, end, address, termiateDays, amount, dailyPrice, monthlyPrice, rangeDate: { m, d } }, idx) => <div
          key={idx}
          className='reservationFailed__item'>

          <div className='item__header'>
            <div>{list?.length > 1 ? `${idx + 1}. ` : ''}{name}</div>

            {address && (({ street, estateNumber, localNumber, province }) => <div>
              ul.{street} {estateNumber}{localNumber ? `/${localNumber}` : ''}, {province}
            </div>)(address)}
          </div>

          <div className='item__decs group'>
            <div className='center'>
              <Calendar />
              <div>{t('basket.rentalPeriod')}</div>
            </div>
            <div>{getLocalDateString(start)} - {end ? getLocalDateString(end) : 'niokreślono'}</div>
          </div>

          {m !== 0 && <div className='item__decs group'>
            <div className='center'>
              <Cost />
              <div>{t(m === 1 && d === 0 ? 'basket.rentalCost' : 'basket.monthlyCost')}</div>
            </div>
            <div>{amount * Number(m === 0 ? dailyPrice * d : monthlyPrice)} zł</div>
          </div>}

          {d > 0 && <div className='item__decs group'>
            <div className='center'>
              <Cost />
              <div>{t(m === 0 ? 'basket.rentalCost' : 'basket.costOfRemainingDays')}</div>
            </div>
            <div>{d} {t(d === 1 ? 'day' : 'days')} x {dailyPrice} zł = {dailyPrice * d} zł</div>
          </div>}

          <div className='item__decs group'>
            <div className='center'>
              <Terminate className='terminate' />
              <div>{t('basket.periodOfNotice')}</div>
            </div>
            <div>{termiateDays} {t('basket.days')}</div>
          </div>

          {result === 'notification_created' && errors && <div className='item__decs group'>
            {errors?.map((e, i) => <div key={i}>*{e}</div>)}
            <div></div>
          </div>}
          <hr />
        </div>)}
      </div>

      <div className='reservationFailed__buttons'>
        <ArrowBtn
          showArrow={false}
          className='--disabled'
          text={t('reservation.notifyMeOfAvailability')}
          action={() => showForm(true)}
        />

        <ArrowBtn
          showArrow={false}
          text={t('reservation.seeOtherSizes')}
          action={() => console.log('maybe someday...')}
        />

        <BackBtn
          text={t('reservation.deleteSelectedItems')}
          action={() => dispatch(removeItemFromBasket({ removeIdentifiers: list.map(({ identifier }) => identifier) }))} />
      </div>
    </div>
  )
}

export default List