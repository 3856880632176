import { BASKET_TYPES, selectBasket } from 'containers/Basket/basketSlice'
import React, { useEffect, useRef, useState } from 'react'
import useWarehouseName from 'hooks/useWarehouseName'
import { useReduxSelector } from 'store'

const HeaderTitle: React.FC = () => {
  const { selected, list } = useReduxSelector(selectBasket)
  const [basketAlert, showBasketAlert] = useState(false)
  const previousWarehousesAmount = useRef(0)
  const getName = useWarehouseName()

  useEffect(() => {
    const warehousesAmount = list.reduce((r: number, e: { type: string }) => r += e.type === BASKET_TYPES.rent ? 1 : 0, 0)
    if (warehousesAmount > previousWarehousesAmount.current) {
      showBasketAlert(true)
    }
    previousWarehousesAmount.current = warehousesAmount
  }, [list])

  useEffect(() => {
    if (basketAlert) {
      const timeout = setTimeout(() => { showBasketAlert(false) }, 3000)
      return () => {
        clearTimeout(timeout)
      }
    }
  }, [basketAlert])


  return (
    basketAlert
      ? <div className='header__title --animated'>
        <div className='--sb'>Magazyn zarezerwowano na 3 minuty</div>
      </div>
      : <div className='header__title'>
        <div className='--sb'>{getName(list[selected])}</div>
        <div>{list[selected]?.address?.city}, {list[selected]?.address?.street} {list[selected]?.address?.estateNumber}</div>
      </div>
  )
}

export default HeaderTitle