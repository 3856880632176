
import { BASKET_TYPES, selectBasketList } from 'containers/Basket/basketSlice'
import { useReduxSelector } from 'store'

type GroupedCartIds = Record<string, Record<string, string[]>>
interface Data {
  identifier: string
  placeId: string
  hash: string
  name: string
}

const names = [
  '', 'Pierwszy', 'Drugi', 'Trzeci', 'Czwarty', 'Piąty', 'Szósty', 'Siódmy', 'Ósmy', 'Dziewiąty'
]

const useWarehouseName = () => {
  const list = useReduxSelector(selectBasketList)
  const groupCartIdsWithRent = () => {
    return (list as basic.Form[]).reduce((r, c) => {
      if (c.type === BASKET_TYPES.rent) {
        if (r[c.placeId]?.[c.hash]) {
          r[c.placeId][c.hash].push(c.identifier)
        } else {
          r[c.placeId] = {
            ...r[c.placeId],
            [c.hash]: [c.identifier]
          }
        }
      }
      return r
    }, {} as GroupedCartIds)
  }

  const group = ((Array.isArray(list) && list.length > 0)
    ? groupCartIdsWithRent()
    : {}) as GroupedCartIds

  const getName = (_: Data) => {
    const list = group?.[_.placeId]?.[_.hash]
    if (!list || list.length === 1) {
      return _.name
    }

    const index = list.findIndex(id => id === _.identifier) + 1
    return `${_.name} ${index < 10 ? names[index] : `#${index}`}`
  }

  return getName
}

export default useWarehouseName