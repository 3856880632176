import EditPhonePopup from './BasicInfo/EditPhonePopup'
import EditMailPopup from './BasicInfo/EditMailPopup'
import EditUserPopup from './BasicInfo/EditUserPopup'
import usePopupState from '../../hooks/usePopupState'
import DevicePopup from './Settings/DevicePopup'
import PinPopup from './Settings/PinPopup'

const Popups = () => {
  const { popup } = usePopupState()

  switch (popup) {
    case 'editAdditionalPhone': return <EditPhonePopup isAdditionalPhone />
    case 'editAdditionalMail': return <EditMailPopup isAdditionalMail />
    case 'editPhone': return <EditPhonePopup />
    case 'editMail': return <EditMailPopup />
    case 'editUser': return <EditUserPopup />
    case 'deviceList': return <DevicePopup />
    case 'changePin': return <PinPopup />
    default: return false
  }
}

export default Popups