import ImageGallery from 'react-image-gallery'
import { useHistory } from 'react-router-dom'

import 'react-image-gallery/styles/css/image-gallery.css'
import './style.scss'

const GalleryPopup = ({ data, initialSlide }) => {
  const { goBack } = useHistory()

  const images = data.map(({ public_path, thumbnail }) => ({
    original: public_path,
    thumbnail: thumbnail
  }))

  return (
    <div className='galleryPopup'>
      <div className='galleryPopup__content'>
        <div className='galleryPopup__content__head'>
          <i className='icon icon--close' onClick={goBack}></i>
        </div>
        <div className='galleryPopup__content__body'>
          <ImageGallery
            items={images}
            startIndex={initialSlide}
            showPlayButton={false}
            showFullscreenButton={false}
            showThumbnails={true}
          />
        </div>
      </div>
    </div>
  )
}

export default GalleryPopup
