import React, { useContext, useEffect, useRef, useState } from 'react'
import { SearchMarkersContext } from './context/data/Context'
import Input from 'components/inputs/Input'

interface SearchProps {
  initialSearch?: string
  initialServices: string[]
}

const DELAY = 500
// const options = [{
//   name: 'MM',
//   value: 'mm'
// }, {
//   name: 'InPost',
//   value: 'inpost'
// }, {
//   name: 'DPD',
//   value: 'dpd'
// }, {
//   name: 'DHL',
//   value: 'dhl'
// }, {
//   name: 'UPS',
//   value: 'ups'
// }, {
//   name: 'FedEx',
//   value: 'fedex'
// }, {
//   name: 'Orlen paczka',
//   value: 'orlen'
// }, {
//   name: 'Poczta Polska',
//   value: 'poczta'
// }]

const Search: React.FC<SearchProps> = ({ initialSearch = '', initialServices }) => {
  // const [services, setServices] = useState(allServices)
  const [search, setSearch] = useState(initialSearch)
  const timeout = useRef<NodeJS.Timeout | null>(null)

  const searchMarks = useContext(SearchMarkersContext)

  useEffect(() => {
    if (initialSearch) {
      searchMarks(initialSearch, initialServices)
    }
  }, [initialSearch, initialServices, searchMarks])

  const updateSearch = ({ _serach = search, _services = initialServices }) => {
    if (timeout.current) {
      clearTimeout(timeout.current)
    }
    timeout.current = setTimeout(() => {
      searchMarks(_serach, _services)
    }, DELAY)
  }

  return (
    <Input
      name='search'
      value={search}
      placeholder='Wpisz adres...'
      changeHandler={e => {
        const _serach = e.target.value
        setSearch(_serach)
        updateSearch({ _serach })
      }}
    />
  )
}

export default Search