import ArrowBtn from 'components/buttons/ArrowBtn'
import { useHistory } from 'react-router-dom'
import Popup from 'components/popups/Popup'

const WarehouseAttrPopup = ({ cancelAction, body }) => {
  const { goBack } = useHistory()
  console.log(body)
  return (
    <Popup close={cancelAction || goBack}>
      <div className='popup__title'>
        <div>Co oznaczają atrybuty?</div>
      </div>
      <div className='popup__infoBlocks'>
        {body?.map((item) => {
          return (
            <div key={item.id} className={`lockItem lockItem--info`}>
              <div><i className='icon icon--byapp'></i>{item.name} </div>
              <div>{item.description}</div>
            </div>
          )
        })}
        <ArrowBtn
          enlarged
          showArrow={false}
          text={'Rozumiem'}
          action={cancelAction || goBack} />
      </div>
    </Popup>
  )
}

export default WarehouseAttrPopup