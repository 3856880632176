import MagNumber from 'components/MagNumber'
import './style.scss'

const Favorite = () => {
  return (
    <div className='dashboard__favorite'>

      <div className='favorite__card'>
        <div className='favorite__card__head'>
          <div className='badge'>
            <i className='icon icon--location-500'></i>
            <span>20 stycznia 49/1</span>
          </div>
          <div className='options'>
            <i className='icon icon--dots'></i>
          </div>
        </div>
        <div className='favorite__card__body'>
          <MagNumber number={7} isLight={true} />
        </div>
        <div className='favorite__card__footer'>
          <div className='status'>
            <span className='red'></span>
            Otwórz bramę
          </div>
          <div className='arrow'>
            <i className='icon icon--arrow-right s24'></i>
          </div>
        </div>
      </div>

      <div className='favorite__card'>
        <div className='favorite__card__head'>
          <div className='badge'>
            <i className='icon icon--location-500'></i>
            <span>20 stycznia 49/1</span>
          </div>
          <div className='options'>
            <i className='icon icon--dots'></i>
          </div>
        </div>
        <div className='favorite__card__body'>
          <MagNumber number={7} isLight={true} />
        </div>
        <div className='favorite__card__footer'>
          <div className='status'>
            <span className='red'></span>
            Otwórz bramę
          </div>
          <div className='arrow'>
            <i className='icon icon--arrow-right s24'></i>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default Favorite