import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { STATUS } from '../../utils/constants'
import { GET } from '../../rest/request'
import * as urls from '../../rest/urls'

export const ACCOUNT_TYPE = {
  basic: 'basic',
  cards: 'cards',
  company: 'company',
  invoices: 'invoices',
  payments: 'payments',
  settings: 'settings',
}

const payments = {
  history: [{
    date: '02.02.2022',
    title: 'Wynajem magazynu',
    desc: 'MAG #32, Pabianice',
    mag: ['22']
  }, {
    date: '04.02.2022',
    title: 'Ubezpieczenie magazynu',
    desc: 'MAG #32, #33 Pabianice',
    mag: ['22', '33']
  }, {
    date: '02.03.2022',
    title: 'Wynajem magazynu',
    desc: 'MAG #32, #33, #44 Pabianice',
    mag: ['22', '33', '44']
  }, {
    date: '02.04.2022',
    title: 'Wynajem magazynu',
    desc: 'MAG #32, #33, #44 Pabianice',
    mag: ['22', '33', '44']
  }]
}

const initialState = {
  payments
}

const getUrl = type => {
  switch (type) {
    case ACCOUNT_TYPE.company: return urls.companies
    case ACCOUNT_TYPE.cards: return urls.paymentmethods
    default: return urls.userInfo
  }
}

export const fetchUserData = createAsyncThunk('user/fetchUserData', async (type = ACCOUNT_TYPE.basic) => {
  const response = await GET(getUrl(type))
  return { data: response?.data, type }
})

const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    updateValue: (state, action) => {
      const { type, name, value } = action.payload
      if (type && name) {
        state[type] = { ...state[type], [name]: value }
      }
    },
    resetAccount: () => {
      return initialState
    }
  },
  extraReducers: {
    [fetchUserData.fulfilled]: (state, action) => {
      const { data, type } = action.payload
      state[type] = { data, status: STATUS.succeed }
    },
    [fetchUserData.pending]: (state, action) => {
      const { arg: type } = action.meta
      state[type] = { status: STATUS.pending }
    },
    [fetchUserData.rejected]: (state, action) => {
      const { arg: type } = action.meta
      state[type] = { status: STATUS.failed }
    },
  }
})

export const { updateValue, resetAccount } = accountSlice.actions

export const selectUserData = (state, type = ACCOUNT_TYPE.basic) => state.account[type] || {}

export default accountSlice.reducer