import { ReactComponent as Ring } from 'assets/ring.svg'
import useTranslation from 'hooks/useTranslation'
import Switch from 'components/inputs/Switch'
import { useState } from 'react'
import Item from '../Item'

const areNotificationsAvailable = p => p === 'granted'

const NotificationItem = () => {

  const [notifications, showNotifications] = useState(areNotificationsAvailable(Notification.permission))
  const { t } = useTranslation('account')

  return (
    <Item
      icon={<Ring />}
      title={t('notifications')}
      desc={t(notifications ? 'on' : 'off')}
      right={<Switch
        name='notifications'
        checked={notifications}
        changeHandler={() => {
          if (!notifications) Notification.requestPermission().then((p) => showNotifications(areNotificationsAvailable(p)))
        }}
      />}
    />
  )
}

export default NotificationItem