import { useReduxDispatch, useReduxSelector } from 'store'
import VerificationPopup from './auth/VerificationPopup'
import useRestPopupState from 'hooks/useRestPopupState'
import { selectRest, showPopup } from 'rest/restSlice'
import { UNAUTHORIZED_TYPES } from 'utils/constants'
import usePopupState from 'hooks/usePopupState'
import AuthPopup from './auth/BiometricPopup'
import Popup from 'components/popups/Popup'
import React, { useEffect } from 'react'
import NewMagPopup from './NewMagPopup'
import OtpPopup from './auth/OtpPopup'
import PinPopup from './auth/PinPopup'
import ErrorPopup from './ErrorPopup'
import { selectDevice } from 'appSlice'
import NativeAuth from './NativeAuth'

const Popups: React.FC = () => {
  return (
    <div className='popups'>
      <BasicPopups />
      <RestPopups />
    </div>
  )
}

const BasicPopups: React.FC = () => {
  const { popup } = usePopupState()
  return popup === 'newMag' ? <NewMagPopup /> : null
}

const RestPopups: React.FC = () => {
  const { isPopup, popupBody } = useReduxSelector(selectRest)
  const isPopupVisible = useRestPopupState(popupBody?.type)
  const device = useReduxSelector(selectDevice)
  const dispatch = useReduxDispatch()

  useEffect(() => {
    if (isPopup) {
      const onPopState = () => { dispatch(showPopup()) }
      window.addEventListener('popstate', onPopState)
      return () => window.addEventListener('popstate', onPopState)
    }
  }, [dispatch, isPopup])

  if (isPopupVisible) {
    if (device && (popupBody?.type === UNAUTHORIZED_TYPES.webAuth)) return <NativeAuth />
    return <Popup isPopup={isPopup}>
      {(() => {
        switch (popupBody?.type) {
          case UNAUTHORIZED_TYPES.mail:
          case UNAUTHORIZED_TYPES.phone:
          case UNAUTHORIZED_TYPES.device: return <VerificationPopup />
          case UNAUTHORIZED_TYPES.mailOtp:
          case UNAUTHORIZED_TYPES.smsOtp: return <OtpPopup />
          case UNAUTHORIZED_TYPES.webAuth: return <AuthPopup />
          case UNAUTHORIZED_TYPES.pin: return <PinPopup />
          case 'formError': return <ErrorPopup />
          default: return null
        }
      })()}
    </Popup>
  }

  return null
}

export default Popups