import { updateSelectedBasketItem, selectBasketItem, resetTime, extendFormToMap } from 'containers/Basket/basketSlice'
import { getStartDate, getEndDate, getRangeFromDates, DAY } from 'utils/date'
import { useEffect, useLayoutEffect, useState, useCallback } from 'react'
import { fetchCalendar, selectCalendar } from './calendarSlice'
import { LOCATION_STATES, STATUS } from 'utils/constants'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useHistory } from 'react-router'
import RentalPeriodPopup from './RentalPeriodPopup'
import useTranslation from 'hooks/useTranslation'
import usePopupState from 'hooks/usePopupState'
import { displayPrice } from 'utils/string'
import CalendarPopup from './CalendarPopup'
import { Prompt } from 'react-router-dom'
import Loading from 'components/Loading'
import Error from 'components/Error'
import DateTile from './DateTile'
import Range from './Range'
import './style.scss'

const Calendar = () => {
  const { reservation, start, end, rangeDate: range = { m: 0, d: 0 }, hash: basketHash } = useSelector(selectBasketItem)
  const { status, calendar, priceList, pricelistId, hash: calendarHash, termiateDays, ownerNote } = useSelector(selectCalendar)
  const [selectedStart, selectStart] = useState(true)

  const { t } = useTranslation()

  const { hash: paramHash, placeId: placeID } = useParams()
  const { popup } = usePopupState()
  const { push } = useHistory()

  const dispatch = useDispatch()


  const updateBasket = useCallback(obj => dispatch(updateSelectedBasketItem(obj)), [dispatch])
  const updateEndDate = useCallback(date => updateBasket({ end: date }), [updateBasket])
  const updateStartDate = useCallback(date => updateBasket({ start: date }), [updateBasket])

  useLayoutEffect(() => { dispatch(extendFormToMap(false)) }, [dispatch])

  useEffect(() => {
    dispatch(fetchCalendar({ placeID, hash: paramHash }))
  }, [dispatch, paramHash, placeID])

  useEffect(() => {
    if (status === STATUS.succeed) dispatch(resetTime())
  }, [status, dispatch])

  useLayoutEffect(() => {
    // to restore from session
    if (start && typeof start !== 'object') updateStartDate(getStartDate(start))
    if (end && typeof end !== 'object') updateEndDate(getStartDate(end))
  }, [start, end, updateStartDate, updateEndDate])

  useEffect(() => {
    if (calendarHash === paramHash && calendarHash !== basketHash && !basketHash && priceList.length > 0) {
      const { m, d, gross_price: monthlyPrice, gross_price_d: dailyPrice } = priceList[priceList.length - 1]
      const date = getStartDate(calendar?.start)

      updateStartDate(date)
      updateEndDate(calendar?.end || getEndDate({ m, d }, date))
      updateBasket({ hash: calendarHash, pricelistId, termiateDays, rangeDate: { m, d }, dailyPrice, monthlyPrice })
    }
  }, [basketHash, calendarHash, calendar, paramHash, priceList, pricelistId, termiateDays, updateBasket, updateEndDate, updateStartDate])

  const calendarChange = d => {
    const date = getStartDate(d)

    if (selectedStart) {
      selectStart(false)
      updateStartDate(date)
      updateEndDate(getEndDate(range, date))

    } else if (date - start > DAY) {
      const rangeDate = getRangeFromDates(date, start)
      const priceIdx = priceList.findIndex(({ m, d }) => (m > rangeDate.m) || (rangeDate.m === 0 && d > rangeDate.d))
      const priceObj = priceIdx > 0 ? priceList[priceIdx - 1] : priceList[priceList.length - 1]
      const { gross_price: monthlyPrice, gross_price_d: dailyPrice } = priceObj

      updateEndDate(date)
      updateBasket({ rangeDate, dailyPrice, monthlyPrice })
    }
  }

  const rangeCom = <Range
    range={range}
    ranges={priceList}
    updateRange={obj => {
      const { m, d, gross_price: monthlyPrice, gross_price_d: dailyPrice } = obj

      selectStart(false)
      updateEndDate(getEndDate({ m, d }, start))
      updateBasket({ rangeDate: { m, d }, dailyPrice, monthlyPrice })
    }}
  />

  const startDateTile = <DateTile
    start
    date={start}
    action={() => {
      push(window.location.pathname, LOCATION_STATES.popup('calendar'))
      selectStart(true)
    }}
  />

  const endDateTile = <DateTile
    date={end}
    action={() => {
      push(window.location.pathname, LOCATION_STATES.popup('calendar'))
      selectStart(false)
    }}
  />

  return (
    <>
      <Prompt
        message={({ pathname }) => {
          return pathname.match(/(?=.*(map|calendar|login|locks))(?!.*mapdetails)/) ? true : t('calendar.areYouSure')
        }}
      />

      <div className='calendar'>
        {popup === 'info' && <RentalPeriodPopup />}
        {popup === 'calendar' && <CalendarPopup
          end={end}
          start={start}
          selected={selectedStart}
          minDate={calendar?.minDate}
          minEndDate={priceList.length > 0 ? getEndDate(priceList[0], start) : getStartDate(start.getTime() + DAY)}
          rangeCom={rangeCom}
          select={selectStart}
          change={calendarChange} />}

        <div className='calendar__main'>
          {ownerNote && <div className='ownershipInfo'>
            <p dangerouslySetInnerHTML={{ __html: ownerNote }} />
          </div>}

          <div className='title'>
            <div className='group'>
              <span>{t('calendar.leasePeriod')}</span>
              <i className='icon icon--quest' onClick={() => push(window.location.pathname, LOCATION_STATES.popup('info'))}></i>
            </div>
          </div>

          {status === STATUS.failed ? <Error />
            : status === STATUS.succeed ? <>
              <div className='center'>
                <div className='center__main'>
                  <div className='calendar__inputs center'>
                    {startDateTile}
                    {endDateTile}
                  </div>

                  <div className='calendar__range'>
                    {rangeCom}
                  </div>
                </div>
              </div>

              {reservation && (({ calendar, grossPrice }) => {
                const startDate = new Date(calendar?.start)
                const endDate = new Date(calendar?.end)
                return <div className='calendar__info'>
                  <p>{t('basket.reservationForTheMissingPeriod')}</p>
                  <div>
                    <span>Za okres:&nbsp;</span>
                    <span>{startDate.toLocaleDateString()} - {endDate.toLocaleDateString()}</span>
                  </div>
                  <div>
                    <span>Opłata:&nbsp;</span>
                    <span>{displayPrice(grossPrice)}</span>
                  </div>
                </div>
              })(reservation)}

              <div className='calendar__info'>
                <p>{t('basket.periodOfNotice')}: {termiateDays} {t(`basket.${termiateDays === 1 ? 'day' : 'days'}`)}</p>
                <div>{t('calendar.billingInformationDesc')}</div>
              </div>
            </> : <Loading middleOfPage />}
        </div>
      </div>
    </>
  )
}

export default Calendar