import ArrowBtn from 'components/buttons/ArrowBtn'
import { LOCATION_STATES } from 'utils/constants'
import BackBtn from 'components/buttons/BackBtn'
import usePopupState from 'hooks/usePopupState'
import gateImg from '../../assets/img/gate.png'
import cardImg from '../../assets/img/card.png'
import magImg from '../../assets/img/mag.png'
import { useHistory } from 'react-router-dom'
import MagNumber from 'components/MagNumber'
import { useState } from 'react'
import './style.scss'
import InfoMagbox from './popups/InfoMagbox'

const DetailsSlide = ({ body, setFirstSlideVisible }) => {
  const { push } = useHistory()
  const [currentLockIndex, setCurrentLockIndex] = useState(0)
  const [currentPageIndex, setCurrentPageIndex] = useState(0)
  const [pinVisible, setPinVisible] = useState(false);

  const { popup, pathname } = usePopupState()
  const locks = body.locks
  const currentLock = locks[currentLockIndex]
  const currentPage = currentLock.pages[currentPageIndex]

  const handleNext = () => {
    if (currentPageIndex < currentLock.pages.length - 1) {
      setCurrentPageIndex(currentPageIndex + 1)
    } else if (currentLockIndex < locks.length - 1) {
      setCurrentPageIndex(0)
      setCurrentLockIndex(currentLockIndex + 1)
    } else {
      push('/warehouses/22')
    }
  }

  const handleBack = () => {
    if (currentPageIndex > 0) {
      setCurrentPageIndex(currentPageIndex - 1)
    } else if (currentLockIndex > 0) {
      setCurrentLockIndex(currentLockIndex - 1)
      setCurrentPageIndex(locks[currentLockIndex - 1].pages.length - 1)
    } else {
      setFirstSlideVisible(true)
    }
  }

  const renderSectionContent = (section) => {
    switch (section.type) {
      case 'text':
        return <p>{section.content}</p>
      case 'phone':
        return <p className='phone'>{section.content}</p>
      case 'infoModal':
        return <button onClick={() => push(pathname, LOCATION_STATES.popup(section.content))}>{section.title}</button>
      case 'pin':
        return (
          section.content.split('').map((char, index) => (
            <div key={index} className='rec'>{pinVisible ? char : '*'}</div>
          ))
        )
      default:
        return <p>{section.content}</p>
    }
  }

  return (
    <>
    {popup === 'magbox' && <InfoMagbox />}
    {popup === 'totem' && <InfoMagbox />}
    <div className='detailsSlide'>
      <div className='detailsSlide__head'>
        <MagNumber number={currentLock.warehouseNumber} />
        <div className='slide__address'>{currentLock.placeAdress}</div>
      </div>

      <div className='detailsSlide__body'>
        <div className='detailsSlide__body__img'>
          {(() => {
            switch (currentPage.img) {
              case 'gate': return <img src={gateImg} alt=''></img>
              case 'mag': return <img src={magImg} alt=''></img>
              case 'card': return <img src={cardImg} alt=''></img>
              default: <img src={magImg} alt=''></img>
            }
          })()}

        </div>

        {currentPage.sections.map((section, index) => (
          <div key={index} className='section'>
            <div className='section__title'>
              <h2>{section.title}</h2>
              {section.type === 'pin' && (
                <i
                  className={`icon icon--eye${pinVisible ? '-close' : ''}`}
                  onClick={() => setPinVisible(!pinVisible)}
                />
              )}
            </div>
            <div className='section__content'>
              {renderSectionContent(section)}
            </div>
          </div>
        ))}
      </div>

      <div className='detailsSlide__footer'>
        <BackBtn text='Wróć' action={handleBack} />
        <ArrowBtn action={handleNext} enlarged showArrow text={'Dalej'} />
      </div>
    </div>
    </>)
}

export default DetailsSlide