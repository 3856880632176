import FirebaseProvider from './firebase/Provider'
import CallProvider from './call/CallProvider'
import React from 'react'

const AppProvider = ({ children }) => {
  return (
    <FirebaseProvider>
      <CallProvider>
        {children}
      </CallProvider>
    </FirebaseProvider>
  )
}

export default AppProvider