import { useState, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { warehousesAction } from 'rest/urls'
import objectHash from 'object-hash'
import { POST } from 'rest/request'

const useResignationData = (
  action: payments.CancelRentalBody['action'],
  deliveries: payments.Delivery[]
) => {
  const { id } = useParams<warehouse.PageParams>()
  const [selectedData, selectData] = useState<payments.SelectedData>({ date: '' })
  const [data, setData] = useState<payments.CancelRentalResponse | undefined>(undefined)
  const [isLoading, setLoading] = useState(false)

  const previousWarehousesHash = useRef('')
  const currentData = useRef(data)
  currentData.current = data

  const getWarehousesData = async (body: payments.CancelRentalBody) => {
    setLoading(true)
    const response: payments.CancelRentalResponse = await POST(warehousesAction, { body })
    setLoading(false)
    if (response.warehouses) {
      setData(prev => {
        return prev ? { ...prev, ...response } : response
      })
    } else {
      console.error('Bad API 😡')
    }
  }

  const getSchedule = async (decision: payments.CancelRentalBodyDecision) => {
    setLoading(true)
    const body: payments.CancelRentalBody = {
      ...generateBody(action, selectedData, id, deliveries),
      decision
    }
    const response: payments.CancelRentalResponse = await POST(warehousesAction, { body })
    setLoading(false)
    if (response.warehouses) {
      setData(prev => ({ ...prev, ...response }))
      return response
    } else {
      console.error('Bad API 😡')
    }
  }

  const generateInvoice = async (payments: payments.Payment[], selectedTimePeriods: payments.SelectedTimePeriods, type?: string) => {
    if (!type || !data?.warehouses) return
    setLoading(true)
    const newDocument: payments.CancelRentalBodyNewDocument = {
      type,
      positions: data?.warehouses?.map(({ id, object, schedule }) => ({
        id,
        object,
        date: selectedData.date ?? 'now',
        warehouseRentingIds: getWarehouseRentingIds(selectedTimePeriods ?? 0, schedule)
      })),
      payments
    }

    if (data.newDocuments) {
      newDocument.previousId = data.newDocuments.map(({ id }) => id)
    }

    const body: payments.CancelRentalBody = {
      ...generateBody(action, selectedData, id, deliveries),
      decision: 'payNow',
      newDocument
    }

    const response: payments.CancelRentalResponse = await POST(warehousesAction, { body })
    setLoading(false)
    if (response.newDocuments) {
      setData(prev => ({ ...prev, ...response }))
      return response
    } else {
      console.error('Bad API 😡')
    }
  }

  useEffect(() => {
    const getWarehousesIfDataHasChanged = () => {
      const body = generateBody(action, selectedData, id, deliveries)
      const warehousesHash = objectHash(body)
      if (warehousesHash !== previousWarehousesHash.current) {
        getWarehousesData(body)
        previousWarehousesHash.current = warehousesHash
      }
    }

    if (currentData.current) {
      const timeout = setTimeout(getWarehousesIfDataHasChanged, 200)
      return () => {
        clearTimeout(timeout)
      }

    } else {
      getWarehousesIfDataHasChanged()
    }
  }, [action, selectedData, deliveries, id])

  return {
    response: data,
    isLoading,
    selectedData,
    getSchedule,
    selectData,
    generateInvoice
  }
}

const getWarehouseRentingIds = (selectedTimePeriod: number, schedule: payments.WarehouseRentalSchedule[]) => {
  let warehouseRentingIds: string[] = []
  for (let i = 0; i <= selectedTimePeriod; i++) {
    warehouseRentingIds.push(...schedule[0].tiles[i].warehouseRentingIds)
  }
  return warehouseRentingIds
}

const generateBody = (action: payments.CancelRentalBody['action'], selectedDates: payments.SelectedData, id: string, deliveries: payments.Delivery[]): payments.CancelRentalBody => {
  return {
    action,
    objects: getObjects(selectedDates, id),
    delivery: getDelivery(deliveries)
  }
}

const getDelivery = (deliveries: payments.Delivery[]) => {
  return deliveries.length > 0
    ? deliveries.reduce((r, { id, type, deliveryMethodId, ...params }) => {
      r[id] = {
        id,
        type,
        deliveryMethodId,
        params
      }
      return r
    }, {} as Record<string, basic.Form>)
    : undefined
}

const getObjects = (selectedDates: payments.SelectedData, id: string) => {
  const { date, itemsToReturn } = selectedDates
  const form: payments.CancelRentalBodyObject = { id, object: 'warehouse' }
  if (date) {
    form.date = date
  }
  if (itemsToReturn) {
    form.itemsToReturn = itemsToReturn
  }
  return [form]
}

export default useResignationData