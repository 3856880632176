import { AVAILABLE_LANGUAGES, selectDevice, selectLightTheme, updateLanguage, updateTheme } from 'appSlice'
import { ReactComponent as Language } from 'assets/language.svg'
import { selectIsLoggedIn } from 'containers/Login/loginSlice'
import { ReactComponent as Moon } from 'assets/moon.svg'
import { ReactComponent as Sun } from 'assets/sun.svg'
import { useDispatch, useSelector } from 'react-redux'
import { changeTheme } from 'utils/changeNativeTheme'
import useTranslation from 'hooks/useTranslation'
import NotificationItem from './NotificationItem'
import Switch from 'components/inputs/Switch'
import UserSettings from './UserSettings'
import Item from '../Item'

const Settings = () => {

  const device = useSelector(selectDevice)
  const isLightTheme = useSelector(selectLightTheme)
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const { t, lang } = useTranslation('account')

  const dispatch = useDispatch()

  return (
    <div className='account__settings'>
      <div className='account__header title'>
        <div>{t('settings')}</div>
        <div>{t('subTitle')}</div>
      </div>

      <p>{t('preferences')}</p>
      <div className='account__list'>
        <Item
          icon={isLightTheme ? <Sun /> : <Moon />}
          title={t('theme')}
          desc={t(isLightTheme ? 'bright' : 'dark')}
          right={<Switch
            name='theme'
            checked={isLightTheme}
            changeHandler={() => {
              dispatch(updateTheme(!isLightTheme))
              changeTheme(!isLightTheme)
            }}
          />}
        />

        {!device && <NotificationItem />}

        <Item
          icon={<Language />}
          title={t('language')}
          desc={lang === AVAILABLE_LANGUAGES.pl ? 'polski' : 'english'}
          right={<Switch
            name='language'
            checked={lang === AVAILABLE_LANGUAGES.pl}
            changeHandler={() => {
              dispatch(updateLanguage(lang === AVAILABLE_LANGUAGES.pl ? AVAILABLE_LANGUAGES.en : AVAILABLE_LANGUAGES.pl))
            }}
          />}
        />
      </div>

      {isLoggedIn && <UserSettings />}
    </div>
  )
}

export default Settings