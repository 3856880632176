import { selectWarehouse } from 'containers/Warehouses/warehousesSlice'
import useWarehousesLoading from 'hooks/useWarehousesLoading'
import React, { useEffect, useState, useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import MagNumber from '../../components/MagNumber'
import { isMobile } from '../../utils/isMobile'
import MagResignation from './MagResignation'
import Invoices from '../Account/Invoices'
import { useReduxSelector } from 'store'
import MagDetails from './MagDetails'
import MagHistory from './MagHistory'
import MagAccess from './MagAccess'
import MagStatus from './MagStatus'
import { SECTION_TYPES } from '.'

const mobile = isMobile()
const status = <MagStatus />

const SubMenu: React.FC = () => {
  const [goodbye, setGoodbye] = useState(0)
  const { type, id } = useParams<warehouse.PageParams>()
  const { name, contractId } = useReduxSelector(state => selectWarehouse(state, id))
  useWarehousesLoading(!contractId)

  const listRef = useRef<HTMLDivElement>(null)
  const menuRef = useRef<HTMLDivElement>(null)
  const { goBack, replace } = useHistory()

  useEffect(() => {
    if (mobile) {
      document.body.style.setProperty('overflow', 'hidden')
      return () => {
        document.body.style.removeProperty('overflow')
      }
    }
  }, [])

  const onTouch = (e: React.TouchEvent<HTMLElement>) => {
    const list = listRef.current
    const menu = menuRef.current
    if (!list || !menu) return

    let updateState = true
    const { scrollTop } = list
    const initialY = e.touches[0].clientY

    const touchMove = (e: TouchEvent) => {
      const currentY = e.touches[0].clientY
      const difference = currentY - initialY
      updateState = difference < 80
      if (updateState) setGoodbye(difference > 0 ? Number((difference / 50).toFixed(1)) : 0)
    }

    const touchEnd = () => {
      updateState ? setGoodbye(0) : back()
      menu.removeEventListener('touchend', touchEnd, false)
      menu.removeEventListener('touchmove', touchMove, false)
    }

    if (scrollTop < 1) {
      menu.addEventListener('touchend', touchEnd, false)
      menu.addEventListener('touchmove', touchMove, false)
    }
  }

  const back = () => window.history ? goBack() : replace('/warehouse')

  return (
    <div
      className='warehouse'
      style={{ overflow: 'hidden' }}>

      <div className='warehouse__main'>
        <MagNumber number={name} />
      </div>

      <div
        ref={menuRef}
        style={{ top: 0 }}
        className='menu__main'
        onTouchStart={onTouch}>

        {status}

        <div className='menu__full'>
          {goodbye !== 0 && <div
            className='menu__goodbye'
            style={{
              transform: `scale(${goodbye})`,
              marginTop: `${goodbye - 1}em`,
              padding: `${goodbye}em`
            }}>
            Wróć
          </div>}

          {(() => {
            switch (type) {
              case SECTION_TYPES.history: return <MagHistory id={id} full ref={listRef} />
              case SECTION_TYPES.details: return <MagDetails ref={listRef} />
              case SECTION_TYPES.resignation: return <MagResignation />
              case SECTION_TYPES.invoices: return <div className='account menu__section'>
                <Invoices onlyBody /* mag={id} */ />
              </div>
              default: return <MagAccess full ref={listRef} />
            }
          })()}
        </div>
      </div>
    </div>
  )
}

export default SubMenu