import ActionsButton from "components/ActionsButton";
import { useHistory } from "react-router-dom";
import useTranslation from "hooks/useTranslation";

const Shortcuts = () => {
  let { push } = useHistory()
  const { t } = useTranslation('account')
  return (
    <div className='dashboard__shortcuts'>
      <div className='actionsButton__buttons'>
        <div className='buttons__row center'>
          <ActionsButton
            Icon={'mag-details'}
            text={t('basic')}
            action={() => push('/')} />

          <hr />

          <ActionsButton
            Icon={'terminate'}
            text={t('companies')}
            action={() => push('/')} />

          <hr />

          <ActionsButton
            Icon={'share'}
            text={t('invoices')}
            action={() => push('/')} />
        </div>

        <hr />

        <div className='buttons__row center'>
          <ActionsButton
            Icon={'payments'}
            text={t('payments')}
            action={() => push('/')} />

          <hr />

          <ActionsButton
            Icon={'insurance'}
            text={t('settings')}
            action={() => push('/')} />

          <hr />

          <ActionsButton
            Icon={'insurance'}
            text={t('settings')}
            action={() => push('/')} />
        </div>
      </div>
    </div>
  )
}

export default Shortcuts
