import { ACCOUNT_TYPE, selectUserData, updateValue } from '../accountSlice'
import { finalExp, initialExp } from 'utils/validation'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Submit from 'components/inputs/Submit'
import Input from 'components/inputs/Input'
import Popup from 'components/popups/Popup'
import { STATUS } from 'utils/constants'
import { POST } from 'rest/request'
import { user } from 'rest/urls'
import { useState } from 'react'

const type = ACCOUNT_TYPE.basic

const EditMailPopup = ({ isAdditionalMail = false }) => {

  const { data = {} } = useSelector(state => selectUserData(state, type))
  const [status, setStatus] = useState(STATUS.idle)
  const [warning, setWarning] = useState(false)
  const [mail, setMail] = useState('')

  const { goBack } = useHistory()
  const dispatch = useDispatch()

  const updateVal = (name, value) => dispatch(updateValue({ type, name, value }))

  const submit = async () => {
    const body = isAdditionalMail ? { additional_email: mail } : { email: mail }
    setStatus(STATUS.pending)

    try {
      const response = await POST(`${user}/email`, { body })
      if (response) {
        updateVal('data', { ...data, ...body })
        goBack()
      } else throw new Error('Wrong response')

    } catch (error) {
      setWarning(true)
      setStatus(STATUS.failed)
    }
  }

  const validateMail = () => {
    if (mail.match(finalExp.mail)) submit()
    else setWarning(true)
  }

  return (
    <Popup>
      <div className='popup__verification'>
        <div className='popup__title'>
          <div>{isAdditionalMail ? 'Zmiana dodatkowego adresu email' : 'Zmiana adresu email'}</div>
        </div>

        <div className='popup__form'>
          <Input
            required
            value={mail}
            name='mail'
            label='Adres e-mail'
            minLength='4'
            maxLength='100'
            warning={warning}
            placeholder='Wpisz e-mail'
            disabled={status === STATUS.pending}
            blurHandler={() => setWarning(mail.match(finalExp.mail) ? false : 'niewłaściwy e-mail')}
            changeHandler={e => {
              const { value } = e.target
              if (!value || value.match(initialExp.mail)) setMail(value)
            }} />

          <Submit
            text={(isAdditionalMail ? data?.additional_email : data?.email) ? 'Zmień' : 'Dodaj'}
            block={status === STATUS.pending}
            isLoading={status === STATUS.pending}
            action={validateMail} />
        </div>
      </div>
    </Popup>
  )
}

export default EditMailPopup