import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { STATUS } from 'utils/constants'
import { checkPrice } from 'rest/urls'
import { POST } from 'rest/request'

export const fetchCalendar = createAsyncThunk('calendar/fetchCalendar', async ({ userID = '', placeID, hash }) => {
  const body = { userID, objects: [{ placeID, hash, object: 'Warehouse' }] }
  const response = await POST(checkPrice, { body })
  return response.objects ? { hash, ...response.objects[hash] } : { hash, ...response[hash] }
})

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState: {
    hash: '',
    priceList: [],
    termiateDays: 0,
    status: STATUS.idle,
    defaultAttributes: [],
    availableAttributes: [],
    availableAttributesAmount: {
      insurance: 0,
      addons: 0
    },
  },
  reducers: {
    selectAddon: (state, action) => {
      state.selectedAddons.push(action.payload)
    },
    selectInsurance: (state, action) => {
      state.selectedInsurance.push(action.payload)
    },
    deselectAddon: (state, action) => {
      state.selectedAddons = state.selectedAddons.filter(item => item !== action.payload)
    },
    deselectInsurance: (state, action) => {
      state.selectedInsurance = state.selectedInsurance.filter(item => item !== action.payload)
    },
  },
  extraReducers: {
    [fetchCalendar.fulfilled]: (state, action) => {
      const { pricelists, pricelistId, rental_min_days, add_attrs, default_attrs, ...rest } = action.payload
      const priceList = pricelists[pricelistId]?.slider

      if (priceList) {
        const lastElement = priceList.at(-1)
        priceList[priceList.length - 1] = { ...lastElement, v: '∞', m: -1, r: 100 }
      }

      return {
        ...rest,
        priceList,
        pricelistId,
        status: STATUS.succeed,
        termiateDays: rental_min_days,
        availableAttributes: add_attrs,
        defaultAttributes: default_attrs,
        availableAttributesAmount: add_attrs.reduce((r, { isInsurance }) => {
          r[isInsurance ? 'insurance' : 'addons'] += 1
          return r
        }, { insurance: 0, addons: 0 })
      }
    },
    [fetchCalendar.rejected]: (state, action) => {
      state.status = STATUS.failed
    },
  }
})

export const selectCalendar = state => state.calendar

export default calendarSlice.reducer
