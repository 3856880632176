import useTranslation from '../../../hooks/useTranslation'
import ActionsButton from 'components/ActionsButton'
import { ACCOUNT_TYPE } from '../accountSlice'
import { useHistory } from 'react-router-dom'


const Actions = () => {

  const { t } = useTranslation('account')
  const { push } = useHistory()

  const goTo = route => push(`/account/${route}`)

  return (
    <div className='actionsButton__buttons'>
      <div className='buttons__row center'>
        <ActionsButton
          Icon={'mag-details'}
          text={t('basic')}
          action={() => goTo(ACCOUNT_TYPE.basic)} />

        <hr />

        <ActionsButton
          Icon={'terminate'}
          text={t('companies')}
          action={() => goTo(ACCOUNT_TYPE.company)} />

        <hr />

        <ActionsButton
          Icon={'share'}
          text={t('invoices')}
          action={() => goTo(ACCOUNT_TYPE.invoices)} />
      </div>

      <hr />

      <div className='buttons__row center'>
        <ActionsButton
          Icon={'payments'}
          text={t('payments')}
          action={() => goTo(ACCOUNT_TYPE.payments)} />

        <hr />

        <ActionsButton
          Icon={'insurance'}
          text={t('settings')}
          action={() => goTo(ACCOUNT_TYPE.settings)} />
      </div>
    </div>
  )
}

export default Actions