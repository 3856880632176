import useTranslation from '../../../hooks/useTranslation'
import Popup from '../../../components/popups/Popup'
import CompaniesElement from './CompaniesElement'
import { STATUS } from '../../../utils/constants'
import Error from '../../../components/Error'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectForm } from '../formSlice'

const CompaniesPopup = ({ chnageNip, selectData }) => {
  const { companyData: data = '', companyStatus: status = STATUS.idle } = useSelector(selectForm)

  const { t } = useTranslation('form')
  const { goBack } = useHistory()

  return (
    <Popup isLine={false}>

      <div className='popup__companies'>
        <h2>{t('chooseCompany')}</h2>

        <div className='center'>
          <div className='companies__main'>
            {status === STATUS.failed ? <Error staticPos />
              : <div className='companies__list list'>
                {status === STATUS.pending ? [1, 2].map(e => <CompaniesElement key={e} />)
                  : Array.isArray(data) ? data.map((e, i) => <CompaniesElement
                    key={i}
                    data={e}
                    showNip={false}
                    selectData={d => {
                      selectData(d)
                      goBack()
                    }} />)
                    : <Error
                      staticPos
                      text={data} />}
              </div>}

            <div
              className='companies__button'
              onClick={() => {
                chnageNip()
                goBack()
              }}>
              <span className='--termina'>{t('change')} {t('tin')}</span>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default CompaniesPopup