import 'components/Range/style.scss'
import { useRef } from 'react'

const Range = ({ ranges, range, updateRange }) => {
  const lineRef = useRef(null)

  const handleClick = e => {
    if (!lineRef.current) return

    const x = (e.type === 'touchmove' ? e.touches[0].clientX : e.clientX) - lineRef.current.offsetLeft
    const percent = (x * 100) / lineRef.current.clientWidth
    const closest = ranges.reduce((prev, curr) => Math.abs(curr.r - percent) < Math.abs(prev.r - percent) ? curr : prev)

    if (closest && (closest.m !== range.m || closest.d !== range.d)) updateRange(closest)
  }

  return (
    <div className='range'>
      <div
        ref={lineRef}
        className='range__input'
        onClick={handleClick}
        onTouchStart={e => {
          const initialX = e.touches[0].clientX
          const initialY = e.touches[0].clientY

          const touchend = () => {
            window.removeEventListener('touchmove', touchmove)
            window.removeEventListener('touchend', touchend)
          }

          const touchmove = e => {
            const currentX = e.touches[0].clientX
            const currentY = e.touches[0].clientY
            const y = Math.abs(initialY - currentY)
            const x = Math.abs(initialX - currentX)

            y < x && y < 40 ? handleClick(e) : touchend()
          }

          window.addEventListener('touchmove', touchmove)
          window.addEventListener('touchend', touchend)
        }}>

        <div className='range__line'>

          {ranges.map(({ m, d, v, r }, idx) => {
            const isSelected = m === range.m && d === range.d
            const isActive = range.m >= 0 ? range.m === 0 ? m === 0 && range.d >= d : m >= 0 && range.m >= m : true
            let isInRange = false

            if (ranges[idx - 1] && !isSelected) {
              if (range.m === 0) isInRange = ranges[idx - 1].d < range.d && range.d < (m === 1 ? 32 : d)
              else isInRange = (ranges[idx - 1].m < range.m || (ranges[idx - 1].m <= range.m && range.d !== 0)) && range.m < m
            }

            const additionalScope = (() => {
              if (!isInRange) return false

              const wanted = m === 0 ? range.d : (range.m * 30) + range.d
              const min = m ? ranges[idx - 1].m * 30 : ranges[idx - 1].d
              const max = d ? d : m * 30

              return (
                <div
                  className='front__line'
                  style={{ width: `${(wanted * 100) / (max + min)}%` }}>
                  <div className='range__thumb --selected' />
                </div>
              )
            })()

            return <div
              key={v}
              className={`range__part ${isActive ? '--active' : ''}`}
              style={{ width: `${ranges[idx - 1] ? r - ranges[idx - 1].r : r}%` }}>
              {isInRange && additionalScope}
              <div className={`range__thumb ${isSelected ? '--selected' : ''}`} />
              <span
                className={isSelected ? '--selected' : ''}
                style={v === '∞' ? { fontSize: '0.75em' } : {}}>{v}</span>
            </div>
          })}
        </div>
      </div>
    </div>
  )
}

export default Range