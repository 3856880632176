import React, { useEffect, useRef, useState } from 'react'
import { isMobile } from 'utils/isMobile'
import './style.scss'

interface Option<T> {
  value: T
  name: string
}

interface SelectProps<T> {
  name?: string
  disabled?: boolean
  secondaryTheme?: boolean
  value?: T
  label: string
  setValue: (value: T) => void
  options: Option<T>[]
}

const mobile = isMobile()

const Select = <T = string>({ name, disabled = false, secondaryTheme = false, value, label, setValue, options }: SelectProps<T>) => {
  const [expanded, expand] = useState(false)
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const container = containerRef.current
    if (container && expanded) {
      const unfocus = (event: Event) => {
        if (!container.contains(event.target as Node)) {
          expand(false)
        }
      }
      const root = document.querySelector('#root')
      root?.addEventListener('click', unfocus)
      return () => {
        root?.removeEventListener('click', unfocus)
      }
    }
  }, [expanded])

  return (
    <div
      ref={containerRef}
      className={`select__container${expanded ? ' --expanded' : ''}${mobile ? ' --mobile' : ''}${secondaryTheme ? ' --secTheme' : ''}`}>
      <div className='input__default'>
        <label className='input__label' htmlFor={name}>
          <span>{label}</span>
        </label>

        <div className='input__main'>
          <div
            id={name}
            className='select'
            onClick={() => {
              if (disabled) return
              expand(e => !e)
            }}>
            <span>{value !== undefined ? (options.find(o => o.value === value)?.name || (value as string)) : 'Wybierz'}</span>
            <i className={`icon icon--chevron-${expanded ? 'up' : 'down'}`} />
          </div>
        </div>
      </div>

      <div className='select__options'>
        {options.map(({ value, name }) => <div
          key={`${value}`}
          className='select__option'
          onClick={() => {
            expand(false)
            setValue(value)
          }}>
          {name}
        </div>)}
      </div>
    </div>
  )
}

export default Select