import ArrowBtn from 'components/buttons/ArrowBtn'
import { useHistory } from 'react-router-dom'
import Popup from 'components/popups/Popup'

const AutomationPopup = ({ cancelAction, body }) => {
  const { goBack } = useHistory()

  return (
    <Popup close={cancelAction || goBack}>
      <div className='popup__title'>
        <div>Co to jest za plakietka</div>
      </div>
      <div className='popup__infoBlocks'>
        <div className={`lockItem lockItem--info`}>
          <div><i className='icon icon--byapp'></i>{body.title} </div>
          <div dangerouslySetInnerHTML={{ __html: body.howTo }} ></div>
        </div>

        <ArrowBtn
          enlarged
          showArrow={false}
          text={'Rozumiem'}
          action={cancelAction || goBack} />
      </div>
    </Popup>
  )
}

export default AutomationPopup