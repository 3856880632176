import useTranslation from '../../hooks/useTranslation'
import BackBtn from '../../components/buttons/BackBtn'
import Popup from '../../components/popups/Popup'
import { useHistory } from 'react-router-dom'
import { useRef } from 'react'

const OptionsPopup = ({ options, select }) => {
  const { t } = useTranslation('payment')
  const { goBack } = useHistory()

  const listRef = useRef()

  return (
    <Popup>
      <div className='popup__payment'>
        <div className='popup__title'>
          <div>{t('chooseBank')}</div>
        </div>

        <div
          ref={listRef}
          className='payment__list'
          onTouchStart={e => {
            if (listRef.current?.scrollTop !== 0) e.stopPropagation()
          }}>

          {options?.map(({ id = 0, name = '', icon = '' }) => <div
            key={id}
            className='list__item'
            onClick={() => {
              select(id)
              goBack()
            }}>
            <img src={icon} alt={name} />
          </div>)}
        </div>

        <BackBtn />
      </div>
    </Popup>
  )
}

export default OptionsPopup