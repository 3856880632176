export const api = process.env.REACT_APP_API || `https://api.mmapp.pl/v1/`
const mobile = `${api}clients/mobile/`

export const config = `${api}config`
export const categories = `${api}categories`
export const checkPrice = `${api}checkPrice`
export const locations = `${api}locations`
export const mapFilters = `${api}mapfilters`
export const mapLications = `${api}maplocations`
export const additional = `${api}additional`
export const insurance = `${api}insurance`
export const locationRequest = `${api}locationrequest`
export const webpushdevices = `${api}webpushdevices`

export const passwordForgot = `${api}password/forgot`
export const passwordReset = `${api}password/reset`
export const preregister = `${api}preregister`
export const refresh = `${mobile}refresh`
export const register = `${api}register`
export const login = `${mobile}login`
export const logout = `${api}logout`
export const cart = `${api}cart`
export const openingmethodoptions = `${api}openingmethodoptions`
export const contracts = `${api}contracts/getlockinfo`
export const warehousesAction = `${api}warehouses/action`

const payment = `${api}payment/`
export const paymentSettlement = `${payment}settlement`
export const paymentMethod = `${payment}methods`
export const paymentStatus = `${payment}status`

export const verify = (type = 'device') => `${api}${type}/verify`

export const removeDevice = `${api}userdevices/`
export const removePaymentMethods = `${api}userpaymentmethods/`

export const checkAddress = `${api}checkaddress`
export const checkAvailability = placeID => `${api}places/${placeID}/checkavailability`
export const delivery_points = `${api}couriers/delivery_points`

export const webauthn = `https://api.mmapp.pl/webauthn/`
export const authProtected = `${api}protected`

export const user = `${api}user`
export const userInfo = `${user}/info`
export const devices = `${user}/devices`
export const userConfig = `${user}/config`
export const invoices = `${user}/invoices`
export const companies = `${user}/companies`
export const newsmscode = `${user}/newsmscode`
export const newmailcode = `${user}/newemailcode`
export const paymentmethods = `${user}/paymentmethods`
export const warehouses = (userId = '') => `${user}s/${userId}/warehouses/rentedreserved`

export const updateCompanies = `${api}companies`
export const companiesFields = `${api}getfields/companies`


// API from do_stoliika
const oldApi = `https://api.dostolika.pl/api/`
export const findCompany = `${oldApi}company/find/`
export const banklist = `${oldApi}functions/banklist`