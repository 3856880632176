import useSummaryData from './hooks/useSummaryData'
import { displayPrice } from 'utils/string'
import { Fragment } from 'react'
import './style.scss'


import Checkbox from 'components/inputs/Checkbox'
import { selectIsExpandedBasket, updateExpandedBasket } from 'appSlice'
import { useReduxDispatch, useReduxSelector } from 'store'

const Summary: React.FC = () => {
  const isExpandedBasket = useReduxSelector(selectIsExpandedBasket)
  const reduxDispatch = useReduxDispatch()

  const data = useSummaryData()

  return (
    <>
      <div className='title'>
        <div className='group'>
          <span>Podsumowanie</span>

          <Checkbox
            name='isExpandedBasket'
            checked={isExpandedBasket}
            label='Wysoki koszyk'
            changeHandler={() => {
              reduxDispatch(updateExpandedBasket(!isExpandedBasket))
            }}
          />
        </div>
      </div>

      <div className='orderInfo__summary'>
        <div className='orderInfo__summary__header'>Opłaty na teraz</div>
        <div className='orderInfo__summary__content'>
          {data?.now?.list?.map(({ text, price, depositAmount }, idx) => {
            return <Fragment key={idx}>
              <div className='orderInfo__summary__content__item'>
                <span>{text}:</span>
                <span>{displayPrice(depositAmount ? (Number(price) - Number(depositAmount)) : price)}</span>
              </div>
              {depositAmount && <div className='orderInfo__summary__content__item'>
                <span>Kaucja:</span>
                <span>{displayPrice(depositAmount)}</span>
              </div>}
            </Fragment>
          })}
        </div>
        <div className='orderInfo__summary__sum'>
          <span>Suma:</span>
          <span>{displayPrice(data?.now?.sum || 0)}</span>
        </div>
      </div>

      <div className='orderInfo__summary'>
        <div className='orderInfo__summary__header'>Harmonogram płatności</div>
        <div className='orderInfo__summary__schedule'>
          {data?.monthly?.map(({ start, end, grossPrice }, idx) => <div
            key={idx}
            className='orderInfo__summary__schedule__row group'>
            <div className='orderInfo__summary__schedule__content'>
              <div>{start} - {end}:</div>
              <div>Płatne 14 dnia każdego miesiąca</div>
            </div>
            <div className='orderInfo__summary__schedule__price'>{displayPrice(grossPrice)}</div>
          </div>)}
        </div>
      </div>
    </>
  )
}

export default Summary