const Sygnet = ({ color: colorName }) => {
  const getMarkerColors = color => {
    switch (color) {
      case 'yellow': return '#ff9100'
      case 'red': return '#eb003d'
      case 'gray': return '#dedee2'
      default: return '#3d59fe'
    }
  }

  const color = getMarkerColors(colorName)

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 47 47">
      <g id="Group_14397" data-name="Group 14397" transform="translate(1.5, 1.5)">
        <circle id="Ellipse_417" data-name="Ellipse 417" cx="22" cy="22" r="22" fill="none" stroke={color} strokeWidth='1.5' />
        <g id="sygnet" transform="translate(13.75 13.75)">
          <path id="Frame" d="M505.927,185a1.047,1.047,0,0,0-1.047,1.047v10.382a1.047,1.047,0,0,0,1.047,1.047h.507v-.857h-.542a.191.191,0,0,1-.19-.19v-10.38a.191.191,0,0,1,.19-.19h1.4v-.314a2.9,2.9,0,0,1,.053-.543Zm14.881,0h-1.413a2.265,2.265,0,0,1,.031.355v.5h1.416a.19.19,0,0,1,.19.19v10.38a.19.19,0,0,1-.19.19h-.553v.857h.518a1.047,1.047,0,0,0,1.047-1.047V186.047A1.047,1.047,0,0,0,520.807,185Z" transform="translate(-504.88 -183.333)" fill={color} />
          <path id="M" d="M586.841,165.551a.108.108,0,0,1-.03,0h-2.122a.111.111,0,0,1-.125-.125v-12.9h-1.495l0,10.618v2.338c0,.049-.041.073-.125.073h-2.122c-.083,0-.125-.024-.125-.073V152.524h-1.5v12.9a.11.11,0,0,1-.095.125.109.109,0,0,1-.031,0h-2.121a.111.111,0,0,1-.125-.094.124.124,0,0,1,0-.03v-13.12a2.487,2.487,0,0,1,.088-.639A2.1,2.1,0,0,1,578.99,150h5.648a2.194,2.194,0,0,1,2.3,2.149v13.277A.11.11,0,0,1,586.841,165.551Z" transform="translate(-573.4 -150)" fill={color} />
        </g>
      </g>
    </svg>
  )
}

export default Sygnet