import useWarehousesLoading from 'hooks/useWarehousesLoading'
import { selectWarehouses } from './warehousesSlice'
import { useHistory } from 'react-router-dom'
import { useReduxSelector } from 'store'
import Loading from 'components/Loading'
import SlideShow from './SlideShow'
import React from 'react'
import './style.scss'

const Warehouses: React.FC = () => {
  const isLoading = useWarehousesLoading()
  const warehouses = useReduxSelector(selectWarehouses)
  const { push, location: { pathname } } = useHistory()

  return (
    <div className='warehouses'>
      <div className='title'>
        <div>Moje magazyny</div>
        <div>Wybierz magazyn do zarządzania</div>
      </div>

      <div className='warehouses__slides center'>
        <div>
          {(warehouses?.length > 0 || !isLoading)
            ? <SlideShow
              autoplay
              showInfo
              action={(id) => {
                push(`${pathname}/${id}`)
              }} />
            : <Loading middleOfPage />}
        </div>
      </div>
    </div>
  )
}

export default Warehouses