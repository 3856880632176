import { ReactComponent as Mark } from 'assets/checked.svg'
import { selectUser } from 'containers/Login/loginSlice'
import useTranslation from 'hooks/useTranslation'
import { displayPrice } from 'utils/string'
import { useSelector } from 'react-redux'
import Option from 'components/Option'

const Status = () => {
  const { unpaidInvoicesAmount, unpaid_invoices } = useSelector(selectUser)
  const { t } = useTranslation('account')

  const amount = Number(unpaidInvoicesAmount)

  return (
    <div className='account__status'>
      {amount
        ? <Option
          selected
          buttonText={t('pay')}
          title={t(unpaid_invoices && unpaid_invoices?.length > 0 ? 'payTheInvoices' : 'payTheInvoice')}
          desc={`${t('toPay')} ${displayPrice(amount)}`}
          action={() => {
            alert('not ready :/')
          }}
        />
        : <Option
          selected
          secondary
          title={t('everythingsOkay')}
          desc={t('paidInvoices')}
          icon={<Mark />}
        />}
    </div>
  )
}

export default Status