import { ACCOUNT_TYPE, selectUserData, updateValue } from '../accountSlice'
import { finalExp, initialExp } from 'utils/validation'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Submit from 'components/inputs/Submit'
import Input from 'components/inputs/Input'
import Popup from 'components/popups/Popup'
import { STATUS } from 'utils/constants'
import { POST } from 'rest/request'
import { user } from 'rest/urls'
import { useState } from 'react'

const type = ACCOUNT_TYPE.basic

const EditPhonePopup = ({ isAdditionalPhone = false }) => {

  const { data = {} } = useSelector(state => selectUserData(state, type))
  const [status, setStatus] = useState(STATUS.idle)
  const [warning, setWarning] = useState(false)
  const [phone, setPhone] = useState('')

  const { goBack } = useHistory()
  const dispatch = useDispatch()

  const updateVal = (name, value) => dispatch(updateValue({ type, name, value }))

  const submit = async () => {
    const body = isAdditionalPhone ? { additional_phone: phone } : { phone }
    setStatus(STATUS.pending)

    try {
      const response = await POST(`${user}/phone`, { body })
      if (response) {
        updateVal('data', { ...data, ...body })
        goBack()
      } else throw new Error('Wrong response')

    } catch (error) {
      setWarning(true)
      setStatus(STATUS.failed)
    }
  }

  const validatePhone = () => {
    if (phone.match(finalExp.phone)) submit()
    else setWarning(true)
  }

  return (
    <Popup>
      <div className='popup__verification'>
        <div className='popup__title'>
          <div>{isAdditionalPhone ? 'Zmiana dodatkowego numeru telefonu' : 'Zmiana numeru telefonu'}</div>
        </div>

        <div className='popup__form'>
          <Input
            required
            name='phone'
            type='tel'
            minLength='9'
            maxLength='12'
            value={phone}
            warning={warning}
            label='Numer telefonu'
            placeholder='Wpisz numer telefonu'
            disabled={status === STATUS.pending}
            blurHandler={() => setWarning(phone.match(finalExp.phone) ? false : 'niewłaściwy numer')}
            changeHandler={e => {
              const { value } = e.target
              if (!value || value.match(initialExp.phone)) setPhone(value)
            }} />

          <Submit
            text={(isAdditionalPhone ? data?.additional_phone : data?.phone) ? 'Zmień' : 'Dodaj'}
            block={phone?.length < 9 || status === STATUS.pending}
            isLoading={status === STATUS.pending}
            action={validatePhone} />
        </div>
      </div>
    </Popup>
  )
}

export default EditPhonePopup