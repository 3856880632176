import { addItemToBasket, removeItemFromBasket, selectBasketDeliveries, selectBasketItem, selectBasketList, selectBasketToPay, updateBasketItem, updateDelivery } from 'containers/Basket/basketSlice'
import { selectCalendar } from 'containers/OrderDetails/Calendar/calendarSlice'
import useOpeningMethodData from '../hooks/useOpeningMethodData'
import DeliveryPopup from 'components/popups/DeliveryPopup'
import { useDispatch, useSelector } from 'react-redux'
import ArrowBtn from 'components/buttons/ArrowBtn'
import useTranslation from 'hooks/useTranslation'
import { useHistory } from 'react-router-dom'
import { displayPrice } from 'utils/string'
import Popup from 'components/popups/Popup'
import Loading from 'components/Loading'
import Error from 'components/Error'
import { useState } from 'react'

const TEXT = 'Tym sposobem możesz wygodnie otwierać wszystkie bramy i magazyny'

const RfidPopup = ({ method, addToGate = false, cancelAction }) => {
  const list = useSelector(selectBasketList)
  const toPay = useSelector(selectBasketToPay)
  const selectedRefids = getAllRfidsFromTheBasket(list)
  const deliveries = useSelector(selectBasketDeliveries)
  const selectedBasketItem = useSelector(selectBasketItem)
  const [productDetails, setProductDetails] = useState(null)
  const { placeName, placeAddress } = useSelector(selectCalendar)
  const recentlySelectedDeliveries = useSelector(selectBasketDeliveries)
  const { isLoading, padlockInfo } = useOpeningMethodData(method.id, setProductDetails)
  const [isShopVisible, setShopVisible] = useState(selectedRefids.length === 0)

  const { t } = useTranslation('delivery')
  const { goBack } = useHistory()
  const dispatch = useDispatch()

  const generateRelatedElement = () => {
    return addToGate
      ? { object: 'Place', name: placeName, id: selectedBasketItem.placeId }
      : { object: selectedBasketItem.object || 'Warehouse', id: selectedBasketItem.id, name: selectedBasketItem.name, cartId: selectedBasketItem.identifier }
  }

  if (isShopVisible && productDetails) {
    return (
      <DeliveryPopup
        additionalText={TEXT}
        placeAddress={placeAddress}
        popupName='Dostęp zbliżeniowy'
        delivery={productDetails?.delivery}
        isEditableOnStartup={!productDetails?.cartId}
        productName={productDetails?.name || method?.name}
        price={productDetails?.grossPrice || method?.grossPrice}
        deliveryMethods={productDetails?.deliveryMethods || method.deliveryMethods}
        recentlySelectedDeliveries={recentlySelectedDeliveries}
        setRecentlySelectedDeliveries={(getState) => {
          const state = typeof getState === 'function' ? getState(recentlySelectedDeliveries) : getState
          dispatch(updateDelivery(state))
        }}
        cancelAction={() => {
          setProductDetails(null)
        }}
        callback={values => {
          // update
          if (productDetails?.cartId) {
            dispatch(updateBasketItem({ identifier: productDetails.cartId, values }))

            // add new
          } else {
            const value = {
              id: method.id,
              placeId: selectedBasketItem.placeId,
              name: productDetails.name || method.name,
              openingMethodKey: method.openingMethodKey,
              depositAmount: method.depositAmount,
              related: [generateRelatedElement()],
              object: productDetails.object,
              objectId: productDetails.id,
              ...values
            }
            dispatch(addItemToBasket({ type: 'rentAddon', ...value }))
          }
          setProductDetails(null)
          setShopVisible(false)
        }}
      />
    )
  }

  return (
    <Popup close={cancelAction || goBack} darkBg={true}>
      {isShopVisible
        ? <>
          <div className='popup__title'>
            <div>Dostęp zbliżeniowy</div>
            <i
              className='icon icon--back s32'
              onClick={() => {
                setShopVisible(false)
              }} />
          </div>
          <div className='popup__rfid'>
            <div className='popup__rfid__info'>{TEXT}</div>
            <div className='popup__rfid__items'>
              <h2>Wybierz rodzaj karty</h2>
              {isLoading
                ? <Loading />
                : padlockInfo
                  ? padlockInfo.map((data) => {
                    const { id, name, grossPrice, depositAmount } = data
                    return <div
                      key={id}
                      className='popup__rfid__item'
                      onClick={() => {
                        setProductDetails(data)
                      }} >
                      <div className='popup__rfid__item__left'>
                        <h3>{name}</h3>
                        {depositAmount && <div>Kaucja: {displayPrice(depositAmount)}</div>}
                        {grossPrice && <div>Cena: {displayPrice(grossPrice)}</div>}
                      </div>
                      <div className='popup__rfid__item__right'>
                        <div className='checkCircle'>
                          <i className='icon icon--check'></i>
                        </div>
                      </div>
                    </div>
                  })
                  : <Error staticPos />}
            </div>
          </div>
        </> : <>
          <div className='popup__title'>
            <div>Dostęp zbliżeniowy</div>
          </div>
          <div className='popup__rfid'>
            <div className='popup__rfid__items'>
              <h2>Wybierz z posiadanych kart</h2>
              {selectedRefids.map(({ identifier, objectId, name, related, deliveryId }) => {
                const isSelected = related.some(r => addToGate ? r.id === selectedBasketItem.placeId : r.cartId === selectedBasketItem.identifier)
                const delivery = (deliveryId && deliveries) ? deliveries.find(d => d.id === deliveryId) : undefined
                const toPayData = (toPay && identifier) ? toPay[identifier] : undefined
                return <div
                  key={identifier}
                  className={`popup__rfid__item${isSelected ? ' checked' : ''}`}
                  onClick={() => {
                    dispatch(updateBasketItem({
                      identifier,
                      values: {
                        related: isSelected
                          ? related?.filter(r => addToGate ? r.id !== selectedBasketItem.placeId : r.cartId !== selectedBasketItem.identifier)
                          : [...(related || []), generateRelatedElement()]
                      }
                    }))
                  }}>
                  <div className='popup__rfid__item__marker'>Zamówiono</div>
                  <div className='popup__rfid__item__left'>
                    <h3>{name}{(toPayData?.to_pay?.depositAmount && toPayData?.to_pay?.price_sum) ? ` (${displayPrice(Number(toPayData.to_pay.price_sum) - Number(toPayData.to_pay.depositAmount))})` : ''}</h3>
                    {toPayData?.to_pay?.depositAmount && <div>Kaucja: {displayPrice(toPayData?.to_pay?.depositAmount)}</div>}
                    {toPayData?.to_pay?.price_sum && <div>Do zapłaty: {displayPrice(toPayData?.to_pay?.price_sum)}</div>}
                    {delivery?.type && <div>Dostawa: {t(delivery?.type)}</div>}
                  </div>
                  <div className='popup__rfid__item__right'>
                    <i
                      className='icon icon--trash'
                      onClick={e => {
                        e.stopPropagation()
                        dispatch(removeItemFromBasket({ removeIdentifiers: [identifier] }))
                      }} />
                    <i
                      className='icon icon--rename'
                      onClick={e => {
                        e.stopPropagation()
                        const selectedRfid = padlockInfo?.find(p => p.id === objectId)
                        if (selectedRfid) {
                          setProductDetails({
                            ...selectedRfid,
                            cartId: identifier,
                            delivery
                          })
                        }
                      }} />
                    <div className='checkCircle'>
                      <i className='icon icon--check'></i>
                    </div>
                  </div>
                </div>
              })}
            </div>

            <button
              className='button --coupon'
              onClick={() => setShopVisible(true)}>+ Kup RFID</button>
          </div>
        </>}

      <ArrowBtn
        enlarged
        text='Gotowe'
        showArrow={false}
        action={goBack}
      />
    </Popup>
  )
}

const getAllRfidsFromTheBasket = (basketList) => {
  const list = []
  for (const item of basketList) {
    if (item?.openingMethodKey === 'rfid') {
      list.push(item)
    }
  }
  return list
}

export default RfidPopup