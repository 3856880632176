import React from 'react'

interface ItemProps {
  icon: JSX.Element | string
  title: string
  desc: string
  extra?: string
}

const Item: React.FC<ItemProps> = ({ icon, title, desc, extra }) => {
  return (
    <div className='list__item'>
      <div className='item__icon center'>
        {typeof icon === 'string' ? <i className={`icon icon--${icon}`} /> : icon}
      </div>

      <div className='item__desc'>
        <div>{title}</div>
        <div>{desc}</div>
      </div>

      {extra && <div className='item__extra center'>{extra}</div>}
    </div>
  )
}

export default Item