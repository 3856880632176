import useGroupedBasket from 'hooks/useGroupedBasket'
import BasketSummaryItem from './BasketSummaryItem'

const BasketSummary = () => {
  const list = useGroupedBasket()
  return (
    <div className='basket__summary'>
      {list.map((item) => <BasketSummaryItem
        key={item.index}
        {...item}
      />)}
    </div>
  )
}

export default BasketSummary