import { getWarehouses } from 'containers/Warehouses/warehousesSlice'
import { selectUserId } from 'containers/Login/loginSlice'
import { useReduxSelector, useReduxDispatch } from 'store'
import { useState, useEffect } from 'react'

const useWarehousesLoading = (reload = true) => {
  const [isLoading, setLoading] = useState(reload)
  const clientId = useReduxSelector(selectUserId)
  const dispatch = useReduxDispatch()

  useEffect(() => {
    if (clientId && reload) {
      dispatch(getWarehouses(clientId)).then(() => {
        setLoading(false)
      })
    }
  }, [clientId, dispatch, reload])

  return isLoading
}

export default useWarehousesLoading