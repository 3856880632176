import usePaymentProcessing from './hooks/usePaymentProcessing'
import OptionsPopup from 'containers/Payment/OptionsPopup'
import usePaymentMethods from './hooks/usePaymentMethods'
import { ReactComponent as GPay } from 'assets/GPay.svg'
import BlikPopup from 'containers/Payment/BlikPopup'
import { useState, useEffect, useRef } from 'react'
import ArrowBtn from 'components/buttons/ArrowBtn'
import useTranslation from 'hooks/useTranslation'
import { LOCATION_STATES } from 'utils/constants'
import BackBtn from 'components/buttons/BackBtn'
import usePopupState from 'hooks/usePopupState'
import { useHistory } from 'react-router-dom'
import { displayPrice } from 'utils/string'
import Loading from 'components/Loading'
import Option from 'components/Option'
import blikSrc from 'assets/blik.png'
import Error from 'components/Error'

const gPay = <GPay />
const blik = <img
  src={blikSrc}
  alt='blik'
/>

const Payment = ({ getInvoices, grossPrice, summaryTop, summaryBottom, close }) => {
  const bottomRef = useRef(null)

  const { popup, pathname } = usePopupState()
  const [selected, select] = useState({ methodId: '' })
  const { isLoading, paymentMethods } = usePaymentMethods()
  const { isProcessing, purchase, generateBody } = usePaymentProcessing(selected, getInvoices, grossPrice, paymentMethods)

  const { t } = useTranslation('payment')
  const { push } = useHistory()

  useEffect(() => {
    if (selected.methodId) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [selected.methodId])

  return (
    <>
      {popup && <div>
        {popup.type === 'banks'
          ? <OptionsPopup
            options={paymentMethods?.find(p => p.id === popup.methodId)?.options}
            select={id => {
              select({ methodId: popup.methodId, optionId: id })
            }}
          />
          : popup.type === 'blik'
            ? <BlikPopup
              getBody={generateBody}
              callback={close}
            />
            : null}
      </div>}

      <div className='payment --secondary'>
        <div className='title'>
          <div>{t('payment')}</div>
          <div>{t('chooseOneOfOptions')}</div>
        </div>

        <div className='payment__main center'>
          <div>
            <div className='payment__summary center'>
              <div>
                <div className='summary__item --primary'>
                  <div>{t('toPay')}</div>
                  <div>{displayPrice(grossPrice)}</div>
                </div>
              </div>

              <div>
                <div className='summary__item'>
                  <div>{summaryTop}</div>
                  <div>{summaryBottom}</div>
                </div>
              </div>
            </div>

            <p>{t('methodsAvailable')}</p>
            <div
              className='payment__methods'
              style={isProcessing ? { opacity: 0.5 } : undefined}>
              {paymentMethods
                ? paymentMethods.map(({ id, key, name, description, isBlocked, options }) => {
                  if (key === 'applepay' && !window.ApplePaySession) return null
                  const isSelected = selected.methodId === id
                  const option = (options && isSelected)
                    ? options.find(o => o.id === selected.optionId)
                    : undefined
                  const icon = option ? <img src={option.icon} alt='bankIcon' />
                    : key === 'blik' ? blik
                      : key === 'gpay' ? gPay
                        : undefined

                  return <Option
                    key={id}
                    icon={icon}
                    title={name}
                    selected={isSelected}
                    desc={option ? option.name : description}
                    changeIconColor={false}
                    action={() => {
                      if (isBlocked || isProcessing) return
                      if (options) {
                        push(pathname, LOCATION_STATES.popup({ type: 'banks', methodId: id }))
                      } else {
                        select({ methodId: id })
                      }
                    }} />
                })
                : isLoading
                  ? <Loading />
                  : <Error />}
            </div>

            <ArrowBtn
              enlarged
              text={t('payNow')}
              action={purchase} />

            <BackBtn
              action={() => { close() }}
            />
            <div ref={bottomRef} />
          </div>
        </div>
      </div>
    </>
  )
}

export default Payment