import { selectIsLoading, selectIsLoggedIn } from '../Login/loginSlice'
import { ACCOUNT_TYPE } from 'containers/Account/accountSlice'
import { useHistory, useParams } from 'react-router-dom'
import { LOCATION_STATES } from '../../utils/constants'
import Status from 'containers/Account/Main/Status'
// import useTranslation from 'hooks/useTranslation'
import { useSelector } from 'react-redux'
import Popups, { POPUPS } from './Popups'
import Shortcuts from './Shortcuts'
import { useEffect } from 'react'
import Favorite from './Favorite'
import './style.scss'

const Dashboard = () => {
  const isLoading = useSelector(selectIsLoading)
  const isLogin = useSelector(selectIsLoggedIn)
  
  let { push } = useHistory()
  // const { t } = useTranslation()
  const { replace } = useHistory()
  const { key } = useParams()

  const showPopup = p => push(window.location.pathname, LOCATION_STATES.popup(p))

  useEffect(() => {
    if (!isLogin && !isLoading && key !== ACCOUNT_TYPE.settings) replace('/login')
  }, [isLoading, isLogin, key, replace])

  return (
    <div className='dashboard'>
      <Popups />
      <div className='dashboard__title'>
        <h2>Rozliczenie</h2>
        <div onClick={() => push('/')}>
          <span>Przejdź</span>
          <i className='icon icon--arrow-right'></i>
        </div>
      </div>
      <Status />

      <div className='dashboard__title'>
        <h2>Ulubione</h2>
        <div onClick={() => showPopup(POPUPS.editFavorite)}>
          <span>Edytuj</span>
          <i className='icon icon--arrow-right'></i>
        </div>
      </div>
      <Favorite />

      <div className='dashboard__title'>
        <h2>Na skróty</h2>
        <div onClick={() => showPopup(POPUPS.editShortcuts)}>
          <span>Edytuj</span>
          <i className='icon icon--arrow-right'></i>
        </div>
      </div>
      <Shortcuts />
      
    </div>
  )
}

export default Dashboard