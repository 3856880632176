import { openingmethodoptions } from 'rest/urls'
import { useState, useEffect } from 'react'
import { GET } from 'rest/request'

const useOpeningMethodData = (
  methodId,
  setProductDetails
) => {
  const [isLoading, setLoading] = useState(true)
  const [padlockInfo, setPadlockInfo] = useState(null)

  const fetchPadlockInfo = async (_id) => {
    try {
      const response = await GET(`${openingmethodoptions}/${_id}`)
      const list = response?.data?.list
      if (Array.isArray(list)) {
        if (list.length === 1) {
          setProductDetails(list[0])
        }
        setPadlockInfo(list)
        setLoading(false)
      } else {
        throw new Error('Nie udało się pobrać informacji o zabezpieczeniu')
      }
    } catch (error) {
      setLoading(false)
      console.error('Błąd podczas pobierania informacji o zabezpieczeniu:', error)
    }
  }

  useEffect(() => {
    if (methodId) fetchPadlockInfo(methodId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methodId])

  return {
    padlockInfo,
    isLoading
  }
}

export default useOpeningMethodData