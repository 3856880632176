import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { STATUS } from '../../../utils/constants'
import { locations } from '../../../rest/urls'
import { POST } from '../../../rest/request'

export const fetchLocations = createAsyncThunk('location/fetchLocations', async ({ body, signal }) => {
  const response = await POST(locations, { body, signal })
  return response
})

export const locationSlice = createSlice({
  name: 'location',
  initialState: {
    places: {},
    status: STATUS.idle,
  },
  reducers: {},
  extraReducers: {
    [fetchLocations.fulfilled]: (state, action) => {
      const { places } = action.payload

      state.places = places
      state.status = STATUS.succeed
    },
    [fetchLocations.pending]: (state, action) => {
      state.status = STATUS.pending
    },
    [fetchLocations.rejected]: (state, action) => {
      state.status = STATUS.failed
    },
  }
});

export const selectLocationStatus = state => state.location.status
export const selectPlaces = state => state.location.places

export default locationSlice.reducer
