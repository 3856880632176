import InaccessiblePopup from '../../../components/popups/InaccessiblePopup'
import { resetContract, selectContract } from '../../Login/loginSlice'
import { selectBasketItem } from '../../Basket/basketSlice'
import { useDispatch, useSelector } from 'react-redux'
import { resetForm, updateValues } from '../formSlice'
import Loading from '../../../components/Loading'
import { STATUS } from '../../../utils/constants'
import { useHistory } from 'react-router-dom'
import { PATCH } from '../../../rest/request'
import { useState, useEffect } from 'react'
import { user } from '../../../rest/urls'
import Form from './Form'
import '../style.scss'

const Contract = () => {

  const [status, setStatus] = useState(STATUS.idle)
  const [error, setError] = useState('')

  const { hash = '' } = useSelector(selectBasketItem)
  const contract = useSelector(selectContract)

  const dispatch = useDispatch()
  const { replace } = useHistory()

  useEffect(() => {
    const savedFields = typeof contract === 'object' && Object
      .entries(contract)
      .reduce((r, [k, v]) => {
        if (!v) {
          const key = k === 'apartment_nr' ? 'localNumber'
            : k === 'identity_card_type' ? 'cardType'
              : k === 'identity_card' ? 'identityCard'
                : k === 'building_nr' ? 'houseNumber'
                  : k === 'zip_code' ? 'postalCode' : k
          r = { ...r, [key]: v }
        }
        return r
      }, {})

    if (Object.keys(savedFields) > 0) {
      dispatch(updateValues(savedFields))
    }
  }, [dispatch, contract])

  const submit = async body => {
    setStatus(STATUS.pending)

    try {
      await PATCH(user, { body, credentials: true })
      setStatus(STATUS.succeed)
      dispatch(resetForm())
      dispatch(resetContract())
      replace(hash ? '/billing' : '/')

    } catch (err) {
      setStatus(STATUS.failed)
      console.error(`Error: ${err}`)
    }
  }


  return (
    <div className='contract'>
      <div className='contract__title title'>
        <div>Dane do umowy</div>
        <div>Uzupełnij dane</div>
      </div>

      {error && <InaccessiblePopup
        desc={error}
        close={() => setError('')}
      />}

      <div className='contract__main main center'>
        {status === STATUS.pending && <Loading middleOfPage />}

        <Form
          block={status === STATUS.pending}
          submit={submit} />
      </div>
    </div>
  )
}

export default Contract