
import HeadersManager from '../rest/HeadersManager'
import { getUuid } from './uuid'

const uuid = getUuid()

export const getNativeHeaders = () => {
  const headers = {
    xClientHash: uuid,
    token: HeadersManager.jwt.get(),
    wssId: HeadersManager.connectionId.get(),
    xDatabase: process.env.REACT_APP_DB_HEADER,
  }

  return JSON.stringify(headers)
}