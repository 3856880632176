import { ReactComponent as User } from '../../../assets/account.svg'
// import ArrowBtn from '../../../components/buttons/ArrowBtn'
import useTranslation from '../../../hooks/useTranslation'
// import { fetchLogOut } from '../../Login/loginSlice'
// import { useDispatch } from 'react-redux'
import Actions from './Actions'
import Status from './Status'

const Main = () => {
  const { t } = useTranslation('account')
  // const dispatch = useDispatch()

  return (
    <>
      <div>
        <div className='account__header'>
          <div className='account__icon'>
            <User />
          </div>

          <div className='title'>
            <div>{t('customerPanel')}</div>
            <div>{t('subTitle')}</div>
          </div>
        </div>
      </div>

      <Status />
      <Actions />

      {/* <ArrowBtn
        enlarged
        showArrow={false}
        text={t('logOut')}
        action={() => dispatch(fetchLogOut())} /> */}
    </>
  )
}

export default Main