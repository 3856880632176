import { ReactComponent as Fingerprint } from 'assets/fingerprint.svg'
import useAuthSuccessCallback from './useAuthSuccessCallback'
import { selectRestPopupBody } from 'rest/restSlice'
import * as WebAuth from 'containers/Test/webauth'
import { useHistory } from 'react-router-dom'
import React, { useEffect } from 'react'
import { useReduxSelector } from 'store'

const BiometricPopup: React.FC = () => {
  const popupBody = useReduxSelector(selectRestPopupBody)
  const { publicKey, verified } = popupBody as rest.UnauthorizedResponse
  const { goBack } = useHistory()

  const successCallback = useAuthSuccessCallback()

  useEffect(() => {
    const onSuccess = (token: string) => {
      successCallback({ success: true, token })
    }

    if (publicKey) {
      try {
        WebAuth.sign(
          publicKey,
          onSuccess
        )

      } catch (err) {
        console.error(`Error from WebAuth: ${err}`)
        goBack()
      }
    } else console.error('Where is publicKey?')
  }, [goBack, publicKey, successCallback])

  return (
    <div className='popup__verification'>
      <div className='popup__title'>
        <div>Autoryzacja</div>
      </div>

      {verified === false && <p className='verification__warning'>Nie rozpoznano!</p>}

      <div
        className='popup__desc'
        style={{ textAlign: 'center', color: 'var(--theme-text)' }}>
        <Fingerprint />
        <p>{publicKey ? 'Przyłóż palec do czytnika biometrycznego' : 'Coś poszło nie tak!'}</p>
      </div>
    </div>
  )
}

export default BiometricPopup