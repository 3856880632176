import { selectCalendar } from 'containers/OrderDetails/Calendar/calendarSlice'
import { getDateString, getEndDate, getStartDate } from 'utils/date'
import { addItemToBasket, selectBasketItem } from './basketSlice'
import { ReactComponent as Arrow } from 'assets/arrowRight.svg'
import { useDispatch, useSelector } from 'react-redux'
import useTranslation from 'hooks/useTranslation'
import { useHistory } from 'react-router-dom'
import Popup from 'components/popups/Popup'

const AnotherMagPopup = () => {
  const calendar = useSelector(selectCalendar)
  const item = useSelector(selectBasketItem)

  const dispatch = useDispatch()
  const { replace } = useHistory()
  const { t } = useTranslation('basket')

  const addNewItem = withTheSameDate => {
    const { identifier, ...newItem } = item
    if (!withTheSameDate) {
      const { m, d, gross_price, gross_price_d } = calendar?.priceList?.at(-1) || {}
      const date = getStartDate(calendar?.start)
      newItem.rangeDate = { m, d }
      newItem.start = date
      newItem.monthlyPrice = gross_price
      newItem.dailyPrice = gross_price_d
      newItem.end = calendar?.end || getEndDate({ m, d }, date)
    }
    dispatch(addItemToBasket(newItem))
    replace(`/calendar/${item.placeId}/${item.hash}`)
  }

  return (
    <Popup>
      <div className='popup__newMag'>
        <h2>{t('anotherMAG')}</h2>
        <p>{t('duration')}: <span className='--sb'>{getDateString(item.start)} → {item.end ? getDateString(item.end) : '∞'}</span></p>

        <div className='popup__menu'>
          <div
            className='menu__item group'
            onClick={() => addNewItem(true)}>
            <div className='item__desc --termina'>{t('keepTheSameLeaseTerm')}</div>
            <div className='menu__arrow center'>
              <Arrow />
            </div>
          </div>

          <div
            className='menu__item group'
            onClick={() => addNewItem(false)}>
            <div className='item__desc --termina'>{t('selectAgain')}</div>
            <div className='menu__arrow center'>
              <Arrow />
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default AnotherMagPopup