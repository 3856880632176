import ArrowBtn from 'components/buttons/ArrowBtn'
import { useHistory } from 'react-router-dom'
import Input from 'components/inputs/Input'
import Popup from 'components/popups/Popup'
import React, { useState } from 'react'
import './style.scss'

const priceList = [
  {
    "price_percentage": 100,
    "gross_price": "615.00",
    "v": "1m",
    "m": 1,
    "d": 0,
    "r": 0,
    "gross_price_d": "21.00",
    "net_price": "500.00",
    "net_price_d": "17.00",
    "selected": true
  },
  {
    "price_percentage": 100,
    "gross_price": "585.00",
    "v": "6m",
    "m": 6,
    "d": 0,
    "r": 50,
    "gross_price_d": "21.00",
    "net_price": "500.00",
    "net_price_d": "17.00"
  },
  {
    "price_percentage": 100,
    "gross_price": "550.00",
    "v": "12m",
    "m": 12,
    "d": 0,
    "r": 100,
    "gross_price_d": "21.00",
    "net_price": "500.00",
    "net_price_d": "17.00"
  }
]

interface DiscountPopupProps {

}

const DiscountPopup: React.FC<DiscountPopupProps> = () => {
  const [value, setValue] = useState('')
  const { goBack } = useHistory()
  return (
    <Popup>
      <div className='discounts__popup'>
        <div className='popup__title'>
          <div>Uzyskaj rabat</div>
          <div>Wprowadź kod rabatowy</div>
        </div>
        <Input
          name='discount'
          value={value}
          placeholder='Kod rabatowy'
          changeHandler={e => {
            setValue(e.target.value)
          }}
        />

        <div className='popup__title'>
          <div>Czy wiesz że...</div>
        </div>

        <div className='discounts__popup__section'>
          <div className='discounts__popup__section__header'>Trzeci magazyn za pół ceny!</div>
          <div className='discounts__popup__section__content'>Przy jednorazowym wynajmie 3 lub więcej magazynów, najtańszy będzie 50% tańszy!</div>
        </div>

        <div className='discounts__popup__section'>
          <div className='discounts__popup__section__header'>Regał gratis do 5 magazynu</div>
          <div className='discounts__popup__section__content'>Przy jednorazowym wynajmie 5 lub więcej magazynów, regał dostaniesz gratis!</div>
        </div>

        <div className='discounts__popup__section'>
          <div className='discounts__popup__section__header'>Dłużej magazynujesz, więcej zyskujesz! </div>
          <div className='discounts__popup__section__content'>Miesięczna cena wynajmu zmienia się na przestrzeni czasu</div>
          <div className='discounts__popup__section__promotion'>
            {priceList.map(({ d, m, selected, gross_price, gross_price_d }, i) => <div
              key={i}
              className={`promotion__line center${selected ? ' --selected' : ''}`}>
              {m
                ? <div className={`promotion__label ${selected ? ' --selected' : ''}`}>
                  <span>{gross_price} zł</span>
                  <span className='--bottom'>{m} m-c</span>
                </div>
                : <div className={`promotion__label ${selected ? ' --selected' : ''}`}>
                  <span>{gross_price_d} zł</span>
                  <span className='--bottom'>{d} dzień</span>
                </div>}
            </div>)}
          </div>
        </div>

        <ArrowBtn
          enlarged
          text='Zakończ'
          showArrow={false}
          action={goBack}
        />
      </div>
    </Popup>
  )
}

export default DiscountPopup