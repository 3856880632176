import { selectWarehouse, updateWarehouse } from '../Warehouses/warehousesSlice'
import { useReduxDispatch, useReduxSelector } from 'store'
import Option from 'components/Option'
import React from 'react'

interface MagMessagesProps {
  id: string
}

const MagMessages: React.FC<MagMessagesProps> = ({ id }) => {
  const { alerts } = useReduxSelector(state => selectWarehouse(state, id))

  const dispatch = useReduxDispatch()
  const action = (index: number) => {
    dispatch(updateWarehouse({
      id,
      values: { alerts: [...(alerts || [])].filter((_, i) => i !== index) }
    }))
  }

  return (
    (alerts && alerts?.length > 0) ?
      <div className='menu__section'>
        <p>Wymagane akcje</p>
        <div className='menu__message'>
          {alerts.map((e, i) => <Option
            key={i}
            {...e}
            selected
            action={() => action(i)}
          />)}
        </div>
      </div> 
      : null
  )
}

export default MagMessages