import BasketContainer from 'containers/Basket/BasketContainer'
import { Switch, Redirect, Route } from 'react-router-dom'
import Registration from 'containers/forms/Registration'
import SubWarehouse from 'containers/Warehouse/SubMenu'
import EntranceLogin from 'containers/Entrance/Login'
import NewWarehouse from 'containers/NewWarehouse'
import FirstOpening from 'containers/FirstOpening'
import OrderDetails from 'containers/OrderDetails'
import Contract from 'containers/forms/Contract'
import Billing from 'containers/forms/Billing'
import MapDetails from 'containers/MapDetails'
import Categories from 'containers/Categories'
import Warehouses from 'containers/Warehouses'
import Warehouse from 'containers/Warehouse'
import MapSearch from 'containers/MapSearch'
import Dashboard from 'containers/Dashboard'
import Entrance from 'containers/Entrance'
import Reset from 'containers/forms/Reset'
import Summary from 'containers/Summary'
import Payment from 'containers/Payment'
import Account from 'containers/Account'
import Login from 'containers/Login'
import Chats from 'containers/Chats'
import Home from 'containers/Home'
import Maps from 'containers/Maps'
import Help from 'containers/Help'
import Test from 'containers/Test'
import MRoute from './MRoute'

import { selectIsExpandedBasket } from 'appSlice'
import { useReduxSelector } from 'store'

const Router = () => {
  const isExpandedBasket = useReduxSelector(selectIsExpandedBasket)
  return (
    <Switch>
      <MRoute exact path={['/home', '/']} bottomBar>
        <Home />
      </MRoute>

      <MRoute exact path='/dashboard' bottomBar>
        <Dashboard />
      </MRoute>

      <MRoute exact path={['/account/:key/:action', '/account/:key', '/account']} bottomBar>
        <Account />
      </MRoute>

      <MRoute exact path='/billing' protectedPath>
        <BasketContainer extendedAppearance={false} children={<Billing />} />
      </MRoute>

      <MRoute exact path='/calendar/:placeId/:hash' animation={false} darkHeader roundedHeader>
        <BasketContainer extendedAppearance={isExpandedBasket} children={<OrderDetails />} />
      </MRoute>

      <MRoute exact path='/categories' darkHeader animation={false}>
        <Categories />
      </MRoute>

      <MRoute exact path={'/chat'} bottomBar>
        <Chats />
      </MRoute>

      <MRoute exact path={'/chat/:id'}>
        <Chats />
      </MRoute>

      <MRoute exact path='/contract' protectedPath>
        <Contract />
      </MRoute>

      <MRoute exact path='/entrance' animation={false}>
        <Entrance />
      </MRoute>

      <MRoute exact path='/entrance/login' animation={false}>
        <EntranceLogin />
      </MRoute>

      <Route exact path={['/first/:number/:key', '/first/:number']}>
        <FirstOpening />
      </Route>

      <MRoute exact path='/help' bottomBar>
        <Help />
      </MRoute>

      <MRoute exact path='/login'>
        <Login />
      </MRoute>

      <MRoute exact path='/login/reset'>
        <Reset />
      </MRoute>

      <MRoute exact path={['/map/:placeId', '/map']} flyingHeader>
        <Maps />
      </MRoute>

      <Route exact path={['/mapsearch/:provinceIdx/:cityIdx', '/mapsearch/:provinceIdx', '/mapsearch']}>
        <MapSearch />
      </Route>

      <MRoute exact path='/mapdetails/:placeId' whiteHeader darkHeader animation={false}>
        <MapDetails />
      </MRoute>

      <Route exact path={['/newwarehouse/:key/:id', '/newwarehouse/:key', '/newwarehouse']}>
        <main>
          <NewWarehouse />
        </main>
      </Route>

      <MRoute exact path='/payment' protectedPath>
        <Payment />
      </MRoute>

      <MRoute exact path='/registration'>
        <Registration />
      </MRoute>

      <Route exact path='/test'>
        <Test />
      </Route>

      <MRoute exact path='/summary' protectedPath>
        <Summary />
      </MRoute>

      <MRoute exact path='/warehouses' animation={false} protectedPath>
        <Warehouses />
      </MRoute>

      <MRoute exact path='/warehouses/:id' animation={false} protectedPath>
        <Warehouse />
      </MRoute>

      <MRoute exact path={['/warehouses/:id/:type/:action', '/warehouses/:id/:type']} animation={false} protectedPath>
        <SubWarehouse />
      </MRoute>

      <Redirect to='/' />
    </Switch>
  )
}

export default Router