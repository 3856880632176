import { selectWarehouse } from 'containers/Warehouses/warehousesSlice'
import { dateToYMDformat, getRangeFromDates } from 'utils/date'
import { useHistory, useParams } from 'react-router-dom'
import ArrowBtn from 'components/buttons/ArrowBtn'
import BackBtn from 'components/buttons/BackBtn'
import ReactCalendar from 'react-calendar'
import { useReduxSelector } from 'store'
import { useState } from 'react'

const dateStrToObj = (s) => s ? new Date(s) : undefined

const Calendar = ({ response, selectedData, selectData }) => {
  const { id } = useParams()
  const [date, setDate] = useState(dateStrToObj(selectedData.date))
  const { pricelist, terminationTerms } = useReduxSelector(state => selectWarehouse(state, id))
  const warehouseData = response?.warehouses?.[0]

  const { goBack } = useHistory()
  const getPrices = () => {
    const prices = Array.isArray(pricelist)
      ? (pricelist.findLast(list => list?.selected) || pricelist?.[0])
      : {}
    return {
      daily: Number(prices?.gross_price_d || 0),
      monthly: Number(prices?.gross_price || 0)
    }
  }

  const rangeToAmount = (r) => {
    const prices = getPrices()
    return r.m * prices.monthly + r.d * prices.daily
  }

  const amount = rangeToAmount(getRangeFromDates(date))

  return <>
    <div className='title'>
      <div>Wybierz datę</div>
      <div>Kiedy chcesz skończyć wynajem?</div>
    </div>

    <div className='resignation__main'>
      <div className='resignation__calendar calendar'>
        <div className='calendar__labels group'>
          <div>Koniec: <span>{date ? date.toLocaleDateString() : 'Wybierz'}</span></div>
          <div>Do zapłaty: <span>{amount}zł</span></div>
        </div>

        <ReactCalendar
          value={date}
          className='--light'
          maxDate={dateStrToObj(warehouseData?.endingDate)}
          minDate={dateStrToObj(warehouseData?.minTerminationDate)}
          defaultActiveStartDate={warehouseData?.minTerminationDate ? new Date(warehouseData?.minTerminationDate) : new Date()}
          showNeighboringMonth={false}
          onChange={setDate}
        />

        <div className='calendar__info'><b>Okres wypowiedzenia: </b><div>{terminationTerms?.infoForClient}</div></div>
      </div>

      <div className='resignation__buttons'>
        <ArrowBtn
          enlarged
          text='Dalej'
          disabled={!date}
          action={() => {
            if (date) {
              selectData(prev => ({ ...prev, date: dateToYMDformat(date) }))
              goBack()
            }
          }}
        />

        <BackBtn
          text='Wróć'
          action={() => {
            goBack()
          }}
        />
      </div>
    </div>
  </>
}

export default Calendar