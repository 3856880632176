import React from 'react'

interface MagSectionProps extends Partial<warehouse.WarehouseSize> {
  name: string
}

const MagSection: React.FC<MagSectionProps> = ({ name, area, internalHeight, internalLength, internalWidth }) => {
  return (
    <div>
      <div className='list__header element__title'>{name}</div>
      <div className='list__element --big'>
        <div className='element__desc'>
          <div>Powierzchnia: {Number(area)}m²</div>
          <div>Długość: {internalLength}m</div>
          <div>Szerokość: {internalWidth}m</div>
          <div>Wysokość: {internalHeight}m</div>
        </div>
      </div>
    </div>
  )
}

export default MagSection