import { selectBasketList, BASKET_TYPES } from 'containers/Basket/basketSlice'
import { useReduxSelector } from 'store'

type Object = Record<string, string>
export type GroupedWarehouseAddons = Record<string, string[]>

const useGroupedWarehouseAddons = () => {
  const list = useReduxSelector(selectBasketList)
  const groupWarehouseAddons = () => {
    const object: GroupedWarehouseAddons = {}
    if (Array.isArray(list) && list.length > 0) {
      let addonList = list.filter(l => l.type === 'rentAddon')
      list.forEach((item) => {
        if (item.type === BASKET_TYPES.rent) {
          const locks = addonList.reduce((r, l) => {
            const isRelated = l.ownerId
              ? l.ownerId === item.identifier
              : l.related
                ? (l.related.some((r: Object) => r.cartId === item.identifier || r.id === item.placeId))
                : l.placeId === item.placeId
            if (isRelated) r.related.push(l.identifier)
            else r.rest.push(l)
            return r
          }, { related: [], rest: [] })
          object[item.identifier] = locks.related
          addonList = locks.rest
        }
      })
    }
    return object
  }

  return groupWarehouseAddons
}

export default useGroupedWarehouseAddons