import { selectUser } from 'containers/Login/loginSlice'
import Payments from 'components/Payments'
import { useReduxSelector } from 'store'
import { getAmount } from './Summary'
import React from 'react'
import { Step } from '.'

interface PaymentsProps {
  selectedData: payments.SelectedData
  response?: payments.CancelRentalResponse
  selectedTimePeriods: payments.SelectedTimePeriods
  setStep: React.Dispatch<React.SetStateAction<Step>>
  generateInvoice: (payments: payments.Payment[], selectedTimePeriods: payments.SelectedTimePeriods, type?: string) => Promise<payments.CancelRentalResponse | undefined>
}

interface Invoice {
  invoiceId: string
  amount: string
}

const PaymentsContainer: React.FC<PaymentsProps> = ({ response, selectedData, selectedTimePeriods, setStep, generateInvoice }) => {
  const { unpaidInvoicesAmount, unpaid_invoices } = useReduxSelector(selectUser)
  const tiles = response?.warehouses?.[0]?.schedule?.[0]?.tiles
  const amount = Number(unpaidInvoicesAmount) + getAmount(selectedTimePeriods, tiles)

  const getInvoices = async (payment: payments.Payment) => {
    const invoices: Invoice[] = []
    const documentResponse = await generateInvoice([payment], selectedTimePeriods, 'invoice')
    if (unpaid_invoices && Array.isArray(unpaid_invoices)) {
      unpaid_invoices.forEach(invoice => {
        invoices.push({ invoiceId: invoice.id, amount: invoice.remainingAmount })
      })
    }
    if (documentResponse?.newDocuments) {
      documentResponse.newDocuments.forEach(({ id, toPay }) =>
        invoices.push({ invoiceId: id, amount: toPay })
      )
    }
    return invoices
  }

  return (
    <Payments
      grossPrice={amount}
      summaryTop='Zakończenie wynajmu'
      summaryBottom={selectedData.date}
      getInvoices={getInvoices}
      close={(remotePaymentId?: string) => {
        setStep(remotePaymentId ? 'finish' : 'init')
      }}
    />
  )
}

export default PaymentsContainer