import { ReactComponent as Filter } from '../../../assets/filter.svg'
import { useState, useEffect, useCallback } from 'react'
import { LOCATION_STATES } from '../../../utils/constants'
import { selectLocation } from '../menu/filtersSlice'
import { selectPlaces } from './locationSlice'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import ResultList from './ResultList'
import Filters from './Filters'


const Result = ({ expanded, menuRef, show }) => {
  const [animate, setAnimate] = useState(false)

  const location = useSelector(selectLocation)
  const data = useSelector(selectPlaces)

  const { push, location: { pathname } } = useHistory()

  useEffect(() => !expanded && !!location && data?.filteredAndLocalized?.length > 0 && setAnimate(true)
    , [expanded, data, location])

  useEffect(() => expanded && setAnimate(false), [expanded])


  const showFilters = useCallback(() => push(pathname, LOCATION_STATES.popup('filters')), [pathname, push])

  return (
    <div
      className={`map__result --full ${animate ? '--animate' : ''}`}
      onClick={() => setAnimate(false)}>

      <div className='result__header'>
        <div className='header__main group'>
          <div
            className='header__title'
            onClick={show}>Pokaż listę MAGów</div>

          <div
            className='result__filter'
            onClick={showFilters}>
            <Filter />
            <span>Filtruj</span>
          </div>
        </div>
      </div>

      <Filters
        menuRef={menuRef}
        showFilters={showFilters}
      />

      {expanded && <ResultList />}
    </div >
  )
}

export default Result