// import MarkerClusterGroup from 'react-leaflet-markercluster'
import { MarkersContext } from '../context/data/Context'
import { Marker, Popup, Tooltip } from 'react-leaflet'
import useTranslation from 'hooks/useTranslation'
import React, { useContext } from 'react'
import { getIcon } from './assets/icons'

interface MarkersProps {
  close: (pointId: string) => void
}

const Markers: React.FC<MarkersProps> = ({ close }) => {
  const data = useContext(MarkersContext)
  const { t } = useTranslation('delivery')

  return (
    <>
      {data.map(({ code, name, is_delivery_point, is_send_point, active, cod, description, service, address, opening_hours, coordinates }) => {
        return <Marker
          key={code}
          icon={getIcon(service)}
          position={{
            lat: coordinates.latitude,
            lng: coordinates.longitude
          }}>
          <Tooltip
            permanent
            direction='top'>
            <Popup>
              <div className='marker__popup'>
                <div>
                  <h3 className='marker__popup__header'>
                    <span>{name}</span>
                    <span className={`marker__popup__header__dot${active ? ' --active' : ''}`} />
                  </h3>
                  <div>{description}</div>
                  <div>{address.street}</div>
                  <div>{address.postcode} {address.city}</div>
                </div>
                <div className='marker__popup__info'>
                  {is_send_point && <span>#{t('services_sender')}</span>}
                  {is_delivery_point && <span>#{t('services_receiver')}</span>}
                  {cod && <span>#{t('services_cod')}</span>}
                </div>
                {opening_hours && <div>
                  <div className='marker__popup__label'>{t('openingHours')}:</div>
                  <div className='marker__popup__availability'>
                    {Object.entries(opening_hours).map(([day, hours]) => <div key={day}>
                      <span>{t(day)}:</span>
                      <span>{hours?.start_hour} - {hours?.end_hour}</span>
                    </div>)}
                  </div>
                </div>}
                <button
                  className='button --arrow --black'
                  onClick={() => {
                    close(code)
                  }}>
                  <span>{t('select')}</span>
                </button>
              </div>
            </Popup>
          </Tooltip>
        </Marker>
      })}
    </>
  )
}

export default Markers