import { errorMessage, finalExp, initialExp } from '../../../utils/validation'
import { ACCOUNT_TYPE, selectUserData, updateValue } from '../accountSlice'
import InputWithOptions from '../../forms/Contract/InputWithOptions'
import { useDispatch, useSelector } from 'react-redux'
import Submit from '../../../components/inputs/Submit'
import Input from '../../../components/inputs/Input'
import Popup from '../../../components/popups/Popup'
import { STATUS } from '../../../utils/constants'
import { useHistory } from 'react-router-dom'
import { PATCH } from '../../../rest/request'
import { user } from '../../../rest/urls'
import { useState } from 'react'

const type = ACCOUNT_TYPE.basic

const EditUserPopup = () => {

  const { data = {} } = useSelector(state => selectUserData(state, type))
  const [{ name, surname, city, postalCode, street, houseNumber, localNumber, streetPrefix, cityDistricted }, setForm] = useState({
    name: data.name || '',
    surname: data.surname || '',
    city: data.city || '',
    street: data.street || '',
    postalCode: data.zip_code || '',
    houseNumber: data.building_nr || '',
    localNumber: data.apartment_nr || '',
    streetPrefix: '',
    cityDistricted: ''
  })
  const [status, setStatus] = useState(STATUS.idle)
  const [warning, setWarning] = useState({
    name: false,
    surname: false,
    city: false,
    postalCode: false,
    street: false,
    houseNumber: false,
  })

  const { goBack } = useHistory()
  const dispatch = useDispatch()

  const refs = []
  const isLoading = status === STATUS.pending

  const saveForm = v => setForm(f => { return { ...f, ...v } })
  const updateVal = (name, value) => dispatch(updateValue({ type, name, value }))

  const submit = async body => {
    setStatus(STATUS.pending)

    try {
      const response = await PATCH(user, { body })
      if (response.data) {
        updateVal('data', response.data)
        goBack()
      } else throw new Error('Wrong response')
    } catch (error) {
      console.error(error)
      setStatus(STATUS.failed)
    }
  }

  const changeHandler = e => {
    if (!e) return
    const { name, value } = e.target

    if (name === 'postalCode' && value && !value.match(initialExp[name])) return
    else setForm(f => { return { ...f, [name]: value } })

    if (value && warning[name] && ((name === 'houseNumber') ||
      ((name === 'name' || name === 'surname' || name === 'city' || name === 'street') && value.length > 1) ||
      (name === 'postalCode' && value.length === 6)))
      setWarning({
        ...warning,
        [name]: false,
      })
  }

  const blurHandler = e => {
    if (!e) return
    const { name, value } = e.target
    let warn = false

    switch (name) {
      case 'name':
        warn = value.length < 3 ? errorMessage(3 - value.length) : false
        break
      case 'houseNumber':
        warn = !value ? 'niewłaściwy numer' : false
        break
      case 'postalCode':
        warn = !value.match(finalExp.postalCode) ? '**-***' : false
        break
      default: warn = value.length < 2 ? errorMessage(2 - value.length) : false
    }

    if (warn || (warning[name] && !warn)) setWarning({
      ...warning,
      [name]: warn,
    })
  }

  const enterClicked = ref => {
    const index = refs.findIndex(r => Object.is(r, ref))
    if (index !== undefined && refs[index + 1]) refs[index + 1].focus()
  }

  const validation = () => {
    const codeOK = !!postalCode.match(finalExp.postalCode)

    if (name.length > 2 && surname.length > 1 && city.length > 1 && street.length > 1 && codeOK && houseNumber) {
      const body = { name, surname, city, zip_code: postalCode, street, building_nr: houseNumber, apartment_nr: localNumber }
      submit(body)

    } else setWarning({
      name: name.length < 3 ? errorMessage(3 - name.length) : false,
      surname: surname.length < 2 ? errorMessage(2 - surname.length) : false,
      city: city.length < 2 ? errorMessage(2 - city.length) : false,
      postalCode: !codeOK ? '**-***' : false,
      street: street.length < 2 ? errorMessage(2 - street.length) : false,
      houseNumber: !houseNumber ? 'niewłaściwy numer' : false,
    })
  }

  return (
    <Popup>
      <div
        className='popup__edit registration'
        style={{ width: '100%' }}>

        <div className='main center'>
          <div className='popup__title --sticky'>
            <div>Zmień dane</div>
          </div>

          <div className='form'>
            <Input
              required
              ref={r => refs.push(r)}
              value={name}
              name='name'
              label='Imię'
              minLength='3'
              maxLength='100'
              disabled={isLoading}
              warning={warning.name}
              placeholder='Wpisz imię'
              blurHandler={blurHandler}
              enterClicked={enterClicked}
              changeHandler={changeHandler} />

            <Input
              required
              ref={r => refs.push(r)}
              value={surname}
              name='surname'
              label='Nazwisko'
              minLength='2'
              maxLength='100'
              disabled={isLoading}
              warning={warning.surname}
              placeholder='Wpisz nazwisko'
              blurHandler={blurHandler}
              enterClicked={enterClicked}
              changeHandler={changeHandler} />

            <InputWithOptions
              required
              secondaryTheme
              ref={r => refs.push(r)}
              value={city}
              name='city'
              label='Miejscowość'
              minLength='2'
              maxLength='200'
              disabled={isLoading}
              warning={warning.city}
              placeholder='Wpisz miejscowość'
              optionalValueSetter={saveForm}
              enterClicked={enterClicked}
              changeHandler={changeHandler}
              blurHandler={blurHandler} />

            <InputWithOptions
              required
              secondaryTheme
              ref={r => refs.push(r)}
              value={street}
              name='street'
              label='Ulica'
              minLength='2'
              maxLength='200'
              disabled={isLoading}
              additionalValues={{ city }}
              warning={warning.street}
              placeholder='Wpisz ulicę'
              optionalValueSetter={saveForm}
              enterClicked={enterClicked}
              changeHandler={changeHandler}
              blurHandler={blurHandler} />

            <InputWithOptions
              required
              secondaryTheme
              ref={r => refs.push(r)}
              value={houseNumber}
              name='houseNumber'
              label='Numer domu'
              maxLength='10'
              disabled={isLoading}
              additionalValues={city.length > 1 && street.length > 1 ? { city, cityDistricted: cityDistricted || city, street, houseNumber } : false}
              warning={warning.houseNumber}
              placeholder='Wpisz numer domu'
              optionalValueSetter={saveForm}
              enterClicked={enterClicked}
              changeHandler={changeHandler}
              blurHandler={blurHandler} />

            <Input
              ref={r => refs.push(r)}
              value={localNumber}
              name='localNumber'
              type='tel'
              label='Numer mieszkania'
              maxLength='10'
              disabled={isLoading}
              placeholder='Wpisz numer domu'
              enterClicked={enterClicked}
              changeHandler={changeHandler} />

            <InputWithOptions
              required
              secondaryTheme
              ref={r => refs.push(r)}
              value={postalCode}
              name='postalCode'
              label='Kod pocztowy'
              maxLength='6'
              disabled={isLoading}
              additionalValues={city.length > 1 && street.length > 1 ? { city, street, houseNumber, streetPrefix } : false}
              warning={warning.postalCode}
              placeholder='Wpisz kod pocztowy'
              optionalValueSetter={saveForm}
              enterClicked={enterClicked}
              blurHandler={blurHandler}
              changeHandler={e => {
                e.target.value = e.target.value.replace(/^(\d{2})(\d+)$/, '$1-$2')
                changeHandler(e)
              }} />

            <Submit
              text='Zapisz'
              block={isLoading}
              isLoading={isLoading}
              action={validation} />
          </div>
        </div>
      </div>
    </Popup>
  )
}

export default EditUserPopup