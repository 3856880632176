import { selectWarehouse } from '../../Warehouses/warehousesSlice'
import { ReactComponent as Account } from 'assets/accountBig.svg'
import { ReactComponent as Checked } from 'assets/checked.svg'
import { ReactComponent as Safety } from 'assets/safety.svg'
import { useHistory, useParams } from 'react-router-dom'
import OutsourcePhotoPopup from './OutsourcePhotoPopup'
import ArrowBtn from 'components/buttons/ArrowBtn'
import { LOCATION_STATES } from 'utils/constants'
import BackBtn from 'components/buttons/BackBtn'
import usePopupState from 'hooks/usePopupState'
import { useReduxSelector } from 'store'
import { useState } from 'react'
import { useRef } from 'react'
import Item from './Item'

interface CameraProps {
  selectedData: payments.SelectedData
  selectData: React.Dispatch<React.SetStateAction<payments.SelectedData>>
}

const Camera: React.FC<CameraProps> = ({ selectedData, selectData }) => {

  const [photo, setPhoto] = useState<File | null>(selectedData?.photo || null)
  const { id } = useParams<warehouse.PageParams>()
  const { hasOnlineAccess } = useReduxSelector(state => selectWarehouse(state, id))
  const { popup, pathname } = usePopupState()

  const inputRef = useRef<HTMLInputElement>(null)
  const { push, goBack } = useHistory()

  return (
    <>
      <div>
        {popup === 'photo' && <OutsourcePhotoPopup />}
      </div>

      <div style={{ display: 'none' }}>
        <input
          ref={inputRef}
          type='file'
          accept='image/*'
          // capture='camera'
          onChange={e => {
            const { files } = e.target
            if (files?.[0]) setPhoto(files[0])
          }}
        />
      </div>

      <div className='title'>
        <div>Zrób zdjęcie</div>
        <div>Pokaż, że magazyn jest już pusty, czysty<br /> i gotowy dla innych klientów</div>
      </div>

      <div className='resignation__main'>
        <div className='resignation__list'>
          <div className='list__label'>Po co zdjęcia?</div>

          <Item
            icon={<Safety />}
            title='Bezpieczeństwo'
            desc='Upewnimy się, że nic nie zostawiłeś'
          />

          <Item
            icon={<Checked />}
            title='Jakość'
            desc='Dbamy, aby MAGi były w 100% sprawne'
          />

          <Item
            icon={<Account />}
            title='Dobro klientów'
            desc='Każdy chce wynająć czysty magazyn!'
          />
        </div>

        <div className='resignation__buttons'>
          <ArrowBtn
            enlarged
            text={photo ? `Dodaj ${photo?.name}` : `${hasOnlineAccess ? 'Otwórz MAGa i z' : 'Z'}rób zdjęcie`}
            action={() => {
              if (photo) {
                selectData(prev => ({ ...prev, photo }))
                goBack()
              } else {
                inputRef.current?.click()
              }
            }}
          />

          <BackBtn
            text='Zleć to Nam'
            action={() => push(pathname, LOCATION_STATES.popup('photo'))}
          />
        </div>
      </div>
    </>
  )
}

export default Camera