import ArrowBtn from 'components/buttons/ArrowBtn'
import { useHistory } from 'react-router-dom'
import Popup from 'components/popups/Popup'

const InfoMagbox = ({ cancelAction }) => {
  const { goBack } = useHistory()

  return (
    <Popup close={cancelAction || goBack}>
      <div className='popup__title'>
        <div>Magbox</div>
      </div>
      <div className='popup__infoBlocks'>
        <span className='desc'>
          Informacje o tym jak wygląda Magbox i jak działa
        </span>
       

        <ArrowBtn
          enlarged
          showArrow={false}
          text={'Rozumiem'}
          action={cancelAction || goBack} />
      </div>
    </Popup>
  )
}

export default InfoMagbox