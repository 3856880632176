import React, { useEffect, useRef, useState } from 'react'
import './style.scss'

interface PinFormProps {
  disabled?: boolean
  value: string
  clear?: boolean
  warning: boolean
  setWarning: (w: boolean) => void
  setValue: React.Dispatch<React.SetStateAction<string>>
  autoFocus?: boolean
  isCode?: boolean
  autoLoadCodeFromSMS?: boolean
  submit?: () => void
}

interface CredentialRequestOptions {
  otp?: OTPOptions
  signal?: AbortSignal
}

interface OTPOptions {
  transport: string[]
}

const INIT_PIN = {
  value: { 0: '', 1: '', 2: '', 3: '' },
  length: 4
}

const INIT_CODE = {
  value: { 0: '', 1: '', 2: '', 3: '', 4: '', 5: '' },
  length: 6
}

const PinForm: React.FC<PinFormProps> = ({ disabled = false, value, clear = false, warning, setWarning, setValue, autoFocus = false, isCode = false, autoLoadCodeFromSMS = false, submit }) => {

  const init = useRef(isCode ? INIT_CODE : INIT_PIN)
  const [code, setCode] = useState<Record<number, string>>(value?.length === init.current.length ? { ...value.split('') } : init.current.value)

  const refs: HTMLInputElement[] = []

  useEffect(() => {
    if (clear) setCode(init.current.value)
  }, [clear])

  useEffect(() => {
    if (autoFocus) {
      const index = value ? init.current.length - 1 : 0
      if (refs[index]) refs[index]?.focus()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (warning) {
      const timeout = setTimeout(() => setWarning(false), 3000)
      return () => clearTimeout(timeout)
    }
  }, [setWarning, warning])

  useEffect(() => {
    if ('OTPCredential' in window && autoLoadCodeFromSMS) {
      const abortController = new AbortController()
      const loadCodeFromSMS = () => {
        console.log('DOMContentLoaded event')
        const options: CredentialRequestOptions = {
          otp: { transport: ['sms'] },
          signal: abortController.signal
        }
        navigator.credentials
          .get(options)
          .then(otp => {
            if (otp && 'code' in otp && typeof otp.code === 'string') {
              const codeList = otp.code.split('')
              setCode({ ...codeList })
              submit && submit()
            } else {
              throw new Error('Wrong otp response')
            }
          })
          .catch(err => { console.warn(err) })
      }

      window.addEventListener('DOMContentLoaded', loadCodeFromSMS)

      return () => {
        abortController.abort()
        window.removeEventListener('DOMContentLoaded', loadCodeFromSMS)
      }
    }
  }, [autoLoadCodeFromSMS, submit])

  const codeToString = (c = code) => Object.entries(c).reduce((r, [, v]) => r += v, '')

  const moveFocus = (ref: EventTarget, forward = true) => {
    const step = forward ? 1 : -1
    const index = refs.findIndex(r => Object.is(r, ref))
    const key = index > -1 ? index + step : undefined

    if (key !== undefined && refs[key]) {
      const len = code[key].length
      refs[key].setSelectionRange(0, len)
      refs[key].focus()
    }
  }

  return (
    <div className={`pin__form center${isCode ? ' --secondary' : ''}${warning ? ' --warning' : ''}`}>
      {Object.entries(code).map(([k, v]) => <input
        required
        key={k}
        value={v}
        type='tel'
        disabled={disabled}
        ref={r => r && refs.push(r)}
        onKeyUp={e => {
          if (e.key === 'Backspace' && !v) {
            moveFocus(e.target, false)
          }
        }}
        onChange={e => {
          const { value } = e.target
          const firstChar = value.charAt(0)

          if (value.length === init.current.length && value.match(/^[0-9]{4}$/)) {
            setCode({ ...value.split('') })
            setValue(value)
          }
          else if (!firstChar || firstChar.match(/^[0-9]{1}$/)) {
            const newCode = {
              ...code,
              [k]: firstChar
            }
            setCode(newCode)
            setValue(codeToString(newCode))
            moveFocus(e.target, !!firstChar)
          }
        }}
      />)}
    </div>
  )
}

export default PinForm