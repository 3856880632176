import { ReactComponent as Arrow } from '../../../assets/arrowTop.svg'
import { useHistory } from 'react-router-dom'

const Close = ({ expanded, expand, fullScreen, placeId, toggleMenu, setFullScreen }) => {
  const { goBack, push } = useHistory()

  const closePopup = () => {
    if (expanded) expand(false)
    window.history ? goBack() : push('/map')
  }

  return (
    <div
      className='menu__close'
      style={{ position: placeId ? 'static' : 'sticky' }}>
      <div
        className={`center --${expanded || placeId ? 'expanded' : 'collapsed'}`}
        onClick={() => placeId ? closePopup() : fullScreen ? setFullScreen(false) : toggleMenu()}>
        <Arrow />
        <span>{placeId || fullScreen ? 'Zamknij' : expanded ? ' Pokaż mape ' : 'Ukryj mape'}</span>
      </div>
    </div>
  )
}

export default Close