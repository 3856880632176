import { selectBasketContracts } from 'containers/Basket/basketSlice'
import { selectUser } from 'containers/Login/loginSlice'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import DetailsSlide from './DetalisSlide'
import FirstSlide from './FirstSlide'
import { POST } from 'rest/request'
import * as urls from 'rest/urls'
import PinSlide from './PinSlide'
import './style.scss'


// const contract = {
//   "locks": [
//     {
//       "warehouseId": "9c001ee4-7516-42c4-8056-dfb99e610278",
//       "warehouseNumber": "1",
//       "warehouseAdress": "Pabianice, ul. Grota 1",
//       "pages": [
//         {
//           "sections": [
//             {
//               "title": "Jak otworzyć bramę do placu?",
//               "content": "Brama otwiera się z aplikacji lub z panelu wjazdowego znajudjącego się obok bramy, gdzie naley wpisać numer telefonu i swój pin do aplikacji",
//               "type": "text"
//             },
//             {
//               "title": "Twój pin do aplikacji i bramy",
//               "content": "xxxx",
//               "type": "pin"
//             }
//           ]
//         },
//         {
//           "sections": [
//             {
//               "title": "Jak otworzyć magazyn na placu?",
//               "content": "Twój magazyn aktualnie nie jest zabezpieczony. Musisz uzyc kłódki",
//               "type": "text"
//             },
//             {
//               "title": "skąd wziąć kłódkę?",
//               "content": "{dane dostawy}",
//               "type": "text"
//             }
//           ]
//         }
//       ]
//     },
//     {
//       "warehouseId": "9c001ee4-7516-42c4-8056-dfb99e610218",
//       "warehouseNumber": "2",
//       "warehouseAdress": "Pabianice, ul. Grota 2",
//       "pages": [
//         {
//           "sections": [
//             {
//               "title": "Jak otworzyć bramę do placu?",
//               "content": "Brama otwiera się z aplikacji lub z panelu wjazdowego znajudjącego się obok bramy, gdzie naley wpisać numer telefonu i swój pin do aplikacji",
//               "type": "text"
//             },
//             {
//               "title": "Twój pin do aplikacji i bramy",
//               "content": "xxxx",
//               "type": "pin"
//             }
//           ]
//         },
//         {
//           "sections": [
//             {
//               "title": "Jak otworzyć magazyn na placu?",
//               "content": "Twój magazyn aktualnie nie jest zabezpieczony. Musisz uzyc kłódki",
//               "type": "text"
//             },
//             {
//               "title": "skąd wziąć kłódkę?",
//               "content": "{dane dostawy}",
//               "type": "text"
//             }
//           ]
//         }
//       ]
//     }
//   ]
// }


const NewWarehouse = () => {
  const user = useSelector(selectUser)
  const contracts = useSelector(selectBasketContracts)

  const [data, setData] = useState(undefined)
  const [isLoading, setLoading] = useState(false)
  const [pinSlideVisible, setPinSlideVisible] = useState(!user?.pin)
  const [firstSlideVisible, setFirstSlideVisible] = useState(true)

  const fetchContracts = async (ids) => {
    setLoading(true)
    try {
      const response = await POST(urls.contracts, { body: { ids } })
      setLoading(false)
      if (response) setData(response)
    } catch (error) {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!contracts || !Array.isArray(contracts)) return
    fetchContracts(contracts.map(c => c.id))
  }, [contracts])

  return (
    <section className='newWarehouse --animated'>
      <div className='slides'>
        {firstSlideVisible
          ? <FirstSlide setFirstSlideVisible={setFirstSlideVisible} />
          : pinSlideVisible
            ? <PinSlide setPinSlideVisible={setPinSlideVisible} />
            : isLoading
              ? <div />
              : <DetailsSlide
                body={data}
                setFirstSlideVisible={setFirstSlideVisible}
              />
        }
      </div>
    </section>
  )
}

export default NewWarehouse