import { selectChatUnreadMessages, updateUnreadMessages } from '../../../containers/Chats/chatSlice'
import { useHistory, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'

const Messages = () => {
  const unreadMessages = useSelector(selectChatUnreadMessages)
  const [isNotification, showNotification] = useState(false)

  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { push } = useHistory()

  useEffect(() => {
    if (pathname.includes('chat')) {
      dispatch(updateUnreadMessages(0))
      showNotification(false)
    }
  }, [dispatch, pathname])

  useEffect(() => {
    if (unreadMessages && !window.location.pathname.includes('chat')) {
      if (!('Notification' in window) || Notification.permission !== 'granted') {
        showNotification(true)
      }
    }
  }, [unreadMessages])

  useEffect(() => {
    if (isNotification > 0) {
      const timeout = setTimeout(() => showNotification(false), 6000)
      return () => clearTimeout(timeout)
    }
  }, [isNotification])

  return (
    isNotification
      ? <div className='notification'>
        <div className='notification__desc'>
          {unreadMessages === 1 ? 'Masz nieprzeczytaną wiadomość' : `Masz nieprzeczytane ${unreadMessages} wiadomości`}
        </div>
        <div
          className='notification__button --termina'
          onClick={() => { push('/chat/new') }}>Zobacz</div>
      </div>
      : null
  )
}

export default Messages