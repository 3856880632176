import { ReactComponent as Bulldozer } from '../../assets/bulldozer.svg'
import { selectMapOptions } from '../Maps/Map/mapSlice'
import { getDistance } from '../Maps/Map/mapUtils'
import { selectPlace } from './mapDetailsSlice'
import Skeleton from 'react-loading-skeleton'
import MmRating from 'components/MmRating'
import { useSelector } from 'react-redux'

const DetailsLocation = ({ succeed }) => {
  const { street, estateNumber, mm_score, localNumber, city, constructionDate, gpslatitude, gpslongitude } = useSelector(selectPlace)
  const { position } = useSelector(selectMapOptions)

  return (
    <div className='details__location'>
      <div className='location__main'>
        {succeed ? <>
          <div className='location__title --termina'>{city}, ul.{street} {estateNumber}{localNumber ? `/${localNumber}` : ''}</div>
          {constructionDate
            ? <div className='location__construction'>
              <div className='location__construction__top center'>
                <Bulldozer />
                <div>Plac w budowie</div>
              </div>
              <div className='location__construction__bottom'>Dostępny od {constructionDate}</div>
            </div>
            : <div className='location__address center'>
              <MmRating number={mm_score} />
            </div>}
          {(position && succeed) && <div className='location__distance'>
            {constructionDate ? 'W budowie, ' : ''}{getDistance(position, [gpslatitude || 51.666, gpslongitude || 19.389]).toFixed(1)}km od Ciebie
          </div>}
        </> : <Skeleton
          count={2}
          containerClassName='skeleton__desc' />}
      </div>
    </div>
  )
}

export default DetailsLocation