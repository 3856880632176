import { selectBasketTime, resetTime } from 'containers/Basket/basketSlice'
import { useReduxDispatch, useReduxSelector } from 'store'
import Warning from 'containers/Header/WarningPopup'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import TimerContext from './Context'

interface TimerProviderProps {
  children: React.ReactNode
}

const INIT_TIME = 180

const TimerProvider: React.FC<TimerProviderProps> = ({ children }) => {
  const startTime = useReduxSelector(selectBasketTime)
  const [time, setTime] = useState(-1)

  const dispatch = useReduxDispatch()
  const { replace } = useHistory()


  useEffect(() => {
    setTime(startTime ? INIT_TIME : -1)
  }, [startTime])

  useEffect(() => {
    if (time > 0) {
      const resetCounter = () => {
        dispatch(resetTime())
      }
      window.addEventListener('click', resetCounter)
      return () => {
        window.removeEventListener('click', resetCounter)
      }
    }
  }, [dispatch, time])

  useEffect(() => {
    if (time !== -1 && startTime > 0) {
      const interval = setInterval(() => {
        const newTime = INIT_TIME - ((Date.now() - startTime) / 1000)
        if (newTime < 1) {
          replace('/map')
          setTime(-1)
        } else {
          setTime(newTime)
        }
      }, 1000)
      return () => {
        clearInterval(interval)
      }
    }
  }, [replace, startTime, time])

  return (
    <TimerContext.Provider value={time}>
      {(time > 0 && time < 9) && <Warning time={time} />}
      {children}
    </TimerContext.Provider>
  )
}

export default TimerProvider