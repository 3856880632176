import { selectBasketToPay } from 'containers/Basket/basketSlice'
import { getDateString, getRangeString } from 'utils/date'
import { displayPrice } from 'utils/string'
import { Fragment, useState } from 'react'
import { useSelector } from 'react-redux'

const MagSection = ({ identifier, placeId, locks, address, termiateDays, size, name, start, end, rangeDate }) => {
  const { street, estateNumber, localNumber, city, province, gpslatitude, gpslongitude } = address || {}
  const { area, internalHeight, internalLength, internalWidth } = size || {}
  const [isExpanded, expand] = useState(false)
  const toPay = useSelector(selectBasketToPay)

  const renderItem = ({ text, price, depositAmount }, idx) => {
    return <Fragment key={idx}>
      <div>
        <span>{text}: </span>
        <span>{displayPrice(depositAmount ? (Number(price) - Number(depositAmount)) : price)}</span>
      </div>
      {depositAmount && <div>
        <span>Kaucja: </span>
        <span>{displayPrice(depositAmount)}</span>
      </div>}
    </Fragment>
  }

  const renderLink = (id, name, isFirst) => {
    return <span
      key={id}
      className='link'
      onClick={() => {
        const element = document.querySelector(`#lock_${id}`)
        if (element) element.scrollIntoView({ behavior: 'smooth' })
      }}>
      {isFirst ? '' : ', '}{name}
    </span>
  }

  return (
    <div>
      <div className='list__header element__title'>Magazyn</div>
      <div className='list__element --big'>
        <div className='group'>
          <div className='element__title'>{name}</div>
          <i
            className='icon icon--help'
            onClick={() => {
              expand(e => !e)
            }}
          />
        </div>
        <div className={`hidableElement --${isExpanded ? 'visible' : 'hidden'}`}>
          <div className='element__desc'>
            <div>Powierzchnia: {Number(area)}m²</div>
            <div>Długość: {internalLength}m</div>
            <div>Szerokość: {internalWidth}m</div>
            <div>Wysokość: {internalHeight}m</div>
          </div>
        </div>

        <div className='element__title'>Lokalizacja</div>
        <div className='group'>
          <div
            className='element__desc link'
            onClick={() => {
              window.open(`https://www.google.com/maps/search/?api=1&query=${gpslatitude},${gpslongitude}`)
            }}>
            <div>{street} {estateNumber}{localNumber ? `/${localNumber}` : ''}, pow. {province}, {city}</div>
          </div>
        </div>

        <div className='element__title'>Czas wynajęcia</div>
        <div className='element__desc'>
          <div>{getRangeString(rangeDate)}</div>
          <div>{getDateString(start)} → {end ? getDateString(end) : '∞'}</div>
          <div>Okres wypowiedzenia: {termiateDays} dni</div>
        </div>

        <div className='element__title'>Rodzaj otwarcia</div>
        <div className='element__desc'>
          <div>
            <span>Placu: </span>
            {locks.map(({ identifier: id, ownerId, related, name }, idx) => {
              if (ownerId || !related?.some(r => r.object === 'Place' && r.id === placeId)) return null
              return renderLink(id, name, !idx)
            })}
          </div>
          <div>
            <span>Magazynu: </span>
            {locks.map(({ identifier: id, ownerId, related, name }, idx) => {
              if (!ownerId && !related?.some(r => r.cartId === identifier)) return null
              return renderLink(id, name, !idx)
            })}
          </div>
        </div>

        <div className='element__title'>Koszty</div>
        {toPay && <div className='element__desc'>
          {toPay[identifier]?.to_pay?.list?.map(renderItem)}
          {toPay[identifier]?.to_pay_monthly?.list?.map(renderItem)}
        </div>}
      </div>
    </div>
  )
}

export default MagSection